import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import useAccounntsLogic from "../../lookup/accounts/accounts.logic";
import { useDecodeJWT } from "../../hook/use-decodeJWT";
import { MySettingDto } from "../../../models/my-setting-dto";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { red } from "@mui/material/colors";

interface AccountSettingsProps {
  mySetting: MySettingDto;
  handleInputChange: (updatedData: any) => void;
  formErrors: any;
}

const AccountSettings: React.FC<AccountSettingsProps> = ({
  mySetting,
  handleInputChange,
  formErrors,
}) => {
  const { error, account } = useAccounntsLogic();
  const [accounts, setAccounts] = useState<any>([]);
  const token = useDecodeJWT();

  useEffect(() => {
    getAccounts();
  }, []);

  const getAccounts = async () => {
    try {
      const param = {
        userId: token.UserId,
        companyID: 0,
        useIncludeAllItemrId: false,
      };
      const response = await account(param);
      if (response.data && response.data.data) {
        setAccounts(response.data.data);
      } else {
        setAccounts([]);
      }
    } catch (err) {
      setAccounts([]);
    }
  };

  interface MenuStyles {
    maxHeight: string;
  }

  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
    maxHeight: "200px", // Default max height for desktop
  });

  return (
    <Box className="p16 border rounded">
      <Typography className="sub_header">Account settings</Typography>
      <Grid container spacing={2}>
        <Grid item lg={12 / 5} md={6} xs={6}>
          <TextField
            id="txtLogonName"
            name="logonName"
            label="User Id"
            variant="outlined"
            className="w-100"
            size="small"
            defaultValue={mySetting.logonName}
            onChange={handleInputChange}
            error={!!formErrors.logonName}
            InputProps={{
              endAdornment: !!formErrors.logonName && (
                <InputAdornment position="end" disablePointerEvents={true}>
                  <InfoRoundedIcon
                    style={{ color: red[500] }}
                    fontSize="small"
                  ></InfoRoundedIcon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item lg={12 / 5} md={6} xs={6}>
          <TextField
            id="txtPassword"
            name="password"
            label="New Password"
            variant="outlined"
            className="w-100"
            size="small"
            type="password"
            defaultValue={mySetting.password}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item lg={12 / 5} md={6} xs={6}>
          <TextField
            id="txtFirstName"
            name="firstName"
            label="First Name"
            variant="outlined"
            className="w-100"
            size="small"
            defaultValue={mySetting.firstName}
            onChange={handleInputChange}
            error={!!formErrors.firstName}
            InputProps={{
              endAdornment: !!formErrors.firstName && (
                <InputAdornment position="end" disablePointerEvents={true}>
                  <InfoRoundedIcon
                    style={{ color: red[500] }}
                    fontSize="small"
                  ></InfoRoundedIcon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item lg={12 / 5} md={6} xs={6}>
          <TextField
            id="txtlastName"
            name="lastName"
            label="Last Name"
            variant="outlined"
            className="w-100"
            size="small"
            defaultValue={mySetting.lastName}
            onChange={handleInputChange}
            error={!!formErrors.lastName}
            InputProps={{
              endAdornment: !!formErrors.lastName && (
                <InputAdornment position="end" disablePointerEvents={true}>
                  <InfoRoundedIcon
                    style={{ color: red[500] }}
                    fontSize="small"
                  ></InfoRoundedIcon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          lg={12 / 5}
          md={6}
          xs={6}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
            },
          }}
        ></Grid>
      </Grid>
      <Grid container spacing={2} className="mt-0">
        <Grid item lg={4} md={4} sm={6} xs={6}>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-select-small-label">Bill To</InputLabel>
            <Select
              className="w-100"
              labelId="demo-select-small-label"
              id="ddBillToType"
              label="Bill To"
              name="billToType"
              defaultValue={mySetting.billToType}
              onChange={handleInputChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },

                PaperProps: {
                  style: menuStyles,
                },
              }}
            >
              <MenuItem value="S">SHIPPER</MenuItem>
              <MenuItem value="C">CONSIGNEE</MenuItem>
              <MenuItem value="3">3RD PARTY</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={6}>
          <FormControl size="small" fullWidth>
            <InputLabel id="Bill Acct">Bill Acct # </InputLabel>
            <Select
              className="w-100"
              id="ddBillAccount"
              label="Bill Acct #"
              name="accountNumber"
              defaultValue={mySetting.accountNumber}
              onChange={handleInputChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },

                PaperProps: {
                  style: menuStyles,
                },
              }}
            >
              {accounts.length > 0 &&
                accounts.map((account: any) => (
                  <MenuItem
                    key={account.userAccountRowGUID}
                    value={account.accountNumber}
                  >
                    {account.displayName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-select-small-label">Shipper Acct #</InputLabel>
            <Select
              className="w-100"
              labelId="demo-select-small-label"
              id="ddAccountNumberShipper"
              label="Shipper Acct #"
              name="accountNumberShipper"
              defaultValue={mySetting.accountNumberShipper}
              onChange={handleInputChange}
              error={!!formErrors.accountNumberShipper}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },

                PaperProps: {
                  style: menuStyles,
                },
              }}
            >
              {accounts.length > 0 &&
                accounts.map((account: any) => (
                  <MenuItem
                    key={account.userAccountRowGUID}
                    value={account.accountNumber}
                  >
                    {account.displayName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountSettings;
