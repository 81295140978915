import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { LongFormRequestDTO } from "../../models/long-form-request-item-dto";


// Action Types for Default Data
const LONGFORM_ORDER_REQUEST = "LONGFORM_ORDER_REQUEST";
const LONGFORM_ORDER_SUCCESS = "LONGFORM_ORDER_SUCCESS";
const LONGFORM_ORDER_FAILURE = "LONGFORM_ORDER_FAILURE";


// Action Creators for Default Data
export const longFormOrderRequest = () => ({
    type: LONGFORM_ORDER_REQUEST,
  });
  
  export const longFormOrderSuccess = () => ({
    type: LONGFORM_ORDER_SUCCESS,
  });
  
  export const longFormOrderFailure = (error: string) => ({
    type: LONGFORM_ORDER_FAILURE,
    payload: error,
  });



// Initial State for  Active Order
export interface LongFormOrderState {
    isAuthenticated: boolean;
    LongFormOrderLoading: boolean;
    LongFormOrderError: string | null;
  }


  const initialAuthState: LongFormOrderState = {
    isAuthenticated: false,
    LongFormOrderLoading: false,
    LongFormOrderError: null,
  };
  

  // Define the interface for the API response
interface LongFormOrderResponse {
    success: boolean;
    message: string;
    data: any;
    statusCode: string;
  }
  
  // Reducer for Authentication
  const longFormOrderReducer = (state = initialAuthState, action: any): LongFormOrderState => {
    switch (action.type) {
      case LONGFORM_ORDER_REQUEST:
        return { ...state, LongFormOrderLoading: true, LongFormOrderError: null };
      case LONGFORM_ORDER_SUCCESS:
        return { ...state, LongFormOrderLoading: false, isAuthenticated: true };
      case LONGFORM_ORDER_FAILURE:
        return { ...state, LongFormOrderLoading: false, LongFormOrderError: action.payload };
      default:
        return state;
    }
  };
  
  // Thunk for Active Order
  export const LongFormOrder = (param: LongFormRequestDTO) => async (dispatch: Dispatch) => {
      dispatch(longFormOrderRequest());
      try {
        const response = await axiosInstance.post(
          `${API_BASE_URL}/api/Orders/CreateOrder`,
          param
      );
        // Assuming  Active Order was successful if no error occurred
        dispatch(longFormOrderSuccess());
        const data: LongFormOrderResponse = response.data;
        // Store token in localStorage
         if (response.status === 200 && data.success) {
          // Store token in localStorage
          return { data };
        } else {
          // Handle different status codes if necessary
          dispatch(longFormOrderFailure(data.message));
        }
        return response.data;
      } catch (error: any) {
        if(error.response.data.message){
          dispatch(longFormOrderFailure(error.response.data.message));
        }else{
          dispatch(longFormOrderFailure(error.message));
        }

      }
    };


    export default longFormOrderReducer;  
  