import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { getDangerousGoods } from "./track-order-get-dangerous-good.reducer";

const useDangerousGoodsLogic = () => {
  const dispatch = useDispatch();
  const { dangerousGoodsLoading, dangerousGoodsError } = useSelector((state: RootState) => state.getDangerousGoods);

  useEffect(() => {
    if (dangerousGoodsError) {
      // dispatch(showNotification('Login failed: ' + error, 'error'));
    }
  }, [dangerousGoodsError, dispatch]);

  const handleGetDangerousGoods = async (param: {
    shipmentGUID: string;
  }) => {
    try {
      const response = await dispatch(getDangerousGoods(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { dangerousGoodsLoading, dangerousGoodsError, getDangerousGoods: handleGetDangerousGoods };
};

export default useDangerousGoodsLogic;