import {
  Box,
  TextField,
  Typography,
  Button,
  Toolbar,
  Alert,
  Paper,
  Container,
  Grid,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import trackordericon from "../../assets/images/trackorder/img_icon_left.svg";

import AssignmentIcon from "@mui/icons-material/Assignment";
import React, { useEffect, useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { TrackOrderItemDto } from "../../models/track-order-item-dto";
import PlannedItinerary from "../../shared/components/track-order/PlannedItinerary";
import useUnAuthTrackLogic from "./track-order.unauth.logic";
import AuthHeader from "../../shared/components/AuthHeader";
import Footer from "../../shared/components/Footer";
import Loader from "../../shared/util/loader";
import {
  GoogleMap,
  MarkerF,
  OverlayView,
  useJsApiLoader,
} from "@react-google-maps/api";
import { MAX_MAP_API_KEY, ENCRYPT_DECRYPT_KEY } from "../../config/constants";
import CryptoJS from 'crypto-js';

const TrackOrderWithoutLogin: React.FC = () => {
  const [track, setTrack] = useState("");
  // eslint-disable-next-line
  const [shipmentGUID, setshipmentGUID] = useState("");
  const [trackFormErrors, setTrackFormErrors] = useState<string | null>(null);
  const [trackDetails, setTrackDetails] = useState<TrackOrderItemDto | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const { trackError, trackorder } = useUnAuthTrackLogic();
  const [showInfo, setShowInfo] = useState(false);

  const getTrackDetails = async (
    TrackingNumber: string,
    ShipmentGUID: string
  ) => {
    try {
      setIsLoading(true);
      const param = {
        trackingNumber: TrackingNumber,
        shipmentGUID: ShipmentGUID,
      };

      const response = await trackorder(param);
      if (response.data && response.data.data) {
        setTrackDetails(response.data.data);
      } else {
        setTrackFormErrors(
          "Invalid Tracking Number. Please ensure the correct tracking number is entered."
        );
        setTrackDetails(null);
      }
      setIsLoading(false);
    } catch {
      setTrackFormErrors(trackError);
      setIsLoading(false);
    }
  };

  const handleChangeTrack = (e: any) => {
    setTrackFormErrors(null);
    setTrack(e.target.value);
  };

  const validateTrack = () => {
    let errors = "";
    let isValid = true;

    if (!track) {
      errors =
        "Invalid Tracking Number. Please ensure the correct tracking number is entered.";
      isValid = false;
    }

    setTrackFormErrors(errors);
    return isValid;
  };

  const handleSubmitTrack = () => {
    if (!validateTrack()) {
      setTrackDetails(null);
      return;
    } else {
      setTrackFormErrors(null);
      getTrackDetails(track, shipmentGUID);

      const url = new URL(window.location.href);
      url.searchParams.set("TrackingNumber", track);

      window.history.pushState({}, "", url);
    }
  };

  const base36Chars = "0123456789abcdefghijklmnopqrstuvwxyz";

  function base36Decode(input : string) {
      let output = [];
      for (let i = 0; i < input.length; i += 2) {
          let high = base36Chars.indexOf(input[i]);
          let low = base36Chars.indexOf(input[i + 1]);
          output.push((high << 4) | low);
      }
      return new Uint8Array(output);
  }

  function decryptString(cipherText : string) {
        const key = CryptoJS.enc.Utf8.parse(ENCRYPT_DECRYPT_KEY as any);
        const iv = CryptoJS.enc.Utf8.parse('\0\0\0\0\0\0\0\0\0\0\0\0\0\0\0\0');

        const buffer = base36Decode(cipherText);

        const cipherParams = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.lib.WordArray.create(buffer)
        });

        const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const trackNumber = urlParams.get("TrackingNumber");
    const shipmentGUID = urlParams.get("ShipmentGUID");

    if (trackNumber) {
      let ShipmentGUID: string = "";

      const hasAlphabet = /[a-zA-Z]/.test(trackNumber);
      let trackingID = trackNumber;

      if (hasAlphabet) {
        trackingID = decryptString(trackNumber);
      }

      setTrack(trackingID);
      if (shipmentGUID) {
        ShipmentGUID = shipmentGUID;
      }
      getTrackDetails(trackingID, ShipmentGUID);
    }
    // eslint-disable-next-line
  }, []);

  const containerStyle = {
    width: "100%",
    height: "368px",
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: MAX_MAP_API_KEY,
  });

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  return (
    <>
      <div className="track-order Track-screen">
        <Box sx={{ display: "flex" }}>
          <AuthHeader />
          <Box component="main" className="w-100 Main-container" sx={{ p: 3 }}>
            <Toolbar className="login-header" />
            <div className="wrap">
              <Box className="d-flex align-items-center Track-section">
                <Box className="pr-64">
                  <Typography
                    variant="h4"
                    color="primary"
                    className="d-flex Main-Header"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    TRACK AN ORDER
                  </Typography>
                </Box>
                <Box className="d-flex flex-end WidthRightTrackinput">
                  <TextField
                    id="txtTrackingnumber"
                    name="Tracking Field"
                    label="Airbill #/Order #"
                    variant="outlined"
                    size="small"
                    className="d-flex mr-8 Trackinput"
                    value={track}
                    onChange={handleChangeTrack}
                    error={!!trackFormErrors}
                    // helperText={trackFormErrors}
                    InputProps={{
                      endAdornment: trackFormErrors ? (
                        <ErrorIcon color="error" />
                      ) : null,
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className="Order_btn Track_lbl_btn"
                    onClick={handleSubmitTrack}
                    id="btnTrackBtn"
                    startIcon={<img src={trackordericon} alt="icon left" />}
                  >
                    TRACK
                  </Button>
                </Box>
              </Box>

              {trackFormErrors && (
                <Box className="w-100">
                  <Alert severity="error" variant="filled" id="txtTouError">
                    {trackFormErrors}
                  </Alert>
                </Box>
              )}

              {isLoading ? (
                <Box className="d-flex justify-content-center align-items-center vh-100">
                  <Loader />
                </Box>
              ) : (
                trackDetails && (
                  <Box>
                    <Box>
                      <Box className="d-flex w-100 Track_info_lbl">
                        <AssignmentIcon
                          color="primary"
                          className="Assignment_icon"
                        />
                        <Typography
                          color="primary"
                          className="d-flex w-100 Status_label"
                        >
                          {trackDetails.statusDisplay}
                        </Typography>
                      </Box>
                      <Container className="d-flex w-100 padding0 maxwidth100">
                        {trackDetails.showMap && (
                          <>
                            <Grid container spacing={2}>
                              <Grid item lg={4} md={12} sm={12} xs={12}>
                                <Box className="d-flex w-100 Map-view">
                                  {isLoaded && (
                                    <GoogleMap
                                      mapContainerStyle={containerStyle}
                                      center={{
                                        lat: trackDetails.mapScriptDto.latitude,
                                        lng: trackDetails.mapScriptDto
                                          .longitude,
                                      }}
                                      zoom={14}
                                      options={mapOptions}
                                    >
                                      <MarkerF
                                        position={{
                                          lat: trackDetails.mapScriptDto
                                            .latitude,
                                          lng: trackDetails.mapScriptDto
                                            .longitude,
                                        }}
                                        onMouseOver={() => setShowInfo(true)}
                                        onMouseOut={() => setShowInfo(false)}
                                      />
                                      {showInfo && (
                                        <OverlayView
                                          position={{
                                            lat: trackDetails.mapScriptDto
                                              .latitude,
                                            lng: trackDetails.mapScriptDto
                                              .longitude,
                                          }}
                                          mapPaneName={
                                            OverlayView.OVERLAY_MOUSE_TARGET
                                          }
                                        >
                                          <div
                                            style={{
                                              background: "white",
                                              whiteSpace: "nowrap",
                                              padding: "5px",
                                              display: "inline-block",
                                              fontSize: "16px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              {
                                                trackDetails.mapScriptDto
                                                  .pushPinDescription
                                              }
                                            </span>
                                          </div>
                                        </OverlayView>
                                      )}
                                    </GoogleMap>
                                  )}
                                </Box>
                              </Grid>
                              <Grid item lg={4} md={6} sm={6} xs={12}>
                                <Box
                                  className="d-flex flex-column Map-view p16"
                                  sx={{ height: "100%" }}
                                >
                                  <Box className="d-flex flex-column pb-16 pl-8 pr-8">
                                    <Typography className="track_lbl">
                                      PICKUP
                                    </Typography>
                                    <Typography className="comp_name">
                                      {trackDetails.pickupCityName
                                        ? trackDetails.pickupCityName.toUpperCase()
                                        : ""}
                                      ,{" "}
                                      {trackDetails.pickupStateProvID
                                        ? trackDetails.pickupStateProvID.toUpperCase()
                                        : ""}{" "}
                                      {trackDetails.pickupPostalCode}
                                    </Typography>
                                  </Box>
                                  <Box className="d-flex flex-column pb-16 pl-8 pr-8">
                                    <Typography
                                      variant="body2"
                                      className="track_lbl"
                                    >
                                      DELIVER TO
                                    </Typography>
                                    <Typography className="comp_name">
                                      {trackDetails.deliveryCityName
                                        ? trackDetails.deliveryCityName.toUpperCase()
                                        : ""}
                                      ,{" "}
                                      {trackDetails.deliveryStateProvID
                                        ? trackDetails.deliveryStateProvID.toUpperCase()
                                        : ""}{" "}
                                      {trackDetails.deliveryPostalCode}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item lg={4} md={6} sm={6} xs={12}>
                                <Box
                                  className="d-flex flex-column Map-view p16"
                                  sx={{ height: "100%" }}
                                >
                                  <Box className="d-flex gap-4 mb-8">
                                    <Box className="d-flex flex-1 flex-column align-items-start pl-8 pr-8">
                                      <Typography className="track_lbl">
                                        DATE
                                      </Typography>
                                      <Typography>
                                        {trackDetails.orderDateTimeDisplay}
                                      </Typography>
                                    </Box>

                                    <Box className="d-flex flex-column align-items-start pl-8 pr-8">
                                      <Typography className="track_lbl">
                                        PIECES
                                      </Typography>
                                      <Typography className="other_dtl">
                                        {trackDetails.pieces}
                                      </Typography>
                                    </Box>

                                    <Box className="d-flex flex-column align-items-start pl-8 pr-8">
                                      <Typography className="track_lbl">
                                        WEIGHT
                                      </Typography>
                                      <Typography className="other_dtl">
                                        {trackDetails.weight}{" "}
                                        {trackDetails.weightUOM}
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Box className="d-flex flex-column align-items-start pl-8 pr-8 mb-8">
                                    <Typography className="track_lbl">
                                      ORDER BY
                                    </Typography>
                                    <Typography className="other_dtl">
                                      {trackDetails.orderByName}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Container>
                    </Box>

                    <Box
                      className="Route_selection mb-8 mt-8"
                      sx={{ width: "100%" }}
                    >
                      <Paper
                        className="p16 Track-route-grid"
                        sx={{ width: "100%" }}
                      >
                        <Typography variant="h6" className="sub-track-header">
                          Planned Itinerary
                        </Typography>
                        <PlannedItinerary itinerary={trackDetails.itinerary} />
                      </Paper>
                    </Box>
                  </Box>
                )
              )}
            </div>
          </Box>
        </Box>
      </div>
      <Footer />
    </>
  );
};

export default TrackOrderWithoutLogin;
