import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { MultiOrderRequestDto } from "../../models/multi-order-item-dto";


// Action Types for Default Data
const MULTI_ORDER_REQUEST = "MULTI_ORDER_REQUEST";
const MULTI_ORDER_SUCCESS = "MULTI_ORDER_SUCCESS";
const MULTI_ORDER_FAILURE = "MULTI_ORDER_FAILURE";


// Action Creators for Default Data
export const multiOrderRequest = () => ({
    type: MULTI_ORDER_REQUEST,
  });
  
  export const multiOrderSuccess = () => ({
    type: MULTI_ORDER_SUCCESS,
  });
  
  export const multiOrderFailure = (error: string) => ({
    type: MULTI_ORDER_FAILURE,
    payload: error,
  });



// Initial State for  Active Order
export interface MultiOrderState {
    isAuthenticated: boolean;
    MultiOrderLoading: boolean;
    MultiOrderError: string | null;
  }


  const initialAuthState: MultiOrderState = {
    isAuthenticated: false,
    MultiOrderLoading: false,
    MultiOrderError: null,
  };
  

  // Define the interface for the API response
interface MultiOrderResponse {
    success: boolean;
    message: string;
    data: any;
    statusCode: string;
  }
  
  // Reducer for Authentication
  const multiOrderReducer = (state = initialAuthState, action: any): MultiOrderState => {
    switch (action.type) {
      case MULTI_ORDER_REQUEST:
        return { ...state, MultiOrderLoading: true, MultiOrderError: null };
      case MULTI_ORDER_SUCCESS:
        return { ...state, MultiOrderLoading: false, isAuthenticated: true };
      case MULTI_ORDER_FAILURE:
        return { ...state, MultiOrderLoading: false, MultiOrderError: action.payload };
      default:
        return state;
    }
  };
  
  // Thunk for Active Order
  export const MultiOrder = (param: MultiOrderRequestDto) => async (dispatch: Dispatch) => {
      dispatch(multiOrderRequest());
      try {
        const response = await axiosInstance.post(
          `${API_BASE_URL}/api/Orders/CreateMultipleOrder`,
          param
      );
        // Assuming  Active Order was successful if no error occurred
        dispatch(multiOrderSuccess());
        const data: MultiOrderResponse = response.data;
        // Store token in localStorage
         if (response.status === 200 && data.success) {
          // Store token in localStorage
          return { data };
        } else {
          // Handle different status codes if necessary
          dispatch(multiOrderFailure(data.message));
        }
        return response.data;
      } catch (error: any) {
        if(error.response.data.message){
          dispatch(multiOrderFailure(error.response.data.message));
          return error.response.data;
        }else{
          dispatch(multiOrderFailure(error.message));
        }

      }
    };


    export default multiOrderReducer;  
  