import React, { useCallback, useEffect, useState } from "react";
import { MAX_COMPANY_ID } from "../../../config/constants";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  InputAdornment,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import calendarIcon from "../../../assets/images/logo/calendaricon.svg";
import useAccounntsLogic from "../../lookup/accounts/accounts.logic";
import { useDecodeJWT } from "../../hook/use-decodeJWT";
import {
  exportDataItemDTO,
  exportDataSearchCriteriaDto,
} from "../../../models/export-data-dto";
import useOfficeLogic from "../../lookup/office/office.logic";
import moment from "moment";
import useCountryLogic from "../../lookup/country/country.logic";
import { CountryItemDto } from "../../../models/country-item-dto";
import CustomDatePicker from "../CustomDatePicker";
interface MenuStyles {
  maxHeight: string;
}

interface ExportCriteriaProps {
  handleExportData: (searchFormState: exportDataSearchCriteriaDto) => void;
  returnToList: () => void;
  exportItemData: exportDataItemDTO;
}

const ExportDataSearchCriteria = ({
  handleExportData,
  returnToList,
  exportItemData,
}: ExportCriteriaProps) => {
  const { account } = useAccounntsLogic();
  const { officelookUpData, getOfficeLookUp } = useOfficeLogic();
  const { country } = useCountryLogic();
  const token = useDecodeJWT();
  const isMobile = useMediaQuery("(max-width:599px)");

  const [accounts, setAccounts] = useState<any>([]);
  const [expanded, setExpanded] = useState(false);
  const [countryData, setCountryData] = useState<CountryItemDto[]>([]);
  const [searchFormState, setSearchFormState] = useState({
    accountNumber: "",
    officeID: "All",
    reference: "",
    arriveDateTime_Start: null,
    arriveDateTime_End: null,
    pickUpStartDate: "",
    pickUpEndDate: "",
    deliveryStartDate: "",
    deliveryEndDate: "",
    pickupCityName: "",
    pickupCountryID: "All",
    deliveryCityName: "",
    deliveryCountryID: "All",
    pickupAirportID: "",
    recoverAirportID: "",
    pickupNumber: null,
    invoiceNumber: null,
    exportMasterID: null,
    companyID: MAX_COMPANY_ID,
    serviceCodeList: "",
    includeDeleted: false,
    includeOnlyScreened: false,
  });

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const [menuStyles] = useState<MenuStyles>({
    maxHeight: "220px", // Default max height for desktop
  });

  const handleTextFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setSearchFormState({
      ...searchFormState,
      [name]: value,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setSearchFormState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setSearchFormState({
      ...searchFormState,
      [name]: value,
    });
  };

  const handleSearch = () => {
    handleExportData(searchFormState as any);
  };
  const getAccounts = useCallback(async () => {
    try {
      const param = {
        userId: token.UserId,
        companyID: 0,
        useIncludeAllItemrId: false,
      };

      const response = await account(param);
      if (response.data && response.data.data) {
        let _data = response.data.data;
        // eslint-disable-next-line
        const isExist = _data.find(
          // eslint-disable-next-line
          (_item: any) => _item.accountNumber == exportItemData.parmAccountList
        );

        if (exportItemData?.parmAccountList && !isExist) {
          _data.push({
            accountNumber: exportItemData.parmAccountList,
            userAccountRowGUID: exportItemData.parmAccountList,
            displayName: exportItemData.parmAccountList,
          });
        }
        setAccounts(_data);
      } else {
        setAccounts([]);
      }
    } catch (err) {
      setAccounts([]);
    }
    // eslint-disable-next-line
  }, [token.UserId]);

  const getCountry = async () => {
    try {
      const param = {};
      const response = await country(param);
      if (response.data && response.data.data) {
        setCountryData(response.data.data);
      }
    } catch (err) {
      setCountryData([]);
    }
  };
  useEffect(() => {
    getCountry();
    getAccounts();
    // eslint-disable-next-line
  }, [getAccounts]);

  useEffect(() => {
    getCountry();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSearchFormState({
      ...searchFormState,
      accountNumber: exportItemData?.parmAccountList,
      pickUpStartDate: exportItemData?.pickUpStartDate
        ? moment(exportItemData?.pickUpStartDate).format("YYYY-MM-DD")
        : "",
      pickUpEndDate: exportItemData?.pickUpEndDate
        ? moment(exportItemData?.pickUpEndDate).format("YYYY-MM-DD")
        : "",
      deliveryStartDate: exportItemData?.deliveryStartDate
        ? moment(exportItemData?.deliveryStartDate).format("YYYY-MM-DD")
        : "",
      deliveryEndDate: exportItemData?.deliveryEndDate
        ? moment(exportItemData?.deliveryEndDate).format("YYYY-MM-DD")
        : "",
      pickupAirportID: exportItemData?.parmPickupAirportList,
      exportMasterID: exportItemData?.exportMasterID as any,
      serviceCodeList: exportItemData?.parmServiceCodeList || "",
    });
    // eslint-disable-next-line
  }, [exportItemData]);

  useEffect(() => {
    const officeData = async () => {
      await getOfficeLookUp();
    };
    if (!officelookUpData || officelookUpData.length === 0) {
      officeData();
    }
    // eslint-disable-next-line
  }, [officelookUpData]);
  return (
    <>
      <Box
        className="Header-main pt-16"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography className="Main-Header " variant="h4" color={"primary"}>
          {exportItemData?.name}
        </Typography>
        <Typography className="info-text" variant="h6">
          Enter search criteria for data source and press the EXPORT DATA button
          to export the data.
        </Typography>
      </Box>

      <Box className="d-flex flex-column w-100 border mt-2 p16 rounded mb-16">
        <Typography variant="h6" className="header-sub-title Search-lbl">
          {isMobile && (
            <>
              Export Criteria
              <Button onClick={toggleExpand} style={{ float: "right" }} id="btnExportCriteria">
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </Button>
            </>
          )}
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: {
              xs: "0px", // margin-left 16px on xs screens
            },
          }}
          className={isMobile && !expanded ? "hide-search-default" : ""}
        >
          <Grid item xs={6} sm={4} md={8 / 3} lg={8 / 3}>
            <FormControl size="small" className="w-100">
              <InputLabel id="demo-select-small-label">
                Account Number
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="ddEdAccountNumber"
                value={searchFormState?.accountNumber}
                label="Account Number"
                name="accountNumber"
                onChange={handleSelectChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: menuStyles,
                  },
                }}
              >
                {accounts.length > 0 &&
                  accounts.map((account: any) => (
                    <MenuItem
                      key={account.userAccountRowGUID}
                      value={account.accountNumber}
                    >
                      {account.displayName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={4} md={8 / 3} lg={8 / 3}>
            <FormControl size="small" className="w-100">
              <InputLabel id="demo-select-small-label">Office</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="ddEdOfficeId"
                value={searchFormState?.officeID}
                label="Office"
                name="officeID"
                onChange={handleSelectChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: menuStyles,
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
                {officelookUpData.length > 0 &&
                  officelookUpData.map((_ofcData: any) => (
                    <MenuItem key={_ofcData.officeId} value={_ofcData.officeId}>
                      {_ofcData.companyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4} md={8 / 3} lg={8 / 3}>
            <TextField
              size="small"
              id="txtEdReference"
              variant="outlined"
              value={searchFormState?.reference}
              onChange={handleTextFieldChange}
              label="Reference"
              name="reference"
              className="w-100"
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
            md={2}
            lg={2}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
            }}
          >
            <div className="small-cal-view">
              <CustomDatePicker
                id="txtEdArriveStartDate"
                handleDateChange={handleTextFieldChange as any}
                label="Arr. Start Date"
                name="arriveDateTime_Start"
                value={searchFormState?.arriveDateTime_Start}
                isClearable={true}
              />
            </div>

            {/* <TextField
              className="w-100"
              size="small"
              id="datetime-start-local"
              label="Arr. Start Date"
              type="date"
              name="arriveDateTime_Start"
              value={searchFormState?.arriveDateTime_Start}
              InputLabelProps={{ shrink: true }}
              onChange={handleTextFieldChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <img
                        src={calendarIcon}
                        alt="Calendar icon"
                        style={{ maxWidth: "100%" }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            /> */}
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            md={2}
            lg={2}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
            }}
          >
            <div className="small-cal-view">
              <CustomDatePicker
                id="txtEdArriveEndDate"
                handleDateChange={handleTextFieldChange as any}
                label="Arr. End Date"
                name="arriveDateTime_End"
                value={searchFormState?.arriveDateTime_End}
                isClearable={true}
              />
            </div>
            {/* <TextField
              className="w-100"
              size="small"
              id="datetime-end-local"
              label="Arr. End Date"
              type="date"
              name="arriveDateTime_End"
              value={searchFormState?.arriveDateTime_End}
              InputLabelProps={{ shrink: true }}
              onChange={handleTextFieldChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <img
                        src={calendarIcon}
                        alt="Calendar icon"
                        style={{ maxWidth: "100%" }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            /> */}
          </Grid>

          <Grid item xs={6} sm={3} md={3} lg={3}>
            <div className="small-cal-view">
              <CustomDatePicker
                id="txtEdPickUpStartDate"
                handleDateChange={handleTextFieldChange as any}
                label="PU Start Date"
                name="pickUpStartDate"
                value={searchFormState?.pickUpStartDate}
                isClearable={true}
              />
            </div>
          </Grid>
          {/* <TextField
              className="w-100"
              size="small"
              id="datetime-start-local"
              label="PU Start Date"
              type="date"
              name="pickUpStartDate"
              value={searchFormState?.pickUpStartDate}
              InputLabelProps={{ shrink: true }}
              onChange={handleTextFieldChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <img
                        src={calendarIcon}
                        alt="Calendar icon"
                        style={{ maxWidth: "100%" }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            /> */}

          <Grid item xs={6} sm={3} md={3} lg={3}>
            <div className="small-cal-view">
              <CustomDatePicker
                id="txtEdPickUpEndDate"
                handleDateChange={handleTextFieldChange as any}
                label="PU End Date"
                name="pickUpEndDate"
                value={searchFormState?.pickUpEndDate}
                isClearable={true}
              />
            </div>
            {/* <TextField
              className="w-100"
              size="small"
              id="datetime-end-local"
              label="PU End Date"
              type="date"
              name="pickUpEndDate"
              value={searchFormState?.pickUpEndDate}
              InputLabelProps={{ shrink: true }}
              onChange={handleTextFieldChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <img
                        src={calendarIcon}
                        alt="Calendar icon"
                        style={{ maxWidth: "100%" }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            /> */}
          </Grid>

          <Grid item xs={6} sm={3} md={3} lg={3}>
            <div className="small-cal-view">
              <CustomDatePicker
                id="txtEdDeliveryStartDate"
                handleDateChange={handleTextFieldChange as any}
                label="Del. Start Date"
                name="deliveryStartDate"
                value={searchFormState?.deliveryStartDate}
                isClearable={true}
              />
            </div>

            {/* <TextField
              className="w-100"
              size="small"
              id="datetime-start-local"
              label="Del. Start Date"
              type="date"
              name="deliveryStartDate"
              value={searchFormState?.deliveryStartDate}
              InputLabelProps={{ shrink: true }}
              onChange={handleTextFieldChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <img
                        src={calendarIcon}
                        alt="Calendar icon"
                        style={{ maxWidth: "100%" }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            /> */}
          </Grid>
          <Grid item xs={6} sm={3} md={3} lg={3}>
            <div className="small-cal-view">
              <CustomDatePicker
                id="txtEdDeliveryEndDate"
                handleDateChange={handleTextFieldChange as any}
                label="Del. End Date"
                name="deliveryEndDate"
                value={searchFormState?.deliveryEndDate}
                isClearable={true}
              />
            </div>
            {/* <TextField
              className="w-100"
              size="small"
              id="datetime-end-local"
              label="Del. End Date"
              type="date"
              name="deliveryEndDate"
              value={searchFormState?.deliveryEndDate}
              InputLabelProps={{ shrink: true }}
              onChange={handleTextFieldChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <img
                        src={calendarIcon}
                        alt="Calendar icon"
                        style={{ maxWidth: "100%" }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            /> */}
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            md={2}
            lg={2}
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
              },
            }}
          >
            <div className="small-cal-view">
              <CustomDatePicker
                id="txtEdArriveStartDateTime"
                handleDateChange={handleTextFieldChange as any}
                label="Arr. Start Date"
                name="arriveDateTime_Start"
                value={searchFormState?.arriveDateTime_Start}
                isClearable={true}
              />
            </div>
            {/* <TextField
              className="w-100"
              size="small"
              id="datetime-start-local"
              label="Arr. Start Date"
              type="date"
              name="arriveDateTime_Start"
              value={searchFormState?.arriveDateTime_Start}
              InputLabelProps={{ shrink: true }}
              onChange={handleTextFieldChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <img
                        src={calendarIcon}
                        alt="Calendar icon"
                        style={{ maxWidth: "100%" }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            /> */}
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            md={0}
            lg={0}
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
              },
            }}
          >
            <div className="small-cal-view">
              <CustomDatePicker
                handleDateChange={handleTextFieldChange as any}
                id="txtEdArriveEndDateTime"
                label="Arr. End Date"
                name="arriveDateTime_End"
                value={searchFormState?.arriveDateTime_End}
                isClearable={true}
              />
            </div>

            {/* <TextField
              className="w-100"
              size="small"
              id="datetime-end-local"
              label="Arr. End Date"
              type="date"
              name="arriveDateTime_End"
              value={searchFormState?.arriveDateTime_End}
              InputLabelProps={{ shrink: true }}
              onChange={handleTextFieldChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <img
                        src={calendarIcon}
                        alt="Calendar icon"
                        style={{ maxWidth: "100%" }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            /> */}
          </Grid>
          <Grid
            item
            xs={0}
            sm={6}
            md={3}
            lg={2}
            sx={{
              display: {
                xs: "none",
                sm: "block",
                md: "none",
                lg: "none",
              },
            }}
          ></Grid>
          <Grid item xs={6} sm={6} md={3} lg={4}>
            <TextField
              id="txtEdPickupCityName"
              size="small"
              variant="outlined"
              value={searchFormState?.pickupCityName}
              onChange={handleTextFieldChange}
              label="PU City"
              name="pickupCityName"
              className="w-100"
            />
          </Grid>

          <Grid item xs={6} sm={4} md={3} lg={2}>
            <FormControl size="small" className="w-100">
              <InputLabel id="demo-select-small-label">PU Ctry</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="ddEdPickupCountry"
                value={searchFormState?.pickupCountryID}
                label="PU Ctry"
                name="pickupCountryID"
                onChange={handleSelectChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: menuStyles,
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
                {countryData.length > 0 &&
                  countryData.map((country: any) => (
                    <MenuItem key={country.countryID} value={country.countryID} >
                      {country.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={0}
            lg={0}
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
              },
            }}
          >
            <TextField
              size="small"
              id="txtEdPickupAirport"
              variant="outlined"
              value={searchFormState?.pickupAirportID}
              onChange={handleTextFieldChange}
              label="PU A/P"
              name="pickupAirportID"
              className="w-100"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={4}>
            <TextField
              id="txtEdDeliveryCityName"
              size="small"
              variant="outlined"
              value={searchFormState?.deliveryCityName}
              onChange={handleTextFieldChange}
              label="Del. City"
              name="deliveryCityName"
              className="w-100"
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <FormControl size="small" className="w-100">
              <InputLabel id="demo-select-small-label">Del. Ctry</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="txtEdDeliveryCountry"
                value={searchFormState?.deliveryCountryID}
                label="Del. Ctry"
                name="deliveryCountryID"
                onChange={handleSelectChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: menuStyles,
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
                {countryData.length > 0 &&
                  countryData.map((country: any) => (
                    <MenuItem key={country.countryID} value={country.countryID}>
                      {country.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={0}
            lg={0}
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
              },
            }}
          >
            <TextField
              size="small"
              variant="outlined"
              value={searchFormState?.recoverAirportID}
              id="txtEdDeliveryAirport"
              onChange={handleTextFieldChange}
              label="Del. A/P"
              name="recoverAirportID"
              className="w-100"
            />
          </Grid>
          <Grid
            item
            xs={0}
            sm={0}
            md={6}
            lg={6}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
            }}
          >
            <TextField
              size="small"
              variant="outlined"
              id="txtEdPickupAirportId"
              value={searchFormState?.pickupAirportID}
              onChange={handleTextFieldChange}
              label="PU A/P"
              name="pickupAirportID"
              className="w-100"
            />
          </Grid>
          <Grid
            item
            xs={0}
            sm={0}
            md={6}
            lg={6}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
            }}
          >
            <TextField
              size="small"
              variant="outlined"
              id="txtEdRecoverAirportId"
              value={searchFormState?.recoverAirportID}
              onChange={handleTextFieldChange}
              label="Del. A/P"
              name="recoverAirportID"
              className="w-100"
            />
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={4}>
            <TextField
              size="small"
              variant="outlined"
              id="txtEdInvoiceNumber"
              value={searchFormState?.invoiceNumber || ""}
              onChange={handleTextFieldChange}
              label="Invoice #"
              name="invoiceNumber"
              className="w-100"
            />
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={4}>
            <TextField
              size="small"
              variant="outlined"
              id="txtEdServiceCodeList"
              value={searchFormState?.serviceCodeList}
              onChange={handleTextFieldChange}
              label="Services"
              name="serviceCodeList"
              className="w-100"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <TextField
              size="small"
              variant="outlined"
              id="txtEdPickupNumber"
              value={searchFormState?.pickupNumber || ""}
              onChange={handleTextFieldChange}
              label="Pickup #"
              name="pickupNumber"
              className="w-100"
            />
          </Grid>

          <Grid item lg={12} md={12} xs={12} sm={12}>
            <FormControlLabel
              sx={{ marginRight: "32px", color: "rgba:(0,0,0,0.87)" }}
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={searchFormState.includeOnlyScreened} //need to verify name
                  name="includeOnlyScreened"
                  id="cbEdIncludeOnlyScreened"
                />
              }
              label="Include Only Screened"
            />
            <FormControlLabel
              sx={{ color: "rgba:(0,0,0,0.87)" }}
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={searchFormState.includeDeleted} //need to verify name
                  name="includeDeleted Orders"
                  id="cbEdincludeDeleted"
                />
              }
              label="Include Deleted Orders"
            />
          </Grid>

          <Grid item className="d-flex List_btn">
            <Button
              onClick={returnToList}
              color="primary"
              variant="outlined"
              className="d-flex rounded me-2 pb-16 return-list-btn"
              id="btnEdReturnToList"
            >
              RETURN TO LIST
            </Button>

            <Button
              variant="contained"
              color="primary"
              className="Search_small_btn return-list-btn"
              onClick={handleSearch}
              id="btnEdExportData"
            >
              EXPORT DATA
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ExportDataSearchCriteria;
