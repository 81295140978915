import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import {
  saveSearchRequestDTO,
  SearchOrderItemDto,
} from "../../models/search-order-item-dto";
import { API_BASE_URL } from "../../config/constants";

// Action Types for  Search Order
const SEARCH_ORDERS_REQUEST = "SEARCH_ORDERS_REQUEST";
const SEARCH_ORDERS_SUCCESS = "SEARCH_ORDERS_SUCCESS";
const SEARCH_ORDERS_FAILURE = "SEARCH_ORDERS_FAILURE";

// Action Creators for  Search Order
export const searchOrdersRequest = () => ({
  type: SEARCH_ORDERS_REQUEST,
});

export const searchOrdersSuccess = () => ({
  type: SEARCH_ORDERS_SUCCESS,
});

export const searchOrdersFailure = (error: string) => ({
  type: SEARCH_ORDERS_FAILURE,
  payload: error,
});

// Initial State for  Search Order
export interface SearchOrdersState {
  isAuthenticated: boolean;
  searchOrderLoading: boolean;
  searchOrderError: string | null;
}

const initialAuthState: SearchOrdersState = {
  isAuthenticated: false,
  searchOrderLoading: false,
  searchOrderError: null,
};

// Define the interface for the API response
interface SearchOrdersResponse {
  success: boolean;
  message: string;
  totalCount: number;
  pageCount: number;
  data: Array<SearchOrderItemDto>;
  statusCode: string;
}

// Reducer for Authentication
const searchOrdersReducer = (
  state = initialAuthState,
  action: any
): SearchOrdersState => {
  switch (action.type) {
    case SEARCH_ORDERS_REQUEST:
      return { ...state, searchOrderLoading: true, searchOrderError: null };
    case SEARCH_ORDERS_SUCCESS:
      return { ...state, searchOrderLoading: false, isAuthenticated: true };
    case SEARCH_ORDERS_FAILURE:
      return {
        ...state,
        searchOrderLoading: false,
        searchOrderError: action.payload,
      };
    default:
      return state;
  }
};

// Thunk for Search Order
export const searchOrders =
  (param: {
    accountNumber?: string;
    startDate?: string | null;
    endDate?: string | null;
    trackingNumber?: string;
    contents?: string;
    pickupCityName?: string;
    pickupCountryId?: string;
    deliveryCityName?: string;
    deliveryCountryId?: string;
    reference?: string;
    reference2?: string;
    reference3?: string;
    reference4?: string;
    reference5?: string;
    referenceTypeId?: number;
    referenceTypeId2?: number;
    referenceTypeId3?: number;
    referenceTypeId4?: number;
    referenceTypeId5?: number;
    invoiceNumber?: number;
    orderByName?: string;
    orderStatus?: string;
    pickupPostalCode?: string;
    pickupStateProvId?: string;
    deliveryPostalCode?: string;
    deliveryStateProvId?: string;
    referenceTypeId6?: number;
    referenceTypeId7?: number;
    referenceTypeId8?: number;
    referenceTypeId9?: number;
    orderBy?: string;
    sorting?: string;
    pageSize?: number;
    pageNo?: number;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(searchOrdersRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/SearchOrders`,
        param
      );
      // Assuming  Search Order was successful if no error occurred
      dispatch(searchOrdersSuccess());
      const data: SearchOrdersResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(searchOrdersFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(searchOrdersFailure(error.response.data.message));
      } else {
        dispatch(searchOrdersFailure(error.message));
      }
    }
  };

export const deleteSavedSearch =
  (param: { CriteriaGUID: string | undefined }) =>
  async (dispatch: Dispatch) => {
    dispatch(searchOrdersRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/DeleteSearchOrders?CriteriaGUID=${param?.CriteriaGUID}`
        // {
        //   CriteriaGUID: param?.CriteriaGUID,
        // }
      );

      dispatch(searchOrdersSuccess());
      const data: any = response.data;

      if (response.status === 200 && data.success) {
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(searchOrdersFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.message) {
        dispatch(searchOrdersFailure(error?.response?.data?.message));
      } else {
        dispatch(searchOrdersFailure(error?.message));
      }
    }
  };
export const saveSearch =
  (param: saveSearchRequestDTO) => async (dispatch: Dispatch) => {
    dispatch(searchOrdersRequest());
    const { name, searchOrder, columnList } = param;

    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/SaveSearchOrder`,
        {
          name,
          searchOrder,
          columnList,
        }
      );

      dispatch(searchOrdersSuccess());
      const data: any = response.data;

      if (response.status === 200 && data.success) {
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(searchOrdersFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.message) {
        dispatch(searchOrdersFailure(error?.response?.data?.message));
      } else {
        dispatch(searchOrdersFailure(error?.message));
      }
    }
  };
export const savedSearchList =
  (param: { UserGUID: string | undefined }) => async (dispatch: Dispatch) => {
    dispatch(searchOrdersRequest());
    try {
      const response = await axiosInstance.get(
        `${API_BASE_URL}/api/Orders/GetSearchOrder?UserGUID=${param.UserGUID}`
      );

      dispatch(searchOrdersSuccess());
      const data: any = response.data;

      if (response.status === 200 && data.success) {
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(searchOrdersFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.message) {
        dispatch(searchOrdersFailure(error?.response?.data?.message));
      } else {
        dispatch(searchOrdersFailure(error?.message));
      }
    }
  };

export default searchOrdersReducer;
