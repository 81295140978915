import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { LongFormOrder } from "./long-form-order-create.reducer";
import { LongFormRequestDTO } from "../../models/long-form-request-item-dto";



const useLongFormOrderLogic = () => {
  const dispatch = useDispatch();
  const { LongFormOrderLoading, LongFormOrderError } = useSelector((state: RootState) => state.longFormOrder);

  useEffect(() => {
    if (LongFormOrderError) {
      // dispatch(showNotification('Active Order failed: ' + error, 'error'));
    }
  }, [LongFormOrderError, dispatch]);

  const handleLongFormOrder = async (param:LongFormRequestDTO ) => {
    try {
      const response = await dispatch(LongFormOrder(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { LongFormOrderLoading, LongFormOrderError, longFormOrder: handleLongFormOrder };
};

export default useLongFormOrderLogic;
