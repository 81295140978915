import axios from "axios";
import { API_BASE_URL } from "./constants";

let activeRequests = 0;

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    activeRequests++;

    const userData = localStorage.getItem("userDetails");
    const parseUserData = JSON.parse(userData || "{}");
    if (parseUserData && parseUserData.token) {
      const token = parseUserData.token;
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    activeRequests--;
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    activeRequests--;
    return response;
  },
  (error) => {
    activeRequests--;

    const { status } = error.response || {};
    if (status === 401) {
      localStorage.clear();
      window.location.replace("/"); // Redirect to login page on unauthorized error
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
