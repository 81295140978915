import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { BatchPrintsDto } from "../../models/batch-prints-dto";
// Action Types for  Batch Prints
const BATCH_PRINTS_REQUEST = "BATCH_PRINTS_REQUEST";
const BATCH_PRINTS_SUCCESS = "BATCH_PRINTS_SUCCESS";
const BATCH_PRINTS_FAILURE = "BATCH_PRINTS_FAILURE";

// Action Creators for  Batch Prints
export const batchPrintsRequest = () => ({
  type: BATCH_PRINTS_REQUEST,
});

export const batchPrintsSuccess = () => ({
  type: BATCH_PRINTS_SUCCESS,
});

export const batchPrintsFailure = (error: string) => ({
  type: BATCH_PRINTS_FAILURE,
  payload: error,
});

// Initial State for  Batch Prints
export interface BatchPrintsState {
  isAuthenticated: boolean;
  batchPrintsLoading: boolean;
  batchPrintsError: string | null;
}

const initialState: BatchPrintsState = {
  isAuthenticated: false,
  batchPrintsLoading: false,
  batchPrintsError: null,
};

// Define the interface for the API response
interface BatchPrintsResponse {
  success: boolean;
  message: string;
  totalCount: number;
  pageCount: number;
  data: Array<BatchPrintsDto>;
  statusCode: string;
}

// Reducer for Authentication
const batchPrintsReducer = (
  state = initialState,
  action: any
): BatchPrintsState => {
  switch (action.type) {
    case BATCH_PRINTS_REQUEST:
      return { ...state, batchPrintsLoading: true, batchPrintsError: null };
    case BATCH_PRINTS_SUCCESS:
      return { ...state, batchPrintsLoading: false, isAuthenticated: true };
    case BATCH_PRINTS_FAILURE:
      return {
        ...state,
        batchPrintsLoading: false,
        batchPrintsError: action.payload,
      };
    default:
      return state;
  }
};

// Handle the API for Batch Prints  with search and sorting
export const batchPrints =
  (param: BatchPrintsDto) => async (dispatch: Dispatch) => {
    dispatch(batchPrintsRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/GetBatchPrintShipLabel`,
        param
      );
      // Assuming  Batch Prints was successful if no error occurred
      dispatch(batchPrintsSuccess());
      const data: BatchPrintsResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(batchPrintsFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(batchPrintsFailure(error.response.data.message));
      } else {
        dispatch(batchPrintsFailure(error.message));
      }
    }
  };

export default batchPrintsReducer;
