import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import { MySettingItemDto } from "../../../models/long-form-item-dto";
import { useEffect, useState } from "react";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { red } from "@mui/material/colors";
interface DeliveryAddressProps {
  deliveryData: any;
  onDeliveryDataChange: (updatedData: any) => void;
  DefaultData: MySettingItemDto;
  formErrors:any;
  setFormErrors: (errors: any) => void;
  onShipperDataChange: (errors: any) => void;
}

const Shipper: React.FC<DeliveryAddressProps> = ({
  deliveryData,
  onDeliveryDataChange,
  DefaultData,
  formErrors,
  setFormErrors,
  onShipperDataChange
}) => {
  const [shipperName, setShipperName] = useState<string>("");
  const [shipperPhone, setShipperPhone] = useState<string>("");
  const [shipperEmail, setShipperEmail] = useState<string>("");
  const [notifyShipper, setNotifyShipper] = useState<boolean>(false);
  const errorControls = [
    {
      name:"shipperName",
      value:"shipperName"
    },
    {
      name:"shipperEmail",
      value:"shipperEmail"
    },
 
  ]

  useEffect(() => {
    setShipperName(DefaultData.firstName + " " + DefaultData.lastName);
    setShipperPhone(DefaultData.officeNumber);
    setShipperEmail(DefaultData.emailAddress);
    const updatedData = {
      shipperName:  DefaultData.firstName + " " + DefaultData.lastName,
      shipperPhone: DefaultData.officeNumber,
      shipperEmail: DefaultData.emailAddress,
      notifyShipper,
    };

    onShipperDataChange(updatedData);
    // onDeliveryDataChange(DefaultData);

  }, [DefaultData]);

  const debouncedHandleChange = debounce((name: string, value: string) => {
    onShipperDataChange({ [name]: value });
  }, 300); // Adjust debounce delay as needed

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // onDeliveryDataChange({ [name]: value });
    let errorChange:any = errorControls.find(ec => ec.name === name);
    if(errorChange){
      handleError(name);
    }
    switch (name) {
      case "shipperName":
        setShipperName(value);
        break;
      case "shipperPhone":
        setShipperPhone(value);
        break;
      case "shipperEmail":
        setShipperEmail(value);
        break;
      default:
        break;
    }
    const updatedData = {
      shipperName: name === "shipperName" ? value : shipperName,
      shipperPhone: name === "shipperPhone" ? value : shipperPhone,
      shipperEmail: name === "shipperEmail" ? value : shipperEmail,
      notifyShipper,
    };

    debouncedHandleChange(name, value);

    // Pass all updated shipper information data to parent component
    onShipperDataChange(updatedData);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setNotifyShipper(checked);
    onShipperDataChange({ [name]: checked });
  };

  const handleError = (name:string) =>{
    let updatedName = name;
    let errorChange:any = errorControls.find(ec => ec.name === name);
    if(errorChange.name === updatedName){
      updatedName = errorChange.value;
      if (formErrors[updatedName]) {
        setFormErrors((prevErrors: any) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[updatedName];
          return updatedErrors;
        });
      }
    }
  }


  return (
    <Box className="d-flex gap-4 mt-4 w-100 Divider pb-32 flex-col">
      <Box className="d-flex flex-column w-100 border p16 rounded">
        <Typography variant="h6" className="Sub-header pb-16">
          Shipper Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={3} xs={12} className="pt-0">
            <TextField
              label="Shipper Information"
              variant="outlined"
              name="shipperName"
              id="txtShipperName"
              onChange={handleChange}
              fullWidth
              margin="normal"
              value={shipperName}
              className="pt-0 mb-0"
              error={!!formErrors.shipperName}
              InputProps={{
                endAdornment: !!formErrors.shipperName && (
                  <InputAdornment position="end" disablePointerEvents={true}>
                    <InfoRoundedIcon
                      style={{ color: red[500] }}
                      fontSize="small"
                    ></InfoRoundedIcon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item lg={3} md={3} sm={3} xs={12} className="pt-0">
            <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              margin="normal"
               id="txtShipperPhonenumber"
              name="shipperPhone"
              onChange={handleChange}
              value={shipperPhone}
              className="mb-0"
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12} className="pt-0">
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              name="shipperEmail"
              id="txtShipperEmail"
              onChange={handleChange}
              value={shipperEmail}
              className="mb-0"
              error={!!formErrors.shipperEmail}
              InputProps={{
                endAdornment: !!formErrors.shipperEmail && (
                  <InputAdornment position="end" disablePointerEvents={true}>
                    <InfoRoundedIcon
                      style={{ color: red[500] }}
                      fontSize="small"
                    ></InfoRoundedIcon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item lg={3} md={3} sm={3} xs={12}>
            <FormControlLabel
              sx={{ height: "56px", display: "flex", alignItems: "center" }}
              control={
                <Checkbox
                  disabled={!deliveryData.willCall}
                  checked={notifyShipper}
                  id="cbShipperNotify"
                  onChange={handleCheckboxChange}
                />
              }
              label="Notify Shipper"
              name="notifyShipper"
              className="w-100 Search-lbl"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Shipper;
