import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import {
  activeOrders,
  recentDeliveries,
  filterOptions,
} from "./active-orders.reducer";

const useActiveOrdersLogic = () => {
  const dispatch = useDispatch();
  const { activeOrderLoading, activeOrderError } = useSelector(
    (state: RootState) => state.activeOrders
  );

  useEffect(() => {
    if (activeOrderError) {
      // dispatch(showNotification('Active Order failed: ' + error, 'error'));
    }
  }, [activeOrderError, dispatch]);

  const handleActiveOrders = async (param: {
    orderType: string;
    accountNumber: string;
    orderBy: string;
    sorting: string;
    pagesize: number;
    pageNo: number;
    days?: string | number | undefined | null;
    reference?: any;
    orderStatusDisplay?: any;
    deliveryCompanyName?: any;
    pickupCompanyName?: any;
    updatedDeliveryDateTime?: any;
  }) => {
    try {
      const response = await dispatch(activeOrders(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleFilterOptions = async (param: {
    orderType: string;
    accountNumber: string;
    orderBy: string;
    sorting: string;
    pagesize: number;
    pageNo: number;
    days?: string | number | undefined | null;
    orderStatusDisplay: any;
    deliveryCompanyName: any;
    pickupCompanyName: any;
    updatedDeliveryDateTime: any;
  }) => {
    try {
      const response = await dispatch(filterOptions(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleRecentDeliveries = async (param: {
    accountNumber: string;
    orderBy: string;
    sorting: string;
    pagesize: number;
    pageNo: number;
    days?: string | number | undefined | null;
  }) => {
    try {
      const response = await dispatch(recentDeliveries(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    activeOrderLoading,
    activeOrderError,
    activeOrders: handleActiveOrders,
    recentDeliveries: handleRecentDeliveries,
    filterOptions: handleFilterOptions,
  };
};

export default useActiveOrdersLogic;
