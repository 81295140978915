import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { DangerousGoodsItemDto } from "../../models/dangerous-goods-item-dto";

const DANGEROUS_GOODS_UPDATE_REQUEST = "DANGEROUS_GOODS_UPDATE_REQUEST";
const DANGEROUS_GOODS_UPDATE_SUCCESS = "DANGEROUS_GOODS_UPDATE_SUCCESS";
const DANGEROUS_GOODS_UPDATE_FAILURE = "DANGEROUS_GOODS_UPDATE_FAILURE";

export const updateDangerousGoodsRequest = () => ({
  type: DANGEROUS_GOODS_UPDATE_REQUEST,
});

export const updateDangerousGoodsSuccess = () => ({
  type: DANGEROUS_GOODS_UPDATE_SUCCESS,
});

export const updateDangerousGoodsFailure = (error: string) => ({
  type: DANGEROUS_GOODS_UPDATE_FAILURE,
  payload: error,
});

export interface UpdateDangerousGoodsState {
  isAuthenticated: boolean;
  dangerousGoodsUpdateLoading: boolean;
  dangerousGoodsUpdateError: string | null;
}

const initialAuthState: UpdateDangerousGoodsState = {
  isAuthenticated: false,
  dangerousGoodsUpdateLoading: false,
  dangerousGoodsUpdateError: null,
};

// Define the interface for the API response
interface GetDangerousGoodsResponse {
  success: boolean;
  message: string;
  data: string;
  statusCode: string;
}

// Reducer for Authentication
const updateDangerousGoodsReducer = (
  state = initialAuthState,
  action: any
): UpdateDangerousGoodsState => {
  switch (action.type) {
    case DANGEROUS_GOODS_UPDATE_REQUEST:
      return {
        ...state,
        dangerousGoodsUpdateLoading: true,
        dangerousGoodsUpdateError: null,
      };
    case DANGEROUS_GOODS_UPDATE_SUCCESS:
      return {
        ...state,
        dangerousGoodsUpdateLoading: false,
        isAuthenticated: true,
      };
    case DANGEROUS_GOODS_UPDATE_FAILURE:
      return {
        ...state,
        dangerousGoodsUpdateLoading: false,
        dangerousGoodsUpdateError: action.payload,
      };
    default:
      return state;
  }
};

export const updateDangerousGoods =
  (param: DangerousGoodsItemDto) => async (dispatch: Dispatch) => {
    dispatch(updateDangerousGoodsRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/orders/DangerousGoods/Update`,
        param
      );
      dispatch(updateDangerousGoodsSuccess());
      const data: GetDangerousGoodsResponse = response.data;
      if (response.status === 200 && data.success) {
        return { data };
      } else {
        dispatch(updateDangerousGoodsFailure(data.message));
        return { data };
      }
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(updateDangerousGoodsFailure(error.response.data.message));
        return error.response;
      } else {
        dispatch(updateDangerousGoodsFailure(error.message));
      }
    }
  };

export default updateDangerousGoodsReducer;
