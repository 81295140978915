import { Box, Alert, Typography } from "@mui/material";

const DisplayError = (props: any) => {
  const { formik } = props;
  const displayErrors = () => {
    const errors = formik.errors;
    const touched = formik.touched;
    const errorMessages: string[] = [];

    if (errors.accountNumber && touched.accountNumber) {
      errorMessages.push(errors.accountNumber);
    }
    if (errors.orderDateTime && touched.orderDateTime) {
      errorMessages.push(errors.orderDateTime);
    }
    if (errors.serviceID && touched.serviceID) {
      errorMessages.push(errors.serviceID);
    }
    if (errors.pickupPostalCode && touched.pickupPostalCode) {
      errorMessages.push(errors.pickupPostalCode);
    }
    if (errors.pickupReadyTime && touched.pickupReadyTime) {
      errorMessages.push(errors.pickupReadyTime);
    }

    if (errors.deliveryPostalCode && touched.deliveryPostalCode) {
      errorMessages.push(errors.deliveryPostalCode);
    }
    if (errors.requestedDeliveryTime && touched.requestedDeliveryTime) {
      errorMessages.push(errors.requestedDeliveryTime);
    }
    if (errors.pickupAddressCode && touched.pickupAddressCode) {
      errorMessages.push(errors.pickupAddressCode);
    }
    if (errors.deliveryAddressCode && touched.deliveryAddressCode) {
      errorMessages.push(errors.deliveryAddressCode);
    }

    if (errors.packageInfo && Array.isArray(errors.packageInfo)) {
      errors.packageInfo.forEach((itemError: any, index: any) => {
        if (itemError && typeof itemError === "object") {
          const { length, height, width } = formik.values.packageInfo[index];
          const combinedLength =
            parseInt(length) + parseInt(height) + parseInt(width);

          // Check individual and combined dimensions limits
          let maxAllowValue = 42;
          let maxCombinedLength = 90;
          if (formik.values.packageInfo[index].unit === "CM") {
            maxAllowValue = 106;
            maxCombinedLength = 228;
          }

          if (
            length > maxAllowValue ||
            height > maxAllowValue ||
            width > maxAllowValue
          ) {
            errorMessages.push(
              `Package info ${
                index + 1
              }: For an accurate estimation of arrival for a package of your size, please call an MNX Operations Center. Warning! Length, Width, and Height fields must have an individual limit no greater than 42 in (106 cm).`
            );
          } else if (combinedLength > maxCombinedLength) {
            errorMessages.push(
              `Package info ${
                index + 1
              }: For an accurate estimation of arrival for a package of your size, please call an MNX Operations Center. Length, Width, and Height fields must have a combined limit no greater than 90 in (228 cm).`
            );
          }

          Object.keys(itemError).forEach((key) => {
            if (key === "height" || key === "width" || key === "length") {
            } else if (
              touched.packageInfo &&
              touched.packageInfo[index] &&
              touched.packageInfo[index][key as keyof typeof itemError]
            ) {
              errorMessages.push(
                `Package info ${index + 1}: ${
                  itemError[key as keyof typeof itemError]
                }`
              );
            }
          });
          if (
            touched.packageInfo &&
            touched.packageInfo[index] &&
            (itemError?.height || itemError?.width || itemError?.length) &&
            (!length ||
              !width ||
              !height ||
              length === 0 ||
              width === 0 ||
              height === 0)
          ) {
            errorMessages.push(
              `Package info ${
                index + 1
              }: Please enter a numeric dimension greater than zero.`
            );
          }
        } else {
          const { length, height, width } = formik.values.packageInfo[index];
          //let maxAllowValue = 42;
          let maxCombinedLength = 90;
          if (formik.values.packageInfo[index].unit === "CM") {
            //  maxAllowValue = 106;
            maxCombinedLength = 228;
          }

          const combinedLength =
            parseInt(length) + parseInt(height) + parseInt(width);
          if (combinedLength > maxCombinedLength) {
            errorMessages.push(
              `Package info ${
                index + 1
              }: For an accurate estimation of arrival for a package of your size, please call an MNX Operations Center. Length, Width, and Height fields must have a combined limit no greater than 90 in (228 cm).`
            );
          }
        }
      });

      // Check for dimensions error message at packageInfo level
      if (errors.packageInfo && typeof errors.packageInfo === "string") {
        errorMessages.push(errors.packageInfo);
      }
    }

    return errorMessages.map((error, index) => (
      <Typography>{error}</Typography>
    ));
  };

  const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  return (
    <>
      {!isEmptyObject(displayErrors()) && (
        <Box className="w-100 mt-24 mb-24">
          <Alert variant="filled" severity="error" id="txtDeError">
            {displayErrors()}
          </Alert>
        </Box>
      )}
    </>
  );
};

export default DisplayError;
