import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import getAhawidget from "../../ahaWidget";

const withAhawidget = (WrappedComponent: React.ComponentType) => {
  return (props: any) => {
    const location = useLocation(); 

    useEffect(() => {
      getAhawidget();
    }, [location]); 

    return <WrappedComponent {...props} />;
  };
};

export default withAhawidget;
