import React, { useState, FC, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Box,
  TableRow,
  Checkbox,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../util/loader";
import editicon from "../../../assets/images/logo/edit-icon.svg";
import deleteicon from "../../../assets/images/logo/delete-icon.svg";
import EditNewPiecesDialog from "./EditNewPieceDialog";
import useDeleteShipmentPieceLogic from "../../lookup/shipment-piece/delete-shipment-piece/delete-shipment-piece.logic";
import { sortAndUpdateTheData } from "../../util/rate-estimate";
import { ShipmentPieceDto } from "../../../models/shipment-piece-dto";

// Define the prop types for the MyDialog component
interface MyDialogProps {
  open: boolean;
  handleClose: () => void;
  shipmentGUID: string;
  setPiecesData: React.Dispatch<React.SetStateAction<ShipmentPieceDto[]>>;
  piecesData: ShipmentPieceDto[];
  getShipmentPieceList: () => Promise<void>; // Function to trigger data fetch
  accessModule?: string;
}

type Order = "desc" | "asc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof ShipmentPieceDto;
  label: string;
  numeric: boolean;
  minwidth: string;
}

const headCells: HeadCell[] = [
  {
    id: "contents",
    numeric: false,
    disablePadding: false,
    label: "Contents",
    minwidth: "200",
  },
  {
    id: "reference",
    numeric: false,
    disablePadding: false,
    label: "Refer",
    minwidth: "110",
  },
  {
    id: "weight",
    numeric: false,
    disablePadding: false,
    label: "Weight",
    minwidth: "110",
  },
  {
    id: "length",
    numeric: false,
    disablePadding: false,
    label: "Size",
    minwidth: "180",
  },
  {
    id: "packagingType",
    numeric: false,
    disablePadding: false,
    label: "Packaging",
    minwidth: "180",
  },
  {
    id: "dimWeight",
    numeric: false,
    disablePadding: false,
    label: "Temp",
    minwidth: "100",
  },
  {
    id: "isDangerousGoods",
    numeric: false,
    disablePadding: false,
    label: "DG",
    minwidth: "60",
  },
  {
    id: "isRadioactive",
    numeric: false,
    disablePadding: false,
    label: "RG",
    minwidth: "60",
  },
  {
    id: "dgUNNumber",
    numeric: false,
    disablePadding: false,
    label: "UN #",
    minwidth: "100",
  },
  {
    id: "dgCommodityClass",
    numeric: false,
    disablePadding: false,
    label: "Class",
    minwidth: "100",
  },
  {
    id: "dgProperName",
    numeric: false,
    disablePadding: false,
    label: "Commodity (PPN)",
    minwidth: "180",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ShipmentPieceDto
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const EditPiecesDialog: FC<MyDialogProps> = ({
  open,
  handleClose,
  shipmentGUID,
  setPiecesData,
  piecesData,
  getShipmentPieceList,
  accessModule,
}) => {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof ShipmentPieceDto>("contents");
  const [isLoading, setIsLoading] = useState(false);
  // const [piecesData, setPiecesData] = useState<ShipmentPieceDto[]>([]);
  const [DialogOpen, setDialogOpen] = useState(false);
  const [currentPieceIndex, setCurrentPieceIndex] = useState<number | null>(
    null
  );
  const { deleteShipmentPiece } = useDeleteShipmentPieceLogic();

  const [currentPiece, setCurrentPiece] = useState<ShipmentPieceDto | null>(
    null
  );

  const deleteShipmentPieceRecord = useCallback(
    async (shipmentPieceID: any) => {
      try {
        const confirm = window.confirm(
          "Are you sure you want to remove this piece?"
        );
        if (confirm && shipmentPieceID) {
          const param = {
            shipmentPieceID: shipmentPieceID,
          };
          const response = await deleteShipmentPiece(param);
          if (response.data && response.data.data) {
            getShipmentPieceList();
          }
        }
      } catch (err) {}
    },
    []
  );

  const initialPieceData: ShipmentPieceDto = {
    contents: "",
    reference: "",
    weight: undefined,
    dimWeight: 0.0,
    weightUOM: "LB",
    length: undefined,
    width: undefined,
    height: undefined,
    sizeUOM: "IN",
    quantity: 1,
    declaredValue: undefined,
    declaredValueCurrencyID: "USD",
    packagingTypeGUID_Outer: "",
    packagingTypeGUID_Inner: "",
    isDangerousGoods: false,
    isRadioactive: false,
    dgUNNumber: "",
    dgCommodityClass: "",
    dgProperName: "",
    dgDryIceWeight: 0,
    dgGelPackCount: 0,
    dgPackingGroup: "",
    dgQuantityAndPackType: "",
    dgPackingInstr: "",
    dgAuthorization: "",
    packagingType: "",
    bagGUID: "",
    bagGUID2: "",
    count: 1,
    insertUserID: undefined,
    oBagGUID: "",
    oBagGUID2: "",
    countryOfOrigin: "",
    hsCode: "",
    carrierWaybillNumber: "",
    temperatureType: "",
    carrierToProvideDryIce: false,
    carrierToProvideGelPack: false,
    shipmentPieceID: 0,
    shipmentGUID: shipmentGUID,
    customsValue: 0,
    insuranceValue: 0,
    updatedUserId: 0,
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ShipmentPieceDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const sortedPiecesData = sortAndUpdateTheData(piecesData, order, orderBy);
    setPiecesData(sortedPiecesData);
  };

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
          <TableCell key="edit" align={"left"}>
            Edit
          </TableCell>
          <TableCell key="delete" align={"left"}></TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const handleClickOpen = () => {
    setCurrentPiece(null);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    getShipmentPieceList();
    setDialogOpen(false);
  };

  const handleNewPieceAdded = (newPiece: ShipmentPieceDto) => {
    if (currentPieceIndex !== null && currentPieceIndex >= 0) {
      // Edit existing piece
      setPiecesData((prevData: ShipmentPieceDto[]) =>
        prevData.map((shipmentPiece, index) =>
          index === currentPieceIndex ? newPiece : shipmentPiece
        )
      );
      setCurrentPieceIndex(null);
    } else {
      // Add new piece
      setPiecesData((prevData: ShipmentPieceDto[]) => [...prevData, newPiece]);
    }
    setDialogOpen(false);
  };

  const handleEdit = (shipmentPiece: ShipmentPieceDto, index: number) => {
    setCurrentPieceIndex(index);
    setCurrentPiece(shipmentPiece);
    setDialogOpen(true);
  };

  const handleDelete = (shipmentPieceID: any, index: number) => {
    if (shipmentPieceID) {
      deleteShipmentPieceRecord(shipmentPieceID);
    } else {
      setPiecesData((prevData: ShipmentPieceDto[]) =>
        prevData.filter((_, i) => i !== index)
      );
    }
  };

  const isAllowEdit = (row: any) => {
    if (accessModule === "editOrder") {
      if (row?.shipmentPieceID) {
        return true;
      }
      return false;
    }
    return true;
  };
  return (
    <Dialog
      style={{ height: "88%" }}
      maxWidth={"xl"}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className="Popup-header" id="responsive-dialog-title">
        Piece Detail
      </DialogTitle>
      <DialogContent>
        <div className="container-fluid Piecesgrid code-form">
          <>
            <Box sx={{ width: "100%" }}>
              <Paper
                className="ActiveOrder Pieces-grid-table"
                sx={{ width: "100%", mb: 2 }}
              >
                <TableContainer className="table-container">
                  <Table aria-labelledby="tableTitle">
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={piecesData.length}
                    />
                    <TableBody>
                      {isLoading ? (
                        <TableRow>
                          <TableCell className="" colSpan={13} align="center">
                            <Loader />
                          </TableCell>
                        </TableRow>
                      ) : piecesData.length === 0 ? (
                        <TableRow>
                          <TableCell className="" colSpan={13}>
                            No data to display
                          </TableCell>
                        </TableRow>
                      ) : (
                        piecesData.map((row: ShipmentPieceDto, index: any) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                className="Info-wrap"
                                style={{ minWidth: 200 }}
                              >
                                {row.contents}
                              </TableCell>
                              <TableCell style={{ minWidth: 110 }}>
                                {row.reference}
                              </TableCell>
                              <TableCell style={{ minWidth: 110 }}>
                                {row.weight && row.weightUOM
                                  ? `${row.weight} ${row.weightUOM}`
                                  : ""}
                              </TableCell>
                              <TableCell style={{ minWidth: 180 }}>
                                {row.length && row.width && row.height
                                  ? `${row.length} X ${row.width} X ${row.height} ${row.sizeUOM}`
                                  : ""}
                              </TableCell>
                              <TableCell style={{ minWidth: 180 }}>
                                {row.packagingType}
                              </TableCell>
                              <TableCell style={{ minWidth: 60 }}></TableCell>
                              <TableCell style={{ minWidth: 60 }}>
                                <Checkbox
                                  className="grid_chkbx"
                                  checked={row.isDangerousGoods}
                                />
                              </TableCell>
                              <TableCell style={{ minWidth: 60 }}>
                                <Checkbox
                                  className="grid_chkbx"
                                  checked={row.isRadioactive}
                                />
                              </TableCell>
                              <TableCell style={{ minWidth: 100 }}>
                                {row.dgUNNumber}
                              </TableCell>
                              <TableCell style={{ minWidth: 100 }}>
                                {row.dgCommodityClass}
                              </TableCell>
                              <TableCell style={{ minWidth: 180 }}>
                                {row.dgProperName}
                              </TableCell>
                              <TableCell style={{ minWidth: 40 }}>
                                {isAllowEdit(row) && (
                                  <>
                                    <Button
                                      onClick={() => handleEdit(row, index)}
                                      id="btnEditPieceEdit"
                                    >
                                      <img src={editicon} alt="Edit icon" />{" "}
                                    </Button>
                                  </>
                                )}
                              </TableCell>
                              <TableCell style={{ minWidth: 40 }}>
                                {index !== 0 && (
                                  <Button
                                    onClick={() =>
                                      handleDelete(row.shipmentPieceID, index)
                                    }
                                    id="btnEditPieceDelete"
                                  >
                                    <img src={deleteicon} alt="Delete icon" />
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </>
        </div>
      </DialogContent>
      <DialogActions className="d-flex w-100 justify-content-between">
        <Button
          variant="text"
          className="align-center fw-bold"
          onClick={handleClickOpen}
          id="btnEditPieceNew"
        >
          NEW
          <AddIcon />
        </Button>
        {currentPiece ? (
          <EditNewPiecesDialog
            open={DialogOpen}
            shipmentPiece={currentPiece}
            handleClose={handleDialogClose}
            onAddNewPiece={handleNewPieceAdded}
            currentPieceIndex={currentPieceIndex}
          />
        ) : (
          <EditNewPiecesDialog
            open={DialogOpen}
            shipmentPiece={initialPieceData}
            handleClose={handleDialogClose}
            onAddNewPiece={handleNewPieceAdded}
            currentPieceIndex={currentPieceIndex}
          />
        )}
        <Box className="d-flex gap-4">
          <Button
            onClick={handleClose}
            className="MuiButton Action-btn"
            variant="text"
            id="btnEditPieceClose"
          >
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditPiecesDialog;
