import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";

// Action Types for Currency
const CURRENCY_REQUEST = "CURRENCY_REQUEST";
const CURRENCY_SUCCESS = "CURRENCY_SUCCESS";
const CURRENCY_FAILURE = "CURRENCY_FAILURE";

// Define the interface for the API response
interface CurrencyResponse {
  success: boolean;
  message: string;
  data: {
    currencyID: string;
    shortName: string;
    name: string;
    description: string;
    symbolText: string;
    symbolImage: string;
  };
  statusCode: string;
}

// Action Creators for Currency
export const currencyRequest = () => ({
  type: CURRENCY_REQUEST,
});

export const currencySuccess = (data: any) => ({
  type: CURRENCY_SUCCESS,
  payload: data,
});

export const currencyFailure = (error: string) => ({
  type: CURRENCY_FAILURE,
  payload: error,
});

// Initial State for Currency
export interface CurrencyState {
  isAuthenticated: boolean;
  currencyLoading: boolean;
  currencyError: string | null;
  currencyData: any;
}

const initialAuthState: CurrencyState = {
  isAuthenticated: false,
  currencyLoading: false,
  currencyError: null,
  currencyData: [],
};

// Reducer for Authentication
const currencyReducer = (
  state = initialAuthState,
  action: any
): CurrencyState => {
  switch (action.type) {
    case CURRENCY_REQUEST:
      return { ...state, currencyLoading: true, currencyError: null };
    case CURRENCY_SUCCESS:
      return {
        ...state,
        currencyLoading: false,
        isAuthenticated: true,
        currencyData: action.payload,
      };
    case CURRENCY_FAILURE:
      return {
        ...state,
        currencyLoading: false,
        currencyError: action.payload,
      };
    default:
      return state;
  }
};

// Thunk for Currency
export const currency = () => async (dispatch: Dispatch) => {
  dispatch(currencyRequest());
  try {
    const response = await axiosInstance.get(
      `${API_BASE_URL}/api/Lookup/Getcurrency`
    );
    // Assuming Account was successful if no error occurred

    const data: CurrencyResponse = response.data;
    // Store token in localStorage
    if (response.status === 200 && data.success) {
      // Store token in localStorage
      dispatch(currencySuccess(response));
      return { data };
    } else {
      // Handle different status codes if necessary
      dispatch(currencyFailure(data.message));
    }
    return response.data;
  } catch (error: any) {
    if (error.response.data.message) {
      dispatch(currencyFailure(error.response.data.message));
    } else {
      dispatch(currencyFailure(error.message));
    }
  }
};

export default currencyReducer;
