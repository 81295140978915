// loaderManager.js
type SetLoadingType = (loading: boolean) => void;

let setLoading: SetLoadingType | undefined;

export const loaderManager = {
  setLoaderFunction: (setLoadingFunction: SetLoadingType) => {
    setLoading = setLoadingFunction;
  },
  setLoading: (loading: boolean) => {
    if (setLoading) {
      setLoading(loading);
    }
  },
};
