import React from "react";
const UnAuthorized: React.FC = () => {

  return (
    <div>
    </div>
  );
};

export default UnAuthorized;
