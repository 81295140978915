import React, { Component, ErrorInfo, ReactNode } from "react";
import error from "../../assets/images/error/error.png";
import ErrorMessage from "../../modules/error-message/error-message";
import { useNavigate } from "react-router-dom";

interface ErrorBoundaryProps {
  children: ReactNode;
  navigate: (path: string) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Log error details to the console with additional context
    console.error("ErrorBoundary caught an error:", error.toString());
    console.error("Error info:", errorInfo.componentStack);

    this.props.navigate("/error-page");
  }

  render(): ReactNode {
    
    // if (this.state.hasError) {

    //   Display a user-friendly error message
    //   return (
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         minHeight: "80vh",
    //         textAlign: "center",
    //         padding: "20px",
    //         boxSizing: "border-box",
    //       }}
    //     >
    //       <div>
    //         <img
    //           src={error}
    //           alt="Error"
    //           style={{ maxWidth: "15%", marginTop: "20px" }}
    //         />
    //         <h1>Oops! Something went wrong.</h1>
    //         <p>We apologize for the inconvenience. Please try again later.</p>
    //       </div>
    //     </div>
    //   );
    // }

    return this.props.children;
  }
}


const ErrorBoundaryWithNavigate = (props: Omit<ErrorBoundaryProps, 'navigate'>) => {
  const navigate = useNavigate();
  return <ErrorBoundary {...props} navigate={navigate} />;
};

export default ErrorBoundaryWithNavigate;

