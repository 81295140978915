import { Dispatch } from "redux"; // Import Dispatch type
import { API_BASE_URL } from "../../../config/constants";
import { CountryItemDto } from "../../../models/country-item-dto";
import axiosInstanceWithoutLoader from "../../../config/axios-without-loader";
// Action Types for attributes
const ATT_REQUEST = "ATT_REQUEST";
const ATT_SUCCESS = "ATT_SUCCESS";
const ATT_FAILURE = "ATT_FAILURE";

// Action Creators for attributes
export const attributesRequest = () => ({
  type: ATT_REQUEST,
});

export const attributesSuccess = () => ({
  type: ATT_SUCCESS,
});

export const attributesFailure = (error: string) => ({
  type: ATT_FAILURE,
  payload: error,
});

// Initial State for attributes
export interface attributesState {
  isAuthenticated: boolean;
  loading: boolean;
  att_error: string | null;
 
}

const initialAuthState: attributesState = {
  isAuthenticated: false,
  loading: false,
  att_error: null,
  
};

// Define the interface for the API response
interface attributesResponse {
  success: boolean;
  message: string;
  data: CountryItemDto[];
  statusCode: string;
}

// Reducer for Authentication
const attributesReducer = (
  state = initialAuthState,
  action: any
): attributesState => {
  switch (action.type) {
    case ATT_REQUEST:
      return { ...state, loading: true, att_error: null };
    case ATT_SUCCESS:
      return { ...state, loading: false, isAuthenticated: true  };
    case ATT_FAILURE:
      return { ...state, loading: false, att_error: action.payload };
    default:
      return state;
  }
};

// Thunk for attributes
export const attributes =
  (param: {
    serviceID: number;
    accountNumber: any;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(attributesRequest());
    try {
      const response = await axiosInstanceWithoutLoader.post(
        `${API_BASE_URL}/api/Orders/AttributesBasedOnServiceCustomer`,
        param
      );
      
      const data:attributesResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        dispatch(attributesSuccess());
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(attributesFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(attributesFailure(error.response.data.message));
      } else {
        dispatch(attributesFailure(error.message));
      }
    }
  };

  export const saveAttributes =
  (param: {
    shipmentGUID: string;
    attributeTypeID: string;
    insertUserID: number;
    }) =>
  async (dispatch: Dispatch) => {
    dispatch(attributesRequest());
    try {
      const response = await axiosInstanceWithoutLoader.post(
        `${API_BASE_URL}/api/Orders/InsertAttributes/Post`,
        param
      );
      
      const data:attributesResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        dispatch(attributesSuccess());
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(attributesFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(attributesFailure(error.response.data.message));
      } else {
        dispatch(attributesFailure(error.message));
      }
    }
  };
  
export default attributesReducer;
