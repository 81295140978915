import Env from "./environment-helper";
export const API_BASE_URL = Env().api;
export const MAX_LOGIN_ATTEMPTS = Number(Env().maxAttempts);
export const MAX_COMPANY_ID = Number(Env().companyId);
export const MAX_MAP_API_KEY =
  Env().key || "AIzaSyDbNxV6rg6dHsgLgSJXgPLKPljkS7uE20c";
export const PUBLIC_KEY = Env().publicKey;
export const PRIVATE_KEY = Env().privateKey;
export const REACT_APP_AHA_ACCOUNT_NAME = Env().ahaAccountName;
export const REACT_APP_AHA_APPLICATION_ID = Env().ahaApplicationId;
export const CRYOCELL_ACCOUNT = 204140;
export const DATETIME_REF_TYPE = 121;
export const DAY_ICE_ACCOUNT = [7384, 1518];
export const DAY_ICE_REF_TYPE = [194, 165];
export const ENCRYPT_DECRYPT_KEY = Env().EncryptDecryptKey;