import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import EnhancedTableHead from "../../shared/components/table-head-cell/enhanced-table-head";
import Loader from "../../shared/util/loader";
import NoDataFound from "../../shared/components/NoDataFound";
import { visuallyHidden } from "@mui/utils";
import {
  ImportOrderItemDto,
  ImportOrderReqItemDto,
} from "../../models/import-order-item-dto";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { API_BASE_URL, MAX_COMPANY_ID } from "../../config/constants";
import useDefaultDataLogic from "../long-form-orders/long-form-order.logic";
import { MySettingItemDto } from "../../models/long-form-item-dto";
import useImportOrderLogic from "./import-order-logic";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
import CommonPagination from "../../shared/components/CustomPagination";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosInstance from "../../config/axios-interceptor";
import {
  downloadAndSavePDF,
  downloadImportOrderTemplate,
} from "../../shared/util/download-pdf";
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';

interface ImportOrdersProps {
  title: string;
}
interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ImportOrderReqItemDto
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}
interface HeadCell {
  disablePadding: boolean;
  id: keyof ImportOrderReqItemDto;
  label: string;
  numeric: boolean;
  minwidth: string;
}
const headCells: HeadCell[] = [
  {
    id: "lineNumber",
    numeric: true,
    disablePadding: false,
    label: "ROW #",
    minwidth: "120",
  },
  {
    id: "shipmentNumber",
    numeric: true,
    disablePadding: false,
    label: "ORDER #",
    minwidth: "120",
  },
  {
    id: "pickupAddress",
    numeric: true,
    disablePadding: false,
    label: "PICKUP",
    minwidth: "299",
  },
  {
    id: "deliveryAddress",
    numeric: true,
    disablePadding: false,
    label: "DELIVER",
    minwidth: "299",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "STATUS",
    minwidth: "235",
  },
  {
    id: "message",
    numeric: true,
    disablePadding: false,
    label: "MESSAGE",
    minwidth: "235",
  },
];
type Order = "desc" | "asc";

const ImportOrder: React.FC<ImportOrdersProps> = ({ title }) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    useState<keyof ImportOrderReqItemDto>("lineNumber");
  const [ImportOrderData, setImportOrderData] = useState<
    ImportOrderReqItemDto[]
  >([]);
  const [fileName, setFileName] = useState<string | undefined>("");
  const [fileExt, setFileExt] = useState<string>("");
  const [file, setFile] = useState<File | undefined>(undefined);
  const [errorText, setErrorText] = useState<string>("");
  const [submitError, setSubmitError] = useState<any>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [alertVisible, setAlertVisible] = useState(!!successMessage);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const totalRecord = ImportOrderData.length;
  const { LongFormError, defaultData } = useDefaultDataLogic();
  const [defaultDatas, setDefaultDatas] = useState<MySettingItemDto>();
  const isMobile = useMediaQuery("(max-width:599px)");
  const [isLoading, setIsLoading] = useState(false);
  const [importDialog, setImportDialog] = useState(false);
  let { handleImportFile, error } = useImportOrderLogic();
  const token = useDecodeJWT();

  const importFieldsInitial: string[] = [
    "3P Carrier",
    "3P Carrier Account",
    "3P Carrier Service",
    "Contents",
    "Delivery Address 2",
    "Delivery Attention",
    "Delivery Date/Time",
    "Delivery Instructions",
    "Delivery Phone",
    "DG Class",
    "DG Contents",
    "DG Date",
    "DG Emergency Phone",
    "DG Instructions",
    "DG Name/Signatory",
    "DG Qty and Packaging Type",
    "DG Trans Index",
    "DG Transport Limits",
    "Dims UOM",
    "Height",
    "Length",
    "Pickup Address 2",
    "Pickup Date/Time",
    "Pickup Instructions",
    "Pickup Phone",
    "Shipper Email",
    "Shipper Phone",
    "UN#",
    "Weight",
    "Weight UOM",
    "Width",
  ];

  const requestedImportFieldsInitial: string[] = [
    "Account Number *",
    "Delivery Address *",
    "Delivery Email Address *",
    "Delivery Company Name *",
    "Delivery Date *",
    "Delivery Time *",
    "Delivery Postal *",
    "Pickup Address *",
    "Pickup Company Name *",
    "Pickup Date *",
    "Pickup Time *",
    "Pickup Postal *",
    "Pieces *",
    "Commodity *",
    "Pickup City *",
    "Pickup State *",
    "Pickup Country *",
    "Delivery City *",
    "Delivery State *",
    "Delivery Country *",
    "Service Code *",
    "Shipper Name *",
  ];

  const [importFields, setImportFields] =
    useState<string[]>(importFieldsInitial);
  const [requestedImportFields, setRequestedImportFields] = useState<string[]>(
    requestedImportFieldsInitial
  );
  const [selectedField, setSelectedField] = useState<string | null>(null);
  const [selectedFieldIndex, setSelectedFieldIndex] = useState<number | null>(null);

  const [importComplete, setImportComplete] = useState<boolean>(false);

  useEffect(() => {
    if (defaultDatas) {
      const defaultReference: string[] = [];
      if (defaultDatas.referenceLabel) {
        defaultReference.push(defaultDatas.referenceLabel);
      }
      if (defaultDatas.referenceLabel2) {
        defaultReference.push(defaultDatas.referenceLabel2);
      }
      if (defaultDatas.referenceLabel3) {
        defaultReference.push(defaultDatas.referenceLabel3);
      }
      if (defaultDatas.referenceLabel4) {
        defaultReference.push(defaultDatas.referenceLabel4);
      }
      if (defaultDatas.referenceLabel5) {
        defaultReference.push(defaultDatas.referenceLabel5);
      }
      if (defaultDatas.referenceLabel6) {
        defaultReference.push(defaultDatas.referenceLabel6);
      }
      if (defaultDatas.referenceLabel7) {
        defaultReference.push(defaultDatas.referenceLabel7);
      }
      if (defaultDatas.referenceLabel8) {
        defaultReference.push(defaultDatas.referenceLabel8);
      }
      if (defaultDatas.referenceLabel9) {
        defaultReference.push(defaultDatas.referenceLabel9);
      }
      const updatedImportFields = [...importFieldsInitial, ...defaultReference];
      const sortedImportFields = updatedImportFields.sort((a, b) =>
        a.localeCompare(b)
      );
      setImportFields(sortedImportFields);
    }
  }, [defaultDatas]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ImportOrderReqItemDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      setFileName(file.name);
      setFileExt(file.name.split(".").pop() || "");
      setFile(file);
      setErrorText("");
      setSubmitError("");
      setImportComplete(false);
    }
    e.target.value = "";
  };


  const handleDelete = () => {
    const fileInput = document.getElementById("file") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ""; // Clear the input value
    }
    setFileExt("");
    setFile(undefined);
    setFileName("");
    setErrorText("");
    setSubmitError("");
    setImportComplete(false);
  };

  const handleImportTemplate = () => {
    setImportDialog(true);
    handleReset();
  };

  const handleTemplateClose = () => {
    setImportDialog(false);
  };

  const moveField = (
    field: string,
    from: string[],
    to: string[],
    setFrom: React.Dispatch<React.SetStateAction<string[]>>,
    setTo: React.Dispatch<React.SetStateAction<string[]>>
  ): void => {
    if (!to.includes(field)) {
      const fromData = from.filter((item) => item !== field);
      const sortedFromData = fromData.sort((a, b) => a.localeCompare(b));
      setFrom(sortedFromData);
      const toData = [...to, field];
      const sortedToData = toData.sort((a, b) => a.localeCompare(b));
      setTo(sortedToData);
    }
  };



  const handleMoveRight = (): void => {
    if (selectedFieldIndex !== null) {
      moveFieldByIndex(
        selectedFieldIndex,
        importFields,
        requestedImportFields,
        setImportFields,
        setRequestedImportFields,
        "right"
      );
      setSelectedFieldIndex(null);
    }
  };
  
  const handleMoveLeft = (): void => {
    if (selectedFieldIndex !== null) {
      const fieldsToMove = requestedImportFields.filter(
        (field) => !field.includes("*")
      );
  
      if (fieldsToMove.includes(requestedImportFields[selectedFieldIndex])) {
        moveFieldByIndex(
          selectedFieldIndex,
          requestedImportFields,
          importFields,
          setRequestedImportFields,
          setImportFields,
          "left"
        );
      }
      setSelectedFieldIndex(null);
    }
  };
  
  const moveFieldByIndex = (
    index: number,
    from: string[],
    to: string[],
    setFrom: React.Dispatch<React.SetStateAction<string[]>>,
    setTo: React.Dispatch<React.SetStateAction<string[]>>,
    direction: "left" | "right"
  ): void => {
    const field = from[index];
    const fromData = [...from.slice(0, index), ...from.slice(index + 1)];
    setFrom(fromData);
  
    if (direction === "right") {
      const toData = [...to, field];
      setTo(toData);
    } else if (direction === "left") {
      const toData = [...to, field].sort((a, b) => a.localeCompare(b));
      setTo(toData);
    }
  };

  const handleMoveAllRight = (): void => {
    moveAllFields(
      importFields,
      requestedImportFields,
      setImportFields,
      setRequestedImportFields,
      "right" // Specify the direction as 'right'
    );
  };
  
  const handleMoveAllLeft = (): void => {
    const fieldsToMove = requestedImportFields.filter(
      (field) => !field.includes("*")
    );
  
    // Update the 'importFields' by sorting them after moving
    setImportFields((prevFields) => {
      const updatedImportFields = [...prevFields, ...fieldsToMove];
      const sortedImportFields = updatedImportFields.sort((a, b) =>
        a.localeCompare(b)
      );
      return sortedImportFields;
    });
  
    // Update the 'requestedImportFields' to keep only the fields with '*'
    setRequestedImportFields(
      requestedImportFields.filter((field) => field.includes("*"))
    );
  };
  
  const moveAllFields = (
    from: string[],
    to: string[],
    setFrom: React.Dispatch<React.SetStateAction<string[]>>,
    setTo: React.Dispatch<React.SetStateAction<string[]>>,
    direction: "left" | "right"
  ): void => {
    if (direction === "right") {
      const toData = [...to, ...from];
      setTo(toData);
    } else if (direction === "left") {
      const toData = [...to, ...from].sort((a, b) => a.localeCompare(b));
      setTo(toData);
    }
    setFrom([]);
  };
  

  const hasNonRequiredFields = (fields: string[]) =>
    fields.some((field) => !field.includes("*"));

  const handleReset = (): void => {
    if (defaultDatas) {
      const defaultReference: string[] = [];
      if (defaultDatas.referencetypeName) {
        defaultReference.push(defaultDatas.referencetypeName);
      }
      if (defaultDatas.referencetypeName2) {
        defaultReference.push(defaultDatas.referencetypeName2);
      }
      if (defaultDatas.referencetypeName3) {
        defaultReference.push(defaultDatas.referencetypeName3);
      }
      if (defaultDatas.referencetypeName4) {
        defaultReference.push(defaultDatas.referencetypeName4);
      }
      if (defaultDatas.referencetypeName5) {
        defaultReference.push(defaultDatas.referencetypeName5);
      }
      if (defaultDatas.referencetypeName6) {
        defaultReference.push(defaultDatas.referencetypeName6);
      }
      if (defaultDatas.referencetypeName7) {
        defaultReference.push(defaultDatas.referencetypeName7);
      }
      if (defaultDatas.referencetypeName8) {
        defaultReference.push(defaultDatas.referencetypeName8);
      }
      if (defaultDatas.referencetypeName9) {
        defaultReference.push(defaultDatas.referencetypeName9);
      }
      const updatedImportFields = [...importFieldsInitial, ...defaultReference];
      const sortedImportFields = updatedImportFields.sort((a, b) =>
        a.localeCompare(b)
      );
      setImportFields(sortedImportFields);
    } else {
      setImportFields(importFieldsInitial);
    }
    setRequestedImportFields(requestedImportFieldsInitial);
    setSelectedField(null);
  };

  useEffect(() => {
    getDefaultData();
  }, [order, orderBy, page]);

  const getDefaultData = async () => {
    try {
      setIsLoading(true);
      const param = {
        companyID: MAX_COMPANY_ID,
      };
      const response = await defaultData(param);
      if (response.data && response.data.data) {
        const res = response.data.data;

        setDefaultDatas(res);
      }
      setIsLoading(false);
    } catch {
      setDefaultDatas(undefined);

      setIsLoading(false);
    }
  };

  const handleImportButton = async () => {
    try {
      setSuccessMessage("");
      if (!fileName || !file) {
        setErrorText("");
        return;
      }
  
      if (!["xlsx", "xls", "csv"].includes(fileExt)) {
        setErrorText(`This file type isn't allowed.`);
        setImportOrderData([]);
        return;
      }
      setImportOrderData([]);
  
      const param = {
        CompanyID: 15693,
        UserId: 0,
        UserGUID: null,
        MainFile: file,
        File: null,
        FileName: null,
        FileExt: null,
        FilePath: null,
        ImportRunGUID: null,
        SizeUOM: defaultDatas?.sizeUOM,
        WeightUOM: defaultDatas?.weightUOM,
      };
  
      const response = await handleImportFile(param);
      if (response.success && response.data) {
        const res = response.data;
        const allStatusW = res.every((item: { status: string; }) => item.status === "W");
        if (!allStatusW) {
          setSuccessMessage("Orders imported successfully. Please see the table below for order details.");
        }
        setImportOrderData(res);
        setSubmitError("");
        setImportComplete(true);
      } else {
        setSubmitError(response.message);
        setSuccessMessage("");
      }
  
      // Reset the file inputs
      setFileExt("");
      setFile(undefined);
      setFileName("");
  
    } catch (error) {
      setSuccessMessage("");
    }
  };
  
  
  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    setRowsPerPage(pageSize);
    setPage(0);
  };

  const descendingComparator = (
    a: ImportOrderReqItemDto,
    b: ImportOrderReqItemDto,
    orderBy: keyof ImportOrderReqItemDto
  ) => {
    const aValue = a[orderBy] ?? "";
    const bValue = b[orderBy] ?? "";
    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
    return 0;
  };

  function getComparator<Key extends keyof ImportOrderReqItemDto>(
    order: Order,

    orderBy: Key
  ): (a: ImportOrderReqItemDto, b: ImportOrderReqItemDto) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const sortedImportedData = ImportOrderData.slice().sort(
    getComparator(order, orderBy)
  );

  const paginatedData =
    rowsPerPage > 0
      ? sortedImportedData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
      : sortedImportedData;

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof ImportOrderReqItemDto) =>
        (event: React.MouseEvent<unknown>) => {
          onRequestSort(event, property);
        };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              width={headCell.minwidth}
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleDownload = async () => {
    try {
      const requestedFields = requestedImportFields.map((field) =>
        field.replace(" *", "")
      );
      const requestedFieldsCommaSeparated = requestedFields.join(", ");
      let mainReference = "";
      let customerReference: string[] = [];

      if (defaultDatas && defaultDatas.referencetypeName) {
        mainReference = defaultDatas.referencetypeName;
      }

      if (defaultDatas) {
        if (defaultDatas.referencetypeName2) {
          customerReference.push(defaultDatas.referencetypeName2);
        }
        if (defaultDatas.referencetypeName3) {
          customerReference.push(defaultDatas.referencetypeName3);
        }
        if (defaultDatas.referencetypeName4) {
          customerReference.push(defaultDatas.referencetypeName4);
        }
        if (defaultDatas.referencetypeName5) {
          customerReference.push(defaultDatas.referencetypeName5);
        }
        if (defaultDatas.referencetypeName6) {
          customerReference.push(defaultDatas.referencetypeName6);
        }
        if (defaultDatas.referencetypeName7) {
          customerReference.push(defaultDatas.referencetypeName7);
        }
        if (defaultDatas.referencetypeName8) {
          customerReference.push(defaultDatas.referencetypeName8);
        }
        if (defaultDatas.referencetypeName9) {
          customerReference.push(defaultDatas.referencetypeName9);
        }
      }
      const customerReferenceCommaSeparated = customerReference.join(", ");

      const param: any = {
        data: requestedFieldsCommaSeparated,
        mainRef: mainReference,
        customerFields: customerReferenceCommaSeparated,
      };

      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Report/ImportTemplate`,
        param,
        { responseType: "arraybuffer" }
      );

      if (response.status) {
        const pdfTitle = "import_template";
        const result = await downloadImportOrderTemplate(
          response.data,
          pdfTitle
        );

        if (result.error !== "") {
        }
      }
    } catch (error: any) { }
  };

  useEffect(() => {
    if (importComplete && ImportOrderData.length > 0) {
      const filteredData = ImportOrderData.filter((item) => {
        if (item.lineNumber === 0) {
          setSubmitError(item.errorMessage);
          return false;
        }
        return true;
      });

      setImportOrderData(filteredData);
    }
  }, [importComplete]);

  useEffect(() => {
    if (successMessage) {
      setAlertVisible(true);
      const timer = setTimeout(() => {
        setAlertVisible(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [successMessage])

  const handleImportFieldsDragEnd = (result: any) => {
    const { destination, source } = result;

    // Dropped outside the list or no change in position
    if (!destination || destination.index === source.index) return;

    const items = Array.from(importFields);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    setImportFields(items);
  };

  // Handle the drag end event for Requested Import Fields list
  const handleRequestedImportFieldsDragEnd = (result: any) => {
    const { destination, source } = result;

    // Dropped outside the list or no change in position
    if (!destination || destination.index === source.index) return;

    const items = Array.from(requestedImportFields);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    setRequestedImportFields(items);
  };

  const toPascalCase = (str:any) => {
    return str
      .replace(/\w\S*/g, (word:any) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
      .replace(/\s+/g, '');
  };
  return (
    <div className="container-fluid active-order">
      <Box className="pt-16 pb-8">
        <Typography className="Main-Header" variant="h4" color="primary">
          {title}
        </Typography>
      </Box>
      {submitError && (
        <Alert variant="filled" severity="error" className="mt-2 mb-2" id="txtIoError">
          {submitError ? submitError : error}
        </Alert>
      )}
      {alertVisible && successMessage && (
        <Box className="w-100 mt-24 mb-24">
          <Alert variant="filled" severity="success" id="txtIoSuccess">
            <div>{successMessage}</div>
          </Alert>
        </Box>
      )}
      <Box className="importFile rounded border">
        <Typography className="pb-8 Privacy_page_info">Import File</Typography>
        <div className="divfile">
          <div className="d-flex" style={{ width: "100%" }}>
            <TextField
              variant="outlined"
              id="txtFileName"
              fullWidth
              value={fileName}
              size="small"
              className="importFile-field"
              label="Select File to Import"
              InputProps={{
                readOnly: true,
                endAdornment: fileName && (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={handleDelete}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={errorText}
              error={!!errorText}
            />
            <Input
              type="file"
              id="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <label htmlFor="file">
              <Button variant="outlined" component="span" className="browseBtn" id="btnBrowse">
                BROWSE
              </Button>
            </label>
          </div>
          <div className="Import_btn">
            <Button
              variant="contained"
              id="btnImport"
              color="primary"
              className="browseBtn ml-16"
              startIcon={<UploadFileIcon />}
              onClick={handleImportButton}
            >
              IMPORT
            </Button>
          </div>
        </div>
      </Box>
      <Button
        className="templateBtn mt-16 mb-16 rounded"
        id="btnCreateImportTemplate"
        variant="outlined"
        startIcon={<UploadFileIcon />}
        onClick={handleImportTemplate}
      >
        CREATE IMPORT TEMPLATE
      </Button>
      <Dialog
        maxWidth="md"
        open={importDialog}
        onClose={handleTemplateClose}
        style={{ maxHeight: "90%" }}
      >
        <DialogTitle className="dialogtitle">
          CREATE IMPORT TEMPLATE
          <IconButton
            aria-label="close"
            id="btnTemplateClose"
            onClick={handleTemplateClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="dialogcontent mt-16 mb-16">
              <Typography
                variant="body1"
                className="import-popup addr_lbl1"
              >
                Assign the items needed for import into the Requested Import
                Fields and click Download File Template to create a CSV file
                with the headers required and sample information.
              </Typography>
            </div>
            <Box
              className="Import_data_Section"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <DragDropContext onDragEnd={handleImportFieldsDragEnd}>
                <Box className="import_left-section">
                  <Typography variant="h6" className="Field_header">
                    Import Fields
                  </Typography>
                  <Droppable droppableId="importFields">
                    {(provided) => (
                      <List
                        component="nav"
                        aria-label="Import Fields List"
                        className="scrollable-list mb-16"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {importFields.map((field, index) => (
                          <Draggable key={index} draggableId={`${index}`} index={index}>
                            {(provided) => (
                              <ListItemButton
                                className="Import_list_btn"
                                id={`libtn${toPascalCase(field)}`}
                                selected={selectedFieldIndex === index}
                                onClick={() => setSelectedFieldIndex(index)}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ListItemText className="Import_list" primary={field} />
                              </ListItemButton>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </List>
                    )}
                  </Droppable>
                </Box>
              </DragDropContext>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                className="gap-8"
              >
                <Button
                  variant="outlined"
                  id="btnMoveAllRight"
                  onClick={handleMoveAllRight}
                  disabled={importFields.length === 0}
                >
                  {'>>'}
                </Button>
                <Button
                  variant="outlined"
                  id="btnMoveRight"
                  onClick={handleMoveRight}
                  disabled={importFields.length === 0}
                >
                  {'>'}
                </Button>
                <Button
                  variant="outlined"
                  id="btnMoveLeft"
                  onClick={handleMoveLeft}
                  disabled={!hasNonRequiredFields(requestedImportFields)}
                >
                  {'<'}
                </Button>
                <Button
                  variant="outlined"
                  id="btnMoveAllLeft"
                  onClick={handleMoveAllLeft}
                  disabled={!hasNonRequiredFields(requestedImportFields)}
                >
                  {'<<'}
                </Button>
              </Box>

              <DragDropContext onDragEnd={handleRequestedImportFieldsDragEnd}>
                <Box
                  className="import_left-section"
                  sx={{
                    mt: {
                      xs: 1, // 1 unit is equivalent to 8px in MUI
                    },
                  }}
                >
                  <Typography variant="h6" className="Field_header">
                    Requested Import Fields
                  </Typography>
                  <Droppable droppableId="requestedImportFields">
                    {(provided) => (
                      <List
                        component="nav"
                        aria-label="Requested Import Fields List"
                        className="scrollable-list mb-16"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {requestedImportFields.map((field, index) => (
                          <Draggable key={index} draggableId={`${index}`} index={index}>
                            {(provided) => (
                              <ListItemButton
                                className="Import_list_btn"
                                id={`libtn${toPascalCase(field)}`}
                                selected={selectedFieldIndex === index}
                                onClick={() => setSelectedFieldIndex(index)}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ListItemText className="Import_list" primary={field} />
                              </ListItemButton>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </List>
                    )}
                  </Droppable>
                </Box>
              </DragDropContext>
            </Box>
          </div>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="right" marginTop={2}>
            <Button
              className="reset_btn"
              id="btnReset"
              style={{ marginRight: "8px" }}
              onClick={handleReset}
            >
              Reset
            </Button>
            <Button
              className="reset_btn"
              id="btnDownload"
              variant="contained"
              color="primary"
              startIcon={<CloudDownloadOutlinedIcon />}
              onClick={handleDownload}
            >
              Download
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Box sx={{ width: "100%" }}>
        <Paper className="ActiveOrder" sx={{ width: "100%", mb: 4 }}>
          <TableContainer className="table-container mat-table-call-order">
            {/* Add class name for styling */}
            <Table aria-labelledby="tableTitle">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={ImportOrderData.length}
              />
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell className="" colSpan={12} align="center">
                      <Loader />
                    </TableCell>
                  </TableRow>
                ) : paginatedData.length === 0 ? (
                  <TableRow className="Nodata_found import_no_data" id="trImportDataNotFound">
                    <TableCell
                      className="No-data-section"
                      colSpan={12}
                      align="center"
                      id="tdDataNotFound"
                    >
                      <Box className="import_no_data_field">
                        <Box>
                          <b className="no-data-header">
                            {"No data to display"}
                          </b>
                          <p className="no-data-para mt-16">
                            {
                              "Browse for your file and import by clicking the IMPORT FILE button. If a template is needed to use for import, add your fields into the Requested Import Fields menu in your preferred order and download a template. Successfully imported job numbers will populate in the screen denoting a success or failure to import. Any failed files will show a failure and a description of the failure. All uploaded files should be in Excel (.xls/.xlsx) or Comma Separated (.csv) format."
                            }
                          </p>
                          <label htmlFor="file" className="btn-100">
                            <Button
                              variant="outlined"
                              id="btnBrowseFiles"
                              className="btn-100 blue-btn mt-16"
                              color="primary"
                              component="span"
                            >
                              {"Browse Files"}
                            </Button>
                          </label>
                        </Box>
                      </Box>

                      {/* <Box>
                        <Box className="no_data_order_field p16">
                          <b className="no-data-header">
                            {"No data to display"}
                          </b>
                          <p className="no-data-para mt-16">
                            {
                              "Browse for your file and import by clicking the IMPORT FILE button. If a template is needed to use for import, add your fields into the Requested Import Fields menu in your preferred order and download a template. Successfully imported job numbers will populate in the screen denoting a success or failure to import. Any failed files will show a failure and a description of the failure. All uploaded files should be in Excel (.xls/.xlsx) or Comma Separated (.csv) format."
                            }
                          </p>

                          <Button
                            variant="outlined"
                            className="btn-100 blue-btn"
                            color="primary"
                          >
                            {"Browse Files"}
                          </Button>
                        </Box>
                      </Box> */}
                    </TableCell>
                  </TableRow>
                ) : (
                  paginatedData.map(
                    (row: ImportOrderReqItemDto, index: any) => {
                      return isMobile ? (
                        <MobileCardView key={row.lineNumber} row={row} />
                      ) : (
                        <TableRow hover key={row.lineNumber} id="trImportData">
                          <TableCell style={{ minWidth: 120 }} id="tdLineNumber">
                            {row?.lineNumber}
                          </TableCell>
                          <TableCell style={{ minWidth: 120 }} id="tdShipmentNumber">
                            {row?.shipmentNumber === 0
                              ? " "
                              : row?.shipmentNumber}
                          </TableCell>
                          <TableCell style={{ minWidth: 299 }} id="tdPickupAddress">
                            {row?.pickupAddress}
                          </TableCell>
                          <TableCell style={{ minWidth: 299 }} id="tdDeliveryAddress">
                            {row?.deliveryAddress}
                          </TableCell>
                          <TableCell style={{ minWidth: 235 }} id="tdStatus">
                            {row?.status === "W" ? "Failure" : row?.status}
                          </TableCell>
                          <TableCell style={{ minWidth: 235 }} id="tdMessage">
                            {row?.message}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CommonPagination
            count={totalRecord}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </div>
  );
};

const MobileCardView: React.FC<{
  row: ImportOrderReqItemDto;
}> = ({ row }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <Paper
      className="Card_view"
      elevation={3}
      sx={{ marginBottom: "16px", padding: "0" }}
    >
      <Typography variant="body1" className="Field_info">
        <span className="field_label">Row #</span>{" "}
        <span className="Field_value">{row.lineNumber}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">Order #</span>{" "}
        <span className="Field_value">{row.shipmentNumber}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">Pickup</span>{" "}
        <span className="Field_value">{row.pickupAddress}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">Deliver</span>{" "}
        <span className="Field_value">{row.deliveryAddress}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">Status</span>{" "}
        <span className="Field_value">{row.status}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">Message</span>{" "}
        <span className="Field_value">{row.message}</span>
      </Typography>
    </Paper>
  );
};

export default ImportOrder;
