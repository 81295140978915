import React from "react";
import {  Typography, Paper } from "@mui/material";

const Tutorialspage: React.FC = () => {

  return (
    <div className="container-fluid tutorials">
    <Typography variant="h4" color={"primary"}>              
        Tutorials
        </Typography>
      <Paper elevation={3} sx={{ padding: 3, marginTop: 5 }}>
        
      
        <Typography variant="body1" paragraph style={{whiteSpace: 'pre-line'}}>
        <a href="http://video.parcellogic.com/MxWeb-Video-1/Video%201%20-%20Getting%20Started.html" target="_blank" rel="noreferrer">
        Video 1 - Getting Started
          </a>
          {" "}
        Use this tutorial to get a general overview of the web site and its features.
        </Typography>
        <Typography variant="body1" paragraph style={{whiteSpace: 'pre-line'}}>          
        <a href="http://video.parcellogic.com/MxWeb-Video-2/Video%202%20-%20Address%20Book.html" target="_blank" rel="noreferrer">
        Video 2 - Address Book
          </a> {" "}
          Use this tutorial for instructions on using the Address Book feature.
        </Typography>
        <Typography variant="body1" paragraph>
        <a href="http://video.parcellogic.com/MxWeb-Video-3/Video%203%20-%20Order%20Entry.html" target="_blank" rel="noreferrer">
        Video 3 - Order Entry
          </a> {" "}
        Use this tutorial for instructions on using the Order Entry features of MxWeb.
        </Typography>        
      </Paper>
    
    </div>
  );
};

export default Tutorialspage;
