import axios from "axios";
import { API_BASE_URL } from "./constants";
import { loaderManager } from "../shared/util/loader-manager";
import { useLocation } from "react-router-dom";

let activeRequests = 0;

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (activeRequests === 0 && config.url) {
      loaderManager.setLoading(true); // Show loader only when starting the first request
    }
    activeRequests++;

    const userData = localStorage.getItem("userDetails");
    const parseUserData = JSON.parse(userData || "{}");
    if (parseUserData && parseUserData.token) {
      const token = parseUserData.token;
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    activeRequests--;
    if (activeRequests === 0) {
      loaderManager.setLoading(false); // Hide loader when all requests are finished
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    activeRequests--;
    if (activeRequests === 0) {
      loaderManager.setLoading(false); // Hide loader when all requests are finished
    }
    return response;
  },
  (error) => {
    activeRequests--;
    if (activeRequests === 0) {
      loaderManager.setLoading(false); // Hide loader when all requests are finished
    }

    const { status } = error.response || {};
    if (status === 401) {
      localStorage.clear();
      window.location.replace("/"); // Redirect to login page on unauthorized error
    }

    // if (error.response) {
    //   console.error(
    //     "Response error",
    //     error.response.status,
    //     error.response.data
    //   );
    // } else if (error.request) {
    //   console.error("Request error", error.request);
    // } else {
    //   console.error("Error", error.message);
    // }

    return Promise.reject(error);
  }
);

export default axiosInstance;
