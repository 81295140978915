import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { ActiveOrderItemDto } from "../../models/active-order-item-dto";

// Action Types for  Active Order
const ACTIVE_ORDERS_REQUEST = "ACTIVE_ORDERS_REQUEST";
const ACTIVE_ORDERS_SUCCESS = "ACTIVE_ORDERS_SUCCESS";
const ACTIVE_ORDERS_FAILURE = "ACTIVE_ORDERS_FAILURE";

// Action Creators for  Active Order
export const activeOrdersRequest = () => ({
  type: ACTIVE_ORDERS_REQUEST,
});

export const activeOrdersSuccess = () => ({
  type: ACTIVE_ORDERS_SUCCESS,
});

export const activeOrdersFailure = (error: string) => ({
  type: ACTIVE_ORDERS_FAILURE,
  payload: error,
});

// Initial State for  Active Order
export interface ActiveOrdersState {
  isAuthenticated: boolean;
  activeOrderLoading: boolean;
  activeOrderError: string | null;
}

const initialAuthState: ActiveOrdersState = {
  isAuthenticated: false,
  activeOrderLoading: false,
  activeOrderError: null,
};

// Define the interface for the API response
interface ActiveOrdersResponse {
  success: boolean;
  message: string;
  totalCount: number;
  pageCount: number;
  data: Array<ActiveOrderItemDto>;
  statusCode: string;
}

// Reducer for Authentication
const activeOrdersReducer = (
  state = initialAuthState,
  action: any
): ActiveOrdersState => {
  switch (action.type) {
    case ACTIVE_ORDERS_REQUEST:
      return { ...state, activeOrderLoading: true, activeOrderError: null };
    case ACTIVE_ORDERS_SUCCESS:
      return { ...state, activeOrderLoading: false, isAuthenticated: true };
    case ACTIVE_ORDERS_FAILURE:
      return {
        ...state,
        activeOrderLoading: false,
        activeOrderError: action.payload,
      };
    default:
      return state;
  }
};

// Thunk for Active Order
export const activeOrders =
  (param: {
    orderType: string;
    accountNumber: string;
    orderBy: string;
    sorting: string;
    pagesize: number;
    pageNo: number;
    days?: string | number | undefined | null;
    reference?: any;
    orderStatusDisplay?: any;
    deliveryCompanyName?: any;
    pickupCompanyName?: any;
    updatedDeliveryDateTime?: any;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(activeOrdersRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders`,
        param
      );
      // Assuming  Active Order was successful if no error occurred
      dispatch(activeOrdersSuccess());
      const data: ActiveOrdersResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(activeOrdersFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(activeOrdersFailure(error.response.data.message));
      } else {
        dispatch(activeOrdersFailure(error.message));
      }
    }
  };

export const recentDeliveries =
  (param: {
    accountNumber: string;
    orderBy: string;
    sorting: string;
    pagesize: number;
    pageNo: number;
    days?: string | number | undefined | null;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(activeOrdersRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/RecentDeliveries`,
        param
      );
      // Assuming  Active Order was successful if no error occurred
      dispatch(activeOrdersSuccess());
      const data: ActiveOrdersResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(activeOrdersFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(activeOrdersFailure(error.response.data.message));
      } else {
        dispatch(activeOrdersFailure(error.message));
      }
    }
  };

export const filterOptions =
  (param: {
    orderType: string;
    accountNumber: string;
    orderBy: string;
    sorting: string;
    pagesize: number;
    pageNo: number;
    days?: string | number | undefined | null;
    orderStatusDisplay: any;
    deliveryCompanyName: any;
    pickupCompanyName: any;
    updatedDeliveryDateTime: any;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(activeOrdersRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/FilteredActiveOrderColumn`,
        param
      );
      // Assuming  Active Order was successful if no error occurred
      dispatch(activeOrdersSuccess());
      const data: ActiveOrdersResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(activeOrdersFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(activeOrdersFailure(error.response.data.message));
      } else {
        dispatch(activeOrdersFailure(error.message));
      }
    }
  };
export default activeOrdersReducer;
