import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { currency } from "./currency.reducer";

const useCurrencyLogic = () => {
  const dispatch = useDispatch();
  const { currencyLoading, currencyError, currencyData } = useSelector(
    (state: RootState) => state.currency
  );

  useEffect(() => {
    if (currencyError) {
      // dispatch(showNotification('Account failed: ' + error, 'error'));
    }
  }, [currencyError, dispatch]);

  const handlecurrency = async () => {
    try {
      if (currencyData && currencyData?.data?.data.length > 0) {
        return currencyData;
      } else {
        const response = await dispatch(currency() as any);
        return response;
      }
    } catch (err) {
      throw err;
    }
  };

  return { currencyLoading, currencyError, currency: handlecurrency };
};

export default useCurrencyLogic;
