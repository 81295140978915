import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function Loader() {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column", // Adjusted to stack content vertically
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        zIndex: 9999,
      }}
    > 
      <CircularProgress color="primary" id="btnProgressBar"/>
      <Typography variant="body2" sx={{ marginTop: 1, color: "#046386" }}>
        Please wait...
      </Typography>
    </Box>
  );
}

export default Loader;
