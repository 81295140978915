import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { account } from "./accounts.reducer";

const useAccounntsLogic = () => {
  const dispatch = useDispatch();
  const { loading, error, accounts } = useSelector(
    (state: RootState) => state.acconts
  );

  useEffect(() => {
    if (error) {
      // dispatch(showNotification('Account failed: ' + error, 'error'));
    }
  }, [error, dispatch]);

  const handleAccount = async (
    param: {
      userId: number;
      companyID: number;
      useIncludeAllItemrId: boolean;
    },
    fetchDataFromAPI: boolean = false
  ) => {
    try {
      if (!fetchDataFromAPI && accounts && accounts?.data?.data?.length > 0) {
        return accounts;
      } else {
        const response = await dispatch(account(param) as any);
        return response;
      }
    } catch (err) {
      throw err;
    }
  };

  return { loading, error, account: handleAccount };
};

export default useAccounntsLogic;
