import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo/mxweb-logo.svg";
import {
  TextField,
  Container,
  Box,
  Typography,
  IconButton,
  InputAdornment,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate, useSearchParams } from "react-router-dom";
import useChangePasswordLogic from "./change-password.logic";
import { ErrorOutline } from "@mui/icons-material";
import Loader from "../../shared/util/loader";

const ChangePassword: React.FC = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [recoveryDataError, setRecoveryDataError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const guid = searchParams.get("GUID");
  const [errorStatus, setErrorStatus] = useState<any>("");
  let { recoverData, submit } = useChangePasswordLogic();

  useEffect(() => {
    const fetchRecoveryData = async () => {
      if (guid) {
        try {
          const response = await recoverData({ GUID: guid });
          if (response.success) {
            setRecoveryDataError(false);
            setIsLoading(false);
          } else {
            navigate("/");
          }
          // Proceed with success handling if needed
        } catch (error: any) {
          setRecoveryDataError(true);
          navigate("/"); // Navigate to login page on error
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };
    fetchRecoveryData();
    // eslint-disable-next-line
  }, [navigate]);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validatePasswords = () => {
    let isValid = true;

    // Check if the password is provided
    if (!password) {
      setPasswordError("Please enter a new password");
      isValid = false;
    } else {
      const passwordCriteria =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&#+-]{8,}$/;
      if (!passwordCriteria.test(password)) {
        setPasswordError(
          "Passwords must contain one uppercase letter, one lowercase letter, and one numeric character."
        );
        isValid = false;
      } else {
        setPasswordError("");
        // Check if passwords match
        if (password !== confirmPassword) {
          setConfirmPasswordError("Passwords do not match.");
          isValid = false;
        } else {
          setConfirmPasswordError("");
        }

        // Check if password meets criteria
      }
    }

    return isValid;
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordError("");
    setConfirmPasswordError("");
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
    setPasswordError("");
    setConfirmPasswordError("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validatePasswords()) {
      try {
        // setLoading(true);
        const response = await submit({
          GUID: guid,
          Password: password,
        });
        if (response && response.success) {
          navigate("/");
        } else {
          setErrorStatus(response.message);
        }

        setLoading(false);
        // if (!response.ok) {
        //   throw new Error("Failed to change password");
        // }
        // Handle success if needed
      } catch (error: any) {
        setErrorStatus("Error changing password: " + error.message);
        setLoading(false);
        // Handle error (could navigate to login or show an alert)
      }
    }
  };

  const handlePrivacyStatementClick = () => {
    navigate("/privacy-statement");
  };
  const handleSupportSignIn = () => {
    navigate("/support-sign-in");
  };

  return (
    <>
      {isLoading ? (
        <Box className="d-flex justify-content-center align-items-center vh-100">
          <Loader />
        </Box>
      ) : (
        <Container
          className="change-password-section"
          maxWidth="sm"
          sx={{ mt: 4 }}
        >
          {/* Logo */}
          <Box className="lognin-image">
            <img src={logo} alt="Company Logo" style={{ maxWidth: "100%" }} />
          </Box>
          {/* Forgot Password title */}
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            textAlign="start"
            className="sign-in-title"
          >
            Change Password
          </Typography>
          <div className="change-password-form-border">
            {/* Instructions */}
            <Typography
              variant="h5"
              component="h1"
              gutterBottom
              textAlign="start"
              className="change-password-statement"
            >
              Please enter a new password twice and select "Confirm Password"
            </Typography>
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert
                id="txtCpInstructions"
                severity="info"
                className="change-password-instructions"
                icon={<ErrorOutline style={{ color: "#008298" }} />}
              >
                <AlertTitle className="instructions-title">
                  Password Hints
                </AlertTitle>
                For the purposes of security, every password must contain:{" "}
                <br />
                <FiberManualRecordIcon className="info-dots" />
                One uppercase letter <br />
                <FiberManualRecordIcon className="info-dots" />
                One lowercase letter <br />
                <FiberManualRecordIcon className="info-dots" />
                One numeric character
              </Alert>
            </Stack>
            {/* Recovery Data Error */}
            {recoveryDataError && (
              <Stack sx={{ width: "100%", marginTop: "10px" }} spacing={2}>
                <Alert severity="error" variant="filled" id="txtCpFetchError">
                  Error fetching recovery data. Please try again or contact
                  support.
                </Alert>
              </Stack>
            )}

            {/* Validation */}
            {(confirmPasswordError || passwordError) && (
              <Stack sx={{ width: "100%", marginTop: "10px" }} spacing={2}>
                <Alert severity="error" variant="filled" id="txtCpErrorText">
                  {confirmPasswordError && <div>{confirmPasswordError}</div>}
                  {passwordError && <div>{passwordError}</div>}
                </Alert>
              </Stack>
            )}

            {errorStatus && (
              <Stack sx={{ width: "100%", marginTop: "10px" }} spacing={2}>
                <Alert severity="error" variant="filled" id="txtCpErrorStatus">
                  {errorStatus && <div>{errorStatus}</div>}
                </Alert>
              </Stack>
            )}

            <div>
              <form onSubmit={handleSubmit}>
                {/* Password input */}
                <TextField
                  id="txtCpPassword"
                  name="password"
                  label="New Password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={password}
                  onChange={handlePasswordChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                          id="btnTogglePasswordVisiblility"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* Confirm Password input */}
                <TextField
                  id="txtCpConfirmPassword"
                  name="confirm-password"
                  label="Re-enter New Password"
                  type={showConfirmPassword ? "text" : "password"}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={handleToggleConfirmPasswordVisibility}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box
                  className="form_info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={2}
                ></Box>
                {/* Recover button */}
                <LoadingButton
                  className="change-password-btn"
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  loading={loading}
                  id="btnCpConfirmNewPassword"
                >
                  Confirm New Password
                </LoadingButton>
              </form>
            </div>
          </div>

          {/* Other links */}
          <Box>
            <Box
              className="Privacy_info"
              maxWidth="sm"
              sx={{ display: "flex", gap: "64px", width: "100%" }}
            >
              <Button className="text" onClick={handlePrivacyStatementClick} id="btnCpPrivacyStatement">
                Privacy Statement
              </Button>
              <Button className="text-custom" onClick={handleSupportSignIn} id="btnCpSupportLogin">
                Support login
              </Button>
            </Box>
          </Box>
        </Container>
      )}
    </>
  );
};

export default ChangePassword;
