import React, { useEffect, useState } from "react";
import useAttributesLogic from "../../lookup/attributes/attributes.logic";
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

interface props {
  handleAttChange: (_item: any) => void;
  serviceID: any;
  accountNumber: any;
  preSelectedItems?: any;
  setIsDisplayAttributes: (_item: boolean) => void;
}
const Attributes = (props: props) => {
  const { Attributes } = useAttributesLogic();
  const {
    handleAttChange,
    serviceID,
    accountNumber,
    preSelectedItems,
    setIsDisplayAttributes,
  } = props;
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [attributesList, setAttributesList] = useState<any>([]);

  // Handle change for checkbox toggle
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: string
  ) => {
    if (event.target.checked) {
      setSelectedItems((prev) => [...prev, item]);
    } else {
      // Remove item from the selected list if unchecked
      setSelectedItems((prev) => prev.filter((selected) => selected !== item));
    }
  };

  useEffect(() => {
    if (selectedItems && selectedItems.length > 0) {
      const attributeTypeID = selectedItems;
      const selectedAttributeNames = attributesList
        .filter((attribute: any) =>
          selectedItems.includes(attribute.attributeTypeID)
        )
        .map((attribute: any) => attribute.attributeTypeName);
      const selectedAttributeCode = attributesList
        .filter((attribute: any) =>
          selectedItems.includes(attribute.attributeTypeID)
        )
        .map((attribute: any) => attribute.attributeTypeCode);
      const attData = {
        attributeList: selectedAttributeNames,
        attributeTypeID: attributeTypeID,
        attributeCode: selectedAttributeCode,
      };

      handleAttChange(attData);
    } else {
      handleAttChange([]);
    }
  }, [selectedItems]);

  useEffect(() => {
    if (preSelectedItems) {
      const attributeTypeIDs = preSelectedItems?.map(
        (item: any) => item.attributeTypeID
      );

      setSelectedItems(attributeTypeIDs);
    }
  }, [preSelectedItems]);

  useEffect(() => {
    if (serviceID && accountNumber) {
      if (!preSelectedItems) {
        setSelectedItems([]);
      }

      /* Hide the attributes story: 59026, commented getAttributes() fn so setIsDisplayAttributes always be a false and Attributes section will not be display. Once we want to enable functionality just uncomment below line  */
      //getAttributes();
    }
  }, [serviceID, accountNumber]);

  const getAttributes = async () => {
    try {
      const param = {
        accountNumber: accountNumber,
        serviceID: serviceID,
      };
      const response = await Attributes(param);

      if (response.data && response.data.data) {
        setIsDisplayAttributes(true);
        setAttributesList(response.data.data);
      } else {
        setIsDisplayAttributes(false);
        setAttributesList([]);
      }
    } catch (err) {
      setIsDisplayAttributes(false);
      setAttributesList([]);
      handleAttChange([]);
      setSelectedItems([]);
    }
  };
  if (!attributesList || attributesList.length === 0) {
    setIsDisplayAttributes(false);
    return null;
  }
  return (
    <>
      <Typography variant="h5" color="primary" className="ml-8 mt-40">
        4. Attributes
      </Typography>

      <Typography
        variant="subtitle2"
        className="ml-8 mt-8 mb-16 head_lbl_header demm_slass"
      >
        Attributes will be used to help our operations team adequately process
        your order and generate an accurate cost estimate.
      </Typography>
      <Box className="d-flex flex-column border p16 rounded w-100">
        <Typography variant="h6" className="Sub-header">
          Attributes
        </Typography>

        <FormGroup>
          {attributesList?.map((option: any, index: number) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={selectedItems.includes(option?.attributeTypeID)}
                  onChange={(e) => handleChange(e, option?.attributeTypeID)}
                  name={option?.attributeTypeID}
                  id={`attribute-${option?.attributeTypeID}`}
                />
              }
              label={option?.attributeTypeName}
            />
          ))}
        </FormGroup>
      </Box>
    </>
  );
};

export default Attributes;
