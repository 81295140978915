import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";
import { UnDetailsItemDto } from "../../../models/long-form-item-dto";

// Action Types for unDetails
const UN_DETAILS_REQUEST = "UN_DETAILS_REQUEST";
const UN_DETAILS_SUCCESS = "UN_DETAILS_SUCCESS";
const UN_DETAILS_FAILURE = "UN_DETAILS_FAILURE";

// Action Creators for unDetails
export const unDetailsRequest = () => ({
  type: UN_DETAILS_REQUEST,
});

export const unDetailsSuccess = () => ({
  type: UN_DETAILS_SUCCESS,
});

export const unDetailsFailure = (error: string) => ({
  type: UN_DETAILS_FAILURE,
  payload: error,
});

// Initial State for UnDetails
export interface UnDetailsState {
  isAuthenticated: boolean;
  unDetailsLoading: boolean;
  unDetailsError: string | null;
}

const initialAuthState: UnDetailsState = {
  isAuthenticated: false,
  unDetailsLoading: false,
  unDetailsError: null,
};

// Define the interface for the API response
interface unDetailsResponse {
  success: boolean;
  message: string;
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
  totalPages: number;
  items: keyof UnDetailsItemDto;
  statusCode: string;
}

// Reducer for Authentication
const unDetailsReducer = (
  state = initialAuthState,
  action: any
): UnDetailsState => {
  switch (action.type) {
    case UN_DETAILS_REQUEST:
      return { ...state, unDetailsLoading: true, unDetailsError: null };
    case UN_DETAILS_SUCCESS:
      return { ...state, unDetailsLoading: false, isAuthenticated: true };
    case UN_DETAILS_FAILURE:
      return { ...state, unDetailsLoading: false, unDetailsError: action.payload };
    default:
      return state;
  }
};

// Thunk for UnDetails
export const unDetails =
  (param: {
    companyID: number;
    pageNumber: number;
    pageSize: number;
    sortColumn: string;
    sortOrder: string;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(unDetailsRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Lookup/GetUnDetails`,
        param
      );
      // Assuming Account was successful if no error occurred
      dispatch(unDetailsSuccess());
      const data: unDetailsResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(unDetailsFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(unDetailsFailure(error.response.data.message));
      } else {
        dispatch(unDetailsFailure(error.message));
      }
    }
  };

export default unDetailsReducer;
