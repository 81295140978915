import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { MySettingDto } from "../../models/my-setting-dto";

// Action Types for My Settings
const MY_SETTINGS_REQUEST = "MY_SETTINGS_REQUEST";
const MY_SETTINGS_SUCCESS = "MY_SETTINGS_SUCCESS";
const MY_SETTINGS_FAILURE = "MY_SETTINGS_FAILURE";

// Action Creators for My Settings
export const mySettingsRequest = () => ({
  type: MY_SETTINGS_REQUEST,
});

export const mySettingsSuccess = () => ({
  type: MY_SETTINGS_SUCCESS,
});

export const mySettingsFailure = (error: string) => ({
  type: MY_SETTINGS_FAILURE,
  payload: error,
});

// Initial State for  My Settings
export interface MySettingsState {
  isAuthenticated: boolean;
  MySettingsLoading: boolean;
  MySettingsError: string | null;
}

const initialAuthState: MySettingsState = {
  isAuthenticated: false,
  MySettingsLoading: false,
  MySettingsError: null,
};

// Define the interface for the API response
interface MySettingsResponse {
  success: boolean;
  message: string;
  data: string;
  statusCode: string;
}

// Reducer for Authentication
const mySettingsReducer = (
  state = initialAuthState,
  action: any
): MySettingsState => {
  switch (action.type) {
    case MY_SETTINGS_REQUEST:
      return { ...state, MySettingsLoading: true, MySettingsError: null };
    case MY_SETTINGS_SUCCESS:
      return { ...state, MySettingsLoading: false, isAuthenticated: true };
    case MY_SETTINGS_FAILURE:
      return {
        ...state,
        MySettingsLoading: false,
        MySettingsError: action.payload,
      };
    default:
      return state;
  }
};

// Thunk for My Settings
export const MySettings =
  (param: MySettingDto) => async (dispatch: Dispatch) => {
    dispatch(mySettingsRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Authentication/mysetting/update`,
        param
      );
      // Assuming  My Settings was successful if no error occurred
      dispatch(mySettingsSuccess());
      const data: MySettingsResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(mySettingsFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(mySettingsFailure(error.response.data.message));
      } else {
        dispatch(mySettingsFailure(error.message));
      }
    }
  };

export default mySettingsReducer;
