import React, { useEffect, useState } from "react";
import {
  Button,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  SelectChangeEvent,
} from "@mui/material";

import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";
import useWillCallLogic from "../../../modules/will-call-orders/will-call-orders.logic";
import CustomDatePicker from "../CustomDatePicker";

interface MyDialogProps {
  open: boolean;
  handleClose: (isReload: boolean) => void;
  shipmentGUID: string;
}

const ActiveOrderDialog: React.FC<MyDialogProps> = ({
  open,
  handleClose,
  shipmentGUID,
}) => {
  const { willCallOrder } = useWillCallLogic();
  const [shipmentReadyTime, setShipmentReadyTime] = useState<any>(null);
  const [shipmentReadyTimeError, setShipmentReadyTimeError] =
    useState<string>("");

  const [requestedDeliveryTime, setRequestedDeliveryTime] = useState<any>(null);
  const [initialShipmentReadyTime, setInitialShipmentReadyTime] =
    useState<any>(null);
  const [requestedDeliveryTimeError, setRequestedDeliveryTimeError] =
    useState<string>("");
  const [deliveryOption, setDeliveryOption] = useState("B");
  const [asap, setAsap] = useState(true);

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);

  //   const shipmentGUID = urlParams.get("ShipmentGUID");

  //   if (shipmentGUID) {
  //     getWillCallDetails("", shipmentGUID);
  //   }
  // }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setAsap(isChecked);
    setRequestedDeliveryTimeError("");
    if (isChecked) {
      setRequestedDeliveryTime("");
      setRequestedDeliveryTimeError("");
    }
  };

  // const [loading, setLoading] = useState(false);

  // const willCallLoading = (isLoading: boolean) => {
  //   setLoading(isLoading);
  // };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    setDeliveryOption(value);
  };

  const handleSave = async () => {
    let hasError = false;

    if (!shipmentReadyTime) {
      setShipmentReadyTimeError("Please specify a Shipment Ready Time.");
      hasError = true;
    }

    if (!asap && !requestedDeliveryTime) {
      setRequestedDeliveryTimeError(
        "Please specify a Requested Delivery Time."
      );
      hasError = true;
    }

    if (shipmentReadyTimeError || requestedDeliveryTimeError) {
      hasError = true;
    }

    if (hasError) {
      return;
    }
    const formatDate = (date: any) => {
      if (!date) return null;
      return new Date(date).toISOString();
    };
    const formattedShipmentReadyTime = formatDate(shipmentReadyTime);
    const formattedRequestedDeliveryTime = formatDate(requestedDeliveryTime);
    try {
      const param = {
        shipmentGUID: shipmentGUID,
        pickupReadyDate: formattedShipmentReadyTime,
        pickupReadyNow: false,
        deliverWhen: deliveryOption,
        deliveryRequestDate: formattedRequestedDeliveryTime,
        deliveryRequestAsap: asap,
      };

      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/WillCAllOrder/Activate`,
        param
      );

      if (response.status === 200) {
        handleClose(true);
      }
    } catch (error: any) {
      setRequestedDeliveryTimeError(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }

    handleDialogClose();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const now = new Date();
    const initialTime = new Date(initialShipmentReadyTime);
    if (name === "pickupReadyTime") {
      if (!value) {
        setShipmentReadyTimeError("Please specify a Shipment Ready Time.");
      } else if (new Date(value) < initialTime) {
        setShipmentReadyTimeError("The ready time cannot be in past.");
      } else {
        setShipmentReadyTimeError("");
      }
      setShipmentReadyTime(value);
    } else if (name === "deliveryRequestTime") {
      if (!value) {
        setRequestedDeliveryTimeError(
          "Please specify a Requested Delivery Time."
        );
      } else if (!asap && new Date(value) < now) {
        setRequestedDeliveryTimeError("The delivery time cannot be in past.");
      } else {
        setRequestedDeliveryTimeError("");
      }
      setRequestedDeliveryTime(value);
    }
  };

  const handleDialogClose = () => {
    handleClose(false);
    setShipmentReadyTime(null);
    setRequestedDeliveryTime(null);
    setAsap(true);
    setDeliveryOption("B");
  };

  const getCurrentLocalDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  useEffect(() => {
    if (open) {
      getWillCallDetails(shipmentGUID);
      setShipmentReadyTimeError("");
      setRequestedDeliveryTimeError("");
    }
  }, [open, shipmentGUID]);

  const getWillCallDetails = async (ShipmentGUID: string) => {
    try {
      //willCallLoading(true);
      const param = {
        shipmentguid: ShipmentGUID,
      };
      const response = await willCallOrder(param);
      if (response.data) {
        const currentDateTime = response.data.currentDateTime;
        // Convert the date string to a Date object
        const dateObj = new Date(currentDateTime);
        // Format the date to YYYY-MM-DDTHH:MM
        const formattedDate = dateObj
          .toISOString()
          .replace(/T/, " ")
          .substring(0, 16);
        setInitialShipmentReadyTime(formattedDate);
        setShipmentReadyTime(formattedDate);
      } else {
        // Handle non-200 responses here if needed
      }
    } catch {
    } finally {
      // willCallLoading(false);
    }
  };

  return (
    <div className="container-fluid active-order">
      <Dialog open={open} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" className="fw-bold">
              Activate Order
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent className="w-100">
          <Box className="d-flex flex-column w-100 border rounded p-3">
            <Typography variant="h6" className="Sub-header">
              Shipment Ready Time
            </Typography>
            <CustomDatePicker
              handleDateChange={handleChange}
              id="txtAodReadyDate"
              label="Ready Date"
              name="pickupReadyTime"
              value={shipmentReadyTime}
              isClearable={false}
              helperText={
                !!shipmentReadyTimeError ? shipmentReadyTimeError : ""
              }
              showError={!!shipmentReadyTimeError}
              showTimeSelect={true}
              minDate={getCurrentLocalDateTime()}
              isPortal={true}
            />
          </Box>
          <Box className="d-flex flex-column w-100 border rounded p16 mt-3">
            <Typography variant="h6" className="Sub-header">
              Requested Delivery Time
            </Typography>

            <Grid container spacing={2}>
              <Grid item lg={6} sm={6} xs={12}>
                <Select
                  variant="outlined"
                  defaultValue="B"
                  className="w-100 mb-2"
                  id="ddAodDeliveryWhen"
                  name="deliveryWhen"
                  value={deliveryOption}
                  onChange={handleSelectChange}
                  disabled={asap}
                >
                  <MenuItem value="B" >Before</MenuItem>
                  <MenuItem value="A">After</MenuItem>
                  <MenuItem value="T">At</MenuItem>
                </Select>
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <CustomDatePicker
                  handleDateChange={handleChange}
                  label="Requested Delivery Time"
                  name="deliveryRequestTime"
                   id="txtAodDeliveryDate"
                  value={requestedDeliveryTime}
                  isClearable={false}
                  showError={!asap && !!requestedDeliveryTimeError}
                  errorColor={requestedDeliveryTimeError ? "#d32f2f" : "blue"}
                  // errorColor="#d32f2f"
                  helperText={
                    !!requestedDeliveryTimeError
                      ? requestedDeliveryTimeError
                      : ""
                  }
                  showTimeSelect={true}
                  minDate={getCurrentLocalDateTime()}
                  isDisable={asap}
                  isPortal={true}
                />
              </Grid>
              <Grid item lg={12} sm={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="deliveryRequestAsap"
                      checked={asap}
                      onChange={handleCheckboxChange}
                      id="cbAodAsap"
                    />
                  }
                  label="ASAP"
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box className="d-flex gap-2 p8">
            <Button
              onClick={handleDialogClose}
              className="MuiButton Action-btn"
              variant="text"
               id="btnAodCancel"
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              className="MuiButton Action-btn"
              id="btnAodSave"
            >
              SAVE
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ActiveOrderDialog;
