import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../types/global";
import { deleteShipmentPiece } from "./delete-shipment-piece.reducer";



const useDeleteShipmentPieceLogic = () => {
  const dispatch = useDispatch();
  const { deleteShipmentPieceLoading, deleteShipmentPieceError } = useSelector((state: RootState) => state.deleteShipmentPiece);

  useEffect(() => {
    if (deleteShipmentPieceError) {
      // dispatch(showNotification('Country failed: ' + error, 'error'));
    }
  }, [deleteShipmentPieceError, dispatch]);

  const handleDeleteShipmentPiece= async (param: {
    shipmentPieceID:string
  }) => {
    try {
      const response = await dispatch(deleteShipmentPiece(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { deleteShipmentPieceLoading, deleteShipmentPieceError, deleteShipmentPiece: handleDeleteShipmentPiece };
};

export default useDeleteShipmentPieceLogic;
