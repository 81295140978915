import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../types/global";
import { updateShipmentPiece } from "./update-shipment-piece.reducer";
import { ShipmentPieceDto } from "../../../../models/shipment-piece-dto";



const useUpdateShipmentPieceLogic = () => {
  const dispatch = useDispatch();
  const { updateShipmentPieceLoading, updateShipmentPieceError } = useSelector((state: RootState) => state.updateShipmentPiece);

  useEffect(() => {
    if (updateShipmentPieceError) {
      // dispatch(showNotification('Country failed: ' + error, 'error'));
    }
  }, [updateShipmentPieceError, dispatch]);

  const handleUpdateShipmentPiece= async (param: ShipmentPieceDto) => {
    try {
      const response = await dispatch(updateShipmentPiece(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { updateShipmentPieceLoading, updateShipmentPieceError, updateShipmentPiece: handleUpdateShipmentPiece };
};

export default useUpdateShipmentPieceLogic;
