import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { searchUNDetails } from "./searchUNDetails.reducer";

const useSearchUNDetailsLogic = () => {
  const dispatch = useDispatch();
  const { searchUNDetailsLoading, searchUNDetailsError } = useSelector((state: RootState) => state.searchUNDetails);

  useEffect(() => {
    if (searchUNDetailsError) {
      // dispatch(showNotification('Account failed: ' + error, 'error'));
    }
  }, [searchUNDetailsError, dispatch]);

  const handleSearchUnDetails = async (param: {
    companyID: number;
    unNumber: string,
  }) => {
    try {
      const response = await dispatch(searchUNDetails(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { searchUNDetailsLoading, searchUNDetailsError, searchUNDetails: handleSearchUnDetails };
};

export default useSearchUNDetailsLogic;