import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { AzureSupportLogin, GetMicrosoftLoginSupportURL } from "./support-sign in.reducer";

const useSupportSignupLogic = () => {
  const dispatch = useDispatch();
  const { supportSignupLoading, supportSignupError } = useSelector((state: RootState) => state.supportSignup);

  useEffect(() => {
    if (supportSignupError) {
      // dispatch(showNotification('Login failed: ' + error, 'error'));
    }
  }, [supportSignupError, dispatch]);

  const handleGetMicrosoftLoginSupportURL = async (CustomerUserID: string) => {
    try {
      const response = await dispatch(GetMicrosoftLoginSupportURL(CustomerUserID) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleAzureSupportLogin = async (param: {
    code: string;
    state: string;
  }) => {
    try {
      const response = await dispatch(AzureSupportLogin(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { 
    supportSignupLoading, 
    supportSignupError, 
    getMicrosoftLoginSupportURL: handleGetMicrosoftLoginSupportURL,
    azureSupportLogin: handleAzureSupportLogin,
    };
};

export default useSupportSignupLogic;