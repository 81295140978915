import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { DangerousGoodsItemDto } from "../../models/dangerous-goods-item-dto";

const DANGEROUS_GOODS_GET_REQUEST = "DANGEROUS_GOODS_GET_REQUEST";
const DANGEROUS_GOODS_GET_SUCCESS = "DANGEROUS_GOODS_GET_SUCCESS";
const DANGEROUS_GOODS_GET_FAILURE = "DANGEROUS_GOODS_GET_FAILURE";

export const getDangerousGoodsRequest = () => ({
  type: DANGEROUS_GOODS_GET_REQUEST,
});

export const getDangerousGoodsSuccess = () => ({
  type: DANGEROUS_GOODS_GET_SUCCESS,
});

export const getDangerousGoodsFailure = (error: string) => ({
  type: DANGEROUS_GOODS_GET_FAILURE,
  payload: error,
});

export interface GetDangerousGoodsState {
  isAuthenticated: boolean;
  dangerousGoodsLoading: boolean;
  dangerousGoodsError: string | null;
}

const initialAuthState: GetDangerousGoodsState = {
  isAuthenticated: false,
  dangerousGoodsLoading: false,
  dangerousGoodsError: null,
};

// Define the interface for the API response
interface GetDangerousGoodsResponse {
  success: boolean;
  message: string;
  data: DangerousGoodsItemDto;
  statusCode: string;
}

// Reducer for Authentication
const getDangerousGoodsReducer = (
  state = initialAuthState,
  action: any
): GetDangerousGoodsState => {
  switch (action.type) {
    case DANGEROUS_GOODS_GET_REQUEST:
      return {
        ...state,
        dangerousGoodsLoading: true,
        dangerousGoodsError: null,
      };
    case DANGEROUS_GOODS_GET_SUCCESS:
      return { ...state, dangerousGoodsLoading: false, isAuthenticated: true };
    case DANGEROUS_GOODS_GET_FAILURE:
      return {
        ...state,
        dangerousGoodsLoading: false,
        dangerousGoodsError: action.payload,
      };
    default:
      return state;
  }
};

export const getDangerousGoods =
  (param: { shipmentGUID: string }) => async (dispatch: Dispatch) => {
    dispatch(getDangerousGoodsRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/orders/DangerousGoods/Get`,
        param
      );
      dispatch(getDangerousGoodsSuccess());
      const data: GetDangerousGoodsResponse = response.data;
      if (response.status === 200 && data.success) {
        return { data };
      } else {
        dispatch(getDangerousGoodsFailure(data.message));
        return { data };
      }
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(getDangerousGoodsFailure(error.response.data.message));
        return error.response;
      } else {
        dispatch(getDangerousGoodsFailure(error.message));
      }
    }
  };

export default getDangerousGoodsReducer;
