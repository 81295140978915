import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";
import { ContentItemDto } from "../../../models/long-form-item-dto";

// Action Types for Content
const CONTENT_REQUEST = "CONTENT_REQUEST";
const CONTENT_SUCCESS = "CONTENT_SUCCESS";
const CONTENT_FAILURE = "CONTENT_FAILURE";

// Action Creators for Content
export const contentRequest = () => ({
  type: CONTENT_REQUEST,
});

export const contentSuccess = (data: any) => ({
  type: CONTENT_SUCCESS,
  payload: data,
});

export const contentFailure = (error: string) => ({
  type: CONTENT_FAILURE,
  payload: error,
});

// Initial State for Content
export interface ContentState {
  isAuthenticated: boolean;
  contentLoading: boolean;
  contentError: string | null;
  contentData: any;
}

const initialAuthState: ContentState = {
  isAuthenticated: false,
  contentLoading: false,
  contentError: null,
  contentData: [],
};

// Define the interface for the API response
interface ContentResponse {
  success: boolean;
  message: string;
  data: keyof ContentItemDto;
  statusCode: string;
}

// Reducer for Authentication
const contentReducer = (
  state = initialAuthState,
  action: any
): ContentState => {
  switch (action.type) {
    case CONTENT_REQUEST:
      return { ...state, contentLoading: true, contentError: null };
    case CONTENT_SUCCESS:
      return {
        ...state,
        contentLoading: false,
        isAuthenticated: true,
        contentData: action.payload,
      };
    case CONTENT_FAILURE:
      return { ...state, contentLoading: false, contentError: action.payload };
    default:
      return state;
  }
};

// Thunk for Content
export const content =
  (param: { companyID: number; contentsReferenceGroupGUID: string }) =>
  async (dispatch: Dispatch) => {
    dispatch(contentRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Lookup/GetContents`,
        param
      );
      // Assuming Account was successful if no error occurred

      const data: ContentResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        dispatch(contentSuccess(response));
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(contentFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(contentFailure(error.response.data.message));
      } else {
        dispatch(contentFailure(error.message));
      }
    }
  };

export default contentReducer;
