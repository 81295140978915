import React from "react";
import { Box, Typography, Link } from "@mui/material";

const Footer: React.FC = () => {
  const branchName = process.env.REACT_APP_BRANCH_NAME || 'Unknown Branch';
  const version = process.env.REACT_APP_VERSION || 'Unknown Version';

  return (
    <Box className="Footer-lbl"
      component="footer"
      sx={{
        backgroundColor: "#1A1A52", // Dark blue background color
        padding: "10px 20px", // Some padding for spacing
        textAlign: "center", // Center the text
        color: "white", // White text color
      }}
    >
      <Box className="footer-paginator"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", sm: "row" }, // Stack on small screens
        }}
      >
        <Typography variant="body2" component="p">
          Copyright © 2024 MNX, A UPS Company. All rights reserved. 
          <span className="Version-block"> Version {branchName} {version}</span>
        </Typography>
        <Link className="privacylbl" href="/privacy-statement" color="inherit" underline="hover">
          PRIVACY STATEMENT
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;