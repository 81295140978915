import React, { useEffect, useMemo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import Menu from "@mui/material/Menu";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl, InputLabel, MenuItem, Select, Grid } from "@mui/material";

interface Props {
  name: string;
  selectedItems?: any;
  headCellFilters?: any;
  onSelectedItem: (name: string, _items: any) => void;
  isMobile?: boolean;
  headCellFiltersOptions?: any;
}

interface SearchOption {
  value: string;
  label: string;
}

// Define the type for the options mapping object
interface OptionsMapping {
  [key: string]: SearchOption[];
}
const ActiveOrderFilterDropdown = (props: Props) => {
  const {
    name,
    onSelectedItem,
    selectedItems,
    headCellFilters,
    isMobile,
    headCellFiltersOptions,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState<any>([]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formattedDelCompanies = useMemo(() => {
    return headCellFiltersOptions?.deliveryCompanyName
      ? headCellFiltersOptions.deliveryCompanyName
          .filter(
            (company: any) =>
              company.deliveryCompanyName !== null &&
              company.deliveryCompanyName !== ""
          )
          .sort((a: any, b: any) =>
            a.deliveryCompanyName.localeCompare(b.deliveryCompanyName)
          ) // Sort A to Z
          .map((company: any) => ({
            label: company.deliveryCompanyName,
            value: company.deliveryCompanyName,
          }))
      : [];
  }, [headCellFiltersOptions]);

  const formattedPickupCompanies = useMemo(() => {
    return headCellFiltersOptions?.pickupCompanyName
      ? headCellFiltersOptions.pickupCompanyName
          .filter(
            (company: any) =>
              company.pickupCompanyName !== null &&
              company.pickupCompanyName !== ""
          )
          .sort((a: any, b: any) =>
            a.pickupCompanyName.localeCompare(b.pickupCompanyName)
          ) // Sort A to Z
          .map((company: any) => ({
            label: company.pickupCompanyName,
            value: company.pickupCompanyName,
          }))
      : [];
  }, [headCellFiltersOptions]);

  const formattedStatus = useMemo(() => {
    return headCellFiltersOptions?.orderStatusDisplay
      ? headCellFiltersOptions.orderStatusDisplay
          .filter(
            (company: any) =>
              company.orderStatusDisplay !== null &&
              company.orderStatusDisplay !== ""
          )
          .sort((a: any, b: any) =>
            a.orderStatusDisplay.localeCompare(b.orderStatusDisplay)
          )
          .map((company: any) => ({
            label: company.orderStatusDisplay,
            value: company.orderStatus,
          }))
      : [];
  }, [headCellFiltersOptions]);

  const formattedDateTime = useMemo(() => {
    return headCellFiltersOptions?.updatedDeliveryDateTime
      ? headCellFiltersOptions.updatedDeliveryDateTime
          .filter((company: any) => company.updatedDeliveryDateTime !== null) // Filter out rows with null updatedDeliveryDateTime
          .map((company: any) => ({
            label: company.updatedDeliveryDateTime, // Format to DD-MMM-YYYY
            value: company.updatedDeliveryDateTime,
          }))
      : [];
  }, [headCellFiltersOptions]);

  function getUniqueRecordsByLabel(data: { label: string; value: string }[]) {
    const seenLabels = new Set();
    return data.filter((item) => {
      if (!seenLabels.has(item.label)) {
        seenLabels.add(item.label);
        return true;
      }
      return false;
    });
  }
  // Create the options mapping object
  const optionsMapping: OptionsMapping = {
    orderStatusDisplay: getUniqueRecordsByLabel(formattedStatus),
    pickupCompanyName: getUniqueRecordsByLabel(formattedPickupCompanies),
    deliveryCompanyName: getUniqueRecordsByLabel(formattedDelCompanies),
    updatedDeliveryDateTime: getUniqueRecordsByLabel(formattedDateTime),
  };

  // Create the options mapping object
  const optionsMappingOriginalData: OptionsMapping = {
    orderStatusDisplay: formattedStatus,
    pickupCompanyName: formattedPickupCompanies,
    deliveryCompanyName: formattedDelCompanies,
    updatedDeliveryDateTime: formattedDateTime,
  };

  // Create the options mapping object
  const filterLabelMapping: any = {
    orderStatusDisplay: "Status",
    pickupCompanyName: "Pickup At",
    deliveryCompanyName: "Deliver To",
    updatedDeliveryDateTime: "Estimated Delivery",
  };

  type OptionKey = keyof typeof optionsMapping;

  const getSearchOptions = (name: OptionKey): SearchOption[] => {
    return optionsMapping[name] || [];
  };
  const searchOptions = useMemo(() => {
    return getSearchOptions(name);
  }, [name]);

  const searchOptionsOriginalData = useMemo(() => {
    return optionsMappingOriginalData[name] || [];
  }, [name]);

  const handleFilterChange = (event: any) => {
    const value = event.target.name;

    setSelectedFilters(
      (prevSelectedFilters: any) =>
        event.target.checked
          ? [...prevSelectedFilters, value] // Add the checked value
          : prevSelectedFilters.filter((item: any) => item !== value) // Remove the unchecked value
    );

    const data = optionsMappingOriginalData[name] || [];

    let getSameValue: any = null;
    if (name == "orderStatusDisplay") {
      const selectedItem = data?.find((_item: any) => _item.value == value);
      getSameValue = data
        .filter((_item: any) => _item.label === selectedItem?.label)
        .map((_item: any) => _item.value);
    } else {
      getSameValue = data
        .filter((_item: any) => _item.label === value)
        .map((_item: any) => _item.value);
    }

    if (getSameValue && getSameValue.length > 1) {
      if (event.target.checked) {
        const updatedFilters = [...selectedFilters, ...getSameValue];
        onSelectedItem(name, updatedFilters);
      } else {
        // Remove the selected values
        const updatedFilters = selectedFilters.filter(
          (item: any) => !getSameValue.includes(item)
        );
        onSelectedItem(name, updatedFilters);
      }
    } else {
      const updatedFilters = event.target.checked
        ? [...selectedFilters, value] // Add the checked value
        : selectedFilters.filter((item: any) => item !== value);
      onSelectedItem(name, updatedFilters);
    }
  };
  const handleSelectAll = () => {
    if (selectedFilters.length === searchOptionsOriginalData.length) {
      setSelectedFilters([]); // Deselect all if all are selected
      onSelectedItem(name, null);
    } else {
      const allValues = searchOptions.map((_item) => _item.value);
      const allValuesOri = searchOptionsOriginalData.map(
        (_item) => _item.value
      );
      setSelectedFilters(allValues); // Select all if not all are selected
      onSelectedItem(name, allValuesOri);
    }
  };

  useEffect(() => {
    setSelectedFilters(headCellFilters[name] || []);
  }, [headCellFilters]);

  const getGridProps = (name: string) => {
    if (name === "pickupCompanyName" || name === "deliveryCompanyName") {
      return { xs: 6, sm: 4, md: 2, lg: 2 };
    } else {
      return { xs: 12, sm: 5, md: 3, lg: 3 };
    }
  };
  if (isMobile) {
    return (
      <Grid item {...getGridProps(name)}>
        <FormControl size="small" className="w-100 mt-0">
          <InputLabel id="filterLabelMapping">
            {filterLabelMapping[name]}
          </InputLabel>
          <Select
            id="filterLabelMappingSelect"
            value={selectedFilters?.length === 0 ? "All" : selectedFilters} // Empty string if no filters selected
            onChange={() => {}}
            renderValue={(selected) => {
              if (
                selectedFilters?.length > 0 &&
                selected.length === searchOptionsOriginalData.length
              ) {
                return "All";
              } else if (selectedFilters?.length > 0) {
                return `${selected.length} Filters Applied`;
              } else {
                return "All";
              }
            }}
            label={filterLabelMapping[name]}
            name={name}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },

              // PaperProps: {
              //   style: servicestyle,
              // },
            }}
          >
            {/* {selectedFilters.length === 0 && (
              <MenuItem selected value="All">
                All
              </MenuItem>
            )} */}
            <MenuItem key={"all"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      selectedFilters.length ===
                      searchOptionsOriginalData.length
                        ? true
                        : false
                    }
                    onChange={handleSelectAll}
                    name={name}
                    id={`filterLabelMapping-${name}`}
                  />
                }
                label={"Select All"}
              />
            </MenuItem>
            {searchOptions.map((_item: any) => (
              <MenuItem key={_item.value}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedFilters.includes(_item.value as any)}
                      onChange={handleFilterChange}
                      name={_item.value}
                      id={`filterLabelMapping-${_item.value}`}
                    />
                  }
                  label={_item.label}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  } else {
    return (
      <div>
        <IconButton onClick={handleClick} aria-label="filter">
          <FilterListIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem key={"all"}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    selectedFilters.length === searchOptionsOriginalData.length
                      ? true
                      : false
                  }
                  onChange={handleSelectAll}
                  name={name}
                  id={`filterLabelMapping-${name}`}
                />
              }
              label={"Select All"}
            />
          </MenuItem>
          {searchOptions.map((_item: any) => (
            <MenuItem key={_item.value}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFilters.includes(_item.value as any)}
                    onChange={handleFilterChange}
                    name={_item.value}
                    id={`filterLabelMapping-${_item.value}`}
                  />
                }
                label={_item.label}
              />
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
};

export default ActiveOrderFilterDropdown;
