import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { MySettings } from "./my-settings.reducer";
import { MySettingDto } from "../../models/my-setting-dto";

const useMySettingsLogic = () => {
  const dispatch = useDispatch();
  const { MySettingsLoading, MySettingsError } = useSelector(
    (state: RootState) => state.mySettings
  );

  useEffect(() => {
    if (MySettingsError) {
      // dispatch(showNotification('Active Order failed: ' + error, 'error'));
    }
  }, [MySettingsError, dispatch]);

  const handleMySettings = async (param: MySettingDto) => {
    try {
      const response = await dispatch(MySettings(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { MySettingsLoading, MySettingsError, MySettings: handleMySettings };
};

export default useMySettingsLogic;
