import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { updateEditOrder } from "./update-edit-active-order.reducer";
import { UpdateEditOrderDto } from "../../models/edit-active-order-dto";

const useUpdateEditorderLogic = () => {
  const dispatch = useDispatch();
  const { updateEditOrderLoading, updateEditOrderError } = useSelector((state: RootState) => state.updateEditOrder);

  useEffect(() => {
    if (updateEditOrderLoading) {
      // dispatch(showNotification('Login failed: ' + error, 'error'));
    }
  }, [updateEditOrderLoading, dispatch]);

  const handleEditOrder = async (param: UpdateEditOrderDto) => {
    try {
      const response = await dispatch(updateEditOrder(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { updateEditOrderLoading, updateEditOrderError, updateEditOrder: handleEditOrder };
};

export default useUpdateEditorderLogic;