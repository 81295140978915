import React, { useEffect, useState } from "react";
import { Typography, Paper, Box, Button } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CachedIcon from '@mui/icons-material/Cached';
import { useNavigate } from "react-router-dom";
import AuthHeader from "../../shared/components/AuthHeader";

const ErrorMessage: React.FC = () => {
    const navigate = useNavigate();
    const [isAuthorized, setIsAuthorized] = useState(false);
    
    useEffect(() => {
        const userData = localStorage.getItem("userDetails");
        const parseUserData = JSON.parse(userData || "{}");
        if (parseUserData && parseUserData.token) {
            setIsAuthorized(true);
        }
    }, []);

    // const reattemptProcess = () => {
    //     // Assuming you stored the previous process URL or state in the location state
    //     const previousProcess = location.state?.from || "/";
    //     navigate(previousProcess); // Reattempt the previous process
    // };

  return (
    <div className="privacy-statement">
        {!isAuthorized && (
            <Box sx={{ display: "flex" }}>
                <AuthHeader />
            </Box>
        )}
      <Typography className="Main-header-title" variant="h4" color={"primary"}>
            Error
      </Typography>

      <Paper className="Privacy_page_details">
        <Typography variant="h6" className="Main-header-title">
            Something went wrong.
        </Typography>
        <Typography
          variant="body1"
          className="Privacy_page_info"
          sx={{ marginBottom: "32px" }}
        >
          Pardon the inconvenience. A log of this error has been sent to our 
          development team and will be working to resolve it as soon as possible. 
        </Typography> 
        <Typography variant="body1" className="Privacy_page_info">
          Please contact out support team for further assistance.
        </Typography>  
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }} className="d-flex justify-content-between">
            <Button variant="outlined" className="d-flex w-100 rounded fw-bold me-3" 
                    startIcon={<ChevronLeftIcon />} onClick={() => navigate(-1)} style={{ whiteSpace: "nowrap" }}> 
                        GO BACK
            </Button>
            </Box>
      </Paper>
    </div>
  );
};

export default ErrorMessage;
