import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { InputAdornment } from "@mui/material";
import { red } from "@mui/material/colors";
const ErrorInputAdornment = () => {
  return (
    <>
      <InputAdornment position="end" disablePointerEvents={true}>
        <InfoRoundedIcon
          style={{ color: red[500] }}
          fontSize="small"
        ></InfoRoundedIcon>
      </InputAdornment>
    </>
  );
};
export default ErrorInputAdornment;
