import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";

// Action Types for VerifyUser
const VERIFY_USER_REQUEST = "VERIFY_USER_REQUEST";
const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
const VERIFY_USER_FAILURE = "VERIFY_USER_FAILURE";

// Action Creators for VerifyUser
export const verifyUserRequest = () => ({
  type: VERIFY_USER_REQUEST,
});

export const verifyUserSuccess = () => ({
  type: VERIFY_USER_SUCCESS,
});

export const verifyUserFailure = (error: string) => ({
  type: VERIFY_USER_FAILURE,
  payload: error,
});

// Initial State for VerifyUser
export interface VerifyUserState {
  isAuthenticated: boolean;
  verifyUserLoading: boolean;
  verifyUserError: string | null;
}

const initialAuthState: VerifyUserState = {
  isAuthenticated: false,
  verifyUserLoading: false,
  verifyUserError: null,
};

// Define the interface for the API response
interface VerifyUserResponse {
  success: boolean;
  message: string;
  data: string;
  statusCode: string;
}

// Reducer for Authentication
const verifyUserReducer = (
  state = initialAuthState,
  action: any
): VerifyUserState => {
  switch (action.type) {
    case VERIFY_USER_REQUEST:
      return { ...state, verifyUserLoading: true, verifyUserError: null };
    case VERIFY_USER_SUCCESS:
      return { ...state, verifyUserLoading: false, isAuthenticated: true };
    case VERIFY_USER_FAILURE:
      return { ...state, verifyUserLoading: false, verifyUserError: action.payload };
    default:
      return state;
  }
};

// Thunk for VerifyUser
export const verifyUser =
  (param: {
    userGUID: string;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(verifyUserRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Authentication/VerifyNewUser`,
        param
      );
      // Assuming Account was successful if no error occurred
      dispatch(verifyUserSuccess());
      const data: VerifyUserResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(verifyUserFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(verifyUserFailure(error.response.data.message));
        return error.response.data;
      } else {
        dispatch(verifyUserFailure(error.message));
      }
    }
  };

export default verifyUserReducer;
