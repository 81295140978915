import { useEffect } from "react";
import { RootState } from "../../types/global";
import { LongFormRequestDTO } from "../../models/long-form-request-item-dto";
import { routeRecommendations } from "./route-recommendations.reducer";
import { useDispatch, useSelector } from "react-redux";



const useRouteRecommendationsLogic = () => {
  const dispatch = useDispatch();
  const { RouteRecommendationsLoading, RouteRecommendationsError } = useSelector((state: RootState) => state.routeRecommendationsState || { RouteRecommendationsLoading: false, RouteRecommendationsError: null });


  useEffect(() => {
    if (RouteRecommendationsError) {
      // dispatch(showNotification('Active Order failed: ' + error, 'error'));
    }
  }, [RouteRecommendationsError, dispatch]);

  const handleRouteRecommendations = async (param:LongFormRequestDTO,signal?: AbortSignal ) => {
    try {
      const response = await dispatch(routeRecommendations(param,signal) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { RouteRecommendationsLoading, RouteRecommendationsError, routeRecommendations: handleRouteRecommendations};
};

export default useRouteRecommendationsLogic;
