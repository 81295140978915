import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";
import { ZipCodeItemDto } from "../../../models/zip-code-item-dto";


// Action Types for zipcode
const ZIP_CODE_REQUEST = "ZIP_CODE_REQUEST";
const ZIP_CODE_SUCCESS = "ZIP_CODE_SUCCESS";
const ZIP_CODE_FAILURE = "ZIP_CODE_FAILURE";

// Action Creators for zipcode
export const zipCodeRequest = () => ({
  type: ZIP_CODE_REQUEST,
});

export const  zipCodeSuccess = () => ({
  type: ZIP_CODE_SUCCESS,
});

export const zipCodeFailure = (error: string) => ({
  type: ZIP_CODE_FAILURE,
  payload: error,
});

// Initial State for zipcode
export interface ZipCodeState {
  isAuthenticated: boolean;
  loading: boolean;
  zipCodeError: string | null;
}

const initialAuthState: ZipCodeState = {
  isAuthenticated: false,
  loading: false,
  zipCodeError: null,
};

// Define the interface for the API response
interface ZipCodeResponse {
  success: boolean;
  message: string;
  data: ZipCodeItemDto[];
  statusCode: string;
}

// Reducer for Authentication
const zipCodeReducer = (
  state = initialAuthState,
  action: any
): ZipCodeState => {
  switch (action.type) {
    case ZIP_CODE_REQUEST:
      return { ...state, loading: true, zipCodeError: null };
    case ZIP_CODE_SUCCESS:
      return { ...state, loading: false, isAuthenticated: true };
    case ZIP_CODE_FAILURE:
      return { ...state, loading: false, zipCodeError: action.payload };
    default:
      return state;
  }
};

// Thunk for zipcode
export const zipCode =
  (param: {
    postalCode:string;
    countryID:string;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(zipCodeRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Lookup/GetAddressByPostalCode`,
        param
      );
      // Assuming zipcode was successful if no error occurred
      dispatch(zipCodeSuccess());
      const data:ZipCodeResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(zipCodeFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(zipCodeFailure(error.response.data.message));
      } else {
        dispatch(zipCodeFailure(error.message));
      }
    }
  };

export default zipCodeReducer;
