import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { handleGetReferenceGroup, handleAddReferenceGroup,handleUpdateReferenceGroup,handleDeleteReferenceGroup, handleGetReferenceChildGroup, handleAddReferenceGroupItem, handleDeleteReferenceGroupItem } from "./reference-group-reducer";



const useReferenceGroupLogic = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state: RootState) => state.auth);

  const HandleGetData = async (param: {
    UserGUID: string,
    userGroupGUID: string,
    includeBlank: boolean,
    orderBy: string,
    sorting: string,
    pagesize: number,
    pageNo: number
  }) => {
    try {
      const response = await dispatch(handleGetReferenceGroup(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const HandleAddData = async (param: {
    name: string,
    description: string,
    userGUID: string,
    userGroupGUID: string
  }) => {
    try {
      const response = await dispatch(handleAddReferenceGroup(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const HandleUpdateData = async (param: {
    name: string,
    description: string,
    referenceGroupGUID: string | null,
  }) => {
    try {
      const response = await dispatch(handleUpdateReferenceGroup(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const HandleDeleteData = async (param: {
    referenceGroupGUID: string | null,
  }) => {
    try {
      const response = await dispatch(handleDeleteReferenceGroup(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const HandleGetGroupData = async (param: {
    referenceGroupGUID: string | null,
    includeBlank: boolean,
    orderBy: string,
    sorting: string,
    pagesize: number,
    pageNo: number
  }) => {
    try {
      const response = await dispatch(handleGetReferenceChildGroup(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const HandleAddGroupData = async (param: {
    referenceGroupGUID: string | null,
    value: string,
  }) => {
    try {
      const response = await dispatch(handleAddReferenceGroupItem(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const HandleDeleteGroupData = async (param: {
    referenceGroupItemGUID: string | null,
  }) => {
    try {
      const response = await dispatch(handleDeleteReferenceGroupItem(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    loading,
    error,
    GetReferenceGroup: HandleGetData,
    AddReferenceGroup: HandleAddData,
    UpdateReferenceGroup: HandleUpdateData,
    DeleteReferenceGroup: HandleDeleteData,
    GetReferenceChildGroup: HandleGetGroupData,
    AddReferenceChildGroup: HandleAddGroupData,
    DeleteReferenceChildGroup: HandleDeleteGroupData,

  };
}
export default useReferenceGroupLogic;