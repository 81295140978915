import * as Yup from "yup";

export const createRateEstimateValidationSchema = (zipCodeData: any, zipCodeDelData: any, pickupAddressCodeError : any,
  deliveryAddressCodeError: any) => {
return   Yup.object().shape({
    accountNumber: Yup.string().required('Please select a Account.'),
    orderDateTime: Yup.date().required("Please specify an Order Date & Time."),
    serviceID: Yup.string().required("Please specify a Shipment Service Level."),
    pickupCityName: Yup.string(),
    pickupPostalCode: Yup.string()
      .required("Please enter a Pickup ZIP Code.")
      .test('valid-postal-code', 'You have entered an invalid Pickup Zip Code. Please ensure you have entered the proper Zip Code. If you continue to experience issues, please contact MNX.', (value) => {
        return zipCodeData.length != 0; // eslint-disable-line
      }),
    pickupCountryID: Yup.string(),
    pickupStateProvID: Yup.string(),
    pickupAddressCode: Yup.string().test('pickupAddressCode', 'Invalid Pickup Address Code.', (value) => {
      return !pickupAddressCodeError; 
    }),
    pickupDistance: Yup.string().nullable(),
    pickupDistanceUnit: Yup.string(),
    pickupDropAirportID: Yup.string(),
    pickupWaitTime: Yup.string(),
    pickupAttempts: Yup.string(),
    pickupReadyTime: Yup.string().required(
      "Please specify a Shipment Ready Date & Time."
    ),
    pickupTimezoneID:Yup.string().nullable(),
    deliveryCityName: Yup.string(),
    deliveryPostalCode: Yup.string().required(
      "Please enter a Delivery ZIP Code."
    ).test('valid-del-postal-code', 'You have entered an invalid Delivery Zip Code. Please ensure you have entered the proper Zip Code. If you continue to experience issues, please contact MNX.', (value) => {
      return zipCodeDelData.length != 0;  // eslint-disable-line
    }),
    deliveryCountryID: Yup.string(),
    deliveryStateProvID: Yup.string(),
    deliveryAddressCode:  Yup.string().test('deliveryAddressCode', 'Invalid Delivery Address Code.', (value) => {
      return !deliveryAddressCodeError; 
    }),
    deliveryDistance: Yup.string(),
    deliveryDistanceUnit: Yup.string(),
    deliveryDropAirportID: Yup.string(),
    deliveryWaitTime: Yup.string(),
    deliveryAttempts: Yup.string(),
    requestedDeliveryTime: Yup.string().required(
      "Please specify a Shipment Delivery Date & Time."
    ),
    deliveryTimezoneID: Yup.string().nullable(),
    packageInfo: Yup.array().of(
      Yup.object().shape({
        pieces: Yup.number().required("Please enter a piece count.").test('positive', 'Piece count must be greater than zero.', value => value > 0),
        weightUOM: Yup.string(),
        weight: Yup.number()
        .required("Please enter a package weight.")
        .test(
          'weight-limit',
          function (value) {
            const weightUOM = this.parent.weightUOM; // Access the unit value
            const limit = weightUOM === 'LB' ? 100 : 45;
            const unit = weightUOM === 'LB' ? 'lbs' : 'kg';
            if(value == 0) { // eslint-disable-line
              return false || this.createError({
                message: `Weight must be greater than zero.`
              });
            } else {
              return  value <= limit || this.createError({
                message: `For an accurate estimation of arrival for a package of your size, please call an MNX Operations Center. For each individual piece, the weight limit is ${limit}${unit}.`
              });
            }
           
          }
        ),
        
        unit: Yup.string(),
        dimWt: Yup.string(),
        height: Yup.number()
        .required("Please enter a height.")
        .min(1)
        .test(
          'height-limit',
          'Height must not exceed the limit based on the unit.',
          function (value) {
            const unit = this.parent.unit; // Access the unit value
            const limit = unit === 'CM' ? 106 : 42;
            return value <= limit;
          }
        ),
      width: Yup.number()
        .required("Please enter a width.")
        .min(1)
        .test(
          'width-limit',
          'Width must not exceed the limit based on the unit.',
          function (value) {
            const unit = this.parent.unit; // Access the unit value
            const limit = unit === 'CM' ? 106 : 42;
            return  value <= limit;
          }
        ),
      length: Yup.number()
        .required("Please enter a length.")
        .min(1)
        .test(
          'length-limit',
          'Length must not exceed the limit based on the unit.',
          function (value) {
            const unit = this.parent.unit; // Access the unit value
            const limit = unit === 'CM' ? 106 : 42;
            return  value <= limit;
          }
        ),
      }).test(
        'combined-length',
        'For an accurate estimation of arrival for a package of your size, please call an MNX Operations Center. Length, Width, and Height fields must have a combined limit no greater than 90 in (228 cm).',
        function (packageInfo) {
          const { length, width, height, unit } = packageInfo;
          const combinedLength = length + width + height;
          const maxCombinedLength = unit === 'CM' ? 228 : 90;
          return combinedLength <= maxCombinedLength;
        }
      )
    ),
    declValue: Yup.string(),
    totalDst: Yup.string(),
    totalDstUnit: Yup.string(),
    totalWeight: Yup.string(),
    totalDMWeight: Yup.string(),
    attributeList: Yup.array().nullable(),
  });
};


  export const RateEstimateInitialValues =  {
    accountNumber: "",
    orderDateTime: '',
    serviceID: "",
    pickupCityName: "",
    pickupPostalCode: "",
    pickupCountryID: "",
    pickupStateProvID: "",
    pickupAddressCode: "",
    pickupDistance: "",
    pickupDistanceUnit: "",
    pickupDropAirportID: "",
    pickupWaitTime: "",
    pickupAttempts: "",
    pickupReadyTime: "",
    pickupTimezoneID: "",
    deliveryCityName: "",
    deliveryPostalCode: "",
    deliveryCountryID: "",
    deliveryStateProvID: "",
    deliveryAddressCode: "",
    deliveryDistance: "",
    deliveryDistanceUnit: "",
    deliveryDropAirportID: "",
    deliveryWaitTime: "",
    deliveryAttempts: "",
    requestedDeliveryTime: "",
    deliveryTimezoneID: "",
    declValue: "",
    totalDst: "",
    totalDstUnit: "MI",
    totalWeight: "",
    totalDMWeight: "",
    attributeList: "",
    packageInfo: [
      {
        pieces: "",
        weight: "",
        width: "",
        length: "",
        height: "",
        weightUOM: "LB",
        unit: "IN",
        dimWt: "",
      },
    ],
  }