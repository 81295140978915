export default function Env() {
  if (typeof window !== "undefined") {
    switch (window.location.host) {
      case process.env.REACT_APP_HOST_URL_LOCAL?.split("//")[1]:
        return {
          api: process.env.REACT_APP_API_URL_LOCAL,
          host: process.env.REACT_APP_HOST_URL_LOCAL,
          key: process.env.REACT_APP_KEY_LOCAL,
          maxAttempts: process.env.REACT_APP_MAX_LOGIN_ATTEMPTS_LOCAL,
          companyId: process.env.REACT_APP_COMPANY_ID_LOCAL,
          publicKey:process.env.REACT_APP_PUBLIC_KEY,
          privateKey:process.env.REACT_APP_PRIVATE_KEY,
          ahaAccountName:process.env.REACT_APP_AHA_ACCOUNT_NAME,
          ahaApplicationId:process.env.REACT_APP_AHA_APPLICATION_ID,
          EncryptDecryptKey:process.env.REACT_APP_ENCRYPT_DECRYPT_KEY
        };
      case process.env.REACT_APP_HOST_URL_DEVELOPMENT?.split("//")[1]:
        return {
          api: process.env.REACT_APP_API_URL_DEVELOPMENT,
          host: process.env.REACT_APP_HOST_URL_DEVELOPMENT,
          key: process.env.REACT_APP_KEY_DEVELOPMENT,
          maxAttempts: process.env.REACT_APP_MAX_LOGIN_ATTEMPTS_DEVELOPMENT,
          companyId: process.env.REACT_APP_COMPANY_ID_DEVELOPMENT,
          publicKey:process.env.REACT_APP_PUBLIC_KEY,
          privateKey:process.env.REACT_APP_PRIVATE_KEY,
          ahaAccountName:process.env.REACT_APP_AHA_ACCOUNT_NAME,
          ahaApplicationId:process.env.REACT_APP_AHA_APPLICATION_ID,
          EncryptDecryptKey:process.env.REACT_APP_ENCRYPT_DECRYPT_KEY
        };
      case process.env.REACT_APP_HOST_URL_STAGING?.split("//")[1]:
        return {
          api: process.env.REACT_APP_API_URL_STAGING,
          host: process.env.REACT_APP_HOST_URL_STAGING,
          key: process.env.REACT_APP_KEY_STAGING,
          maxAttempts: process.env.REACT_APP_MAX_LOGIN_ATTEMPTS_STAGING,
          companyId: process.env.REACT_APP_COMPANY_ID_STAGING,
          publicKey:process.env.REACT_APP_PUBLIC_KEY,
          privateKey:process.env.REACT_APP_PRIVATE_KEY,
          ahaAccountName:process.env.REACT_APP_AHA_ACCOUNT_NAME,
          ahaApplicationId:process.env.REACT_APP_AHA_APPLICATION_ID,
          EncryptDecryptKey:process.env.REACT_APP_ENCRYPT_DECRYPT_KEY
        };
        case process.env.REACT_APP_HOST_URL_PROD?.split("//")[1]:
          return {
            api: process.env.REACT_APP_API_URL_PROD,
            host: process.env.REACT_APP_HOST_URL_PROD,
            key: process.env.REACT_APP_KEY_PROD,
            maxAttempts: process.env.REACT_APP_MAX_LOGIN_ATTEMPTS_PROD,
            companyId: process.env.REACT_APP_COMPANY_ID_PROD,
            publicKey:process.env.REACT_APP_PUBLIC_KEY,
            privateKey:process.env.REACT_APP_PRIVATE_KEY,
            ahaAccountName:process.env.REACT_APP_AHA_ACCOUNT_NAME,
            ahaApplicationId:process.env.REACT_APP_AHA_APPLICATION_ID,
            EncryptDecryptKey:process.env.REACT_APP_ENCRYPT_DECRYPT_KEY
          };
      default:
        return {
          api: process.env.REACT_APP_API_URL_LOCAL,
          host: process.env.REACT_APP_HOST_URL_LOCAL,
          key: process.env.REACT_APP_KEY_LOCAL,
          maxAttempts: process.env.REACT_APP_MAX_LOGIN_ATTEMPTS_LOCAL,
          companyId: process.env.REACT_APP_COMPANY_ID_STAGING,
          publicKey:process.env.REACT_APP_PUBLIC_KEY,
          privateKey:process.env.REACT_APP_PRIVATE_KEY,
          ahaAccountName:process.env.REACT_APP_AHA_ACCOUNT_NAME,
          ahaApplicationId:process.env.REACT_APP_AHA_APPLICATION_ID,
          EncryptDecryptKey:process.env.REACT_APP_ENCRYPT_DECRYPT_KEY
        };
    }
  } else {
    return {
      api: process.env.REACT_APP_API_URL_LOCAL,
      host: process.env.REACT_APP_HOST_URL_LOCAL,
      key: process.env.REACT_APP_KEY_LOCAL,
      maxAttempts: process.env.REACT_APP_MAX_LOGIN_ATTEMPTS_LOCAL,
      companyId: process.env.REACT_APP_COMPANY_ID_LOCAL,
      publicKey:process.env.REACT_APP_PUBLIC_KEY,
      privateKey:process.env.REACT_APP_PRIVATE_KEY,
      ahaAccountName:process.env.REACT_APP_AHA_ACCOUNT_NAME,
      ahaApplicationId:process.env.REACT_APP_AHA_APPLICATION_ID,
      EncryptDecryptKey:process.env.REACT_APP_ENCRYPT_DECRYPT_KEY
    };
  }
}
