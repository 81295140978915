import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import {
  calculateRateEstimate,
  attributeByServices,
  zipcodeDetails,
  totalPickupDeliveryDistance,
  flightOptions,
  checkServiceList,
  getDefaultDate,
  estimateETA,
} from "./rate-estimate.reducer";
import {
  RateEstimateFlightRequest,
  RateEstimateFormDTO,
  RateEstimateServicesDTO,
  RateEstimateZipcodeDto,
} from "../../models/rate-estimate-dto";
const useRateEstimateLogic = () => {
  const dispatch = useDispatch();
  const { rateEstimateError, rateEstimateLoading } = useSelector(
    (state: RootState) => state.rateEstimate
  );

  const handleCalculateRateEstimate = async (param: RateEstimateFormDTO) => {
    try {
      const response = await dispatch(calculateRateEstimate(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleGetAttributeByServices = async (
    param: RateEstimateServicesDTO
  ) => {
    try {
      const response = await dispatch(attributeByServices(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleZipCodeChange = async (param: RateEstimateZipcodeDto) => {
    try {
      const response = await dispatch(zipcodeDetails(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleTotalPickupDeliveryDistance = async (param: any) => {
    try {
      const response = await dispatch(
        totalPickupDeliveryDistance(param) as any
      );
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleFlightOptions = async (param: RateEstimateFlightRequest) => {
    try {
      const response = await dispatch(flightOptions(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleCheckServiceList = async (param: any) => {
    try {
      const response = await dispatch(checkServiceList(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleGetDeafultDate = async (param: any) => {
    try {
      const response = await dispatch(getDefaultDate(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const hanldeEstimateETA = async (param: any) => {
    try {
      const response = await dispatch(estimateETA(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    rateEstimateError,
    rateEstimateLoading,
    calculateRateEstimate: handleCalculateRateEstimate,
    attributeByServices: handleGetAttributeByServices,
    zipcodeDetails: handleZipCodeChange,
    totalPickupDeliveryDistance: handleTotalPickupDeliveryDistance,
    flightOptions: handleFlightOptions,
    checkServiceList: handleCheckServiceList,
    getDefaultDate: handleGetDeafultDate,
    estimateETA: hanldeEstimateETA,
  };
};

export default useRateEstimateLogic;
