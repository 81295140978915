import React, { useEffect, useState } from "react";
import { Typography, Paper, Box } from "@mui/material";
import AuthHeader from "../../shared/components/AuthHeader";

const PrivacyStatement: React.FC = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const userData = localStorage.getItem("userDetails");
    const parseUserData = JSON.parse(userData || "{}");
    if (parseUserData && parseUserData.token) {
      setIsAuthorized(true);
    }
  }, []);
  return (
    <div className="privacy-statement">
      {!isAuthorized && (
        <Box sx={{ display: "flex" }}>
          <AuthHeader />
        </Box>
      )}
      <Typography className="Main-header-title" variant="h4" color={"primary"}>
        Privacy Statement
      </Typography>

      <Paper className="Privacy_page_details">
        <Typography variant="h6" className="Main-header-title">
          MNX Global Logistics has created this privacy statement in order to
          demonstrate our firm commitment to privacy.
        </Typography>
        <Typography
          variant="body1"
          className="Privacy_page_info"
          sx={{ marginBottom: "32px" }}
        >
          The following discloses our information gathering and dissemination
          practices for this website. When you make a registration to access
          sections of our website that are reserved to our customers
          (“Registered User”), the system requires you to give contact
          information such as name, address, phone number and e-mail address.
          This information is used to contact registered users with purely
          operational matters related to the customers’ orders and for
          notification of downtimes of the website or new features of the
          system.
        </Typography>

        <Typography variant="h6" className="Main-header-title">
          We do not sell individual customer names or other private profile
          information to third parties. 
        </Typography>
        <Typography variant="body1" className="Privacy_page_info">
          We furthermore do not share information provided by registered users
          with our partners or any other third party. All information provided
          by registered users is stored in accordance and for as long as
          required by applicable laws.
        </Typography>
      </Paper>
    </div>
  );
};

export default PrivacyStatement;
