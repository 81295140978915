import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";


// Action Types for reference
const REFERENCE_TYPE_REQUEST = "REFERENCE_TYPE_REQUEST";
const REFERENCE_TYPE_SUCCESS = "REFERENCE_TYPE_SUCCESS";
const REFERENCE_TYPE_FAILURE = "REFERENCE_TYPE_FAILURE";

// Action Creators for reference
export const referenceTypeRequest = () => ({
  type: REFERENCE_TYPE_REQUEST,
});

export const referenceTypeSuccess = () => ({
  type: REFERENCE_TYPE_SUCCESS,
});

export const referenceTypeFailure = (error: string) => ({
  type: REFERENCE_TYPE_FAILURE,
  payload: error,
});

// Initial State for reference
export interface ReferenceTypeState {
  isAuthenticated: boolean;
  referenceTypeLoading: boolean;
  referenceTypeError: string | null;
}

const initialAuthState: ReferenceTypeState = {
  isAuthenticated: false,
  referenceTypeLoading: false,
  referenceTypeError: null,
};

// Define the interface for the API response
interface referenceTypeResponse {
  success: boolean;
  message: string;
  data: any;
  statusCode: string;
}

// Reducer for Authentication
const referenceTypeReducer = (
  state = initialAuthState,
  action: any
): ReferenceTypeState => {
  switch (action.type) {
    case REFERENCE_TYPE_REQUEST:
      return { ...state, referenceTypeLoading: true, referenceTypeError: null };
    case REFERENCE_TYPE_SUCCESS:
      return { ...state, referenceTypeLoading: false, isAuthenticated: true };
    case REFERENCE_TYPE_FAILURE:
      return { ...state, referenceTypeLoading: false, referenceTypeError: action.payload };
    default:
      return state;
  }
};

// Thunk for referenceType
export const referenceType =
  (param: {
    referenceTypeID:number,
    code:string
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(referenceTypeRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Lookup/GetReferenceType`,
        param
      );
      dispatch(referenceTypeSuccess());
      const data:referenceTypeResponse = response.data;
      if (response.status === 200 && data.success) {
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(referenceTypeFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(referenceTypeFailure(error.response.data.message));
      } else {
        dispatch(referenceTypeFailure(error.message));
      }
    }
  };

export default referenceTypeReducer;
