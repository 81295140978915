import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";
import { AddressItemDto } from "../../../models/address-item-dto";

// Action Types for Address
const ADDRESS_REQUEST = "ADDRESS_REQUEST";
const ADDRESS_SUCCESS = "ADDRESS_SUCCESS";
const ADDRESS_FAILURE = "ADDRESS_FAILURE";

// Action Creators for Address
export const addressRequest = () => ({
  type: ADDRESS_REQUEST,
});

export const addressSuccess = (data:any) => ({
  type: ADDRESS_SUCCESS,
  payload: data,
});

export const addressFailure = (error: string) => ({
  type: ADDRESS_FAILURE,
  payload: error,
});

// Initial State for Address
export interface AddressState {
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
  addressData: any;
}

const initialAuthState: AddressState = {
  isAuthenticated: false,
  loading: false,
  error: null,
  addressData: [],
};

// Define the interface for the API response
interface AddressResponse {
  success: boolean;
  message: string;
  data: AddressItemDto[];
  statusCode: string;
}

// Reducer for Authentication
const addressReducer = (
  state = initialAuthState,
  action: any
): AddressState => {
  switch (action.type) {
    case ADDRESS_REQUEST:
      return { ...state, loading: true, error: null };
    case ADDRESS_SUCCESS:
      return { ...state, loading: false, isAuthenticated: true, addressData:action.payload  };
    case ADDRESS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// Thunk for Address
export const address =
  (param: {
    addressCode: string;
    companyName: string;
    cityName: string;
    stateProvID: string;
    countryID:string;
    postalCode:string;
    userGroupGUID:string;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(addressRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Lookup/GetAddress`,
        param
      );
      // Assuming address was successful if no error occurred
     
      const data:AddressResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        const addressAPIData = {
          ...response,
          apiParams: param,
          apiExecuteAt: new Date()
        }
        dispatch(addressSuccess(addressAPIData));
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(addressFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(addressFailure(error.response.data.message));
      } else {
        dispatch(addressFailure(error.message));
      }
    }
  };

export default addressReducer;
