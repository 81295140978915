import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { zipCode } from "./zip-code.reducer";



const useZipCodeLogic = () => {
  const dispatch = useDispatch();
  const { loading, zipCodeError } = useSelector((state: RootState) => state.zipCode);

  useEffect(() => {
    if (zipCodeError) {
      // dispatch(showNotification('zipcode failed: ' + error, 'error'));
    }
  }, [zipCodeError, dispatch]);

  const handleZipCode = async (param: {
    postalCode:string;
    countryID:string;
  }) => {
    try {
      const response = await dispatch(zipCode(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { loading, zipCodeError, zipCode: handleZipCode };
};

export default useZipCodeLogic;
