import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { unDetails } from "./unDetails.reducer";

const useUnDetailsLogic = () => {
  const dispatch = useDispatch();
  const { unDetailsLoading, unDetailsError } = useSelector((state: RootState) => state.unDetails);

  useEffect(() => {
    if (unDetailsError) {
      // dispatch(showNotification('Account failed: ' + error, 'error'));
    }
  }, [unDetailsError, dispatch]);

  const handleUnDetails = async (param: {
    companyID: number;
    pageNumber: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: string
  }) => {
    try {
      const response = await dispatch(unDetails(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { unDetailsLoading, unDetailsError, unDetails: handleUnDetails };
};

export default useUnDetailsLogic;