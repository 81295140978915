// Define an interface for the pickup location details
export interface PickupLocation {
  pickupCompanyName: string | "";
  pickupAddress1?: string | "";
  pickupAddress2?: string | "";
  pickupAddress3?: string | "";
  pickupCityName: string | "";
  pickupStateProvID?: string | "";
  pickupPostalCode?: string | "";
  pickupCountryID: string | "";
}

// Define the function with the typed parameters
export const formatPickupLocation = ({
  pickupCompanyName,
  pickupAddress1,
  pickupAddress2,
  pickupCityName,
  pickupStateProvID,
}: PickupLocation): string => {
  let dsp = `<div class='weight-600 addr_label'>${pickupCompanyName}</div><div>`;
  dsp += pickupAddress1
    ? `${pickupAddress1}${pickupAddress2 || pickupCityName ? ", " : ""}`
    : "";
  dsp += pickupAddress2
    ? `${pickupAddress2}${pickupCityName ? ", " : ""} `
    : "";
  dsp += pickupCityName;
  dsp += pickupStateProvID ? `, ${pickupStateProvID}` : "";
  dsp += "</div>";

  return dsp;
};

export const formatPickupFullLocation = (row: any): string => {
  let dsp = row?.pickupCityName;
  if (row.pickupCountryID == "US") {
    dsp += row?.pickupStateProvID ? `, ${row?.pickupStateProvID}` : "";
    dsp += row?.pickupPostalCode ? `, ${row?.pickupPostalCode}` : "";
  } else {
    dsp += row?.pickupPostalCode ? `, ${row?.pickupPostalCode}` : "";
    dsp += row?.pickupCountryID ? `, ${row?.pickupCountryID}` : "";
  }
  return dsp;
};
