import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../types/global";
import { getShipmentPiece } from "./get-shipment-piece.reducer";



const useGetShipmentPieceLogic = () => {
  const dispatch = useDispatch();
  const { getShipmentPieceLoading, getShipmentPieceError } = useSelector((state: RootState) => state.getShipmentPiece);

  useEffect(() => {
    if (getShipmentPieceError) {
      // dispatch(showNotification('Country failed: ' + error, 'error'));
    }
  }, [getShipmentPieceError, dispatch]);

  const handleGetShipmentPiece= async (param: {
    shipmentGUID:string
  }) => {
    try {
      const response = await dispatch(getShipmentPiece(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { getShipmentPieceLoading, getShipmentPieceError, getShipmentPiece: handleGetShipmentPiece };
};

export default useGetShipmentPieceLogic;
