import { useState, useCallback } from 'react';
import { PRIVATE_KEY, PUBLIC_KEY } from '../../config/constants';
function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function base64ToArrayBuffer(base64: string): ArrayBuffer {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}



async function generateKeyPair(): Promise<CryptoKeyPair> {
  const keyPair = await window.crypto.subtle.generateKey(
    {
      name: "RSA-OAEP",
      modulusLength: 2048,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: { name: "SHA-256" },
    },
    true,
    ["encrypt", "decrypt"]
  );
  return keyPair;
}

async function exportPublicKey(publicKey: CryptoKey): Promise<string> {
  const spki = await window.crypto.subtle.exportKey("spki", publicKey);
  return arrayBufferToBase64(spki);
}

async function exportPrivateKey(privateKey: CryptoKey): Promise<string> {
  const pkcs8 = await window.crypto.subtle.exportKey("pkcs8", privateKey);
  return arrayBufferToBase64(pkcs8);
}



function getUTCSalt(): string {
  const now = new Date();
  return now.toISOString().split('.')[0] + 'Z'; // Removes the milliseconds
}

async function decryptData(encryptedMessage: string, privateKey: CryptoKey): Promise<string> {
  // Convert the base64-encoded string back to an ArrayBuffer
  const encryptedArrayBuffer = base64ToArrayBuffer(encryptedMessage);

  // Decrypt the data
  const decryptedBuffer = await window.crypto.subtle.decrypt(
    { name: "RSA-OAEP" },
    privateKey,
    encryptedArrayBuffer
  );

  // Convert the decrypted ArrayBuffer back to a string
  const decryptedMessageWithSalt = new TextDecoder().decode(decryptedBuffer);

  // Assuming the salt is a fixed length (ISO 8601 UTC datetime has 20 characters)
  const saltLength = 20;
  const originalMessage = decryptedMessageWithSalt.slice(saltLength);

  return originalMessage;
}

async function encryptData(message: string, publicKey: CryptoKey): Promise<string> {
  const salt = getUTCSalt();
  const saltedMessage = salt + message; // Concatenate the salt with the original message

  const enc = new TextEncoder();
  const encodedMessage = enc.encode(saltedMessage);
  const encryptedData = await window.crypto.subtle.encrypt(
    { name: "RSA-OAEP" },
    publicKey,
    encodedMessage
  );
  return arrayBufferToBase64(encryptedData);
}

async function importPublicKey(spki: string): Promise<CryptoKey> {
  const binaryDer = base64ToArrayBuffer(spki);
  const publicKey = await window.crypto.subtle.importKey(
    "spki",
    binaryDer,
    {
      name: "RSA-OAEP",
      hash: { name: "SHA-256" },
    },
    true,
    ["encrypt"]
  );
  return publicKey;
}

async function importPrivateKey(pkcs8: string): Promise<CryptoKey> {
  const binaryDer = base64ToArrayBuffer(pkcs8);
  const privateKey = await window.crypto.subtle.importKey(
    "pkcs8",
    binaryDer,
    {
      name: "RSA-OAEP",
      hash: { name: "SHA-256" },
    },
    true,
    ["decrypt"]
  );
  return privateKey;
}

export const useEncryption = () => {
  const [encryptedData, setEncryptedData] = useState<string | null>(null);
  const [publicKey, setPublicKey] = useState<string | null>(null);
  const [decryptedData, setDecryptedData] = useState<string | null>(null);

  const generateKeysAndEncrypt = useCallback(async (message: string) => {
    try {
      const { publicKey: cryptoPublicKey } = await generateKeyPair();
      const spki = await exportPublicKey(cryptoPublicKey);
      const { privateKey: cryptoPrivateKey } = await generateKeyPair();
      const spkiPrivate = await exportPrivateKey(cryptoPrivateKey); 
      
      setPublicKey(spki);
      const public_key = await importPublicKey(PUBLIC_KEY as any)
      //const encryptedMessage = await encryptData(message, cryptoPublicKey);
      const encryptedMessage = await encryptData(message, public_key);
      setEncryptedData(encryptedMessage);
      return encryptedMessage;
    } catch {
      
    }
  }, []);

  const generateKeysAndDecrypt = useCallback(async (encryptedMessage: string) => {
    try {
      const private_key = await importPrivateKey(PRIVATE_KEY as any)
      
     // const decryptedMessage = await decryptData(encryptedMessage, cryptoPrivateKey);
     const decryptedMessage = await decryptData(encryptedMessage, private_key);
      setDecryptedData(decryptedMessage);
      return decryptedMessage;
    } catch  {
      
    }
  }, []);

  return { encryptedData, publicKey, decryptedData, generateKeysAndEncrypt, generateKeysAndDecrypt };
};