import {
    Box,
    TableCell,
    TableHead,
    TableRow,
  } from "@mui/material";
  
  interface StaticTableHeadProps {
    headCells: any;
  }
  
  function StaticTableHead(props: StaticTableHeadProps) {
    const { headCells } = props;
  
    return (
      <TableHead className="addr_head">
        <TableRow sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
          {headCells.map((headCell: any) => (
            <TableCell
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  export default StaticTableHead;
  