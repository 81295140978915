import React from "react";
import TablePagination from "@mui/material/TablePagination";
import {
  IconButton,
  TableFooter,
  TableRow,
  TableCell,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputBase,
  InputLabel,
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

interface CommonPaginationProps {
  count: number;
  page: number;
  rowsPerPage: number | null;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onRowsPerPageChange?: ((pageSize: number) => void) | null;
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
}

const TablePaginationActions: React.FC<TablePaginationActionsProps> = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) => {
  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div
      className="paginator-div"
      style={{ flexShrink: 0, marginLeft: "2.5em" }}
    >
      <IconButton
        id="btnFirstPage"
        className="paginator-icon"
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        id="btnPreviousPage"
        className="paginator-icon"
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        id="btnNextPage"
        className="paginator-icon"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        id="btnLastPage"
        className="paginator-icon"
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

const CommonPagination: React.FC<CommonPaginationProps> = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
}) => {
  return (
    <TableFooter>
      <TableRow className="pagination">
        <TableCell colSpan={12}>
          <Box display="flex" justifyContent="flex-end">
            <TablePagination
              rowsPerPageOptions={
                rowsPerPage !== null
                  ? [10, 20, 50, 100, 200, { label: "All", value: -1 }]
                  : []
              }
              colSpan={3}
              id="btnTablePagination"
              count={count}
              rowsPerPage={rowsPerPage === null ? 10 : rowsPerPage}
              page={rowsPerPage === -1 ? 0 : page}
              SelectProps={{
                inputProps: { "aria-label": "Lines per page" },
                native: false, // Render as a styled dropdown
                className: "custom-select", // Customize this class
                MenuProps: {
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  PaperProps: {
                    style: {
                      maxHeight: "100%",
                    },
                  },
                },
              }}
              onPageChange={onPageChange}
              onRowsPerPageChange={
                onRowsPerPageChange
                  ? (event) => {
                    const newRowsPerPage = parseInt(event.target.value, 10);
                    onRowsPerPageChange(newRowsPerPage);
                    onPageChange(null, 0); // Reset to the first page when rows per page changes
                  }
                  : undefined
              }
              ActionsComponent={(subProps) => (
                <TablePaginationActions
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage === null ? 10 : rowsPerPage}
                  onPageChange={onPageChange}
                />
              )}
              labelRowsPerPage={"Lines per page"}
              labelDisplayedRows={({ from, to, count }) =>
                rowsPerPage === -1
                  ? `${count} of ${count}`
                  : `${from}-${to} of ${count !== -1 ? count : `more than ${to}`
                  }`
              }
            />
          </Box>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default CommonPagination;
