import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { userReferenceGroup } from "./user-reference-group.reducer";

const useUserReferenceGroupLogic = () => {
  const dispatch = useDispatch();
  const { loading, userReferenceGroupError } = useSelector(
    (state: RootState) => state.userReferenceGroup
  );

  useEffect(() => {
    if (userReferenceGroupError) {
      // dispatch(showNotification('user reference group failed: ' + error, 'error'));
    }
  }, [userReferenceGroupError, dispatch]);

  const handleUserReferenceGroup = async (param: { userGUID: string }) => {
    try {
      const response = await dispatch(userReferenceGroup(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    loading,
    userReferenceGroupError,
    userReferenceGroup: handleUserReferenceGroup,
  };
};

export default useUserReferenceGroupLogic;
