import moment from "moment-timezone";

export const formatDateTZ = (date: string): string => {
  // Add your date formatting logic here
  const localDate = moment(date).local();
  const timeZoneAbbr = moment.tz(localDate, moment.tz.guess()).format("z");
  return `${localDate.format("HHmm")} ${"LOCAL TIME"}`;
};

export const formatDateAndTimeString = (dateString: string): string => {
  // Parse the input date string using moment
  const parsedDate = moment(dateString, "YYYY-MM-DDTHH:mm:ss");
  return parsedDate.format("M/D/YYYY h:mm:ss A");
};

