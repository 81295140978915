import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ItineraryItemDto } from "../../../models/track-order-item-dto";

interface ItineraryProps {
  itinerary: ItineraryItemDto[];
}

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof ItineraryItemDto;
  label: string;
  numeric: boolean;
  minwidth: string;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ItineraryItemDto
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const PlannedItinerary: React.FC<ItineraryProps> = ({ itinerary }) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] =
    useState<keyof ItineraryItemDto>("stepSequence");
  const [itineraryData, setItineraryData] = useState<ItineraryItemDto[]>([]);
  const [notData, setNotData] = useState<any>("");

  useEffect(() => {
    if (itinerary.length !== 0) {
      setItineraryData(itinerary);
    } else {
      setNotData("No Data Found");
    }
  }, [itinerary]);

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] === null) {
      return -1;
    }

    if (a[orderBy] === null) {
      return 1;
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }

    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof ItineraryItemDto>(
    order: Order,

    orderBy: Key
  ): (a: ItineraryItemDto, b: ItineraryItemDto) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const sortedItineraryData = itineraryData
    .slice()
    .sort(getComparator(order, orderBy));

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ItineraryItemDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const PlannedItineraryHeadCells: readonly HeadCell[] = [
    {
      id: "stepName",
      numeric: false,
      disablePadding: false,
      label: "Step",
      minwidth: "180",
    },
    {
      id: "stepDescription",
      numeric: false,
      disablePadding: false,
      label: "Description",
      minwidth: "400",
    },
    {
      id: "completedDateTime",
      numeric: false,
      disablePadding: false,
      label: "Step Completed (System Time)",
      minwidth: "244",
    },
    {
      id: "completedDateTimeLocal",
      numeric: false,
      disablePadding: false,
      label: "Step Completed (Local Time)",
      minwidth: "244",
    },
  ];

  function PlannedItineraryEnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof ItineraryItemDto) =>
        (event: React.MouseEvent<unknown>) => {
          onRequestSort(event, property);
        };

    return (
      <TableHead>
        <TableRow>
          <TableCell key="statusIcon" align={"left"}></TableCell>
          {PlannedItineraryHeadCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Box>
      <TableContainer>
        <div className="RouteTable">
          <Table className="RouteTablebody" aria-labelledby="tableTitle">
            <PlannedItineraryEnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={itineraryData.length}
            />
            <TableBody>
              {sortedItineraryData.length === 0 ? (
                <TableRow id="trPlannedItinerary">
                  <TableCell
                    className="Datanofound"
                    colSpan={12}
                    align="center"
                  >
                    {notData}
                  </TableCell>
                  {/* Adjust the colspan according to the number of columns in your table */}
                </TableRow>
              ) : (
                sortedItineraryData.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover key={row.stepName} id="trPlannedItinerary">
                      <TableCell className="width-40" id="tdStatusIcon">
                        {row.statusIcon ? (
                          <span className="chkbxcolor">
                            <CheckCircleIcon
                              className="checkcircle_icon"
                              sx={{ color: "#006939" }}
                            />
                          </span>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell
                        id="tdStepName"
                        className="width180"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {row.stepName}
                      </TableCell>
                      <TableCell className="width600" id="tdStepDecription">
                        {row.stepDescription}
                      </TableCell>
                      <TableCell>
                        <div className="width230" id="tdStepCompletedDTDisplay">
                          {row.completedDateTimeDisplay}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="width230" id="tdStepCompletedDTLocalDisplay">
                          {row.completedDateTimeLocalDisplay}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
    </Box>
  );
};

export default PlannedItinerary;
