import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { track } from "./track-order.reducer";

const useTrackLogic = () => {
  const dispatch = useDispatch();
  const { trackLoading, trackError } = useSelector((state: RootState) => state.track);

  useEffect(() => {
    if (trackError) {
      // dispatch(showNotification('Login failed: ' + error, 'error'));
    }
  }, [trackError, dispatch]);

  const handleTrack = async (param: {
    trackingNumber: string;
    shipmentGUID: string;
  }) => {
    try {
      const response = await dispatch(track(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { trackLoading, trackError, trackorder: handleTrack };
};

export default useTrackLogic;