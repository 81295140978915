import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import { MySettingItemDto } from "../../../models/long-form-item-dto";
import { useEffect, useState } from "react";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { red } from "@mui/material/colors";
import { EditWillCallRequestDTO } from "../../../models/edit-will-call-order-request-item-dto";
interface DeliveryAddressProps {
  editWillCallOrderData: EditWillCallRequestDTO;
  handleInputChange: (updatedData: any) => void;
  formErrors: any;
}

const Shipper: React.FC<DeliveryAddressProps> = ({
  editWillCallOrderData,
  handleInputChange,
  formErrors,
}) => {
  return (
    <Box className="d-flex gap-4 mt-4 w-100 Divider pb-32 flex-col">
      <Box className="d-flex flex-column w-100 border p16 rounded">
        <Typography variant="h6" className="Sub-header pb-16">
          Shipper Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={3} xs={12} className="pt-0">
            <TextField
              label="Shipper Information"
              variant="outlined"
              id="txtWcShipperName"
              name="shipperName"
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              value={editWillCallOrderData.shipperName}
              InputLabelProps={{ shrink: !!editWillCallOrderData.shipperName }}
              className="pt-0 mb-0"
              error={!!formErrors.shipperName}
              InputProps={{
                endAdornment: !!formErrors.shipperName && (
                  <InputAdornment position="end" disablePointerEvents={true}>
                    <InfoRoundedIcon
                      style={{ color: red[500] }}
                      fontSize="small"
                    ></InfoRoundedIcon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item lg={3} md={3} sm={3} xs={12} className="pt-0">
            <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              margin="normal"
              name="shipperPhone"
               id="txtWcShipperPhone"
              onChange={handleInputChange}
              value={editWillCallOrderData.shipperPhone}
              InputLabelProps={{ shrink: !!editWillCallOrderData.shipperPhone }}
              className="mb-0"
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12} className="pt-0">
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              name="shipperEmail"
               id="txtWcShipperEmail"
              onChange={handleInputChange}
              value={editWillCallOrderData.shipperEmail}
              InputLabelProps={{ shrink: !!editWillCallOrderData.shipperEmail }}
              className="mb-0"
              error={!!formErrors.shipperEmail}
              InputProps={{
                endAdornment: !!formErrors.shipperEmail && (
                  <InputAdornment position="end" disablePointerEvents={true}>
                    <InfoRoundedIcon
                      style={{ color: red[500] }}
                      fontSize="small"
                    ></InfoRoundedIcon>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {/* <Grid item lg={3} md={3} sm={3} xs={12}>
            <FormControlLabel
              sx={{ height: "56px", display: "flex", alignItems: "center" }}
              control={
                <Checkbox
                  disabled={!editWillCallOrderData.isWillCall}
                  checked={editWillCallOrderData.notifyShipper}
                  onChange={handleInputChange}
                />
              }
              label="Notify Shipper"
              name="notifyShipper"
              className="w-100 Search-lbl"
            />
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
};

export default Shipper;
