import React, { FC } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
interface MyDialogProps {
  open: boolean;
  handleClose: () => void;
}

const RateEstimateTermsDialog: FC<MyDialogProps> = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const handleCloseAction = () => {
    navigate("/active-orders");
  };
  return (
    <>
      <Dialog
        className="rate-popup"
        maxWidth={"md"}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className="Popup-header p16" id="responsive-dialog-title">
          Rate Estimate Terms & Conditions
          {/* <IconButton
            aria-label="close"
            onClick={handleCloseAction}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton> */}
        </DialogTitle>

        <DialogContent className="p16">
          <>
            <Typography variant="h6" className="Status_label">
              <ul className="Rate_info">
                <li style={{ paddingBottom: "16px" }}>
                  The information contained in this rate estimation tool is not
                  intended and should not be used or construed as a cost
                  associated with the shipment in any jurisdiction. Actual costs
                  could change due to a variety of factors, including, but not
                  limited to additional accessorial charges. MNX invoices will
                  not be bound by information provided in this reference tool.
                </li>
                <li>
                  Best efforts are made to ensure that the information provided
                  on this Website is useful. Regardless of anything to the
                  contrary, nothing available on or through this Website should
                  be understood as a firm quote or actual cost per shipment.
                </li>
              </ul>
            </Typography>
          </>
        </DialogContent>
        <DialogActions className="p16">
          <Box className="d-flex gap-2">
            <Button
              onClick={handleCloseAction}
              className="MuiButton Action-btn"
              variant="text"
              id="btnReDialogCancel"
            >
              CANCEL
            </Button>
            <Button
              style={{ width: 108, height: 40 }}
              variant="contained"
              onClick={handleClose}
              className="MuiButton Action-btn"
              id="btnReDialogAgree"
            >
              AGREE
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RateEstimateTermsDialog;
