import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";
import { ServiceItemDto } from "../../../models/service-item-dto";

// Action Types for service
const SERVICE_REQUEST = "SERVICE_REQUEST";
const SERVICE_SUCCESS = "SERVICE_SUCCESS";
const SERVICE_FAILURE = "SERVICE_FAILURE";

// Action Creators for service
export const serviceRequest = () => ({
  type: SERVICE_REQUEST,
});

export const serviceSuccess = () => ({
  type: SERVICE_SUCCESS,
});

export const serviceFailure = (error: string) => ({
  type: SERVICE_FAILURE,
  payload: error,
});

// Initial State for service
export interface ServiceState {
  isAuthenticated: boolean;
  loading: boolean;
  serviceError: string | null;
}

const initialAuthState: ServiceState = {
  isAuthenticated: false,
  loading: false,
  serviceError: null,
};

// Define the interface for the API response
interface ServiceResponse {
  success: boolean;
  message: string;
  data: ServiceItemDto[];
  statusCode: string;
}

// Reducer for Authentication
const serviceReducer = (
  state = initialAuthState,
  action: any
): ServiceState => {
  switch (action.type) {
    case SERVICE_REQUEST:
      return { ...state, loading: true, serviceError: null };
    case SERVICE_SUCCESS:
      return { ...state, loading: false, isAuthenticated: true };
    case SERVICE_FAILURE:
      return { ...state, loading: false, serviceError: action.payload };
    default:
      return state;
  }
};

// Thunk for service
export const service =
  (param: {
    companyID: 0,
    userGUID: "",
    serviceAvailType: "",
    baseDateTime: "",
    origCountryID: "",
    origCityName: "",
    origStateProvID: "",
    origPostalCode: "",
    origAirportID: "",
    destCountryID: "",
    destCityName: "",
    destStateProvID: "",
    destPostalCode: "",
    destAirportID: ""
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(serviceRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Lookup/GetService`,
        param
      );
      // Assuming country was successful if no error occurred
      dispatch(serviceSuccess());
      const data:ServiceResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(serviceFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(serviceFailure(error.response.data.message));
      } else {
        dispatch(serviceFailure(error.message));
      }
    }
  };

export default serviceReducer;
