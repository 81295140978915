import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import { DocumentsItemDto } from "../../../models/track-order-item-dto";
import { API_BASE_URL } from "../../../config/constants";
import axiosInstance from "../../../config/axios-interceptor";
import { downloadAndSavePDF } from "../../util/download-pdf";

interface DocumentProps {
  document: DocumentsItemDto[];
}

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof DocumentsItemDto;
  label: string;
  numeric: boolean;
  minwidth: string;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof DocumentsItemDto
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const Documents: React.FC<DocumentProps> = ({ document }) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] =
    useState<keyof DocumentsItemDto>("documentTypeID");
  const [documentData, setDocumentData] = useState<DocumentsItemDto[]>([]);
  const [notData, setNotData] = useState<any>("");

  useEffect(() => {
    if (document.length !== 0) {
      setDocumentData(document);
    } else {
      setNotData(
        "(no customer viewable documents are available for this shipment)"
      );
    }
  }, [document]);

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] === null) {
      return -1;
    }

    if (a[orderBy] === null) {
      return 1;
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }

    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof DocumentsItemDto>(
    order: Order,

    orderBy: Key
  ): (a: DocumentsItemDto, b: DocumentsItemDto) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const sortedDocumentData = documentData
    .slice()
    .sort(getComparator(order, orderBy));

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DocumentsItemDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const DocumentsHeadCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
      minwidth: "33%",
    },
    {
      id: "originalFileName",
      numeric: false,
      disablePadding: false,
      label: "File Name",
      minwidth: "33%",
    },
    {
      id: "documentTypeName",
      numeric: false,
      disablePadding: false,
      label: "Type",
      minwidth: "33%",
    },
  ];

  function DocumentsEnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof DocumentsItemDto) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {DocumentsHeadCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleDownload = async (
    SystemFileName: string,
    OriginalFileName: string,
    DateTime: string
  ) => {
    try {
      const date = DateTime.split("T")[0];

      const response = await axiosInstance.get(
        `${API_BASE_URL}/api/Report/api/ShipmentDocument/DownLoad?systemFileName=${SystemFileName}&date=${date}`,
        { responseType: "arraybuffer" }
      );

      if (response.status) {
        const pdfTitle = OriginalFileName;
        const result = await downloadAndSavePDF(response.data, pdfTitle);

        if (result.error !== "") {
        }
      }
    } catch (error: any) {
    }
  };

  return (
    <Box>
      <TableContainer>
        <div className="RouteTable">
          <Table className="RouteTablebody" aria-labelledby="tableTitle">
            <DocumentsEnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={documentData.length}
            />
            <TableBody>
              {sortedDocumentData.length === 0 ? (
                <TableRow id="trDocuments">
                  <TableCell
                    className="Datanofound"
                    colSpan={12}
                    align="center"
                    id="tdDataNotFound"
                  >
                    {notData}
                  </TableCell>
                  {/* Adjust the colspan according to the number of columns in your table */}
                </TableRow>
              ) : (
                sortedDocumentData.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover key={row.shipmentDocumentID} id="trDocuments">
                      <TableCell className="auto-width" id="tdName">{row.name}</TableCell>
                      <TableCell className="auto-width" id="tdFileName">
                        <Link
                        id="liFileName"
                          onClick={() =>
                            handleDownload(
                              row.systemFileName,
                              row.originalFileName,
                              row.insertDateTime
                            )
                          }
                          className="primary"
                          sx={{ cursor: "pointer" }}
                        >
                          {row.originalFileName}
                        </Link>
                      </TableCell>
                      <TableCell className="auto-width" id="tdDocumentsType">
                        {row.documentTypeName}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
    </Box>
  );
};

export default Documents;
