import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";
import { CountryItemDto } from "../../../models/country-item-dto";

// Action Types for Country
const COUNTRY_REQUEST = "COUNTRY_REQUEST";
const COUNTRY_SUCCESS = "COUNTRY_SUCCESS";
const COUNTRY_FAILURE = "COUNTRY_FAILURE";

// Action Creators for Country
export const countryRequest = () => ({
  type: COUNTRY_REQUEST,
});

export const countrySuccess = (data:any) => ({
  type: COUNTRY_SUCCESS,
  payload: data,
});

export const countryFailure = (error: string) => ({
  type: COUNTRY_FAILURE,
  payload: error,
});

// Initial State for Country
export interface CountryState {
  isAuthenticated: boolean;
  loading: boolean;
  countyError: string | null;
  countryData: any;
}

const initialAuthState: CountryState = {
  isAuthenticated: false,
  loading: false,
  countyError: null,
  countryData: [],
};

// Define the interface for the API response
interface CountryResponse {
  success: boolean;
  message: string;
  data: CountryItemDto[];
  statusCode: string;
}

// Reducer for Authentication
const countryReducer = (
  state = initialAuthState,
  action: any
): CountryState => {
  switch (action.type) {
    case COUNTRY_REQUEST:
      return { ...state, loading: true, countyError: null };
    case COUNTRY_SUCCESS:
      return { ...state, loading: false, isAuthenticated: true, countryData: action.payload  };
    case COUNTRY_FAILURE:
      return { ...state, loading: false, countyError: action.payload };
    default:
      return state;
  }
};

// Thunk for country
export const country =
  (param: {
   
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(countryRequest());
    try {
      const response = await axiosInstance.get(
        `${API_BASE_URL}/api/Lookup/GetCountry`,
        param
      );
      
      const data:CountryResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        dispatch(countrySuccess(response));
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(countryFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(countryFailure(error.response.data.message));
      } else {
        dispatch(countryFailure(error.message));
      }
    }
  };

export default countryReducer;
