import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { SignupItemDto } from "../../models/SignupItemDto";

// Action Types for signup
const SIGNUP_REQUEST = "SIGNUP_REQUEST";
const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
const SIGNUP_FAILURE = "SIGNUP_FAILURE";


// Action Creators for signup
export const signupRequest = () => ({
    type: SIGNUP_REQUEST,
  });
  
  export const signupSuccess = () => ({
    type: SIGNUP_SUCCESS,
  });
  
  export const signupFailure = (error: string) => ({
    type: SIGNUP_FAILURE,
    payload: error,
  });



// Initial State for Signup
export interface SignupState {
    isAuthenticated: boolean;
    signupLoading: boolean;
    signupError: string | null;
  }


  const initialAuthState: SignupState = {
    isAuthenticated: false,
    signupLoading: false,
    signupError: null,
  };
  

  // Define the interface for the API response
interface SignupResponse {
    success: boolean;
    message: string;
    data: string;
    statusCode: string;
  }
  
  // Reducer for Authentication
  const signupReducer = (state = initialAuthState, action: any): SignupState => {
    switch (action.type) {
      case SIGNUP_REQUEST:
        return { ...state, signupLoading: true, signupError: null };
      case SIGNUP_SUCCESS:
        return { ...state, signupLoading: false, isAuthenticated: true };
      case SIGNUP_FAILURE:
        return { ...state, signupLoading: false, signupError: action.payload };
      default:
        return state;
    }
  };
  
  // Thunk for Active Order
  export const signup = (param: SignupItemDto) => async (dispatch: Dispatch) => {
      dispatch(signupRequest());
      try {
        const response = await axiosInstance.post(
          `${API_BASE_URL}/api/Authentication/CreateUser`,
          param
      );
        // Assuming Signup was successful if no error occurred
        dispatch(signupSuccess());
        const data: SignupResponse = response.data;
        // Store token in localStorage
         if (response.status === 200 && data.success) {
          // Store token in localStorage
          return { data };
        } else {
          // Handle different status codes if necessary
          dispatch(signupFailure(data.message));
        }
        return response.data;
      } catch (error: any) {
        if(error.response.data.message){
          dispatch(signupFailure(error.response.data.message));
          return error.response.data;
        }else{
          dispatch(signupFailure(error.message));
        }

      }
    };


    export default signupReducer;  
  