import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { attributes, saveAttributes } from "./attributes.reducer";
import { attributesRequestDTO } from "../../../models/long-form-item-dto";


const useAttributesLogic = () => {
  const dispatch = useDispatch();
  const { loading, att_error } = useSelector((state: RootState) => state.attributes);



  const handleAttributes = async (param: {
    serviceID: number;
    accountNumber: any;
  }) => {
    try {
    
      const response = await dispatch(attributes(param) as any);
      return response;
      
    } catch (err) {
      throw err;
    }
  };

  const handleSaveAttributes = async (param: attributesRequestDTO) => {
    try {
    
      const response = await dispatch(saveAttributes(param) as any);
      return response;
      
    } catch (err) {
      throw err;
    }
  };

  return { loading, att_error, Attributes: handleAttributes, saveAttributes: handleSaveAttributes };
};

export default useAttributesLogic;
