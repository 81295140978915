import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { referenceType } from "./reference-type.reducer";




const useReferenceTypeLogic = () => {
  const dispatch = useDispatch();
  const { referenceTypeLoading, referenceTypeError } = useSelector((state: RootState) => state.referenceType);

  useEffect(() => {
    if (referenceTypeError) {
      // dispatch(showNotification('Country failed: ' + error, 'error'));
    }
  }, [referenceTypeError, dispatch]);

  const handleReferenceType= async (param: {
    referenceTypeID:number
    code:string
  }) => {
    try {
      const response = await dispatch(referenceType(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { referenceTypeLoading, referenceTypeError, referenceType: handleReferenceType };
};

export default useReferenceTypeLogic;
