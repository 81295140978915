import React from "react";
import { Typography, Paper } from "@mui/material";

const ContactListpage: React.FC = () => {
  
 
  return (
    <div className="container-fluid tutorials">
    <Typography variant="h4" color={"primary"}>              
    Contact List
        </Typography>
      <Paper elevation={3} sx={{ padding: 3, marginTop: 5 }}>
        Coming Soon
      </Paper>
    
    </div>
  );
};

export default ContactListpage;
