import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { MultiOrderRequestDto } from "../../models/multi-order-item-dto";
import { MultiOrder } from "./multi-form-order-create.reducer";

const useMultiOrderLogic = () => {
  const dispatch = useDispatch();
  const { MultiOrderLoading, MultiOrderError } = useSelector((state: RootState) => state.multiOrder);

  useEffect(() => {
    if (MultiOrderError) {
      // dispatch(showNotification('Active Order failed: ' + error, 'error'));
    }
  }, [MultiOrderError, dispatch]);

  const handleMultiOrder = async (param:MultiOrderRequestDto ) => {
    try {
      const response = await dispatch(MultiOrder(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { MultiOrderLoading, MultiOrderError, multiOrder: handleMultiOrder };
};

export default useMultiOrderLogic;
