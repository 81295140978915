import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { service } from "./service.reducer";


const useServiceLogic = () => {
  const dispatch = useDispatch();
  const { loading, serviceError } = useSelector((state: RootState) => state.service);

  useEffect(() => {
    if (serviceError) {
      // dispatch(showNotification('Country failed: ' + error, 'error'));
    }
  }, [serviceError, dispatch]);

  const handleService= async (param: {
    companyID: 0,
    userGUID: "",
    serviceAvailType: "",
    baseDateTime: "",
    origCountryID: "",
    origCityName: "",
    origStateProvID: "",
    origPostalCode: "",
    origAirportID: "",
    destCountryID: "",
    destCityName: "",
    destStateProvID: "",
    destPostalCode: "",
    destAirportID: ""
  }) => {
    try {
      const response = await dispatch(service(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { loading, serviceError, service: handleService };
};

export default useServiceLogic;
