import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { AddressBookDto } from "../../models/address-book-dto";
import { addressSuccess } from "../../shared/lookup/address/address.redux";
// Action Types for  Active Order
const ACTIVE_ORDERS_REQUEST = "ACTIVE_ORDERS_REQUEST";
const ACTIVE_ORDERS_SUCCESS = "ACTIVE_ORDERS_SUCCESS";
const ACTIVE_ORDERS_FAILURE = "ACTIVE_ORDERS_FAILURE";

// Action Creators for  Active Order
export const activeOrdersRequest = () => ({
  type: ACTIVE_ORDERS_REQUEST,
});

export const activeOrdersSuccess = () => ({
  type: ACTIVE_ORDERS_SUCCESS,
});

export const activeOrdersFailure = (error: string) => ({
  type: ACTIVE_ORDERS_FAILURE,
  payload: error,
});

// Initial State for  Search Order
export interface AddressBooksState {
  isAuthenticated: boolean;
  addressBookLoading: boolean;
  addressBookError: string | null;
}

const initialAuthState: AddressBooksState = {
  isAuthenticated: false,
  addressBookLoading: false,
  addressBookError: null,
};

// Define the interface for the API response
interface AddressBookResponse {
  success: boolean;
  message: string;
  totalCount: number;
  pageCount: number;
  data: Array<AddressBookDto>;
  statusCode: string;
}

// Reducer for Authentication
const addressBookReducer = (
  state = initialAuthState,
  action: any
): AddressBooksState => {
  switch (action.type) {
    case ACTIVE_ORDERS_REQUEST:
      return { ...state, addressBookLoading: true, addressBookError: null };
    case ACTIVE_ORDERS_SUCCESS:
      return { ...state, addressBookLoading: false, isAuthenticated: true };
    case ACTIVE_ORDERS_FAILURE:
      return {
        ...state,
        addressBookLoading: false,
        addressBookError: action.payload,
      };
    default:
      return state;
  }
};

// Thunk for Search Order
export const addressBooks =
  (param: AddressBookDto) => async (dispatch: Dispatch) => {
    dispatch(activeOrdersRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Customer/GetAddress`,
        param
      );
      // Assuming  Active Order was successful if no error occurred
      dispatch(activeOrdersSuccess());
      const data: AddressBookResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(activeOrdersFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(activeOrdersFailure(error.response.data.message));
      } else {
        dispatch(activeOrdersFailure(error.message));
      }
    }
  };

export const addEditAddress =
  (param: AddressBookDto) => async (dispatch: Dispatch) => {
    dispatch(activeOrdersRequest());
    try {
      let response: any;

      if (param.addressGUID) {
        response = await axiosInstance.post(
          `${API_BASE_URL}/api/Customer/UpdateAddress`,
          param
        );
      } else {
        response = await axiosInstance.post(
          `${API_BASE_URL}/api/Customer/InsertAddress`,
          param
        );
      }

      // Assuming  Active Order was successful if no error occurred
      dispatch(activeOrdersSuccess());
      const data: AddressBookResponse = response.data;
      // Store token in localStorage

      if (response.status === 200 && data.success) {
        //Reset the address look up redux state
        dispatch(addressSuccess([]));
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(activeOrdersFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.message) {
        dispatch(activeOrdersFailure(error?.response?.data?.message));
      } else {
        dispatch(activeOrdersFailure(error?.message));
      }
    }
  };

export const deleteAddress =
  (param: { original_AddressGUID: string | undefined }) =>
  async (dispatch: Dispatch) => {
    dispatch(activeOrdersRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Customer/DeleteAddress`,
        {
          original_AddressGUID: param?.original_AddressGUID,
        }
      );

      // Assuming  Active Order was successful if no error occurred
      dispatch(activeOrdersSuccess());
      const data: any = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        //Reset the address look up redux state
        dispatch(addressSuccess([]));
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(activeOrdersFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.message) {
        dispatch(activeOrdersFailure(error?.response?.data?.message));
      } else {
        dispatch(activeOrdersFailure(error?.message));
      }
    }
  };

export default addressBookReducer;
