import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { customerAddress } from "./customer-address.redux";


const useCustomerAddressLogic = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state: RootState) => state.address);

  useEffect(() => {
    if (error) {
      // dispatch(showNotification('Address failed: ' + error, 'error'));
    }
  }, [error, dispatch]);

  const handleAddress = async (param: {
    companyName: string;
    cityName: string;
    stateProvID: string;
    addressCode: string;
    countryID:string;
    postalCode:string;
    returnAllIfNoParms:boolean;
    orderBy:string;
    sorting:string;
    pagesize:number;
    pageNo:number;
  }) => {
    try {
      const response = await dispatch(customerAddress(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { loading, error, customerAddress: handleAddress };
};

export default useCustomerAddressLogic;
