import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-without-loader";
import { API_BASE_URL } from "../../config/constants";
import { LongFormRequestDTO } from "../../models/long-form-request-item-dto";


// Action Types for Default Data
const ROUTE_RECOMMENDATIONS_REQUEST = "ROUTE_RECOMMENDATIONS_REQUEST";
const ROUTE_RECOMMENDATIONS_SUCCESS = "ROUTE_RECOMMENDATIONS_SUCCESS";
const ROUTE_RECOMMENDATIONS_FAILURE = "ROUTE_RECOMMENDATIONS_FAILURE";


// Action Creators for Default Data
export const  routeRecommendationOrderRequest = () => ({
    type: ROUTE_RECOMMENDATIONS_REQUEST,
  });
  
  export const routeRecommendationsSuccess = () => ({
    type: ROUTE_RECOMMENDATIONS_SUCCESS,
  });
  
  export const routeRecommendationsFailure = (error: string) => ({
    type: ROUTE_RECOMMENDATIONS_FAILURE,
    payload: error,
  });


// Initial State for  Active Order
export interface RouteRecommendationsState {
    isAuthenticated: boolean;
   RouteRecommendationsLoading: boolean;
   RouteRecommendationsError: string | null;
  }

  const initialAuthState: RouteRecommendationsState = {
    isAuthenticated: false,
   RouteRecommendationsLoading: false,
   RouteRecommendationsError: null,
  };
  

  // Define the interface for the API response
interface RouteRecommendationsResponse {
    success: boolean;
    message: string;
    data: any;
    statusCode: string;
  }
  
  // Reducer for Authentication
  const routeRecommendationsReducer = (state = initialAuthState, action: any): RouteRecommendationsState => {
    switch (action.type) {
      case ROUTE_RECOMMENDATIONS_REQUEST:
        return { ...state, RouteRecommendationsLoading: true, RouteRecommendationsError: null };
      case ROUTE_RECOMMENDATIONS_SUCCESS:
        return { ...state, RouteRecommendationsLoading: false, isAuthenticated: true };
      case ROUTE_RECOMMENDATIONS_FAILURE:
        return { ...state, RouteRecommendationsLoading: false, RouteRecommendationsError: action.payload };
      default:
        return state;
    }
  };
  
  // Thunk for Active Order
  export const routeRecommendations = (param: LongFormRequestDTO,signal?: AbortSignal) => async (dispatch: Dispatch) => {
      dispatch(routeRecommendationOrderRequest());
      try {
        const response = await axiosInstance.post(
          `${API_BASE_URL}/api/Orders/GetRouteRecommendations`,
          param,
          { signal } // Pass the signal to the Axios request
      );
        // Assuming  Active Order was successful if no error occurred
        dispatch(routeRecommendationsSuccess());
        const data: RouteRecommendationsResponse = response.data;
        // Store token in localStorage
         if (response.status === 200 && data.success) {
          // Store token in localStorage
          return { data };
        } else {
          // Handle different status codes if necessary
          dispatch(routeRecommendationsFailure(data.message));
        }
        return response.data;
      } catch (error: any) {
        if (error.response) {
          const errorMessage = error.response.data;
          dispatch(routeRecommendationsFailure(errorMessage.message));
          return errorMessage;
        } else {
          // If no response, it's likely a network error or similar
          dispatch(routeRecommendationsFailure(error.message));
        }

      }
    };


    export default routeRecommendationsReducer;  
  