import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { multipleFileUploadWithoutLoader } from "./multiple-document-without-loader-upload.redux";


const useMultipleFileUploadWithoutLoaderLogic = () => {
  const dispatch = useDispatch();
  const { loading, fileUploadError } = useSelector((state: RootState) => state.multipleFileUpload);

  useEffect(() => {
    if (fileUploadError) {
      // dispatch(showNotification('Address failed: ' + fileUploadError, 'error'));
    }
  }, [fileUploadError, dispatch]);

  const handleMultipleFileUpload = async (param:{
    files: File[], 
    shipmentGUID: string, 
    insertUserID: number,
    documentTypeID?: any,
  }) => {
    try {
      const response = await dispatch(multipleFileUploadWithoutLoader(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { loading, fileUploadError, multipleFileUploadWithoutLoader: handleMultipleFileUpload };
};

export default useMultipleFileUploadWithoutLoaderLogic;
