import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { editOrder } from "./edit-active-order.reducer";

const useEditorderLogic = () => {
  const dispatch = useDispatch();
  const { editOrderLoading, editOrderError } = useSelector((state: RootState) => state.editOrder);

  useEffect(() => {
    if (editOrderLoading) {
      // dispatch(showNotification('Login failed: ' + error, 'error'));
    }
  }, [editOrderLoading, dispatch]);

  const handleEditOrder = async (param: {
    trackingValue: string;
    userGUID: string;
  }) => {
    try {
      const response = await dispatch(editOrder(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { editOrderLoading, editOrderError, editOrder: handleEditOrder };
};

export default useEditorderLogic;