import { Dispatch } from "redux"; // Import Dispatch type
import { API_BASE_URL } from "../../config/constants";
import axios from "axios";

// Action Types for Login
const LOGIN_REQUEST = "auth/LOGIN_REQUEST";
const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
const LOGIN_FAILURE = "auth/LOGIN_FAILURE";

// Action Creators for Login
export const loginRequest = () => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = () => ({
  type: LOGIN_SUCCESS,
});

export const loginFailure = (error: string) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

// Initial State for Authentication
export interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
}

const initialAuthState: AuthState = {
  isAuthenticated: false,
  loading: false,
  error: null,
};

// Define the interface for the API response
interface LoginResponse {
  success: boolean;
  message: string;
  data: {
    userId: number;
    userGUID: string;
    firstName: string;
    lastName: string;
    isPasswordChange: boolean;
    token: string;
  };
  statusCode: string;
}

// Reducer for Authentication
const authReducer = (state = initialAuthState, action: any): AuthState => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, loading: true, error: null };
    case LOGIN_SUCCESS:
      return { ...state, loading: false, isAuthenticated: true };
    case LOGIN_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// Thunk for Login
export const login =
  (param: { LogonName: string; Password: string }) =>
  async (dispatch: Dispatch) => {
    dispatch(loginRequest());
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/Authentication/Login`,
        param
      );
      // Assuming login was successful if no error occurred
      dispatch(loginSuccess());
      const data: LoginResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        localStorage.setItem("userDetails", JSON.stringify(data.data));

        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(loginFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        dispatch(loginFailure(error.response.data.message));
      } else {
        dispatch(loginFailure(error.message));
      }
    }
  };

export const empLogin = () => async (dispatch: Dispatch) => {
  dispatch(loginRequest());
  try {
    const response = await axios.get(
      `${API_BASE_URL}/api/Authentication/GetMicrosoftLoginURL`
    );
    // Assuming login was successful if no error occurred
    dispatch(loginSuccess());
    const data: LoginResponse = response.data;
    // Store token in localStorage
    if (response.status === 200 && data.success) {
      return { data };
    } else {
      // Handle different status codes if necessary
      dispatch(loginFailure(data.message));
    }
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data.message) {
      dispatch(loginFailure(error.response.data.message));
    } else {
      dispatch(loginFailure(error.message));
    }
  }
};

export const asureLogin =
  (param: { Code: string }) => async (dispatch: Dispatch) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/Authentication/AzureLogin?Code=${param?.Code}`,
        param
      );
      // Assuming login was successful if no error occurred
      dispatch(loginSuccess());
      const data: LoginResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        localStorage.setItem("userDetails", JSON.stringify(data.data));
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(loginFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        dispatch(loginFailure(error.response.data.message));
      } else {
        dispatch(loginFailure(error.message));
      }
    }
  };

export default authReducer;
