import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { timezoneFilter } from "./timezone-filter.reducer";

const useTimezoneFilterLogic = () => {
  const dispatch = useDispatch();
  const { timezoneloading, timezoneError } = useSelector((state: RootState) => state.timezoneFilter);

  useEffect(() => {
    if (timezoneError) {
      // dispatch(showNotification('Country failed: ' + error, 'error'));
    }
  }, [timezoneError, dispatch]);

  const handleTimezone = async (param: {timezoneId:string}) => {
    try {
      const response = await dispatch(timezoneFilter(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { timezoneloading, timezoneError, timezoneFilter: handleTimezone };
};

export default useTimezoneFilterLogic;
