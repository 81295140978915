export const saveLongFormData = (data: any) => ({
  type: SAVE_LONG_FORM_DATA,
  payload: data,
});


const SAVE_LONG_FORM_DATA = 'SAVE_LONG_FORM_DATA';

// Initial State
export interface LongFormOrderSaveState {
  longFormData: any | null; // Add this state property
  LongFormOrderLoading: boolean;
  LongFormOrderError: string | null;
}

const initialState: LongFormOrderSaveState = {
  longFormData: null, // Initialize as null or empty
  LongFormOrderLoading: false,
  LongFormOrderError: null,
};

// Reducer
const longFormSaveOrderReducer = (state = initialState, action: any): LongFormOrderSaveState => {
  switch (action.type) {
    case SAVE_LONG_FORM_DATA:
      return {
        ...state,
        longFormData: action.payload, // Store the longFormData
      };
    // Handle other action types
    default:
      return state;
  }
};

export default longFormSaveOrderReducer;
