import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { MySettingDto } from "../../../models/my-setting-dto";
import { CountryItemDto } from "../../../models/country-item-dto";
import useZipCodeLogic from "../../lookup/zip-code/zip-code.logic";
import { ZipCodeItemDto } from "../../../models/zip-code-item-dto";
import { extractTime } from "../../util/numeric-value";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
interface DefaultDeliveryDetailsProps {
  mySetting: MySettingDto;
  handleInputChange: (updatedData: any) => void;
  countryData: CountryItemDto[];
  SetDeliveryZipCodeError: (errors: any) => void;
  deliveryZipCodeError: any;
  setSuccessMessage: (success: any) => void;
}

const DefaultDeliveryDetails: React.FC<DefaultDeliveryDetailsProps> = ({
  mySetting,
  handleInputChange,
  countryData,
  SetDeliveryZipCodeError,
  deliveryZipCodeError,
  setSuccessMessage
}) => {
  const { zipCodeError, zipCode } = useZipCodeLogic();
  const [zipCodeData, setZipCodeData] = useState<ZipCodeItemDto[]>([]);
  const [showAddress3, setShowAddress3] = useState(false); // New state for visibility

  useEffect(() => {
    getZipCode();
  }, []);

  useEffect(() => {
    if (mySetting.deliveryAddress3) {
      setShowAddress3(true); // Toggle visibility
    }
  }, [mySetting.deliveryAddress3]);

  const showAddress3Visibility = () => {
    setShowAddress3(true); // Toggle visibility
  };
  interface MenuStyles {
    maxHeight: string;
  }

  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
    maxHeight: "200px", // Default max height for desktop
  });

  const getZipCode = async () => {
    try {
      const param = {
        postalCode: mySetting.deliveryPostalCode || "",
        countryID: mySetting.deliveryCountryID || "",
      };
      const response = await zipCode(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        if (res.length > 0) {
          setZipCodeData(res);
          return res;
        } else {
          setZipCodeData([]);
          return [];
        }
      }
    } catch (err) {
      setZipCodeData([]);
    }
  };

  const handleZipCodeKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Tab") {
      const res = await getZipCode(); // Wait for getZipCode to complete
      if (res.length) {
        handleInputChange({
          target: { name: "deliveryCityName", value: res[0].cityName },
        } as any);
        handleInputChange({
          target: { name: "deliveryAirportID", value: res[0].airportID },
        } as any);
        handleInputChange({
          target: { name: "deliveryStateProvID", value: res[0].stateProvID },
        } as any);
      } else {
        handleInputChange({
          target: { name: "deliveryCityName", value: "" },
        } as any);
        handleInputChange({
          target: { name: "deliveryAirportID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "deliveryStateProvID", value: "" },
        } as any);
      }
    }
  };

  const handleZipCodeFocusOut = async (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const res = await getZipCode(); // Wait for getZipCode to complete
    if (res) {
      SetDeliveryZipCodeError(false);
      if (res.length) {
        handleInputChange({
          target: { name: "deliveryCityName", value: res[0].cityName},
        } as any);
        handleInputChange({
          target: { name: "deliveryAirportID", value: res[0].airportID },
        } as any);
        handleInputChange({
          target: { name: "deliveryStateProvID", value: res[0].stateProvID },
        } as any);
      } else {
        if(mySetting?.deliveryPostalCode)
          {
             setSuccessMessage("");
             SetDeliveryZipCodeError(true);
          }
        handleInputChange({
          target: { name: "deliveryCityName", value: "" },
        } as any);
        handleInputChange({
          target: { name: "deliveryAirportID", value: "" },
        } as any);
        handleInputChange({
          target: { name: "deliveryStateProvID", value: "" },
        } as any);
      }
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            id="txtMsDeliveryCompanyName"
            name="deliveryCompanyName"
            label="Company Name"
            defaultValue={mySetting.deliveryCompanyName}
            onChange={handleInputChange}
            variant="outlined"
            className="w-100"
            size="small"
          />
        </Grid>

        <Grid item lg={12} md={12} xs={12}>
          <TextField
            id="txtMsDeliveryAddressLine1"
            name="deliveryAddress1"
            defaultValue={mySetting.deliveryAddress1}
            onChange={handleInputChange}
            label="Address Line 1"
            variant="outlined"
            className="w-100"
            size="small"
            inputProps={{ maxLength: 50 }}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Box className="d-flex align-center gap-8">
            <TextField
              style={{ width: !showAddress3 ? "90%" : "100%" }}
              variant="outlined"
               id="txtMsDeliveryAddressLine2"
              label="Address Line 2 (Optional)"
              name="deliveryAddress2"
              size="small"
              defaultValue={mySetting.deliveryAddress2}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
            />
            {!showAddress3 && (
              <IconButton onClick={showAddress3Visibility} id="btnDddAddAddress">
                <AddIcon className="Add-icon" />
              </IconButton>
            )}
          </Box>
        </Grid>
        {showAddress3 && (
          <Grid item lg={12} md={12} xs={12}>
            <TextField
              id="txtMsDeliveryAddressLine3"
              label="Address Line 3 (Optional)"
              name="deliveryAddress3"
              defaultValue={mySetting.deliveryAddress3}
              onChange={handleInputChange}
              variant="outlined"
              className="w-100"
              size="small"
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
        )}
        <Grid item lg={6} md={6} xs={6}>
          <FormControl size="small" className="w-100">
            <InputLabel
              id="demo-select-small-label"
              shrink={!!mySetting.deliveryCityName}
            >
              City
            </InputLabel>
            <Select
              size="small"
              label="City"
              id="ddMsDeliveryCity"
              name="deliveryCityName"
              defaultValue={mySetting.deliveryCityName}
              value={mySetting.deliveryCityName}
              onChange={handleInputChange}
              fullWidth
            >
              {zipCodeData.length > 0 &&
                zipCodeData.map((city: any) => (
                  <MenuItem key={city.cityName} value={city.cityName}>
                    {city.cityName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={6} md={6} xs={6}>
          <TextField
            size="small"
            variant="outlined"
            label="State"
            id="txtMsDeliveryState"
            name="deliveryStateProvID"
            defaultValue={mySetting.deliveryStateProvID}
            value={mySetting.deliveryStateProvID}
            InputLabelProps={{ shrink: !!mySetting.deliveryStateProvID }}
            onChange={handleInputChange}
            inputProps={{ maxLength: 3 }}
            className="w-100"
          />
        </Grid>
        <Grid item lg={6} md={6} xs={6}>
          <TextField
            size="small"
            variant="outlined"
            id="txtMsDeliveryPostalCode"
            name="deliveryPostalCode"
            defaultValue={mySetting.deliveryPostalCode}
            onKeyDown={handleZipCodeKeyDown}
            onChange={handleInputChange}
            onBlur={handleZipCodeFocusOut}
            label="ZIP"
            className="w-100"
            inputProps={{ maxLength: 10 }}
            error={deliveryZipCodeError}
          />
        </Grid>
        <Grid item lg={6} md={6} xs={6}>
          <FormControl size="small" className="w-100">
            <InputLabel id="demo-select-small-label">Country</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="ddMsDeliveryCountry"
              label="Country"
              name="deliveryCountry"
              defaultValue={mySetting.deliveryCountryID}
              onChange={handleInputChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                PaperProps: {
                  style: menuStyles,
                },
              }}
            >
              {countryData.length > 0 &&
                countryData.map((country: any) => (
                  <MenuItem key={country.countryID} value={country.countryID}>
                    {country.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        {/* <Select variant="outlined" label="Country" className="w-100"></Select> */}
        <Grid item lg={6} md={6} xs={6}>
          <TextField
            variant="outlined"
            label="ATTN"
            id="txtMsDeliveryAttention"
            className="w-100"
            name="deliveryAttention"
            defaultValue={mySetting.deliveryAttention}
            onChange={handleInputChange}
            size="small"
          />
        </Grid>
        <Grid item lg={6} md={6} xs={6}>
        <TextField
  variant="outlined"
  size="small"
  id="txtMsDeliveryAirport"
  className="w-100"
  label="Airport"
  name="deliveryAirportID"
  inputProps={{ maxLength: 3 }}
  defaultValue={mySetting.deliveryAirportID}
  value={mySetting.deliveryAirportID}
  onChange={(e) => {
    const re = /^[a-zA-Z\s]*$/;
    const value = e.target.value;
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    handleInputChange({
      target: { name: "deliveryAirportID", value: filteredValue },
    } as any);
  }}
/>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            className="w-100"
            variant="outlined"
            id="txtMsDeliveryInstructions"
            label="Instructions"
            inputProps={{ maxLength: 2000 }}
            name="deliveryInstructions"
            defaultValue={mySetting.deliveryInstructions}
            onChange={handleInputChange}
            placeholder="Enter any additional instructions"
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
      <Typography className="sub_header pt-8 pb-8 mb-8 mt-16 pl-0">
        Default Delivery Contact
      </Typography>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} xs={6}>
          <TextField
            id="txtMsDeliveryPhoneNumber"
            name="deliveryPhoneNumber"
            label="Phone"
            defaultValue={mySetting.deliveryPhoneNumber}
            onChange={handleInputChange}
            variant="outlined"
            className="w-100"
            size="small"
          />
        </Grid>
        <Grid item lg={4} md={4} xs={6}>
          <TextField
            id="txtMsDeliveryFax"
            name="deliveryFaxNumber"
            label="Fax"
            variant="outlined"
            defaultValue={mySetting.deliveryFaxNumber}
            onChange={handleInputChange}
            className="w-100"
            size="small"
          />
        </Grid>
        <Grid item lg={4} md={4} xs={6}>
          <TextField
            id="txtMsDeliveryEmail"
            name="deliveryEmailAddress"
            label="Email"
            defaultValue={mySetting.deliveryEmailAddress}
            onChange={handleInputChange}
            variant="outlined"
            className="w-100"
            size="small"
          />
        </Grid>
      </Grid>
      <Typography className="sub_header pt-8 pb-8 mb-8 mt-16 pl-0">
        Default Requested Delivery Time
      </Typography>

      <Grid container spacing={2}>
        <Grid item lg={4} md={4} sm={6} xs={6}>
          <FormControl size="small" className="w-100">
            <InputLabel id="demo-select-small-label">Before</InputLabel>
            <Select
              id="ddMsDeliveryWhen"
              label="Before"
              name="deliverWhen"
              defaultValue={mySetting.deliverWhen}
              disabled={mySetting.deliveryRequestAsap}
              onChange={handleInputChange}
            >
              <MenuItem value="B">Before</MenuItem>
              <MenuItem value="A">After</MenuItem>
              <MenuItem value="T">At</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={6}>
          <DatePicker
            disabled={mySetting.deliveryRequestAsap}
            selected={
              mySetting.deliveryRequestTime
                ? new Date(mySetting.deliveryRequestTime)
                : null
            }
            id="txtMsDeliveryTimePicker"
            onChange={(val: any) => {
              handleInputChange({
                target: {
                  name: "deliveryRequestTime",
                  value: val,
                },
              });
            }}
            name="deliveryRequestTime"
            // isClearable={true}
            showTimeSelect
            showTimeSelectOnly
            popperClassName="custom-datepicker-popper"
            popperPlacement="bottom-start"
            timeIntervals={15}
            timeCaption="Delivery Time"
            dateFormat="hh:mm aa"
            placeholderText="hh:mm AM/PM"
            className="w-100"
            customInput={
              <TextField
                size="small"
                id="txtMsDeliveryTime"
                label="Delivery Time"
                variant="outlined"
                className="w-100"
                value={
                  mySetting.deliveryRequestTime
                    ? extractTime(mySetting.deliveryRequestTime)
                    : null
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccessTimeIcon
                        style={{ width: "16px", height: "16px" }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            }
          />
          {/* <TextField
          size="small"
          id="time-picker"
          label="Requested Delivery Time"
          type="time"
          variant="outlined"
          className="w-100"
          name="deliveryRequestTime"
          disabled={mySetting.deliveryRequestAsap}
          defaultValue={extractTime(mySetting.deliveryRequestTime)}
          onChange={handleInputChange}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccessTimeIcon style={{ width: "16px", height: "16px" }} />
              </InputAdornment>
            ),
          }}
        /> */}
        </Grid>
      </Grid>

      <Grid lg={12} sm={12} md={12} xs={12}>
        <FormControlLabel
          className="chk_bx_50 pr-8 pt-8"
          control={
            <Checkbox
              name="deliveryRequestAsap"
              defaultChecked={mySetting.deliveryRequestAsap}
              onChange={handleInputChange}
              id="cbMsDeliveryAsap"
            />
          }
          label="ASAP"
        />

        <FormControlLabel
          className="chk_bx_50 pr-8 pt-8"
          control={
            <Checkbox
              name="holdForPickup"
              defaultChecked={mySetting.holdForPickup}
              onChange={handleInputChange}
              id="cbHoldForPickup"
            />
          }
          label="Hold for PU"
        />
      </Grid>
    </>
  );
};

export default DefaultDeliveryDetails;
