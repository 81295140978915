import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { officeData } from "./office.reducer";
import { useEffect } from "react";

const useOfficeLogic = () => {
  const dispatch = useDispatch();
  const { loading, error, officelookUpData } = useSelector(
    (state: RootState) => state.office
  );

  const handleGetOfficeData = async () => {
    try {
      const response = await dispatch(officeData() as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    loading,
    error,
    officelookUpData,
    getOfficeLookUp: handleGetOfficeData,
  };
};

export default useOfficeLogic;
