import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { MySettingDto } from "../../../models/my-setting-dto";

interface DefaultShipperNotificationsProps {
  mySetting: MySettingDto;
  handleInputChange: (updatedData: any) => void;
}

const NotificationSettings: React.FC<DefaultShipperNotificationsProps> = ({
  mySetting,
  handleInputChange,
}) => {
  return (
    <>
      {/* Default Shipper Notifications */}
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Box className="d-flex flex-column w-100 border p16 rounded">
          <Typography variant="h6" className="Sub-header">
            Default Shipper Notifications
          </Typography>
          <Typography variant="body1" className="weight-500 font14 textcolor">
            Shipment Milestones
          </Typography>
          <Box className="d-flex flex-column w-100 p-2">
            <FormControlLabel
              control={
                <Checkbox
                  name="notifyShipperOrderAck"
                  defaultChecked={mySetting.notifyShipperOrderAck}
                  onChange={handleInputChange}
                  id="cbMsShipperAcknowledged"
                />
              }
              label="Acknowledged"
            />
            <Grid container alignItems="center">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="notifyShipperOrigQdt"
                      defaultChecked={mySetting.notifyShipperOrigQdt}
                      onChange={handleInputChange}
                      id="cbMsShipperEdtSet"
                    />
                  }
                  label="EDT Set"
                />
              </Grid>
              <Grid item>
                <p
                  className="addr_lbl1"
                  style={{
                    margin: 0,
                    paddingLeft: "32px",
                    marginTop: "-15px",
                  }}
                >
                  Routing has been determined & estimated delivery time (EDT)
                  has been set.
                </p>
              </Grid>
            </Grid>

            <Grid container alignItems="center">
              <Grid item lg={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="cbMsShipperEdtChanged"
                      name="notifyShipperChangeQdt"
                      defaultChecked={mySetting.notifyShipperChangeQdt}
                      onChange={handleInputChange}
                    />
                  }
                  label="EDT Changed"
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <p
                  className="addr_lbl1"
                  style={{
                    margin: 0,
                    paddingLeft: "32px",
                    marginTop: "-15px",
                  }}
                >
                  The estimated delivery time (EDT) has been changed.
                </p>
              </Grid>
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  name="notifyShipperDispatched"
                  defaultChecked={mySetting.notifyShipperDispatched}
                  onChange={handleInputChange}
                  id="cbMsShipperDispatched"
                />
              }
              label="Dispatched"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="notifyShipperPickedUp"
                  defaultChecked={mySetting.notifyShipperPickedUp}
                  onChange={handleInputChange}
                  id="cbMsShipperPickedup"
                />
              }
              label="Picked-Up"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="notifyShipperDropped"
                  defaultChecked={mySetting.notifyShipperDropped}
                  onChange={handleInputChange}
                  id="cbMsShipperDroppedOff"
                />
              }
              label="Dropped-Off"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="notifyShipperDeparted"
                  defaultChecked={mySetting.notifyShipperDeparted}
                  onChange={handleInputChange}
                  id="cbMsShipperDeparted"
                />
              }
              label="Departed"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="notifyShipperArrived"
                  defaultChecked={mySetting.notifyShipperArrived}
                  onChange={handleInputChange}
                  id="cbMsShipperArrived"
                />
              }
              label="Arrived"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="notifyShipperRecovered"
                  defaultChecked={mySetting.notifyShipperRecovered}
                  onChange={handleInputChange}
                  id="cbMsShipperRecovered"
                />
              }
              label="Recovered"
            />
            <Grid container alignItems="center">
              <Grid item lg={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="notifyShipperOutForDelivery"
                      defaultChecked={mySetting.notifyShipperOutForDelivery}
                      onChange={handleInputChange}
                      id="cbMsShipperOfd"
                    />
                  }
                  label="OFD"
                />
              </Grid>

              <Grid item lg={12} xs={12}>
                <p
                  className="addr_lbl1"
                  style={{
                    margin: 0,
                    paddingLeft: "32px",
                    marginTop: "-15px",
                  }}
                >
                  Shipment was marked out for delivery
                </p>
              </Grid>
            </Grid>

            <FormControlLabel
              control={
                <Checkbox
                  name="notifyShipperDelivered"
                  defaultChecked={mySetting.notifyShipperDelivered}
                  onChange={handleInputChange}
                  id="cbMsShipperDelivered"
                />
              }
              label="Delivered"
            />
          </Box>
        </Box>
      </Grid>

      {/* Default Consignee Notifications */}
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Box className="d-flex flex-column w-100 border p16 rounded">
          <Typography variant="h6" className="Sub-header">
            Default Consignee Notifications
          </Typography>
          <Typography variant="body1" className="weight-500 font14 textcolor">
            Shipment Milestones
          </Typography>
          <Box className="d-flex flex-column w-100 p-2">
            <FormControlLabel
              control={
                <Checkbox
                  name="notifyConseeOrderAck"
                  defaultChecked={mySetting.notifyConseeOrderAck}
                  onChange={handleInputChange}
                  id="cbMsConsigneeAcknowledged"
                />
              }
              label="Acknowledged"
            />
            <Grid container alignItems="center">
              <Grid item lg={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="notifyConseeOrigQdt"
                      defaultChecked={mySetting.notifyConseeOrigQdt}
                      onChange={handleInputChange}
                      id="cbMsConsigneeEdtSet"
                    />
                  }
                  label="EDT Set"
                />
              </Grid>
              <Grid item lg={12}>
                <p
                  className="addr_lbl1"
                  style={{
                    margin: 0,
                    paddingLeft: "32px",
                    marginTop: "-15px",
                  }}
                >
                  Routing has been determined & estimated delivery time (EDT)
                  has been set.
                </p>
              </Grid>
            </Grid>

            <Grid container alignItems="center">
              <Grid item lg={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="notifyConseeChangeQdt"
                      defaultChecked={mySetting.notifyConseeChangeQdt}
                      onChange={handleInputChange}
                      id="cbMsConsigneeEdtChanged"
                    />
                  }
                  label="EDT Changed"
                />
              </Grid>

              <Grid item lg={12} xs={12}>
                <p
                  className="addr_lbl1"
                  style={{
                    margin: 0,
                    paddingLeft: "32px",
                    marginTop: "-15px",
                  }}
                >
                  The estimated delivery time (EDT) has been changed.
                </p>
              </Grid>
            </Grid>

            <FormControlLabel
              control={
                <Checkbox
                  name="notifyConseeDispatched"
                  defaultChecked={mySetting.notifyConseeDispatched}
                  onChange={handleInputChange}
                  id="cbMsConsigneeDispatched"
                />
              }
              label="Dispatched"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="notifyConseePickedUp"
                  defaultChecked={mySetting.notifyConseePickedUp}
                  onChange={handleInputChange}
                  id="cbMsConsigneePickedup"
                />
              }
              label="Picked-Up"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="notifyConseeDropped"
                  defaultChecked={mySetting.notifyConseeDropped}
                  onChange={handleInputChange}
                  id="cbMsConsigneeDroppedOff"
                />
              }
              label="Dropped-Off"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="notifyConseeDeparted"
                  defaultChecked={mySetting.notifyConseeDeparted}
                  onChange={handleInputChange}
                  id="cbMsConsigneeDeparted"
                />
              }
              label="Departed"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="notifyConseeArrived"
                  defaultChecked={mySetting.notifyConseeArrived}
                  onChange={handleInputChange}
                  id="cbMsConsigneeArrived"
                />
              }
              label="Arrived"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="notifyConseeRecovered"
                  defaultChecked={mySetting.notifyConseeRecovered}
                  onChange={handleInputChange}
                  id="cbMsConsigneeRecovered"
                />
              }
              label="Recovered"
            />

            <Grid container>
              <Grid item lg={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="notifyConseeOutForDelivery"
                      defaultChecked={mySetting.notifyConseeOutForDelivery}
                      onChange={handleInputChange}
                      id="cbMsConsigneeOfd"
                    />
                  }
                  label="OFD"
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <p
                  className="addr_lbl1"
                  style={{
                    margin: 0,
                    paddingLeft: "32px",
                    marginTop: "-15px",
                  }}
                >
                  Shipment was marked out for delivery
                </p>
              </Grid>
            </Grid>

            <FormControlLabel
              control={
                <Checkbox
                  name="notifyConseeDelivered"
                  defaultChecked={mySetting.notifyConseeDelivered}
                  onChange={handleInputChange}
                  id="cbMsConsigneeDelivered"
                />
              }
              label="Delivered"
            />
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default NotificationSettings;
