import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import {
  API_BASE_URL,
} from "../../../config/constants";

// Action Types for Account
const USER_INFO_REQUEST = "USER_INFO_REQUEST";
const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";
const USER_INFO_FAILURE = "USER_INFO_FAILURE";

// Action Creators for Account
export const userInfoRequest = () => ({
  type: USER_INFO_REQUEST,
});

export const userInfoSuccess = () => ({
  type: USER_INFO_SUCCESS,
});

export const userInfoFailure = (error: string) => ({
  type: USER_INFO_FAILURE,
  payload: error,
});

// Initial State for Account
export interface UserInfoState {
  isAuthenticated: boolean;
  loading: boolean;
  userInfoError: string | null;
}

const initialAuthState: UserInfoState = {
  isAuthenticated: false,
  loading: false,
  userInfoError: null,
};

// Define the interface for the API response
interface UserInfoResponse {
  success: boolean;
  message: string;
  data:any;
  statusCode: string;
}

// Reducer for Authentication
const userInfoReducer = (
  state = initialAuthState,
  action: any
): UserInfoState => {
  switch (action.type) {
    case USER_INFO_REQUEST:
      return { ...state, loading: true, userInfoError: null };
    case USER_INFO_SUCCESS:
      return { ...state, loading: false, isAuthenticated: true };
    case USER_INFO_FAILURE:
      return { ...state, loading: false, userInfoError: action.payload };
    default:
      return state;
  }
};

// Thunk for Account
export const userInfo = () => async (dispatch: Dispatch) => {
  dispatch(userInfoRequest());
  try {
    const response = await axiosInstance.get(
      `${API_BASE_URL}/api/Authentication/UserInfo`
    );
    // Assuming Account was successful if no error occurred
    dispatch(userInfoSuccess());
    const data: UserInfoResponse = response.data;
    // Store token in localStorage
    if (response.status === 200 && data.success) {
      // Store token in localStorage
      return { data };
    } else {
      // Handle different status codes if necessary
      dispatch(userInfoFailure(data.message));
    }
    return response.data;
  } catch (error: any) {
    if (error.response.data.message) {
      dispatch(userInfoFailure(error.response.data.message));
    } else {
      dispatch(userInfoFailure(error.message));
    }
  }
};

export default userInfoReducer;
