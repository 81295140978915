import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";
import { AddressItemDto } from "../../../models/address-item-dto";

// Action Types for Address
const CUSTOMER_ADDRESS_REQUEST = "CUSTOMER_ADDRESS_REQUEST";
const CUSTOMER_ADDRESS_SUCCESS = "CUSTOMER_ADDRESS_SUCCESS";
const CUSTOMER_ADDRESS_FAILURE = "CUSTOMER_ADDRESS_FAILURE";

// Action Creators for Address
export const customerAddressRequest = () => ({
  type: CUSTOMER_ADDRESS_REQUEST,
});

export const  customerAddressSuccess = () => ({
  type: CUSTOMER_ADDRESS_SUCCESS,
});

export const  customerAddressFailure = (error: string) => ({
  type: CUSTOMER_ADDRESS_FAILURE,
  payload: error,
});

// Initial State for Address
export interface CustomerAddressState {
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
}

const initialAuthState: CustomerAddressState = {
  isAuthenticated: false,
  loading: false,
  error: null,
};

// Define the interface for the API response
interface CustomerAddressResponse {
  success: boolean;
  message: string;
  data: AddressItemDto[];
  statusCode: string;
}

// Reducer for Authentication
const customerAddressReducer = (
  state = initialAuthState,
  action: any
): CustomerAddressState => {
  switch (action.type) {
    case CUSTOMER_ADDRESS_REQUEST:
      return { ...state, loading: true, error: null };
    case CUSTOMER_ADDRESS_SUCCESS:
      return { ...state, loading: false, isAuthenticated: true };
    case CUSTOMER_ADDRESS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// Thunk for Address
export const customerAddress =
  (param: {
    companyName: string;
    cityName: string;
    stateProvID: string;
    addressCode: string;
    countryID:string;
    postalCode:string;
    returnAllIfNoParms:boolean;
    orderBy:string;
    sorting:string;
    pagesize:number;
    pageNo:number;
    
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(customerAddressRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Customer/GetAddress`,
        param
      );
      dispatch(customerAddressSuccess());
      const data:CustomerAddressResponse = response.data;
      if (response.status === 200 && data.success) {
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(customerAddressFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(customerAddressFailure(error.response.data.message));
      } else {
        dispatch(customerAddressFailure(error.message));
      }
    }
  };

export default customerAddressReducer;
