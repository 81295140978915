import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { reference } from "./reference.reducer";



const useReferenceLogic = () => {
  const dispatch = useDispatch();
  const { referenceLoading, referenceError } = useSelector((state: RootState) => state.reference);

  useEffect(() => {
    if (referenceError) {
      // dispatch(showNotification('Country failed: ' + error, 'error'));
    }
  }, [referenceError, dispatch]);

  const handleReference= async (param: {
    referenceGroupGUID:string
  }) => {
    try {
      const response = await dispatch(reference(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { referenceLoading, referenceError, reference: handleReference };
};

export default useReferenceLogic;
