import { REACT_APP_AHA_ACCOUNT_NAME, REACT_APP_AHA_APPLICATION_ID } from "./config/constants";

const getAhawidget = async () => {
    const userDataString = localStorage.getItem('userDetails');
    const emailAddress = localStorage.getItem('userEmail');
    if (userDataString) {
      const userData = JSON.parse(userDataString) as { userGUID: string; firstName: string ; lastName: string };
      (function(w: any, d: Document, s: string, o: string, f: string) {
        w['aha-widget'] = o;
        w[o] = w[o] || function() {
          (w[o].q = w[o].q || []).push(arguments);
        };
        const js = d.createElement(s) as HTMLScriptElement;
        const fjs = d.getElementsByTagName(s)[0];
        js.id = o;
        js.src = f;
        js.async = true;
        fjs.parentNode?.insertBefore(js, fjs);
      })(window, document, 'script', 'aha', 'https://cdn.aha.io/assets/feedback/feedback.js');
      (window as any).aha('initialize', {
        account: REACT_APP_AHA_ACCOUNT_NAME,
        applicationId: REACT_APP_AHA_APPLICATION_ID,
        user: {
          id: userData.userGUID,
          name: userData.firstName + ' ' + userData.lastName,
          email: emailAddress || '',
        },
      });
    }
  }

  export default getAhawidget;