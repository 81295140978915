import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { documentType } from "./document-type.reducer";

const useDocumentTypeLogic = () => {
  const dispatch = useDispatch();
  const { documentTypeLoading, documentTypeError } = useSelector((state: RootState) => state.documentType);

  useEffect(() => {
    if (documentTypeError) {
      // dispatch(showNotification('Account failed: ' + error, 'error'));
    }
  }, [documentTypeError, dispatch]);

  const handleDocumentType = async (param: {
    companyID: number;
  }) => {
    try {
      const response = await dispatch(documentType(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { documentTypeLoading, documentTypeError, documentType: handleDocumentType };
};

export default useDocumentTypeLogic;