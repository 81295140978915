import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { willCall } from "./will-call-orders.reducer";

const useWillCallLogic = () => {
  const dispatch = useDispatch();
  const { willCallLoading, willCallError } = useSelector(
    (state: RootState) => state.willCall
  );

  const handleWillCall = async (param: { shipmentguid: string }) => {
    try {
      const response = await dispatch(willCall(param) as any);
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { willCallLoading, willCallError, willCallOrder: handleWillCall };
};

export default useWillCallLogic;
