import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";
import { TimezoneItemDto } from "../../../models/timezone-item-dto";

// Action Types for Timezone
const TIMEZONE_REQUEST = "TIMEZONE_REQUEST";
const TIMEZONE_SUCCESS = "TIMEZONE_SUCCESS";
const TIMEZONE_FAILURE = "TIMEZONE_FAILURE";

// Action Creators for Timezone
export const timezoneRequest = () => ({
  type: TIMEZONE_REQUEST,
});

export const timezoneSuccess = () => ({
  type: TIMEZONE_SUCCESS,
});

export const timezoneFailure = (error: string) => ({
  type: TIMEZONE_FAILURE,
  payload: error,
});

// Initial State for Timezone
export interface TimezoneState {
  isAuthenticated: boolean;
  timezoneloading: boolean;
  timezoneError: string | null;
}

const initialAuthState: TimezoneState = {
  isAuthenticated: false,
  timezoneloading: false,
  timezoneError: null,
};

// Define the interface for the API response
interface TimezoneResponse {
  success: boolean;
  message: string;
  data: TimezoneItemDto[];
  statusCode: string;
}

// Reducer for Authentication
const timezoneReducer = (
  state = initialAuthState,
  action: any
): TimezoneState => {
  switch (action.type) {
    case TIMEZONE_REQUEST:
      return { ...state, timezoneloading: true, timezoneError: null };
    case TIMEZONE_SUCCESS:
      return { ...state, timezoneloading: false, isAuthenticated: true };
    case TIMEZONE_FAILURE:
      return { ...state, timezoneloading: false, timezoneError: action.payload };
    default:
      return state;
  }
};

// Thunk for timezone
export const timezone =
  (param: {
   
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(timezoneRequest());
    try {
      const response = await axiosInstance.get(
        `${API_BASE_URL}/api/Lookup/GetTimezone`,
        param
      );
      // Assuming timezone was successful if no error occurred
      dispatch(timezoneSuccess());
      const data:TimezoneResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(timezoneFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(timezoneFailure(error.response.data.message));
      } else {
        dispatch(timezoneFailure(error.message));
      }
    }
  };

export default timezoneReducer;
