import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { userInfo } from "./user-info.reducer";

const useUserInfoLogic = () => {
  const dispatch = useDispatch();
  const { loading, userInfoError } = useSelector((state: RootState) => state.userInfo);

  useEffect(() => {
    if (userInfoError) {
      // dispatch(showNotification('Account failed: ' + userInfoError, 'userInfoError'));
    }
  }, [userInfoError, dispatch]);

  const handleUserInfo = async () => {
    try {
      const response = await dispatch(userInfo() as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { loading, userInfoError, userInfo: handleUserInfo };
};

export default useUserInfoLogic;
