import {
  HIDE_NOTIFICATION,
  SHOW_NOTIFICATION,
} from "./actions/notification.actions";

interface NotificationState {
  message: string | null;
  severity: "success" | "info" | "warning" | "error" | null;
}

const initialState: NotificationState = {
  message: null,
  severity: null,
};

const notificationReducer = (
  state = initialState,
  action: any
): NotificationState => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return {
        ...state,
        message: action.payload.message,
        severity: action.payload.severity,
      };
    case HIDE_NOTIFICATION:
      return initialState;
    default:
      return state;
  }
};

export default notificationReducer;
