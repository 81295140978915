import {
  Box,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { EditOrderDto } from "../../../models/edit-active-order-dto";

interface DeliveryAddressProps {
  orderDetails: EditOrderDto;
}

const EditDeliveryAddress: React.FC<DeliveryAddressProps> = ({
  orderDetails,
}) => {
  const [formData, setFormData] = useState({
    ...orderDetails,
  });

  useEffect(() => {
    if (orderDetails) {
      setFormData({
        ...orderDetails,
      });
    }
  }, [orderDetails]);

  return (
    <Box className="d-flex flex-column w-100 border rounded detail-info-section">
      <Typography variant="h6" className="Sub-header">
        Delivery Details
      </Typography>
      <Box className="d-flex flex-column gap-12">
        <Box className="d-flex gap-16">
          <TextField
            id="txtEoDeliveryCompanyName"
            size="small"
            variant="outlined"
            label="Company Name"
            className="w-100"
            value={formData.delCompanyName}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <TextField
          size="small"
          id="txtEoDeliveryAddress1"
          variant="outlined"
          label="Address Line 1"
          className="w-100"
          value={formData.delAddress1}
          InputProps={{
            readOnly: true,
            inputProps: { maxLength: 50 }
          }}
        />
        <TextField
          size="small"
          variant="outlined"
          id="txtEoDeliveryAddress2"
          className="w-100"
          label="Address Line 2  (Optional)"
          value={formData.delAddress2}
          InputProps={{
            readOnly: true,
            inputProps: { maxLength: 50 }
          }}
        />
        <TextField
          size="small"
          variant="outlined"
          label="Address Line 3 (Optional)"
          id="txtEoDeliveryAddress3"
          className="w-100"
          value={formData.delAddress3}
          InputProps={{
            readOnly: true,
            inputProps: { maxLength: 50 }
          }}
        />

        <Box className="d-flex gap-16">
          <TextField
            size="small"
            variant="outlined"
            id="txtEoDeliveryCity"
            label="City"
            className="w-100"
            value={formData.delCityName}
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            size="small"
            variant="outlined"
            id="txtEoDeliveryState"
            label="State"
            className="w-100"
            value={formData.delStateProvID}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Box className="d-flex gap-16">
          <TextField
            size="small"
            variant="outlined"
            label="ZIP"
            className="w-100"
            id="txtEoDeliveryPostalCode"
            value={formData.delPostalCode}
            InputProps={{
              readOnly: true,
            }}
          />
          <TextField
            size="small"
            variant="outlined"
            label="Country"
            className="w-100"
            id="txtEoDeliveryCountry"
            value={formData.delCountryID}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Box className="d-flex gap-16">
          <TextField
            size="small"
            variant="outlined"
            label="ATTN"
            className="w-100"
            id="txtEoDeliveryAttention"
            value={formData.delAttention}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
      </Box>
      <Box className="d-flex flex-column mt-3">
        <Typography variant="h6" className="Sub-header">
          Delivery Contact
        </Typography>
        <Box className="d-flex gap-12">
          <TextField
            size="small"
            variant="outlined"
            id="txtEoDeliveryPhoneNumber"
            label="Phone"
            className="w-100"
            value={formData.delPhoneNumber}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EditDeliveryAddress;
