import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";
import { DocumentTypeItemDto } from "../../../models/track-order-item-dto";

// Action Types for DocumentType
const DOCUMENT_TYPE_REQUEST = "DOCUMENT_TYPE_REQUEST";
const DOCUMENT_TYPE_SUCCESS = "DOCUMENT_TYPE_SUCCESS";
const DOCUMENT_TYPE_FAILURE = "DOCUMENT_TYPE_FAILURE";

// Action Creators for DocumentType
export const documentTypeRequest = () => ({
  type: DOCUMENT_TYPE_REQUEST,
});

export const documentTypeSuccess = () => ({
  type: DOCUMENT_TYPE_SUCCESS,
});

export const documentTypeFailure = (error: string) => ({
  type: DOCUMENT_TYPE_FAILURE,
  payload: error,
});

// Initial State for DocumentType
export interface DocumentTypeState {
  isAuthenticated: boolean;
  documentTypeLoading: boolean;
  documentTypeError: string | null;
}

const initialAuthState: DocumentTypeState = {
  isAuthenticated: false,
  documentTypeLoading: false,
  documentTypeError: null,
};

// Define the interface for the API response
interface DocumentTypeResponse {
  success: boolean;
  message: string;
  data: Array<DocumentTypeItemDto>;
  statusCode: string;
}

// Reducer for Authentication
const documentTypeReducer = (
  state = initialAuthState,
  action: any
): DocumentTypeState => {
  switch (action.type) {
    case DOCUMENT_TYPE_REQUEST:
      return { ...state, documentTypeLoading: true, documentTypeError: null };
    case DOCUMENT_TYPE_SUCCESS:
      return { ...state, documentTypeLoading: false, isAuthenticated: true };
    case DOCUMENT_TYPE_FAILURE:
      return { ...state, documentTypeLoading: false, documentTypeError: action.payload };
    default:
      return state;
  }
};

// Thunk for DocumentType
export const documentType =
  (param: {
    companyID: number;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(documentTypeRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/orders/TrackAttachDoc`,
        param
      );
      // Assuming Account was successful if no error occurred
      dispatch(documentTypeSuccess());
      const data: DocumentTypeResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(documentTypeFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(documentTypeFailure(error.response.data.message));
      } else {
        dispatch(documentTypeFailure(error.message));
      }
    }
  };

export default documentTypeReducer;
