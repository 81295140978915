import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";
import { PackageTypeItemDto } from "../../../models/long-form-item-dto";

// Action Types for PackageType
const PACKAGE_TYPE_REQUEST = "PACKAGE_TYPE_REQUEST";
const PACKAGE_TYPE_SUCCESS = "PACKAGE_TYPE_SUCCESS";
const PACKAGE_TYPE_FAILURE = "PACKAGE_TYPE_FAILURE";

// Action Creators for PackageType
export const packageTypeRequest = () => ({
  type: PACKAGE_TYPE_REQUEST,
});

export const packageTypeSuccess = (data: any) => ({
  type: PACKAGE_TYPE_SUCCESS,
  payload: data,
});

export const packageTypeFailure = (error: string) => ({
  type: PACKAGE_TYPE_FAILURE,
  payload: error,
});

// Initial State for PackageType
export interface PackageTypeState {
  isAuthenticated: boolean;
  packageLoading: boolean;
  packageTypeError: string | null;
  packageTypeData: any;
}

const initialAuthState: PackageTypeState = {
  isAuthenticated: false,
  packageLoading: false,
  packageTypeError: null,
  packageTypeData: [],
};

// Define the interface for the API response
interface PackageTypeResponse {
  success: boolean;
  message: string;
  data: Array<PackageTypeItemDto>;
  statusCode: string;
}

// Reducer for Authentication
const packageTypeReducer = (
  state = initialAuthState,
  action: any
): PackageTypeState => {
  switch (action.type) {
    case PACKAGE_TYPE_REQUEST:
      return { ...state, packageLoading: true, packageTypeError: null };
    case PACKAGE_TYPE_SUCCESS:
      return {
        ...state,
        packageLoading: false,
        isAuthenticated: true,
        packageTypeData: action.payload,
      };
    case PACKAGE_TYPE_FAILURE:
      return {
        ...state,
        packageLoading: false,
        packageTypeError: action.payload,
      };
    default:
      return state;
  }
};

// Thunk for PackageType
export const packageType =
  (param: {
    companyID: number;
    showDeleted: boolean;
    customerID: number;
    includeBlank: boolean;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(packageTypeRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Lookup/GetPackagingType`,
        param
      );
      // Assuming Account was successful if no error occurred

      const data: PackageTypeResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        dispatch(packageTypeSuccess(response));
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(packageTypeFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(packageTypeFailure(error.response.data.message));
      } else {
        dispatch(packageTypeFailure(error.message));
      }
    }
  };

export default packageTypeReducer;
