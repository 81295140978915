import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { batchPrints } from "./batch-prints.reducer";
import { BatchPrintsDto } from "../../models/batch-prints-dto";

const useBatchPrintsLogic = () => {
  const dispatch = useDispatch();
  const { batchPrintsLoading, batchPrintsError } = useSelector(
    (state: RootState) => state.batchPrints
  );

  useEffect(() => {
    if (batchPrintsError) {
      // dispatch(showNotification('Active Order failed: ' + error, 'error'));
    }
  }, [batchPrintsError, dispatch]);

  const handleBatchPrints = async (param: BatchPrintsDto) => {
    try {
      const response = await dispatch(batchPrints(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    batchPrintsLoading,
    batchPrintsError,
    batchPrints: handleBatchPrints,
  };
};

export default useBatchPrintsLogic;
