// utils.ts
export const handleNumericValue = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const charCode = event.key.charCodeAt(0);
    // Allow backspace (keyCode 8), tab (keyCode 9), shift (keyCode 16), and numbers (keyCode 48-57)
    if (!(event.key === "Backspace" || event.key === "Tab" || event.key === "Shift" ||  event.key === 'Home' ||  event.key === 'Delete' || event.key === 'F12' || (charCode >= 48 && charCode <= 57))) {
      event.preventDefault();
    }
  };



  export const handleDecimalValue = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const charCode = event.key.charCodeAt(0);
    const currentValue = event.currentTarget.value !== undefined ? event.currentTarget.value : '';
  
    // Allow backspace, tab, shift, numbers, and a single decimal point
    if (
      !(event.key === 'Backspace' || event.key === 'Tab' || event.key === 'Shift' || event.key === 'Home' || event.key === 'F12' ||   event.key === 'Delete'||
        (charCode >= 48 && charCode <= 57) ||
        (event.key === '.' && !currentValue.includes('.')))
    ) {
      event.preventDefault();
    }
  };

  export const handleCopyValue = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const charCode = event.key.charCodeAt(0);
    const currentValue = event.currentTarget.value !== undefined ? event.currentTarget.value : '';
  
    // Allow backspace, tab, shift, home, delete
    if (event.key === 'Backspace' || event.key === 'Tab' || event.key === 'Shift' || event.key === 'Home' || event.key === 'F12' || event.key === 'Delete') {
      return;
    }
  
    // Allow numbers (1-9) and 10
    if ((charCode >= 49 && charCode <= 57 && currentValue.length < 2) || (currentValue === '1' && event.key === '0')) { // 49 is ASCII code for '1', 57 is ASCII code for '9'
      const newValue = parseInt(currentValue + event.key, 10);
  
      if (newValue > 10) {
        event.preventDefault();
      }
      return;
    }
  
    event.preventDefault();
  };

// utils.ts
export const handlePreventInvalidZeroOrLess = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const inputElement = event.currentTarget;
  const currentValue = inputElement.value || ''; // Ensure currentValue is always a string
  const key = event.key;

  // Allow control keys and navigation keys
  if (
    key === 'Backspace' || key === 'Tab' || key === 'Shift' || key === 'ArrowLeft' ||
    key === 'ArrowRight' || key === 'Delete' || key === 'Enter'
  ) {
    return;
  }

  // Prevent entering '0' as the first character
  if (currentValue === '' && key === '0') {
    event.preventDefault();
    return;
  }

  // Allow entering numbers greater than 0
  const newValue = currentValue + key;

  // Ensure the new value is not less than 1
  if (parseInt(newValue, 10) < 1) {
    event.preventDefault();
  }
};


export const extractTime = (dateTime: string | null) => {
  if (!dateTime) return '00:00'; // Default time if null
  const date = new Date(dateTime);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};





  


  
  
  