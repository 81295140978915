import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { InvoiceItemDto, InvoiceRequestDto } from "../../models/invoices-dto";
// Action Types for  Batch Prints
const _REQUEST = "_REQUEST";
const _SUCCESS = "_SUCCESS";
const _FAILURE = "_FAILURE";

// Action Creators for  Batch Prints
export const _Request = () => ({
  type: _REQUEST,
});

export const _Success = () => ({
  type: _SUCCESS,
});

export const _Failure = (error: string) => ({
  type: _FAILURE,
  payload: error,
});

// Initial State for  Batch Prints
export interface InvoiceDataState {
  isAuthenticated: boolean;
  InvoiceDataLoading: boolean;
  InvoiceDataError: string | null;
}

const initialState: InvoiceDataState = {
  isAuthenticated: false,
  InvoiceDataLoading: false,
  InvoiceDataError: null,
};

// Define the interface for the API response
interface InvoicesDataResponse {
  success: boolean;
  message: string;
  totalCount: number;
  pageCount: number;
  data: Array<InvoiceItemDto>;
  statusCode: string;
}

// Reducer for Authentication
const invoicesReducer = (
  state = initialState,
  action: any
): InvoiceDataState => {
  switch (action.type) {
    case _REQUEST:
      return { ...state, InvoiceDataLoading: true, InvoiceDataError: null };
    case _SUCCESS:
      return { ...state, InvoiceDataLoading: false, isAuthenticated: true };
    case _FAILURE:
      return {
        ...state,
        InvoiceDataLoading: false,
        InvoiceDataError: action.payload,
      };
    default:
      return state;
  }
};

export const invoicesData =
  (param: InvoiceRequestDto) => async (dispatch: Dispatch) => {
    dispatch(_Request());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Report/api/GetInvoiceData`,
        param
      );

      dispatch(_Success());
      const data: InvoicesDataResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(_Failure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(_Failure(error.response.data.message));
      } else {
        dispatch(_Failure(error.message));
      }
    }
  };

export const exportInvoice = (param: any) => async (dispatch: Dispatch) => {
  dispatch(_Request());
  try {
    const response = await axiosInstance.post(
      `${API_BASE_URL}/api/Report/api/GetInvoiceReport`,
      param,
      { responseType: "arraybuffer" }
    );
    // Assuming  Batch Prints was successful if no error occurred
    dispatch(_Success());
    const data: any = response.data;
    // Store token in localStorage
    if (response.status === 200 && data.success) {
      // Store token in localStorage
      return { data };
    } else {
      // Handle different status codes if necessary
      dispatch(_Failure(data.message));
    }
    return response.data;
  } catch (error: any) {
    if (error.response.data.message) {
      dispatch(_Failure(error.response.data.message));
    } else {
      dispatch(_Failure(error.message));
    }
  }
};
export default invoicesReducer;
