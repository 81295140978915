import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../shared/components/Header";
import { Box, Toolbar } from "@mui/material";

import { useLoader } from "../../shared/util/loader-context";
import { loaderManager } from "../../shared/util/loader-manager";
import Loader from "../../shared/util/loader";

const AppLayout = () => {
  const { isLoading, setIsLoading } = useLoader();
  const [showHeader, setShowHeader] = useState(true);
  useEffect(() => {
    loaderManager.setLoaderFunction(setIsLoading);
  }, [setIsLoading]);
  return (
    <div>
      <Box sx={{ display: "flex" }}>
        {showHeader && <Header />}
        <Box component="main" className="w-100 Main-container">
          {showHeader && <Toolbar className="login-header" />}

          <div className="wrap">
            {isLoading && <Loader />}
            <Outlet context={[setShowHeader]} />
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default AppLayout;
