import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { updateDangerousGoods } from "./track-order-edit-dangerous-good.reducer";
import { DangerousGoodsItemDto } from "../../models/dangerous-goods-item-dto";

const useUpdateDangerousGoodsLogic = () => {
  const dispatch = useDispatch();
  const { dangerousGoodsUpdateLoading, dangerousGoodsUpdateError } =
    useSelector((state: RootState) => state.updateDangerousGoods);

  useEffect(() => {
    if (dangerousGoodsUpdateError) {
      // dispatch(showNotification('Login failed: ' + error, 'error'));
    }
  }, [dangerousGoodsUpdateError, dispatch]);

  const handleUpdateDangerousGoods = async (param: DangerousGoodsItemDto) => {
    try {
      const response = await dispatch(updateDangerousGoods(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    dangerousGoodsUpdateLoading,
    dangerousGoodsUpdateError,
    updateDangerousGoods: handleUpdateDangerousGoods,
  };
};

export default useUpdateDangerousGoodsLogic;
