import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { MySettingDto } from "../../../models/my-setting-dto";
import { CountryItemDto } from "../../../models/country-item-dto";
import useTimezoneLogic from "../../lookup/timezone/timezone.logic";
import { TimezoneItemDto } from "../../../models/timezone-item-dto";
import useCurrencyLogic from "../../lookup/currency/currency.logic";

interface MeasurementDefaultsProps {
  mySetting: MySettingDto;
  handleInputChange: (updatedData: any) => void;
  countryData:CountryItemDto[];
}

const MeasurementDefaults: React.FC<MeasurementDefaultsProps> = ({
  mySetting,
  handleInputChange,
  countryData
}) => {
  const [timezoneData, setTimezoneData] = useState<TimezoneItemDto[]>([]);
  const [currencies, setCurrencies] = useState<
    {
      currencyID: string;
      shortName: string;
      name: string;
      description: string;
      symbolText: string;
      symbolImage: string;
    }[]
  >([]);
  const { timezoneError, timezone } = useTimezoneLogic();
  const { currencyError, currency } = useCurrencyLogic();

  useEffect(() => {
    getTimezone();
    getCurrency();
  }, []);

  const getTimezone = async () => {
    try {
      const param = {};
      const response = await timezone(param);
      if (response.data && response.data.data) {
        setTimezoneData(response.data.data);
      }
    } catch (err) {
      setTimezoneData([]);
    }
  };

  const getCurrency = async () => {
    try {
      const response = await currency();
      if (response.data && response.data.data) {
        setCurrencies(response.data.data);
      }
    } catch (err) {
    }
  };

  interface MenuStyles {
    maxHeight: string;
  }

  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
    maxHeight: "200px", // Default max height for desktop
  });

  return (
    <Grid item lg={4} md={6} sm={6} xs={12}>
      <Box className="p16 border rounded">
        <Typography className="sub_header padding0 mb-16">
          Measurement Defaults
        </Typography>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small-label">Country</InputLabel>
              <Select
                className="w-100"
                labelId="demo-select-small-label"
                id="ddMsCountry"
                label="Country"
                name="countryID"
                defaultValue={mySetting.countryID}
                onChange={handleInputChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: menuStyles,
                  },
                }}
              >
                {countryData.length > 0 &&
                  countryData.map((country: any) => (
                    <MenuItem key={country.countryID} value={country.countryID}>
                      {country.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small-label">Timezone</InputLabel>
              <Select
                className="w-100"
                labelId="demo-select-small-label"
                id="ddMsTimezone"
                label="Timezone"
                defaultValue={mySetting.timezoneID}
                name="timezoneID"
                onChange={handleInputChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: menuStyles,
                  },
                }}
              >
                {timezoneData?.map((timezone) => (
                  <MenuItem
                    key={timezone.timezoneID}
                    className="wrap-text"
                    value={timezone.timezoneID}
                  >
                    {`${timezone.timezoneID}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small-label">Currency</InputLabel>
              <Select
                className="w-100"
                labelId="demo-select-small-label"
                id="ddMsCurrency"
                label="Currency"
                name="currencyID"
                defaultValue={mySetting.currencyID}
                onChange={handleInputChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: menuStyles,
                  },
                }}
              >
                {currencies.length > 0 &&
                  currencies.map((item) => (
                    <MenuItem key={item.currencyID} value={item.currencyID}>
                      {item.shortName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small-label">
                Wt Measurement
              </InputLabel>
              <Select
                className="w-100"
                labelId="demo-select-small-label"
                id="ddMsWeightUom"
                label="WtMeasurement"
                name="weightUOM"
                defaultValue={mySetting.weightUOM}
                onChange={handleInputChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: menuStyles,
                  },
                }}
              >
                <MenuItem value="LB">Pounds</MenuItem>
                <MenuItem value="KG">Kilograms</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item lg={12} md={12} xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small-label">
                Size Measurement
              </InputLabel>
              <Select
                className="w-100"
                labelId="demo-select-small-label"
                id="ddMsSizeUom"
                label="Size Measurement"
                name="sizeUOM"
                defaultValue={mySetting.sizeUOM}
                onChange={handleInputChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: menuStyles,
                  },
                }}
              >
                <MenuItem value="CM">Centimeters</MenuItem>
                <MenuItem value="IN">Inches</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small-label">
                Distance Measurement
              </InputLabel>
              <Select
                className="w-100"
                labelId="demo-select-small-label"
                id="ddMsDistanceUom"
                label="Distance Measurement"
                name="distanceUOM"
                defaultValue={mySetting.distanceUOM}
                onChange={handleInputChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: menuStyles,
                  },
                }}
              >
                <MenuItem value="MI">Miles</MenuItem>
                <MenuItem value="KM">Kilometers</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default MeasurementDefaults;
