import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { AddressBookDto } from "../../models/address-book-dto";
import {
  RateEstimateServicesDTO,
  RateEstimateZipcodeDto,
  RateEstimateFormDTO,
  RateEstimateApiResponse,
  RateEstimateFlightRequest,
} from "../../models/rate-estimate-dto";
// Action Types for  Rate Estimate
const _REQUEST = "_REQUEST";
const _SUCCESS = "_SUCCESS";
const _FAILURE = "_FAILURE";

// Action Creators for  Rate Estimate
export const _Request = () => ({
  type: _REQUEST,
});

export const _Success = () => ({
  type: _SUCCESS,
});

export const _Failure = (error: string) => ({
  type: _FAILURE,
  payload: error,
});

// Initial State for  Search Order
export interface RateEstimateState {
  isAuthenticated: boolean;
  rateEstimateLoading: boolean;
  rateEstimateError: string | null;
}

const initialAuthState: RateEstimateState = {
  isAuthenticated: false,
  rateEstimateLoading: false,
  rateEstimateError: null,
};

// Define the interface for the API response
interface RateEstimateResponse {
  success: boolean;
  message: string;
  totalCount: number;
  pageCount: number;
  data: Array<AddressBookDto>;
  statusCode: string;
}

// Reducer for Authentication
const rateEstimateReducer = (
  state = initialAuthState,
  action: any
): RateEstimateState => {
  switch (action.type) {
    case _REQUEST:
      return { ...state, rateEstimateLoading: true, rateEstimateError: null };
    case _SUCCESS:
      return { ...state, rateEstimateLoading: false, isAuthenticated: true };
    case _FAILURE:
      return {
        ...state,
        rateEstimateLoading: false,
        rateEstimateError: action.payload,
      };
    default:
      return state;
  }
};

export const calculateRateEstimate =
  (param: RateEstimateFormDTO) => async (dispatch: Dispatch) => {
    dispatch(_Request());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/SelectRateCalculate`,
        param
      );
      // Assuming  Rate Estimate was successful if no error occurred
      dispatch(_Success());
      const data: RateEstimateApiResponse = response.data;

      if (response.status === 200 && data.success) {
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(_Failure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(_Failure(error.response.data.message));
      } else {
        dispatch(_Failure(error.message));
      }
    }
  };

export const attributeByServices =
  (param: RateEstimateServicesDTO) => async () => {
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/ServiceAttribute?ServiceID=${param.ServiceID}&CompanyID=${param.CompanyID}&AccountNumber=${param.AccountNumber}`
      );

      const data: any = response.data;

      if (response.status === 200 && data.success) {
        return { data };
      }
      return response.data;
    } catch (error: any) {}
  };

export const zipcodeDetails = (param: RateEstimateZipcodeDto) => async () => {
  try {
    const response = await axiosInstance.post(
      `${API_BASE_URL}/api/Orders/PickupDeliveryPostalLookupDistance`,
      param
    );

    const data: any = response.data;

    if (response.status === 200 && data.success) {
      return { data };
    }
    return response.data;
  } catch (error: any) {}
};

export const totalPickupDeliveryDistance = (param: any) => async () => {
  try {
    const response = await axiosInstance.post(
      `${API_BASE_URL}/api/Orders/PickupDeliveryAddressLookup`,
      param
    );

    const data: any = response.data;

    if (response.status === 200 && data.success) {
      return { data };
    }
    return response.data;
  } catch (error: any) {}
};

// export const totalPickupDeliveryDistance = (param: any) => async () => {
//   try {
//     const queryString = new URLSearchParams(
//       param as Record<string, string>
//     ).toString();

//     const response = await axiosInstance.post(
//       `${API_BASE_URL}/api/Orders/TotalPickupDeliveryDistance?${queryString}`
//     );

//     const data: any = response.data;

//     if (response.status === 200 && data.success) {
//       return { data };
//     }
//     return response.data;
//   } catch (error: any) {}
// };

export const flightOptions = (param: RateEstimateFlightRequest) => async () => {
  try {
    const response = await axiosInstance.post(
      `${API_BASE_URL}/api/Orders/SelectRoute`,
      param
    );

    const data: any = response.data;

    if (response.status === 200 && data.success) {
      return { data };
    }
    return response.data;
  } catch (error: any) {}
};

export const checkServiceList = (param: any) => async () => {
  try {
    const response = await axiosInstance.post(
      `${API_BASE_URL}/api/Orders/CheckServiceList`,
      param
    );

    const data: any = response.data;

    if (response.status === 200 && data.success) {
      return { data };
    }
    return response.data;
  } catch (error: any) {}
};

export const getDefaultDate = (param: any) => async () => {
  try {
    const response = await axiosInstance.post(
      `${API_BASE_URL}/api/Lookup/GetCurrentDateTime`,
      param
    );

    const data: any = response.data;

    if (response.status === 200 && data.success) {
      return { data };
    }
    return response.data;
  } catch (error: any) {}
};

export const estimateETA = (param: any) => async () => {
  try {
    const response = await axiosInstance.post(
      `${API_BASE_URL}/api/Orders/InsertCalculateRate`,
      param
    );

    const data: any = response.data;

    if (response.status === 200 && data.success) {
      return { data };
    }
    return response.data;
  } catch (error: any) {}
};

export default rateEstimateReducer;
