import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { EditOrderDto } from "../../models/edit-active-order-dto";

// Action Types for Edit Order
const EDITORDER_REQUEST = "EDITORDER_REQUEST";
const EDITORDER_SUCCESS = "EDITORDER_SUCCESS";
const EDITORDER_FAILURE = "EDITORDER_FAILURE";

// Action Creators for Edit Order
export const editOrderRequest = () => ({
  type: EDITORDER_REQUEST,
});

export const editOrderSuccess = () => ({
  type: EDITORDER_SUCCESS,
});

export const editOrderFailure = (error: string) => ({
  type: EDITORDER_FAILURE,
  payload: error,
});

// Initial State for Edit Order
export interface EditOrderState {
  isAuthenticated: boolean;
  editOrderLoading: boolean;
  editOrderError: string | null;
}

const initialAuthState: EditOrderState = {
  isAuthenticated: false,
  editOrderLoading: false,
  editOrderError: null,
};

// Define the interface for the API response
interface EditOrderResponse {
  success: boolean;
  message: string;
  data: Array<EditOrderDto>;
  statusCode: string;
}

// Reducer for Authentication
const editOrderReducer = (
  state = initialAuthState,
  action: any
): EditOrderState => {
  switch (action.type) {
    case EDITORDER_REQUEST:
      return { ...state, editOrderLoading: true, editOrderError: null };
    case EDITORDER_SUCCESS:
      return { ...state, editOrderLoading: false, isAuthenticated: true };
    case EDITORDER_FAILURE:
      return {
        ...state,
        editOrderLoading: false,
        editOrderError: action.payload,
      };
    default:
      return state;
  }
};

// Thunk for Active Order
export const editOrder =
  (param: { trackingValue: string; userGUID: string }) =>
  async (dispatch: Dispatch) => {
    dispatch(editOrderRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/EditOrder`,
        param
      );
      // Assuming  Active Order was successful if no error occurred
      dispatch(editOrderSuccess());
      const data: EditOrderResponse = response.data;
      if (response.status === 200 && data.success) {
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(editOrderFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(editOrderFailure(error.response.data.message));
      } else {
        dispatch(editOrderFailure(error.message));
      }
    }
  };

export default editOrderReducer;
