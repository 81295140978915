import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";

// Action Types for signup
const TERMS_AND_CONDITIONS_REQUEST = "TERMS_AND_CONDITIONS_REQUEST";
const TERMS_AND_CONDITIONS_SUCCESS = "TERMS_AND_CONDITIONS_SUCCESS";
const TERMS_AND_CONDITIONS_FAILURE = "TERMS_AND_CONDITIONS_FAILURE";


// Action Creators for signup
export const termsAndConditionsRequest = () => ({
    type: TERMS_AND_CONDITIONS_REQUEST,
  });
  
  export const termsAndConditionsSuccess = () => ({
    type: TERMS_AND_CONDITIONS_SUCCESS,
  });
  
  export const termsAndConditionsFailure = (error: string) => ({
    type: TERMS_AND_CONDITIONS_FAILURE,
    payload: error,
  });



// Initial State for Signup
export interface TermsAndConditionsState {
    isAuthenticated: boolean;
    termsAndConditionsLoading: boolean;
    termsAndConditionsError: string | null;
  }


  const initialAuthState: TermsAndConditionsState = {
    isAuthenticated: false,
    termsAndConditionsLoading: false,
    termsAndConditionsError: null,
  };
  

  // Define the interface for the API response
interface TermsAndConditionsResponse {
    success: boolean;
    message: string;
    data: {
        termsAndConditionsGUID: string,
        companyID: number,
        applicationID: string,
        revisionDate: any,
        htmlData: string,
        summary: string,
        insertUserGUID: string,
        insertDateTime: any,
        updateUserGUID: string,
        updateDateTime: any,
    };
    statusCode: string;
  }
  
  // Reducer for Authentication
  const termsAndConditionsReducer = (state = initialAuthState, action: any): TermsAndConditionsState => {
    switch (action.type) {
      case TERMS_AND_CONDITIONS_REQUEST:
        return { ...state, termsAndConditionsLoading: true, termsAndConditionsError: null };
      case TERMS_AND_CONDITIONS_SUCCESS:
        return { ...state, termsAndConditionsLoading: false, isAuthenticated: true };
      case TERMS_AND_CONDITIONS_FAILURE:
        return { ...state, termsAndConditionsLoading: false, termsAndConditionsError: action.payload };
      default:
        return state;
    }
  };
  
  // Thunk for Active Order
  export const GetTermsAndConditions = () => async (dispatch: Dispatch) => {
      dispatch(termsAndConditionsRequest());
      try {
        const response = await axiosInstance.get(
          `${API_BASE_URL}/api/Authentication/GetTermsAndConditions`
      );
        // Assuming Signup was successful if no error occurred
        dispatch(termsAndConditionsSuccess());
        const data: TermsAndConditionsResponse = response.data;
        // Store token in localStorage
         if (response.status === 200 && data.success) {
          // Store token in localStorage
          return { data };
        } else {
          // Handle different status codes if necessary
          dispatch(termsAndConditionsFailure(data.message));
        }
        return response.data;
      } catch (error: any) {
        if(error.response.data.message){
          dispatch(termsAndConditionsFailure(error.response.data.message));
          return error.response.data;
        }else{
          dispatch(termsAndConditionsFailure(error.message));
        }

      }
    };

    export const AgreedToTerms = (param: {
        userID: number;
        revisionDate: string;
      }) => async (dispatch: Dispatch) => {
        dispatch(termsAndConditionsRequest());
        try {
          const response = await axiosInstance.post(
            `${API_BASE_URL}/api/Authentication/AgreedToTerms`,
            param
        );
          // Assuming Signup was successful if no error occurred
          dispatch(termsAndConditionsSuccess());
          const data: TermsAndConditionsResponse = response.data;
          // Store token in localStorage
           if (response.status === 200 && data.success) {
            return { data };
          } else {
            // Handle different status codes if necessary
            dispatch(termsAndConditionsFailure(data.message));
          }
          return response.data;
        } catch (error: any) {
          if(error.response.data.message){
            dispatch(termsAndConditionsFailure(error.response.data.message));
            return error.response.data;
          }else{
            dispatch(termsAndConditionsFailure(error.message));
          }
  
        }
      };

    export default termsAndConditionsReducer;  
  