import React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import arrowicon from "../../assets/images/logo/img_arrow_blue.svg";
import newordericon from "../../assets/images/logo/new-order-icon.svg";
interface NoDataProps {
  heading?: string;
  title?: string;
  leftBtnText?: string;
  rightBtnText?: string;
  leftBtnUrl?: string;
  rightBtnUrl?: string;
  isDisplayBtn?: boolean;
}
const NoDataFound: React.FC<NoDataProps> = ({
  heading,
  title,
  leftBtnText,
  rightBtnText,
  leftBtnUrl,
  rightBtnUrl,
  isDisplayBtn = true,
}) => {
  const navigate = useNavigate();

  const handleRecentDeliveryRedirect = () => {
    navigate(leftBtnUrl ? leftBtnUrl : "/recent-deliveries");
  };

  const handleNewOrderRedirect = () => {
    navigate(rightBtnUrl ? rightBtnUrl : "/longform-order");
  };

  return (
    <div className="No_data">
      <Box className="no_data_section_field">
        <b className="no-data-header"> {heading || "No Active Orders Found"}</b>
        <p className="no-data-para">
          {title ||
            "The currently selected accounts do not have any orders actively being processed. Try selecting a new account in the menu above or one of the following:"}
        </p>
        {isDisplayBtn && (
          <>
            <p className="d-flex next-step-btn">
              <Button
                onClick={handleRecentDeliveryRedirect}
                id="btnActiveOrder"
                variant="outlined"
                color="primary"
                className="mr-16 btn-50 blue-btn mb-8"
                endIcon={<img src={arrowicon} alt="icon right" />}
              >
                {leftBtnText || "VIEW RECENT DELIVERIES"}
              </Button>
              <Button
                onClick={handleNewOrderRedirect}
                variant="outlined"
                id="btnNewOrder"
                className="btn-50 blue-btn mb-8"
                color="primary"
                startIcon={<img src={newordericon} alt="new order" />}
              >
                {rightBtnText || "NEW ORDER"}
              </Button>
            </p>
          </>
        )}
      </Box>
    </div>
  );
};

export default NoDataFound;
