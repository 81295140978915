import React, { useEffect, useState } from "react";
import { MySettingDto } from "../../../models/my-setting-dto";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { MAX_COMPANY_ID } from "../../../config/constants";
import useServiceLogic from "../../lookup/service/service.logic";
import { useDecodeJWT } from "../../hook/use-decodeJWT";
import useCommodityLogic from "../../lookup/commodity/commodity.logic";
import {
  handleDecimalValue,
  handleNumericValue,
} from "../../util/numeric-value";

interface ServiceAndPackageDefaultsProps {
  mySetting: MySettingDto;
  handleInputChange: (updatedData: any) => void;
  formErrors: any;
}

interface MenuStyles {
  maxHeight: string;
}

const ServiceAndPackageDefaults: React.FC<ServiceAndPackageDefaultsProps> = ({
  mySetting,
  handleInputChange,
  formErrors,
}) => {
  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
    maxHeight: "200px", // Default max height for desktop
  });

  const token = useDecodeJWT();
  const serviceAvailType = localStorage.getItem("serviceAvailType");
  const { serviceError, service } = useServiceLogic();
  const [serviceData, setServiceData] = useState<any>([]);
  const { commodityError, commodity } = useCommodityLogic();
  const [commodities, setCommodities] = useState<any>([]);

  useEffect(() => {
    // getService();
    getCommodity();
  }, []);

  useEffect(() => {
    let debounceTimeout: NodeJS.Timeout;
    debounceTimeout = setTimeout(() => {
      getService();
    }, 500); // 500ms delay

    // Clear the timeout if one of the dependencies changes before the timeout finishes
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [
    mySetting.pickupCountryID,
    mySetting.pickupCityName,
    mySetting.pickupStateProvID,
    mySetting.pickupPostalCode,
    mySetting.pickupAirportID,
    mySetting.deliveryCountryID,
    mySetting.deliveryCityName,
    mySetting.deliveryStateProvID,
    mySetting.deliveryPostalCode,
    mySetting.deliveryAirportID,
  ]);

  const handleBlurMultiple = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (!value || parseFloat(value) < 1) {
      handleInputChange({
        target: { name: "pieces", value: 1 },
      } as any);
      handleInputChange({
        target: { name: "length", value: 1 },
      } as any);
      handleInputChange({
        target: { name: "width", value: 1 },
      } as any);
      handleInputChange({
        target: { name: "height", value: 1 },
      } as any);
    }
  };

  const handleWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numericValue = value === "" ? null : parseFloat(value);
    handleInputChange({
      target: { name, value: numericValue },
    });
  };

  const getService = async () => {
    try {
      const param: any = {
        companyID: MAX_COMPANY_ID,
        userGUID: token.UserGUID,
        serviceAvailType: serviceAvailType,
        baseDateTime: new Date(),
        origCountryID: mySetting.pickupCountryID,
        origCityName: mySetting.pickupCityName,
        origStateProvID: mySetting.pickupStateProvID,
        origPostalCode: mySetting.pickupPostalCode,
        origAirportID: mySetting.pickupAirportID,
        destCountryID: mySetting.deliveryCountryID,
        destCityName: mySetting.deliveryCityName,
        destStateProvID: mySetting.deliveryStateProvID,
        destPostalCode: mySetting.deliveryPostalCode,
        destAirportID: mySetting.deliveryAirportID,
      };
      const response = await service(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        setServiceData(res);
      } else {
        setServiceData([]);
      }
    } catch (err) {
      setServiceData([]);
    }
  };

  const getCommodity = async () => {
    try {
      const response = await commodity();
      if (response.data && response.data.data) {
        setCommodities(response.data.data);
      }
    } catch (err) {}
  };

  return (
    <Grid item lg={4} md={6} sm={6} xs={12} className="service_package_section">
      <Box className="p16 border rounded">
        <Typography className="sub_header padding0 mb-16">
          Service and Package Defaults
        </Typography>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small-label">Service</InputLabel>
              <Select
                className="w-100"
                labelId="demo-select-small-label"
                id="ddMsService"
                label="Service"
                name="serviceGUID"
                defaultValue={mySetting.serviceGUID}
                onChange={handleInputChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: menuStyles,
                  },
                }}
              >
                <MenuItem value="">(None)</MenuItem>
                {serviceData.length > 0 &&
                  serviceData.map((service: any) => (
                    <MenuItem
                      key={service.serviceGUID}
                      value={service.serviceGUID}
                    >
                      {service.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small-label">Commodity</InputLabel>
              <Select
                className="w-100"
                labelId="demo-select-small-label"
                id="ddMsCommodity"
                label="Commodity"
                name="commodity"
                defaultValue={mySetting.commodity}
                error={!!formErrors.commodity}
                onChange={handleInputChange}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },

                  PaperProps: {
                    style: menuStyles,
                  },
                }}
              >
                <MenuItem value="">(None)</MenuItem>
                {commodities.length > 0 &&
                  commodities.map((item: any) => (
                    <MenuItem
                      key={item.commodityGuid}
                      value={item.commodityName}
                    >
                      {item.commodityName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item lg={12} md={12} xs={12}>
            <TextField
              name="contents"
              defaultValue={mySetting.contents}
              onChange={handleInputChange}
              label="Contents"
              id="txtMsContents"
              variant="outlined"
              className="w-100"
              size="small"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={6}>
            <TextField
              id="txtMsPieces"
              name="pieces"
              label="Quantity"
              defaultValue={mySetting.pieces}
              value={mySetting.pieces}
              InputLabelProps={{ shrink: !!mySetting.pieces }}
              onChange={handleInputChange}
              onKeyDown={handleNumericValue}
              onBlur={handleBlurMultiple}
              inputProps={{ min: 1 }}
              variant="outlined"
              className="w-100"
              size="small"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={6}>
            <TextField
              id="txtMsWeight"
              name="weight"
              label="Weight"
              defaultValue={mySetting.weight}
              onChange={handleWeightChange}
              onKeyDown={handleDecimalValue}
              variant="outlined"
              className="w-100"
              size="small"
              inputProps={{ min: 1, maxLength: 4 }}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={6}>
            <TextField
              id="txtMsLength"
              name="length"
              label="Length"
              defaultValue={mySetting.length}
              onChange={handleInputChange}
              onKeyDown={handleNumericValue}
              onBlur={handleBlurMultiple}
              value={mySetting.length}
              variant="outlined"
              className="w-100"
              size="small"
              inputProps={{ min: 1, maxLength: 4 }}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={6}>
            <TextField
              id="txtMsWidth"
              name="width"
              label="Width"
              defaultValue={mySetting.width}
              onChange={handleInputChange}
              onKeyDown={handleNumericValue}
              onBlur={handleBlurMultiple}
              value={mySetting.width}
              inputProps={{ min: 1, maxLength: 4 }}
              variant="outlined"
              className="w-100"
              size="small"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={6}>
            <TextField
              id="txtMsHeight"
              name="height"
              label="Height"
              defaultValue={mySetting.height}
              onChange={handleInputChange}
              onKeyDown={handleNumericValue}
              onBlur={handleBlurMultiple}
              value={mySetting.height}
              inputProps={{ min: 1, maxLength: 4 }}
              variant="outlined"
              className="w-100"
              size="small"
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ServiceAndPackageDefaults;
