import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { AgreedToTerms, GetTermsAndConditions } from "./terms-and-conditions.reducer";

const useTermsAndConditionsLogic = () => {
  const dispatch = useDispatch();
  const { termsAndConditionsLoading, termsAndConditionsError } = useSelector((state: RootState) => state.termsAndConditions);

  useEffect(() => {
    if (termsAndConditionsError) {
      // dispatch(showNotification('Login failed: ' + error, 'error'));
    }
  }, [termsAndConditionsError, dispatch]);

  const handleGetTermsAndConditions = async () => {
    try {
      const response = await dispatch(GetTermsAndConditions() as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleAgreedToTerms = async (param: {
    userID: number;
    revisionDate: string;
  }) => {
    try {
      const response = await dispatch(AgreedToTerms(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { 
    termsAndConditionsLoading, 
    termsAndConditionsError, 
    getTermsAndConditions: handleGetTermsAndConditions,
    agreedToTerms: handleAgreedToTerms,
    };
};

export default useTermsAndConditionsLogic;