// src/hooks/ontimeLogic.ts
import { useDispatch, useSelector } from "react-redux";
import { fetchOntimePerformanceData } from "./ontime-performane.reducer";
import { useEffect } from "react";
import { exportOntimePerformanceData } from "./ontime-performane.reducer";
import { OntimePerformanceRequestDto } from "../../models/ontime-perfomance-dto";
import { RootState } from "../../types/global";

const useOntimePerformanceLogic = () => {
  const dispatch = useDispatch();
  const { loading, performanceData, ontimeError } = useSelector(
    (state: RootState) => state.ontimePerformance
  ) || {
    loading: false,
    performanceData: [],
    ontimeError: null,
  };

  useEffect(() => {
    if (ontimeError) {
      // dispatch(showNotification('Active Order failed: ' + error, 'error'));
    }
  }, [ontimeError, dispatch]);

  const getOntimePerformanceData = async (
    params: OntimePerformanceRequestDto
  ) => {
    try {
      const data = await dispatch(fetchOntimePerformanceData(params) as any);
      return data;
    } catch {}
  };

  const exportData: (
    params: OntimePerformanceRequestDto,
    exportType: "PDF" | "XLS"
  ) => Promise<void> = async (params, exportType) => {
    try {
      await dispatch(exportOntimePerformanceData(params, exportType) as any);
    } catch {}
  };

  return {
    loading,
    ontimeError,
    getData: getOntimePerformanceData,
    exportData,
  };
};

export default useOntimePerformanceLogic;
