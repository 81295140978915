import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { content } from "./contents.reducer";

const useContentLogic = () => {
  const dispatch = useDispatch();
  const { contentLoading, contentError, contentData } = useSelector(
    (state: RootState) => state.content
  );

  useEffect(() => {
    if (contentError) {
      // dispatch(showNotification('Account failed: ' + error, 'error'));
    }
  }, [contentError, dispatch]);

  const handleContent = async (param: {
    companyID: number;
    contentsReferenceGroupGUID: string;
  }) => {
    try {
      if (contentData && contentData?.data?.data.length > 0) {
        return contentData;
      } else {
        const response = await dispatch(content(param) as any);
        return response;
      }
    } catch (err) {
      throw err;
    }
  };

  return { contentLoading, contentError, content: handleContent };
};

export default useContentLogic;
