import { MAX_COMPANY_ID } from "../../config/constants";
import Cookies from "js-cookie";

const COOKIE_PREFIX = "userTemplate_"; // Prefix to differentiate user-specific cookie
interface HeadCell {
  disablePadding: boolean;
  id: keyof any;
  label: string;
  numeric: boolean;
  minwidth: string;
  isDefault?: boolean;
}
export const defaultSearchState = {
  accountNumber: "All",
  startDate: "",
  endDate: "",
  trackingNumber: "",
  contents: "",
  pickupCityName: "",
  pickupCountryId: "All",
  deliveryCityName: "",
  deliveryCountryId: "All",
  reference: "",
  reference2: "",
  reference3: "",
  reference4: "",
  reference5: "",
  referenceTypeId: null,
  referenceTypeId2: null,
  referenceTypeId3: null,
  referenceTypeId4: null,
  referenceTypeId5: null,
  invoiceNumber: "",
  orderByName: "",
  orderStatus: "",
  pickupPostalCode: "",
  pickupStateProvId: "",
  deliveryPostalCode: "",
  deliveryStateProvId: "",
  referenceTypeId6: null,
  referenceTypeId7: null,
  referenceTypeId8: null,
  referenceTypeId9: null,
  orderBy: "shipmentNumber",
  sorting: "desc",
  pagesize: 200,
  pageNo: 1,
  userId: 0,
  companyID: MAX_COMPANY_ID,
};

export const headCells: HeadCell[] = [
  {
    id: "shipmentNumber",
    numeric: true,
    disablePadding: false,
    label: "ORDER #",
    minwidth: "100",
    isDefault: true,
  },
  {
    id: "orderDateTime",
    numeric: true,
    disablePadding: false,
    label: "PLACED",
    minwidth: "110",
    isDefault: true,
  },
  {
    id: "trackingNumber",
    numeric: true,
    disablePadding: false,
    label: "Track #",
    minwidth: "110",
    isDefault: true,
  },
  {
    id: "pickupAddress",
    numeric: true,
    disablePadding: false,
    label: "PICKUP FULL",
    minwidth: "165",
    isDefault: true,
  },
  {
    id: "deliveryAddress",
    numeric: true,
    disablePadding: false,
    label: "DELIVER FULL",
    minwidth: "165",
    isDefault: true,
  },
  {
    id: "reference",
    numeric: true,
    disablePadding: false,
    label: "REF",
    minwidth: "110",
    isDefault: true,
  },
  {
    id: "weight",
    numeric: true,
    disablePadding: false,
    label: "WT",
    minwidth: "70",
    isDefault: true,
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "STATUS",
    minwidth: "140",
    isDefault: true,
  },
  {
    id: "podSignature",
    numeric: true,
    disablePadding: false,
    label: "SIGNED",
    minwidth: "140",
    isDefault: true,
  },
  {
    id: "podDateTime",
    numeric: true,
    disablePadding: false,
    label: "DEL. DATE",
    minwidth: "120",
    isDefault: true,
  },
  {
    id: "accountNumber",
    numeric: true,
    disablePadding: false,
    label: "Acct#",
    minwidth: "100",
  },
  {
    id: "officeCode",
    numeric: true,
    disablePadding: false,
    label: "Ofc",
    minwidth: "100",
  },
  {
    id: "reference2",
    numeric: true,
    disablePadding: false,
    label: "REF - #2",
    minwidth: "100",
  },
  {
    id: "reference3",
    numeric: true,
    disablePadding: false,
    label: "REF - #3",
    minwidth: "100",
  },
  {
    id: "pieces",
    numeric: true,
    disablePadding: false,
    label: "PCS",
    minwidth: "100",
  },
  {
    id: "weightUOM",
    numeric: true,
    disablePadding: false,
    label: "Wt UOM",
    minwidth: "100",
  },
  {
    id: "quotedDeliveryDateTime",
    numeric: true,
    disablePadding: false,
    label: "QDT",
    minwidth: "100",
  },
  {
    id: "distanceUOM",
    numeric: true,
    disablePadding: false,
    label: "Dst UOM",
    minwidth: "100",
  },
  {
    id: "pickupAttempts",
    numeric: true,
    disablePadding: false,
    label: "Pu Att",
    minwidth: "100",
  },
  {
    id: "deliveryAttempts",
    numeric: true,
    disablePadding: false,
    label: "DEL Att",
    minwidth: "100",
  },
  {
    id: "pickupWaitingTime",
    numeric: true,
    disablePadding: false,
    label: "Pu Wait",
    minwidth: "100",
  },
  {
    id: "deliveryWaitingTime",
    numeric: true,
    disablePadding: false,
    label: "Del Wait",
    minwidth: "100",
  },
  {
    id: "pickupDistance",
    numeric: true,
    disablePadding: false,
    label: "Pu Dst",
    minwidth: "100",
  },
  {
    id: "deliveryDistance",
    numeric: true,
    disablePadding: false,
    label: "Del Dst",
    minwidth: "100",
  },
  {
    id: "pickupAddressCode",
    numeric: true,
    disablePadding: false,
    label: "Pu Code",
    minwidth: "100",
  },
  {
    id: "deliveryAddressCode",
    numeric: true,
    disablePadding: false,
    label: "Del Code",
    minwidth: "100",
  },
  {
    id: "contents",
    numeric: true,
    disablePadding: false,
    label: "Contents",
    minwidth: "100",
  },
  {
    id: "totalCharges",
    numeric: true,
    disablePadding: false,
    label: "$Charges",
    minwidth: "100",
  },
  {
    id: "baseCharge",
    numeric: true,
    disablePadding: false,
    label: "$Base",
    minwidth: "100",
  },
  {
    id: "weightCharge",
    numeric: true,
    disablePadding: false,
    label: "$Weight",
    minwidth: "100",
  },
  {
    id: "fuelCharge",
    numeric: true,
    disablePadding: false,
    label: "$Fuel",
    minwidth: "100",
  },
  {
    id: "securityCharge",
    numeric: true,
    disablePadding: false,
    label: "$Security",
    minwidth: "100",
  },
  {
    id: "pickupMileageCharge",
    numeric: true,
    disablePadding: false,
    label: "$Pu Dst",
    minwidth: "100",
  },
  {
    id: "pickupAttemptCharge",
    numeric: true,
    disablePadding: false,
    label: "$Pu Att",
    minwidth: "100",
  },
  {
    id: "pickupWaitingCharge",
    numeric: true,
    disablePadding: false,
    label: "$Pu Wait",
    minwidth: "100",
  },
  {
    id: "pickupWeekendCharge",
    numeric: true,
    disablePadding: false,
    label: "$Pu WkEnd",
    minwidth: "100",
  },
  {
    id: "deliveryMileageCharge",
    numeric: true,
    disablePadding: false,
    label: "$Del Dst",
    minwidth: "100",
  },
  {
    id: "deliveryAttemptCharge",
    numeric: true,
    disablePadding: false,
    label: "$Del Att",
    minwidth: "100",
  },
  {
    id: "deliveryWaitingCharge",
    numeric: true,
    disablePadding: false,
    label: "$Del Wait",
    minwidth: "100",
  },
  {
    id: "deliveryWeekendCharge",
    numeric: true,
    disablePadding: false,
    label: "$Del WkEnd",
    minwidth: "100",
  },
  {
    id: "otherCharge",
    numeric: true,
    disablePadding: false,
    label: "$Other",
    minwidth: "100",
  },
  {
    id: "holdAndDeliver",
    numeric: true,
    disablePadding: false,
    label: "$Hold",
    minwidth: "100",
  },
  {
    id: "calledorderByNameInBy",
    numeric: true,
    disablePadding: false,
    label: "Caller",
    minwidth: "100",
  },
  {
    id: "pickupPersonToSee",
    numeric: true,
    disablePadding: false,
    label: "Pu Contact",
    minwidth: "100",
  },
  {
    id: "deliveryAttention",
    numeric: true,
    disablePadding: false,
    label: "Del Contact",
    minwidth: "100",
  },
  {
    id: "reference4",
    numeric: true,
    disablePadding: false,
    label: "REF - #4",
    minwidth: "100",
  },
  {
    id: "serviceID",
    numeric: true,
    disablePadding: false,
    label: "Service",
    minwidth: "100",
  },
  {
    id: "securityScreeningCharge",
    numeric: true,
    disablePadding: false,
    label: "$Screening",
    minwidth: "100",
  },
  {
    id: "pickupAddressFull",
    numeric: true,
    disablePadding: false,
    label: "PICKUP",
    minwidth: "100",
  },
  {
    id: "deliveryAddressFull",
    numeric: true,
    disablePadding: false,
    label: "DELIVER",
    minwidth: "100",
  },
  {
    id: "pickupPostalCode",
    numeric: true,
    disablePadding: false,
    label: "Pickup Zip",
    minwidth: "100",
  },
  {
    id: "deliveryPostalCode",
    numeric: true,
    disablePadding: false,
    label: "Deliver Zip",
    minwidth: "100",
  },
  {
    id: "pickupAirportID",
    numeric: true,
    disablePadding: false,
    label: "Orig AP",
    minwidth: "100",
  },
  {
    id: "deliveryAirportID",
    numeric: true,
    disablePadding: false,
    label: "Dest AP",
    minwidth: "100",
  },
  {
    id: "referenceValue_Type4",
    numeric: true,
    disablePadding: false,
    label: "REF - MISC",
    minwidth: "100",
  },
  {
    id: "pickupCompanyName",
    numeric: true,
    disablePadding: false,
    label: "Pu - Company",
    minwidth: "100",
  },
  {
    id: "pickupAddress1",
    numeric: true,
    disablePadding: false,
    label: "Pu - Addr1",
    minwidth: "100",
  },
  {
    id: "pickupAddress2",
    numeric: true,
    disablePadding: false,
    label: "Pu - Addr2",
    minwidth: "100",
  },
  // { id: "pickupAddress2", numeric: true, disablePadding: false, label: "Pu - Addr3", minwidth: "100" },
  {
    id: "pickupCityName",
    numeric: true,
    disablePadding: false,
    label: "Pu - City",
    minwidth: "100",
  },
  {
    id: "pickupStateProvID",
    numeric: true,
    disablePadding: false,
    label: "Pu - State",
    minwidth: "100",
  },
  // { id: "pickupPostalCode", numeric: true, disablePadding: false, label: "Pu - Postal", minwidth: "100" },
  {
    id: "pickupCountryID",
    numeric: true,
    disablePadding: false,
    label: "Pu - Country",
    minwidth: "100",
  },
  {
    id: "deliveryCompanyName",
    numeric: true,
    disablePadding: false,
    label: "Del - Company",
    minwidth: "100",
  },
  {
    id: "deliveryAddress1",
    numeric: true,
    disablePadding: false,
    label: "Del - Addr1",
    minwidth: "100",
  },
  {
    id: "deliveryAddress2",
    numeric: true,
    disablePadding: false,
    label: "Del - Addr2",
    minwidth: "100",
  },
  {
    id: "deliveryAddress3",
    numeric: true,
    disablePadding: false,
    label: "Del - Addr3",
    minwidth: "100",
  },
  {
    id: "deliveryCityName",
    numeric: true,
    disablePadding: false,
    label: "Del - City",
    minwidth: "100",
  },
  {
    id: "deliveryStateProvID",
    numeric: true,
    disablePadding: false,
    label: "Del - State",
    minwidth: "100",
  },
  //  { id: "deliveryPostalCode", numeric: true, disablePadding: false, label: "Del - Postal", minwidth: "100" },
  {
    id: "reference5",
    numeric: true,
    disablePadding: false,
    label: "Ref #5",
    minwidth: "100",
  },
  {
    id: "deliveryCountryID",
    numeric: true,
    disablePadding: false,
    label: "Del - Country",
    minwidth: "100",
  },
  {
    id: "totalDistance",
    numeric: true,
    disablePadding: false,
    label: "Total Distance",
    minwidth: "100",
  },
  {
    id: "updatedPickupDateTime",
    numeric: true,
    disablePadding: false,
    label: "Pu Sched DT/TM",
    minwidth: "100",
  },
  {
    id: "actualPickupArrival",
    numeric: true,
    disablePadding: false,
    label: "Pu Actual DT/TM",
    minwidth: "100",
  },
  {
    id: "routing",
    numeric: true,
    disablePadding: false,
    label: "Flights",
    minwidth: "100",
  },
  {
    id: "invoiceList",
    numeric: true,
    disablePadding: false,
    label: "Invoice#",
    minwidth: "100",
  },
  {
    id: "reference6",
    numeric: true,
    disablePadding: false,
    label: "Ref #6",
    minwidth: "100",
  },
  {
    id: "reference7",
    numeric: true,
    disablePadding: false,
    label: "Ref #7",
    minwidth: "100",
  },
  {
    id: "reference8",
    numeric: true,
    disablePadding: false,
    label: "Ref #8",
    minwidth: "100",
  },
  {
    id: "reference9",
    numeric: true,
    disablePadding: false,
    label: "Ref #5",
    minwidth: "100",
  },
  {
    id: "pickupEmailAddress",
    numeric: true,
    disablePadding: false,
    label: "Pu - Email",
    minwidth: "100",
  },
  {
    id: "pickupPhoneNumber",
    numeric: true,
    disablePadding: false,
    label: "Pu - Phone",
    minwidth: "100",
  },
  {
    id: "deliveryEmailAddress",
    numeric: true,
    disablePadding: false,
    label: "Del - Email",
    minwidth: "100",
  },
  {
    id: "deliveryPhoneNumber",
    numeric: true,
    disablePadding: false,
    label: "Del - Phone",
    minwidth: "100",
  },
  {
    id: "pickupInstructions",
    numeric: true,
    disablePadding: false,
    label: "Pu - Instr",
    minwidth: "100",
  },
  {
    id: "deliveryInstructions",
    numeric: true,
    disablePadding: false,
    label: "Del - Instr",
    minwidth: "100",
  },
  {
    id: "orderSource",
    numeric: true,
    disablePadding: false,
    label: "Order Source",
    minwidth: "100",
  },
];

export const getSaveSearchParams = (
  searchFormState: any,
  defaultDatas: any,
  order: string,
  orderBy: string
) => {
  const {
    accountNumber,
    startDate,
    endDate,
    pickupCityName,
    pickupCountryId,
    deliveryCityName,
    deliveryCountryId,
    reference,
    reference2,
    reference3,
    reference4,
    reference5,
    referenceTypeId,
    // referenceTypeId2,
    // referenceTypeId3,
    // referenceTypeId4,
    // referenceTypeId5,
    trackingNumber,
    contents,
    invoiceNumber,
    orderByName,
    orderStatus,
    pickupPostalCode,
    pickupStateProvId,
    deliveryPostalCode,
    deliveryStateProvId,
    referenceTypeId6,
    referenceTypeId7,
    referenceTypeId8,
    referenceTypeId9,
  } = searchFormState;
  const param = {
    accountNumber: accountNumber === "All" ? "" : accountNumber,
    startDate: startDate !== "" ? startDate : null,
    endDate: endDate !== "" ? endDate : null,
    trackingNumber: trackingNumber,
    contents: contents,
    pickupCityName: pickupCityName,
    pickupCountryId: pickupCountryId == "All" ? "" : pickupCountryId, // eslint-disable-line
    deliveryCityName: deliveryCityName,
    deliveryCountryId: deliveryCountryId == "All" ? "" : deliveryCountryId, // eslint-disable-line
    reference: reference,
    reference2: reference2,
    reference3: reference3,
    reference4: reference4,
    reference5: reference5,
    referenceTypeId: referenceTypeId,
    referenceTypeId2: reference2 !== null ? defaultDatas?.referenceTypeID2 : 0,
    referenceTypeId3: reference3 !== null ? defaultDatas?.referenceTypeID3 : 0,
    referenceTypeId4: reference4 !== null ? defaultDatas?.referenceTypeID4 : 0,
    referenceTypeId5: reference5 !== null ? defaultDatas?.referenceTypeID5 : 0,
    invoiceNumber: parseInt(invoiceNumber, 10),
    orderByName: orderByName,
    orderStatus: orderStatus,
    pickupPostalCode: pickupPostalCode,
    pickupStateProvId: pickupStateProvId,
    deliveryPostalCode: deliveryPostalCode,
    deliveryStateProvId: deliveryStateProvId,
    referenceTypeId6: referenceTypeId6,
    referenceTypeId7: referenceTypeId7,
    referenceTypeId8: referenceTypeId8,
    referenceTypeId9: referenceTypeId9,
    orderBy: orderBy,
    sorting: order,
  };
  return param;
};

export const getHeadingAndRemainColumn = (columns: any) => {
  const filterValues = columns.split(",");
  const filteredItems = headCells.filter((_item: any) =>
    filterValues.includes(_item.id)
  );
  const currentHeadCell = filteredItems.sort((a, b) => {
    return filterValues.indexOf(a.id) - filterValues.indexOf(b.id);
  });
  const remainHeadCell = headCells.filter(
    (item: any) => !filterValues.includes(item.id)
  );
  return { currentHeadCell, remainHeadCell };
};

export function formatISODateToYYYYMMDD(isoDate: string) {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export const getTemplateCookieName = (userId: string) =>
  `${COOKIE_PREFIX}${userId}`;
export const setTemplateCookie = (userId: string, templateData: any) => {
  const cookieName = getTemplateCookieName(userId);
  Cookies.set(cookieName, templateData, { expires: 7 }); // expire in  7 days
};
export const getTemplateCookieValue = (userId: string) => {
  const cookieName = getTemplateCookieName(userId);
  const cookieValue = Cookies.get(cookieName);
  return cookieValue;
};
