interface DeliveryLocation {
  holdForPickup: boolean;
  recoverAirportID?: string;
  waybillNumber?: string;
  routeNumber?: string;
  arriveDateTime?: string;
  deliveryCompanyName: string;
  deliveryAddress1?: string;
  deliveryAddress2?: string;
  deliveryAddress3?: string;
  deliveryCityName: string;
  deliveryStateProvID?: string;
  deliveryPostalCode?: string;
  deliveryCountryID: string;
}

export const formatDeliveryLocation = ({
  holdForPickup,
  recoverAirportID,
  waybillNumber,
  routeNumber,
  arriveDateTime,
  deliveryCompanyName,
  deliveryAddress1,
  deliveryAddress2,
  deliveryCityName,
  deliveryStateProvID,
  deliveryPostalCode,
  deliveryCountryID,
}: DeliveryLocation): string => {
  let dsp = "";

  if (holdForPickup) {
    dsp = `HOLD FOR PICKUP: ${
      recoverAirportID ? recoverAirportID : ""
    } MAWB#: ${waybillNumber ? waybillNumber : ""} FLIGHT: ${
      routeNumber ? routeNumber : ""
    } ARRIVE: ${
      arriveDateTime
        ? new Date(arriveDateTime)
            .toLocaleString("en-US", {
              weekday: "short",
              day: "2-digit",
              month: "short",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })
            .toUpperCase()
        : ""
    } ${deliveryCityName}${
      deliveryStateProvID ? `, ${deliveryStateProvID}` : ""
    }${deliveryPostalCode ? ` ${deliveryPostalCode}` : ""} ${
      deliveryCountryID ? deliveryCountryID : ""
    }`;
  } else {
    dsp = `<div class='weight-600 addr_label'>${deliveryCompanyName}</div><div>`;
    dsp += deliveryAddress1
      ? `${deliveryAddress1}${deliveryAddress2 || deliveryCityName ? ", " : ""}`
      : "";
    dsp += deliveryAddress2
      ? `${deliveryAddress2}${deliveryCityName ? ", " : ""}`
      : "";
    dsp += deliveryCityName;
    dsp += deliveryStateProvID ? `, ${deliveryStateProvID}` : "";
    dsp += `</div>`;
  }

  return dsp;
};

export const formatDeliveryFullLocation = (row: any): string => {
  let dsp = row?.deliveryCityName;
  if (row.deliveryCountryID == "US") {
    dsp += row?.deliveryStateProvID ? `, ${row?.deliveryStateProvID}` : "";
    dsp += row?.deliveryPostalCode ? `, ${row?.deliveryPostalCode}` : "";
  } else {
    dsp += row?.deliveryPostalCode ? `, ${row?.deliveryPostalCode}` : "";
    dsp += row?.deliveryCountryID ? `, ${row?.deliveryCountryID}` : "";
  }
  return dsp;
};
