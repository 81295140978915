import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { verifyUser } from "./verify-user.reducer";

const useVerifyUserLogic = () => {
  const dispatch = useDispatch();
  const { verifyUserLoading, verifyUserError } = useSelector((state: RootState) => state.verifyUser);

  useEffect(() => {
    if (verifyUserError) {
      // dispatch(showNotification('Account failed: ' + error, 'error'));
    }
  }, [verifyUserError, dispatch]);

  const handleVerifyUser = async (param: {
    userGUID: string;
  }) => {
    try {
      const response = await dispatch(verifyUser(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { verifyUserLoading, verifyUserError, verifyUser: handleVerifyUser };
};

export default useVerifyUserLogic;