import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  Box,
  Alert,
} from "@mui/material";

interface PromptDialogProps {
  open: boolean;
  title: string;
  defaultValue: string;
  onClose: (result: string | null) => void;
  savedSearchError: string;
}

const SaveSearchPromptDialog: React.FC<PromptDialogProps> = ({
  open,
  title,
  defaultValue,
  onClose,
  savedSearchError,
}) => {
  const [inputValue, setInputValue] = useState<string>(defaultValue);

  const handleCancel = () => {
    onClose(null); // or any other value to indicate cancellation
  };

  const handleOk = () => {
    onClose(inputValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  useEffect(() => {
    setInputValue("");
  }, [open]);

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>
        {savedSearchError && (
          <Box className="w-100">
            <Alert variant="filled" severity="error" id="txtSsdError">
              <div>{savedSearchError}</div>
            </Alert>
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" className="header-sub-title">
          {title}
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          // label={message}
          type="text"
          id="txtSoDialogText"
          fullWidth
          value={inputValue}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} id="btnSoDialogCancel">Cancel</Button>
        <Button onClick={handleOk} id="btnSoDialogOk">OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveSearchPromptDialog;
