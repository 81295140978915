import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { country } from "./country.reducer";


const useCountryLogic = () => {
  const dispatch = useDispatch();
  const { loading, countyError, countryData } = useSelector((state: RootState) => state.country);

  useEffect(() => {
    if (countyError) {
      // dispatch(showNotification('Country failed: ' + error, 'error'));
    }
  }, [countyError, dispatch]);

  const handleCountry = async (param: {}) => {
    try {
    
      //If data is already fetched then use it from redux
      if(countryData && countryData?.data?.data.length > 0) {
        return countryData;
      } else {
        const response = await dispatch(country(param) as any);
        return response;
      }
      
    } catch (err) {
      throw err;
    }
  };

  return { loading, countyError, country: handleCountry };
};

export default useCountryLogic;
