import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import {} from "../../../models/active-order-item-dto";
import { NotesItemDto } from "../../../models/track-order-item-dto";

interface NotesProps {
  notes: NotesItemDto[];
}

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof NotesItemDto;
  label: string;
  numeric: boolean;
  minwidth: string;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof NotesItemDto
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const Notes: React.FC<NotesProps> = ({ notes }) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof NotesItemDto>("shipmentNoteID");
  const [notesData, setNotesData] = useState<NotesItemDto[]>([]);
  const [notData, setNotData] = useState<any>("");

  useEffect(() => {
    if (notes.length !== 0) {
      setNotesData(notes);
    } else {
      setNotData(
        "(no customer viewable notes are available for this shipment)"
      );
    }
  }, [notes]);

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] === null) {
      return -1;
    }

    if (a[orderBy] === null) {
      return 1;
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }

    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof NotesItemDto>(
    order: Order,

    orderBy: Key
  ): (a: NotesItemDto, b: NotesItemDto) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const sortedNotesData = notesData.slice().sort(getComparator(order, orderBy));

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof NotesItemDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const NotesHeadCells: readonly HeadCell[] = [
    {
      id: "insertDateTime",
      numeric: false,
      disablePadding: false,
      label: "Date/Time",
      minwidth: "144",
    },
    {
      id: "note",
      numeric: false,
      disablePadding: false,
      label: "Note",
      minwidth: "auto",
    },
  ];

  function NotesEnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof NotesItemDto) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {NotesHeadCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "desc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Box>
      <TableContainer>
        <div className="RouteTable">
          <Table className="RouteTablebody" aria-labelledby="tableTitle">
            <NotesEnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={notesData.length}
            />
            <TableBody>
              {sortedNotesData.length === 0 ? (
                <TableRow id="trNotes">
                  <TableCell
                    className="Datanofound"
                    colSpan={12}
                    align="center"
                    id="tdDataNotFound"
                  >
                    {notData}
                  </TableCell>
                  {/* Adjust the colspan according to the number of columns in your table */}
                </TableRow>
              ) : (
                sortedNotesData.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover key={row.shipmentNoteID} id="trNotes">
                      <TableCell className="DatePicker width144" id="tdDTDisplay">
                        {row.insertDateTimeDispaly}
                      </TableCell>
                      <TableCell className="" id="tdNote">{row.note}</TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
    </Box>
  );
};

export default Notes;
