import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";

// Action Types for USER_REFERENCE_GROUP
const USER_REFERENCE_GROUP_REQUEST = "USER_REFERENCE_GROUP_REQUEST";
const USER_REFERENCE_GROUP_SUCCESS = "USER_REFERENCE_GROUP_SUCCESS";
const USER_REFERENCE_GROUP_FAILURE = "USER_REFERENCE_GROUP_FAILURE";

// Action Creators for User Reference Group
export const userReferenceGroupRequest = () => ({
  type: USER_REFERENCE_GROUP_REQUEST,
});

export const userReferenceGroupSuccess = () => ({
  type: USER_REFERENCE_GROUP_SUCCESS,
});

export const userReferenceGroupFailure = (error: string) => ({
  type: USER_REFERENCE_GROUP_FAILURE,
  payload: error,
});

// Initial State for User Reference Group
export interface UserReferenceGroupState {
  isAuthenticated: boolean;
  loading: boolean;
  userReferenceGroupError: string | null;
}

const initialAuthState: UserReferenceGroupState = {
  isAuthenticated: false,
  loading: false,
  userReferenceGroupError: null,
};

// Define the interface for the API response
interface UserReferenceGroupResponse {
  success: boolean;
  message: string;
  data: any;
  statusCode: string;
}

// Reducer for Authentication
const userReferenceGroupReducer = (
  state = initialAuthState,
  action: any
): UserReferenceGroupState => {
  switch (action.type) {
    case USER_REFERENCE_GROUP_REQUEST:
      return { ...state, loading: true, userReferenceGroupError: null };
    case USER_REFERENCE_GROUP_SUCCESS:
      return { ...state, loading: false, isAuthenticated: true };
    case USER_REFERENCE_GROUP_FAILURE:
      return {
        ...state,
        loading: false,
        userReferenceGroupError: action.payload,
      };
    default:
      return state;
  }
};

// Thunk for User Reference Group
export const userReferenceGroup =
  (param: { userGUID: string }) => async (dispatch: Dispatch) => {
    dispatch(userReferenceGroupRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Lookup/GetUserReferenceGroup?UserGUID=${param.userGUID}`,
      {}
      );
      // Assuming User Reference Group was successful if no error occurred
      dispatch(userReferenceGroupSuccess());
      const data: UserReferenceGroupResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(userReferenceGroupFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(userReferenceGroupFailure(error.response.data.message));
      } else {
        dispatch(userReferenceGroupFailure(error.message));
      }
    }
  };

export default userReferenceGroupReducer;
