import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";

// Action Types for Office
const OFC_REQUEST = "OFC_REQUEST";
const OFC_SUCCESS = "OFC_SUCCESS";
const OFC_FAILURE = "OFC_FAILURE";

// Action Creators for Office
export const ofcRequest = () => ({
  type: OFC_REQUEST,
});

export const ofcSuccess = (data: any) => ({
  type: OFC_SUCCESS,
  payload: data,
});

export const ofcFailure = (error: string) => ({
  type: OFC_FAILURE,
  payload: error,
});

// Initial State for Office
export interface OfficeState {
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
  officelookUpData: any[];
}

const initialOfficeState: OfficeState = {
  isAuthenticated: false,
  loading: false,
  error: null,
  officelookUpData: [],
};

// Define the interface for the API response
interface OfcResponse {
  success: boolean;
  message: string;
  data: {
    companyName: string;
    officeId: number;
  }[];
  statusCode: string;
}

// Reducer for Office
const officeReducer = (
  state = initialOfficeState,
  action: any
): OfficeState => {
  switch (action.type) {
    case OFC_REQUEST:
      return { ...state, loading: true, error: null };
    case OFC_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        officelookUpData: action.payload,
      };
    case OFC_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// Thunk for fetching office data
export const officeData = () => async (dispatch: Dispatch) => {
  dispatch(ofcRequest());
  try {
    const response = await axiosInstance.get<OfcResponse>(
      `${API_BASE_URL}/api/Lookup/GetOffices`
    );

    const data = response.data;
    if (response.status === 200 && data.success) {
      dispatch(ofcSuccess(data.data));
      return { data };
    } else {
      dispatch(ofcFailure(data.message));
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || error.message || "Unknown error";
    dispatch(ofcFailure(errorMessage));
  }
};

export default officeReducer;
