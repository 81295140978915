import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import {
  addressBooks,
  addEditAddress,
  deleteAddress,
} from "./address-book.reducer";
import { AddressBookDto } from "../../models/address-book-dto";
const useAddressBookLogic = () => {
  const dispatch = useDispatch();
  const { addressBookLoading, addressBookError } = useSelector(
    (state: RootState) => state.addressBooks
  );

  useEffect(() => {
    if (addressBookError) {
      // dispatch(showNotification('Active Order failed: ' + error, 'error'));
    }
  }, [addressBookError, dispatch]);

  const handleAddressBook = async (param: AddressBookDto) => {
    try {
      const response = await dispatch(addressBooks(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleAddEditAddress = async (param: AddressBookDto) => {
    try {
      const response = await dispatch(addEditAddress(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleDeleteAddress = async (param: {
    original_AddressGUID: string | undefined;
  }) => {
    try {
      const response = await dispatch(deleteAddress(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    addressBookLoading,
    addressBookError,
    addressBooks: handleAddressBook,
    addEditAddress: handleAddEditAddress,
    deleteAddress: handleDeleteAddress,
  };
};

export default useAddressBookLogic;
