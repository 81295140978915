import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { BatchPrintsDto } from "../../models/batch-prints-dto";
import { exportDataSearchCriteriaDto } from "../../models/export-data-dto";
// Action Types for  Batch Prints
const _REQUEST = "_REQUEST";
const _SUCCESS = "_SUCCESS";
const _FAILURE = "_FAILURE";

// Action Creators for  Batch Prints
export const _Request = () => ({
  type: _REQUEST,
});

export const _Success = () => ({
  type: _SUCCESS,
});

export const _Failure = (error: string) => ({
  type: _FAILURE,
  payload: error,
});

// Initial State for  Batch Prints
export interface ExportDataState {
  isAuthenticated: boolean;
  exportDataLoading: boolean;
  exportDataError: string | null;
}

const initialState: ExportDataState = {
  isAuthenticated: false,
  exportDataLoading: false,
  exportDataError: null,
};

// Define the interface for the API response
interface ExportDataResponse {
  success: boolean;
  message: string;
  totalCount: number;
  pageCount: number;
  data: Array<BatchPrintsDto>;
  statusCode: string;
}

// Reducer for Authentication
const exportDataReducer = (
  state = initialState,
  action: any
): ExportDataState => {
  switch (action.type) {
    case _REQUEST:
      return { ...state, exportDataLoading: true, exportDataError: null };
    case _SUCCESS:
      return { ...state, exportDataLoading: false, isAuthenticated: true };
    case _FAILURE:
      return {
        ...state,
        exportDataLoading: false,
        exportDataError: action.payload,
      };
    default:
      return state;
  }
};

// Handle the API for Batch Prints  with search and sorting
export const getMasterItems = () => async (dispatch: Dispatch) => {
  dispatch(_Request());
  try {
    const response = await axiosInstance.get(
      `${API_BASE_URL}/api/Report/GetExportMasterItems`
    );

    dispatch(_Success());
    const data: ExportDataResponse = response.data;
    // Store token in localStorage
    if (response.status === 200 && data.success) {
      // Store token in localStorage
      return { data };
    } else {
      // Handle different status codes if necessary
      dispatch(_Failure(data.message));
    }
    return response.data;
  } catch (error: any) {
    if (error.response.data.message) {
      dispatch(_Failure(error.response.data.message));
    } else {
      dispatch(_Failure(error.message));
    }
  }
};

export const getMasterItemsById =
  (exportMasterID: number) => async (dispatch: Dispatch) => {
    dispatch(_Request());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Report/GetExportMasterItem`,
        { exportMasterID: exportMasterID }
      );

      dispatch(_Success());
      const data: ExportDataResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(_Failure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(_Failure(error.response.data.message));
      } else {
        dispatch(_Failure(error.message));
      }
    }
  };

export const exportData =
  (param: exportDataSearchCriteriaDto) => async (dispatch: Dispatch) => {
    dispatch(_Request());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Report/GetExportData`,
        param,
        { responseType: "arraybuffer" }
      );
      // Assuming  Batch Prints was successful if no error occurred
      dispatch(_Success());
      const data: any = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(_Failure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(_Failure(error.response.data.message));
      } else {
        dispatch(_Failure(error.message));
      }
    }
  };
export default exportDataReducer;
