import moment from "moment";

export const formatDate = (date: string): string => {
  // Add your date formatting logic here
  return moment(date).local().format("DD-MMM-yyyy");
};

export function formatDateToMonthDay(dateString: string) {
  const date = new Date(dateString);
  const options: any = { month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

export const formatTime = (dateString: string, timeZone: string): string => {
  const time = moment(dateString).format("h:mma");
  return `${time} ${timeZone}`;
};

export const formatOnlyTime = (
  dateString: string,
  timeZone: string
): string => {
  const time = moment(dateString).format("h:mm");
  return `${time} ${timeZone}`;
};

export const formatDateAndTime = (
  dateString: string,
  timeZone: string
): string => {
  const date = moment(dateString); // No timezone adjustment
  // Check if the date is valid
  if (!date.isValid()) {
    return ""; // Return blank if the date is invalid
  }
  return `${date.format("MMMM D")}, ${date.format("h:mma")} ${timeZone}`;
};
export const formatDateAndTimeAndPlusMinute = (
  dateString: string,
  timeZone: string,
  minutes:number
): string => {
  const date = moment(dateString); // No timezone adjustment
  // Check if the date is valid
  if (!date.isValid()) {
    return ""; // Return blank if the date is invalid
  }
  date.add(minutes, 'minutes');
  return `${date.format("MMMM D")}, ${date.format("h:mma")} ${timeZone}`;
};

// export function calculateHoursDifference(
//   startTime: string,
//   endTime: string
// ): string {
//   const start = new Date(startTime);
//   const end = new Date(endTime);
//   const difference = end.getTime() - start.getTime(); // Difference in milliseconds
//   const hoursDifference = difference / (1000 * 60 * 60); // Convert to hours
//   // Round the result to 2 decimal places for format like "1.30 hr"
//   const roundedHours = (Math.round(hoursDifference * 100) / 100).toFixed(2);
//   return `${roundedHours} hr`;
// }

export function calculateHoursDifference(
  startTime: string,
  endTime: string
): string {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const difference = end.getTime() - start.getTime(); // Difference in milliseconds

  const totalMinutes = difference / (1000 * 60); // Convert to minutes
  const hours = Math.floor(totalMinutes / 60); // Get whole hours
  const minutes = Math.floor(totalMinutes % 60); // Get remaining minutes

  // Format the result as "1hr 53 min"
  let formattedTime = '';
  if (hours > 0) {
    formattedTime += `${hours}hr `;
  }
  if (minutes > 0) {
    formattedTime += `${minutes} min`;
  } else if (hours === 0) {
    formattedTime = '0 min'; // If both hours and minutes are 0
  }

  return formattedTime.trim();
}


export const formatDateToLocal = (dateString: string): any => {
  if (dateString) {
    return moment(dateString).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  } else {
    return null;
  }
};
