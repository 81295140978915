import {
  Box,
  FormControl,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  Button,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
import Loader from "../../shared/util/loader";
import { useMediaQuery } from "@mui/material";
import useInvoicesLogic from "./invoices.logic";
import { downloadAndSavePDF } from "../../shared/util/download-pdf";
import { Link } from "react-router-dom";
import EnhancedTableHead from "../../shared/components/table-head-cell/enhanced-table-head";
import { InvoiceItemDto } from "../../models/invoices-dto";
import NoDataFound from "../../shared/components/NoDataFound";
import CommonPagination from "../../shared/components/CustomPagination";

import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { formatDate } from "../../shared/util/date-utils";
import CustomDatePicker from "../../shared/components/CustomDatePicker";

type Order = "desc" | "asc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof InvoiceItemDto;
  label: string;
  numeric: boolean;
  minwidth: string;
}

const headCells: HeadCell[] = [
  {
    id: "invoiceNumber",
    numeric: true,
    disablePadding: false,
    label: "INVOICE #",
    minwidth: "115",
  },
  {
    id: "invoicePostDate",
    numeric: true,
    disablePadding: false,
    label: "POST DATE",
    minwidth: "180",
  },
  {
    id: "invoiceDueDate",
    numeric: true,
    disablePadding: false,
    label: "DUE DATE",
    minwidth: "150",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "AMOUNT",
    minwidth: "140",
  },
  {
    id: "taxAmount",
    numeric: true,
    disablePadding: false,
    label: "TAX",
    minwidth: "100",
  },
  {
    id: "totalAmount",
    numeric: true,
    disablePadding: false,
    label: "TOTAL",
    minwidth: "140",
  },
  {
    id: "balance",
    numeric: true,
    disablePadding: false,
    label: "BALANCE",
    minwidth: "140",
  },
  {
    id: "currencyID",
    numeric: true,
    disablePadding: false,
    label: "CURRENCY",
    minwidth: "120",
  },
  {
    id: "payoffDate",
    numeric: true,
    disablePadding: false,
    label: "PAY DATE",
    minwidth: "210",
  },
];

const Invoices = () => {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof InvoiceItemDto>("invoiceNumber");
  const [notData, setNotData] = React.useState<any>("");

  const [isLoading, setIsLoading] = useState(false);
  const { exportInvoice, invoicesData } = useInvoicesLogic();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [invoicesDataItem, setInvoicesData] = useState<InvoiceItemDto[]>([]);
  const token = useDecodeJWT();

  interface servicestyle {
    maxHeight: string;
  }

  const [servicestyle, setservicestyle] = useState<servicestyle>({
    maxHeight: "370px", // Default max height for desktop
  });
  const isTablet = useMediaQuery("(max-width:905px)");
  const isMobile = useMediaQuery("(max-width:599px)");

  const defaultSinceDate = () => {
    // Get the current UTC date and time
    const currentUtcDate = moment.utc();
    // Subtract 60 days
    const dateMinus60Days = currentUtcDate.subtract(60, "days");
    // Format the date and time
    const formattedDateMinus60Days = dateMinus60Days.format("YYYY-MM-DD");
    return formattedDateMinus60Days;
  };

  const [searchFormState, setSearchFormState] = useState({
    postDate: defaultSinceDate(),
    orderNumber: "",
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof InvoiceItemDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  useEffect(() => {
    getInvoicesData();
  }, [page, order, orderBy, rowsPerPage]);

  useEffect(() => {
    // getInvoicesData();
  }, []);

  const handleExportItem = async (
    invoiceGUID: string,
    invoiceNumber: number
  ) => {
    try {
      setIsLoading(true);

      const param = {
        invoiceGUID: invoiceGUID,
      };

      const response = await exportInvoice(param);

      if (response) {
        const Title = `invoice${invoiceNumber}`;
        const result = await downloadAndSavePDF(response, `${Title}.${"pdf"}`);
        if (result.success) {
          setIsLoading(false);
        }
      }
    } catch (err) {
      setIsLoading(false);
    }
  };
  const handleSearch = () => {
    getInvoicesData(1);
  };
  const getInvoicesData = async (defaultPage: number = 0) => {
    try {
      setIsLoading(true);
      const { postDate, orderNumber } = searchFormState;
      const param = {
        userID: token.UserId,
        //postDate: postDate, //2024-07-01T08:34:33.507Z
        postDate:
          postDate !== "" ? moment(postDate).format("yyyy-MM-DD") : null,
        orderNumber: orderNumber,
        invoiceGUID: "",
        orderBy: orderBy,
        sorting: order,
        pagesize: rowsPerPage === -1 ? totalRecord : rowsPerPage,
        pageNo: defaultPage ? defaultPage : page + 1,
      };
      const response = await invoicesData(param);

      if (response.data && response.data.data) {
        setInvoicesData(response.data.data);
        setTotalRecord(response.data.totalCount);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setInvoicesData([]);
      setTotalRecord(0);
    }
  };

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    if (pageSize === -1) {
      setRowsPerPage(-1);
    } else {
      setRowsPerPage(pageSize);
    }
    setPage(0);
  };



  const handleTextFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setSearchFormState({
      ...searchFormState,
      [name]: value,
    });
  };

  return (
    <>
      <div className={"active-order "}>
        <>
          <>
            <Box
              className="Header-main pt-16"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                className="Main-Header marginbottom8"
                variant="h4"
                color={"primary"}
              >
                Invoices
              </Typography>
              <Box
                className="dropdown-combo gap-16 gap8px"
                display="flex"
                alignItems="center"
              >
                <FormControl
                  size="small"
                  sx={{ width: isMobile ? "100%" : isTablet ? 175 : 295 }}
                >
                  <div className="small-cal-view">
                    <CustomDatePicker
                      id="txtInvoiceDate"
                      handleDateChange={handleTextFieldChange as any}
                      label="Invoice Since"
                      name="postDate"
                      value={searchFormState?.postDate}
                      isClearable={false}
                    />
                  </div>
                  {/* <TextField
                    className="w-100"
                    size="small"
                    id="datetime-start-local"
                    label="Invoice Since"
                    type="date"
                    name="postDate"
                    value={searchFormState?.postDate}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleTextFieldChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <img
                              src={calendarIcon}
                              alt="Calendar icon"
                              style={{ maxWidth: "100%" }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                </FormControl>
                <FormControl
                  size="small"
                  sx={{ width: isMobile ? "100%" : isTablet ? 175 : 295 }}
                >
                  <TextField
                    size="small"
                    variant="outlined"
                    value={searchFormState?.orderNumber}
                    onChange={handleTextFieldChange}
                    label="Order #"
                    id="txtOrderNumber"
                    name="orderNumber"
                    className="w-100"
                  />
                </FormControl>
                <FormControl
                  size="small"
                  // sx={{ width: isMobile ? "100%" : isTablet ? 200 : 295 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="Search_small_btn w-100"
                    id="btnSearch"
                    startIcon={<SearchIcon />}
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </FormControl>
              </Box>
            </Box>
            <Box
              className="Header-main pt-8"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography className="other_dtl grey-color" variant="h6">
                Use this page to see the invoices for your account.
              </Typography>
            </Box>
          </>

          {/* search form here */}

          <Box sx={{ width: "100%" }}>
            <Paper className="ActiveOrder Batch-grid" sx={{ width: "100%" }}>
              <TableContainer className="invoice-grid mt-16">
                {/* Add class name for styling */}
                <Table aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={invoicesDataItem.length}
                    headCells={headCells}
                  />
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          <Loader />
                        </TableCell>
                      </TableRow>
                    ) : invoicesDataItem.length === 0 ? (
                      <TableRow className="Nodata_found">
                        <TableCell
                          className="No-data-section"
                          colSpan={12}
                          align="center"
                        >
                          <NoDataFound
                            heading="No Invoices Found"
                            title={
                              "Please ensure you have entered a correct date or order #."
                            }
                            isDisplayBtn={false}
                          />
                        </TableCell>
                      </TableRow>
                    ) : (
                      invoicesDataItem.map(
                        (row: InvoiceItemDto, index: any) => {
                          return isMobile ? (
                            <>
                              <MobileCardView
                                key={row.invoiceGUID}
                                row={row}
                                handleExportItem={handleExportItem}
                              />
                            </>
                          ) : (
                            <TableRow hover key={row.invoiceGUID}>
                              <TableCell style={{ minWidth: 115 }}>
                                <Link
                                  to={""}
                                  id={`lbtn${(row?.invoiceNumber)}`}
                                  onClick={() => {
                                    handleExportItem(
                                      row?.invoiceGUID,
                                      row?.invoiceNumber
                                    );
                                  }}
                                  className="primary"
                                >
                                  {row?.invoiceNumber}
                                </Link>
                              </TableCell>
                              <TableCell style={{ minWidth: 180 }}>
                                {formatDate(row?.invoicePostDate)}
                              </TableCell>
                              <TableCell style={{ minWidth: 150 }}>
                                {formatDate(row?.invoiceDueDate)}
                              </TableCell>
                              <TableCell style={{ minWidth: 140 }}>
                                {row?.amount}
                              </TableCell>
                              <TableCell style={{ minWidth: 100 }}>
                                {row?.taxAmount}
                              </TableCell>
                              <TableCell style={{ minWidth: 140 }}>
                                {row?.totalAmount}
                              </TableCell>
                              <TableCell style={{ minWidth: 140 }}>
                                {row?.balance}
                              </TableCell>
                              <TableCell style={{ minWidth: 120 }}>
                                {row?.currencyID}
                              </TableCell>
                              <TableCell style={{ minWidth: 210 }}>
                                {row.payoffDate
                                  ? formatDate(row.payoffDate as any)
                                  : ""}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <CommonPagination
                count={totalRecord}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {/* )} */}
            </Paper>
          </Box>
        </>
      </div>
    </>
  );
};

// Mobile card view component
const MobileCardView: React.FC<{
  row: InvoiceItemDto;
  handleExportItem: (invoiceGUID: string, invoiceNumber: number) => void;
}> = ({ row, handleExportItem }) => {

  
  return (
    <Paper
      elevation={3}
      sx={{ marginBottom: "16px", padding: "0" }}
      className={"Card_view"}
    >
      <Typography variant="body1" className="Field_info">
        <span className="field_label">INVOICE#</span>
        <span className="Field_value">
          <Link
            to={""}
            id={`lbtn${(row?.invoiceNumber)}`}
            onClick={() => {
              handleExportItem(row?.invoiceGUID, row?.invoiceNumber);
            }}
            className="primary"
          >
            {row?.invoiceNumber}
          </Link>
        </span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">POST DATE</span>{" "}
        <span className="Field_value">{formatDate(row.invoicePostDate)}</span>
      </Typography>

      <Typography variant="body1" className="Field_info">
        <span className="field_label">DUE DATE</span>{" "}
        <span className="Field_value">{formatDate(row.invoiceDueDate)}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">AMOUNT</span>{" "}
        <span className="Field_value">{row.amount}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">TAX</span>{" "}
        <span className="Field_value">{row.taxAmount}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">TOTAL</span>{" "}
        <span className="Field_value">{row.totalAmount}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">BALANCE</span>{" "}
        <span className="Field_value">{row.balance}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">CURRENCY</span>{" "}
        <span className="Field_value">{row.currencyID}</span>
      </Typography>
      <Typography variant="body1" className="Field_info">
        <span className="field_label">PAY DATE</span>{" "}
        <span className="Field_value">
          {row.payoffDate ? formatDate(row.payoffDate as any) : ""}
        </span>
      </Typography>
    </Paper>
  );
};

export default Invoices;
