export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

export const showNotification = (
  message: string,
  severity: "success" | "info" | "warning" | "error"
) => ({
  type: SHOW_NOTIFICATION,
  payload: { message, severity },
});

export const hideNotification = () => ({
  type: HIDE_NOTIFICATION,
});
