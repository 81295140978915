import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import { MySettingItemDto } from "../../models/long-form-item-dto";


// Action Types for Default Data
const DEFAULT_DATA_REQUEST = "DEFAULT_DATA_REQUEST";
const DEFAULT_DATA_SUCCESS = "DEFAULT_DATA_SUCCESS";
const DEFAULT_DATA_FAILURE = "DEFAULT_DATA_FAILURE";


// Action Creators for Default Data
export const defaultDataRequest = () => ({
    type: DEFAULT_DATA_REQUEST,
  });
  
  export const defaultDataSuccess = () => ({
    type: DEFAULT_DATA_SUCCESS,
  });
  
  export const defaultDataFailure = (error: string) => ({
    type: DEFAULT_DATA_FAILURE,
    payload: error,
  });



// Initial State for  Active Order
export interface DefaultDataState {
    isAuthenticated: boolean;
    LongFormLoading: boolean;
    LongFormError: string | null;
  }


  const initialAuthState: DefaultDataState = {
    isAuthenticated: false,
    LongFormLoading: false,
    LongFormError: null,
  };
  

  // Define the interface for the API response
interface DefaultDataResponse {
    success: boolean;
    message: string;
    data: Array<MySettingItemDto>;
    statusCode: string;
  }
  
  // Reducer for Authentication
  const defaultDataReducer = (state = initialAuthState, action: any): DefaultDataState => {
    switch (action.type) {
      case DEFAULT_DATA_REQUEST:
        return { ...state, LongFormLoading: true, LongFormError: null };
      case DEFAULT_DATA_SUCCESS:
        return { ...state, LongFormLoading: false, isAuthenticated: true };
      case DEFAULT_DATA_FAILURE:
        return { ...state, LongFormLoading: false, LongFormError: action.payload };
      default:
        return state;
    }
  };
  
  // Thunk for Active Order
  export const DefaultData = (param: {
    companyID: number;
  }) => async (dispatch: Dispatch) => {
      dispatch(defaultDataRequest());
      try {
        const response = await axiosInstance.post(
          `${API_BASE_URL}/api/Lookup/MySettings`,
          param
      );
        // Assuming  Active Order was successful if no error occurred
        dispatch(defaultDataSuccess());
        const data: DefaultDataResponse = response.data;
        // Store token in localStorage
         if (response.status === 200 && data.success) {
          // Store token in localStorage
          return { data };
        } else {
          // Handle different status codes if necessary
          dispatch(defaultDataFailure(data.message));
        }
        return response.data;
      } catch (error: any) {
        if(error.response.data.message){
          dispatch(defaultDataFailure(error.response.data.message));
        }else{
          dispatch(defaultDataFailure(error.message));
        }

      }
    };


    export default defaultDataReducer;  
  