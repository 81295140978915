import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { login, empLogin, asureLogin } from "./login.reducer";

const useLoginLogic = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (error) {
      // dispatch(showNotification('Login failed: ' + error, 'error'));
    }
  }, [error, dispatch]);

  const handleLogin = async (param: {
    LogonName: string;
    Password: string;
  }) => {
    try {
      const response = await dispatch(login(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleEmpLogin = async () => {
    try {
      const response = await dispatch(empLogin() as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleAsureLogin = async (param: { Code: string }) => {
    try {
      const response = await dispatch(asureLogin(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    loading,
    error,
    login: handleLogin,
    empLogin: handleEmpLogin,
    asureLogin: handleAsureLogin,
  };
};

export default useLoginLogic;
