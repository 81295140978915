interface AirlineInfo {
    carrierName: string;
    routeNumber: string;
  }

  interface DepartInfo {
    departHubID: string;
    departDateTimeDisplay: string;
  }

  interface ArriveInfo {
    arriveHubID: string;
    arriveDateTimeDisplay: string;
  }
  
  export const formatAirline = ({
    carrierName,
    routeNumber,
  }: AirlineInfo): string => {
    let Airline = "";
     
    Airline = `<div class='weight-600 addr_label'>${carrierName}</div><div>`;
    Airline += routeNumber ? `Flight ${routeNumber}` : "";
    Airline += `</div>`;
  
    return Airline;
  };

  export const formatDepart = ({
    departHubID,
    departDateTimeDisplay,
  }: DepartInfo): string => {
    let Airline = "";
     
    Airline = `<div class='weight-600 addr_label'>${departHubID}</div><div>`;
    Airline += departDateTimeDisplay ? `${departDateTimeDisplay}` : "";
    Airline += `</div>`;
  
    return Airline;
  };

  export const formatArrive = ({
    arriveHubID,
    arriveDateTimeDisplay,
  }: ArriveInfo): string => {
    let Airline = "";
     
    Airline = `<div class='weight-600 addr_label'>${arriveHubID}</div><div>`;
    Airline += arriveDateTimeDisplay ? `${arriveDateTimeDisplay}` : "";
    Airline += `</div>`;
  
    return Airline;
  };