import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";

// Action Types for signup
const SUPPORT_SIGNUP_REQUEST = "SUPPORT_SIGNUP_REQUEST";
const SUPPORT_SIGNUP_SUCCESS = "SUPPORT_SIGNUP_SUCCESS";
const SUPPORT_SIGNUP_FAILURE = "SUPPORT_SIGNUP_FAILURE";


// Action Creators for signup
export const supportSignupRequest = () => ({
    type: SUPPORT_SIGNUP_REQUEST,
  });
  
  export const supportSignupSuccess = () => ({
    type: SUPPORT_SIGNUP_SUCCESS,
  });
  
  export const supportSignupFailure = (error: string) => ({
    type: SUPPORT_SIGNUP_FAILURE,
    payload: error,
  });



// Initial State for Signup
export interface SupportSignupState {
    isAuthenticated: boolean;
    supportSignupLoading: boolean;
    supportSignupError: string | null;
  }


  const initialAuthState: SupportSignupState = {
    isAuthenticated: false,
    supportSignupLoading: false,
    supportSignupError: null,
  };
  

  // Define the interface for the API response
interface SupportSignupResponse {
    success: boolean;
    message: string;
    data: string;
    statusCode: string;
  }
  
  // Reducer for Authentication
  const supportSignupReducer = (state = initialAuthState, action: any): SupportSignupState => {
    switch (action.type) {
      case SUPPORT_SIGNUP_REQUEST:
        return { ...state, supportSignupLoading: true, supportSignupError: null };
      case SUPPORT_SIGNUP_SUCCESS:
        return { ...state, supportSignupLoading: false, isAuthenticated: true };
      case SUPPORT_SIGNUP_FAILURE:
        return { ...state, supportSignupLoading: false, supportSignupError: action.payload };
      default:
        return state;
    }
  };
  
  // Thunk for Active Order
  export const GetMicrosoftLoginSupportURL = (CustomerUserID: string) => async (dispatch: Dispatch) => {
      dispatch(supportSignupRequest());
      try {
        const response = await axiosInstance.get(
          `${API_BASE_URL}/api/Authentication/GetMicrosoftLoginSupportURL?CustomerUserID=${CustomerUserID}`
      );
        // Assuming Signup was successful if no error occurred
        dispatch(supportSignupSuccess());
        const data: SupportSignupResponse = response.data;
        // Store token in localStorage
         if (response.status === 200 && data.success) {
          // Store token in localStorage
          return { data };
        } else {
          // Handle different status codes if necessary
          dispatch(supportSignupFailure(data.message));
        }
        return response.data;
      } catch (error: any) {
        if(error.response.data.message){
          dispatch(supportSignupFailure(error.response.data.message));
          return error.response.data;
        }else{
          dispatch(supportSignupFailure(error.message));
        }

      }
    };

    export const AzureSupportLogin = (param: {
        code: string;
        state: string;
      }) => async (dispatch: Dispatch) => {
        dispatch(supportSignupRequest());
        try {
          const response = await axiosInstance.post(
            `${API_BASE_URL}/api/Authentication/AzureSupportLogin?code=${param.code}&StateValue=${param.state}`,
            {}
        );
          // Assuming Signup was successful if no error occurred
          dispatch(supportSignupSuccess());
          const data: SupportSignupResponse = response.data;
          // Store token in localStorage
           if (response.status === 200 && data.success) {
            // Store token in localStorage
            localStorage.setItem("userDetails", JSON.stringify(data.data));
            return { data };
          } else {
            // Handle different status codes if necessary
            dispatch(supportSignupFailure(data.message));
          }
          return response.data;
        } catch (error: any) {
          if(error.response.data.message){
            dispatch(supportSignupFailure(error.response.data.message));
            return error.response.data;
          }else{
            dispatch(supportSignupFailure(error.message));
          }
  
        }
      };

    export default supportSignupReducer;  
  