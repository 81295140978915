import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../types/global";
import { insertShipmentPiece } from "./insert-shipment-piece.reducer";
import { ShipmentPieceDto } from "../../../../models/shipment-piece-dto";



const useInsertShipmentPieceLogic = () => {
  const dispatch = useDispatch();
  const { insertShipmentPieceLoading, insertShipmentPieceError } = useSelector((state: RootState) => state.insertShipmentPiece);

  useEffect(() => {
    if (insertShipmentPieceError) {
      // dispatch(showNotification('Country failed: ' + error, 'error'));
    }
  }, [insertShipmentPieceError, dispatch]);

  const handleInsertShipmentPiece= async (param: ShipmentPieceDto) => {
    try {
      const response = await dispatch(insertShipmentPiece(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { insertShipmentPieceLoading, insertShipmentPieceError, insertShipmentPiece: handleInsertShipmentPiece };
};

export default useInsertShipmentPieceLogic;
