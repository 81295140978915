import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import {
  DefaultData,
  deleteDocument,
  editWillCallOrder,
} from "./edit-will-call-order-reducer";

const useDefaultData1Logic = () => {
  const dispatch = useDispatch();
  const { LongFormLoading, LongFormError } = useSelector(
    (state: RootState) => state.longForm
  );

  useEffect(() => {
    if (LongFormError) {
      // dispatch(showNotification('Active Order failed: ' + error, 'error'));
    }
  }, [LongFormError, dispatch]);

  const handleDefaultData = async (param: { shipmentGUID: string | null }) => {
    try {
      const response = await dispatch(DefaultData(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleDeleteDocument = async (param: {
    shipmentDocumentId: string | null;
  }) => {
    try {
      const response = await dispatch(deleteDocument(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleEditWillCallOrder = async (param: any) => {
    try {
      const response = await dispatch(editWillCallOrder(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    LongFormLoading,
    LongFormError,
    defaultData1: handleDefaultData,
    deleteDocument: handleDeleteDocument,
    editWillCallOrder: handleEditWillCallOrder,
  };
};

export default useDefaultData1Logic;
