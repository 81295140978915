import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import MUI_Key from "./globals";
import { createTheme } from "@mui/material";
import theme from "./container/theme";
import { LoaderProvider } from "./shared/util/loader-context";

const materialUiTheme = createTheme(theme, {
  licensing: {
    key: { MUI_Key },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
    <ThemeProvider theme={materialUiTheme}>
      <LoaderProvider>
        <Router basename={process.env.PUBLIC_URL}>
          <App />
        </Router>
      </LoaderProvider>
    </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
