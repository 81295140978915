import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import { ReferenceInfoItemDto } from "../../../models/track-order-item-dto";

interface ReferenceProps {
  reference: ReferenceInfoItemDto[];
}

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof ReferenceInfoItemDto;
  label: string;
  numeric: boolean;
  minwidth: string;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ReferenceInfoItemDto
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const ReferenceInfo: React.FC<ReferenceProps> = ({ reference }) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] =
    useState<keyof ReferenceInfoItemDto>("referenceTypeID");
  const [referenceData, setReferenceData] = useState<ReferenceInfoItemDto[]>(
    []
  );
  const [notData, setNotData] = useState<any>("");

  useEffect(() => {
    if (reference.length !== 0) {
      setReferenceData(reference);
    } else {
      setNotData("(no references are available for this shipment)");
    }
  }, [reference]);

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] === null) {
      return -1;
    }

    if (a[orderBy] === null) {
      return 1;
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }

    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof ReferenceInfoItemDto>(
    order: Order,

    orderBy: Key
  ): (a: ReferenceInfoItemDto, b: ReferenceInfoItemDto) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const sortedReferenceData = referenceData
    .slice()
    .sort(getComparator(order, orderBy));

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ReferenceInfoItemDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const ReferenceHeadCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
      minwidth: "50%",
    },
    {
      id: "value",
      numeric: false,
      disablePadding: false,
      label: "Value",
      minwidth: "50%",
    },
  ];

  function ReferenceEnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof ReferenceInfoItemDto) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {ReferenceHeadCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Box>
      <TableContainer>
        <div className="RouteTable">
          <Table className="RouteTablebody" aria-labelledby="tableTitle">
            <ReferenceEnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={referenceData.length}
            />
            <TableBody>
              {sortedReferenceData.length === 0 ? (
                <TableRow id="trRefernce">
                  <TableCell
                    className="Datanofound"
                    colSpan={12}
                    align="center"
                    id="tdDataNotFound"
                  >
                    {notData}
                  </TableCell>
                  {/* Adjust the colspan according to the number of columns in your table */}
                </TableRow>
              ) : (
                sortedReferenceData.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover key={row.referenceTypeID} id="trRefernce">
                      <TableCell className="auto-width" id="tdName">{row.name}</TableCell>
                      <TableCell className="auto-width" id="tdValue">{row.value}</TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
    </Box>
  );
};

export default ReferenceInfo;
