import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TextField,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  InputAdornment,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import calendarIcon from "../../assets/images/logo/calendaricon.svg";

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import useAccounntsLogic from "../../shared/lookup/accounts/accounts.logic";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
import useRateEstimateLogic from "./rate-estimate.logic";
import Loader from "../../shared/util/loader";
import { useMediaQuery } from "@mui/material";
import { AddressBookDto } from "../../models/address-book-dto";

import { MAX_COMPANY_ID } from "../../config/constants";
import arrowicon from "../../assets/images/logo/img_arrowright_white_a700.svg";
import useServiceLogic from "../../shared/lookup/service/service.logic";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useFormik, FieldArray, FormikProvider } from "formik";
import CodeDialog from "../../shared/components/code-dialog/code-dialog";
import { CountryItemDto } from "../../models/country-item-dto";
import { ZipCodeItemDto } from "../../models/zip-code-item-dto";
import useCountryLogic from "../../shared/lookup/country/country.logic";
import useZipCodeLogic from "../../shared/lookup/zip-code/zip-code.logic";
import useAddressLogic from "../../shared/lookup/address/address.logic";
import ErrorInputAdornment from "../../shared/components/error-input-adornment";
import RateEstimateTermsDialog from "../../shared/components/rate-estimate-terms-dialog/rate-estimate-terms-dialog";
import useDefaultDataLogic from "../long-form-orders/long-form-order.logic";
import { MySettingItemDto } from "../../models/long-form-item-dto";
import { calculateDimWeight } from "../../shared/util/calculate-dim-weight";
import {
  RateEstimateInitialValues,
  createRateEstimateValidationSchema,
} from "../../shared/util/validate-schema";
import DisplayError from "../../shared/components/rate-estimate/display-error";
import EnhancedTableHead from "../../shared/components/table-head-cell/enhanced-table-head";
import {
  calculateTotalAmount,
  calculateTotalNetAmount,
  calculateTotalTaxAmount,
  estimateETAPayload,
  geCalculateEstimatePayload,
  getCalculateTotalDmWeight,
  getCalculateTotalWeight,
  getTotalDistancePayload,
  sortAndUpdateTheData,
} from "../../shared/util/rate-estimate";
import { RateEstimateChargeDTO } from "../../models/rate-estimate-dto";
import FlightIcon from "@mui/icons-material/Flight";
import RateEstimateRouteDialog from "../../shared/components/rate-estimate-route-dialog/rate-estimate-route-dialog";
///import defaultDataReducer from "../long-form-orders/long-form-order.reducer";
import { handleDecimalValue } from "../../shared/util/numeric-value";
import moment from "moment-timezone";
import timezoneNames from "../../shared/util/timezones";
import CustomDatePicker from "../../shared/components/CustomDatePicker";
import { flightOptions } from "./rate-estimate.reducer";
type Order = "desc" | "asc";
interface HeadCell {
  disablePadding: boolean;
  id: keyof RateEstimateChargeDTO;
  label: string;
  numeric: boolean;
  minWidth: string;
}

interface rateEstimateProps {
  title: string;
  orderType: string;
  // other props
}
interface MenuStyles {
  maxHeight: string;
}

const headCells: HeadCell[] = [
  {
    id: "chargeCode",
    numeric: true,
    disablePadding: false,
    label: "CODE",
    minWidth: "100",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "NAME",
    minWidth: "154",
  },
  {
    id: "description",
    numeric: true,
    disablePadding: false,
    label: "DESCRIPTION",
    minWidth: "225",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "AMOUNT",
    minWidth: "120",
  },
  {
    id: "taxAmount",
    numeric: true,
    disablePadding: false,
    label: "TAX",
    minWidth: "104",
  },
  {
    id: "netAmount",
    numeric: true,
    disablePadding: false,
    label: "NET",
    minWidth: "104",
  },
  {
    id: "fuelApply",
    numeric: true,
    disablePadding: false,
    label: "FUEL?",
    minWidth: "70",
  },
  {
    id: "taxable",
    numeric: true,
    disablePadding: false,
    label: "TAX?",
    minWidth: "70",
  },
  {
    id: "discountable",
    numeric: true,
    disablePadding: false,
    label: "DSC?",
    minWidth: "70",
  },
  {
    id: "securityApply",
    numeric: true,
    disablePadding: false,
    label: "TC",
    minWidth: "62",
  },
];

const RateEstimate: React.FC<rateEstimateProps> = ({ title, orderType }) => {
  const errorRef: any = useRef(null);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof RateEstimateChargeDTO>("chargeCode");
  const [notData, setNotData] = React.useState<any>("");
  const [countryData, setCountryData] = useState<CountryItemDto[]>([]);
  const [zipCodeData, setZipCodeData] = useState<ZipCodeItemDto[]>([]);
  const [zipCodeDelData, setZipCodeDelData] = useState<ZipCodeItemDto[]>([]);
  const { countyError, country } = useCountryLogic();
  const { zipCodeError, zipCode } = useZipCodeLogic();
  const { error, account } = useAccounntsLogic();
  const { defaultData: mySettings } = useDefaultDataLogic();
  const [settingsData, setSettingsData] = useState<MySettingItemDto>();
  const [isLoading, setIsLoading] = useState(false);
  const { service } = useServiceLogic();
  const { address } = useAddressLogic();
  const {
    calculateRateEstimate,
    attributeByServices,
    zipcodeDetails,
    totalPickupDeliveryDistance,
    checkServiceList,
    getDefaultDate,
    estimateETA,
  } = useRateEstimateLogic();
  const [accounts, setAccounts] = useState<any>([]);
  const [serviceData, setServiceData] = useState<any>([]);
  const [rateEstimateData, setRateEstimateData] = useState<
    RateEstimateChargeDTO[]
  >([]);
  const [servicesAttribute, setServicesAttribute] = useState<any[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<
    AddressBookDto | undefined
  >(undefined);
  const token = useDecodeJWT();
  const [selectAccount, setSelectAccount] = React.useState("All");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [isFlightOptionVisible, setIsFlightOptionVisible] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [servicesDependancyFinished, setServicesDependancyFinished] = useState({
    settingsAPI: false,
    pickUpPostalCode: false,
    deliveryPostalCode: false,
    pickUpDistance: false,
    deliveryDistance: false,
  });
  const [menuStyles, setMenuStyles] = useState<MenuStyles>({
    maxHeight: "200px", // Default max height for desktop
  });
  const [open, setOpen] = React.useState(false);
  const [openTermDialog, setOpenTermDialog] = React.useState(true);
  const [openRouteDialog, setOpenRouteDialog] = React.useState(false);

  const userGroupGUID: any = localStorage.getItem("userGroupGUID");
  const serviceAvailType = localStorage.getItem("serviceAvailType");
  const [codeFieldType, setCodeFieldType] = useState("");
  const [pickupAddressCodeError, setPickupAddressCodeError] =
    React.useState(false);
  const [deliveryAddressCodeError, setDeliveryAddressCodeError] =
    React.useState(false);

  const RateEstimateValidationSchema = createRateEstimateValidationSchema(
    zipCodeData,
    zipCodeDelData,
    pickupAddressCodeError,
    deliveryAddressCodeError
  );
  const formik = useFormik({
    initialValues: RateEstimateInitialValues,
    validationSchema: RateEstimateValidationSchema,
    onSubmit: (values) => {
      etimateDeliveryETA();
    },
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof RateEstimateChargeDTO
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  useEffect(() => {
    const sortedData = sortAndUpdateTheData(rateEstimateData, order, orderBy);
    setRateEstimateData(sortedData);
  }, [order, orderBy]);

  const getAccounts = useCallback(async () => {
    try {
      const param = {
        userId: token.UserId,
        companyID: 0,
        useIncludeAllItemrId: false,
      };

      const response = await account(param);
      if (response.data && response.data.data) {
        setAccounts(response.data.data);
      } else {
        setAccounts([]);
        setNotData("No data to display");
      }
    } catch (err) {
      setAccounts([]);
      setNotData("No data to display");
    }
  }, [token.UserId]);

  // const getDefaultDateSetting = useCallback(async () => {
  //   try {
  //     const param = {
  //       companyId: MAX_COMPANY_ID,
  //     };

  //     const response = await getDefaultDate(param);
  //     if (response.data && response.data.data) {
  //       const currentDateTime = response.data.data?.currentDateTime;
  //       const formatTime = currentDateTime.split(".")[0];
  //       formik.setFieldValue("orderDateTime", formatTime || "");
  //       formik.setFieldValue("pickupReadyTime", formatTime || "");
  //       formik.setFieldValue("requestedDeliveryTime", formatTime || "");
  //     }
  //   } catch (err) {}
  // }, [token.UserId]);

  const getService = async () => {
    try {
      const param: any = {
        companyID: MAX_COMPANY_ID,
        userGUID: token.UserGUID,
        serviceAvailType: serviceAvailType || "A",
        baseDateTime: new Date(),
        origCountryID: formik.values?.pickupCountryID,
        origCityName: formik.values?.pickupCityName,
        origStateProvID: formik.values?.pickupStateProvID,
        origPostalCode: formik.values?.pickupPostalCode,
        origAirportID: formik.values?.pickupDropAirportID,
        destCountryID: formik.values?.deliveryCountryID,
        destCityName: formik.values?.deliveryStateProvID,
        destStateProvID: formik.values?.deliveryStateProvID,
        destPostalCode: formik.values?.deliveryPostalCode,
        destAirportID: formik.values?.deliveryDropAirportID,
      };
      const response = await service(param);
      if (response.data && response.data.data) {
        setServiceData(response.data.data);
      } else {
        setServiceData([]);
      }
    } catch (err) {
      setServiceData([]);
    }
  };

  useEffect(() => {
    // getDefaultDateSetting();
    getAccounts();
    setNotData("Data not found");
  }, [getAccounts]);

  const etimateDeliveryETA = async () => {
    try {
      setIsLoading(true);
      let payload: any = estimateETAPayload(
        formik,
        serviceData,
        isFlightOptionVisible
      );

      const param = {
        ...payload,
        userID: token.UserId,
        userName: settingsData ? settingsData?.logonName : null,
        userGUID: token.UserGUID,
      };
      const response = await estimateETA(param);
      if (response.data && response.data.data) {
        const dateTime = response.data.data.dateTime;
        setIsLoading(false);
        const userTimezone = moment.tz.guess();
        const timezoneName: any = moment.tz(userTimezone).zoneName();

        const localizedTimezoneName =
          (timezoneNames[userTimezone] as any) || timezoneName;
        const formattedTime = moment(dateTime)
          // .tz(userTimezone)
          .format("ddd MMM DD YYYY HH:mm:ss [GMT]Z");

        if (dateTime) {
          const confirm = window.confirm(
            `Are you sure you want to change the quoted delivery time to ${formattedTime} (${localizedTimezoneName})?`
          );
          if (confirm) {
            formik.setFieldValue(
              "requestedDeliveryTime",
              moment(dateTime).format("YYYY-MM-DDTHH:mm:ss")
            );
            getRateEstimate(moment(dateTime).format("YYYY-MM-DDTHH:mm:ss"));
          } else {
            getRateEstimate();
          }
        } else {
          getRateEstimate();
        }
      }
    } catch (err: any) {
      setIsLoading(false);
    }
  };
  const getRateEstimate = async (requestedDeliveryTime: string = "") => {
    try {
      let param: any = geCalculateEstimatePayload(formik);
      if (requestedDeliveryTime) {
        param = {
          ...param,
          delDate: requestedDeliveryTime || "",
          delTime: requestedDeliveryTime || "",
        };
      }
      setIsLoading(true);

      const response = await calculateRateEstimate(param);
      if (response.data && response.data.data) {
        const sortedData = sortAndUpdateTheData(
          response.data.data,
          order,
          orderBy
        );
        setRateEstimateData(sortedData);
        setTotalRecord(response.data.totalCount);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setRateEstimateData([]);
      setTotalRecord(0);
    }
  };

  const getAttributeByServices = async () => {
    try {
      setIsLoading(true);

      const { accountNumber, serviceID } = formik.values;
      const param = {
        ServiceID: parseInt(serviceID),
        CompanyID: MAX_COMPANY_ID,
        AccountNumber: accountNumber,
      };
      const response = await attributeByServices(param);
      if (response.data && response.data.data) {
        setServicesAttribute(response.data.data);
        formik.setFieldValue("attributeList", "");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setServicesAttribute([]);
    }
  };

  const handleClickOpen = (fieldType: string) => {
    setCodeFieldType(fieldType);
    setOpen(true);
  };

  const handleClose = () => {
    setCodeFieldType("");
    setOpen(false);
  };

  const onSelectAddress = (address: any) => {
    const _zipCodeData = [
      {
        cityName: address?.cityName,
        postalCode: "",
        stateProvID: "",
        timezoneID: "",
        countryID: "",
        countryName: "",
        airportID: "",
        airportPostalCode: "",
        address1: "",
        address2: "",
        address3: "",
      },
    ];

    setSelectedAddress(address);
    if (codeFieldType === "pickupAddressCode") {
      setPickupAddressCodeError(false);
      formik.setFieldValue("pickupCountryID", address?.countryID);
      formik.setFieldValue("pickupStateProvID", address?.stateProvID);
      formik.setFieldValue("pickupCityName", address?.cityName);
      formik.setFieldValue("pickupTimezoneID", address?.timezoneID);
      formik.setFieldValue("pickupPostalCode", address?.postalCode || "");
      formik.setFieldValue("pickupAddressCode", address?.addressCode || "");
      // formik.setFieldValue("pickupReadyTime", address?.pickupReadyTime || "");

      if (address?.cityName) {
        setZipCodeData(_zipCodeData);
      }
      if (address?.postalCode) {
        getZipCode(
          "pickupPostalCode",
          address?.postalCode,
          "",
          address.cityName
        );
      }
    } else {
      setDeliveryAddressCodeError(false);
      formik.setFieldValue("deliveryCountryID", address?.countryID);
      formik.setFieldValue("deliveryStateProvID", address?.stateProvID);
      formik.setFieldValue("deliveryCityName", address?.cityName);
      formik.setFieldValue("deliveryTimezoneID", address?.timezoneID);
      formik.setFieldValue("deliveryPostalCode", address?.postalCode || "");
      formik.setFieldValue("deliveryAddressCode", address?.addressCode || "");
      // formik.setFieldValue(
      //   "requestedDeliveryTime",
      //   address?.deliveryRequestTime || ""
      // );

      if (address?.cityName) {
        setZipCodeDelData(_zipCodeData);
      }
      if (address?.postalCode) {
        getZipCode(
          "deliveryPostalCode",
          address?.postalCode,
          "",
          address.cityName
        );
      }
    }

    setCodeFieldType("");
  };

  const getCountry = async () => {
    try {
      setIsLoading(true);
      const param = {};
      const response = await country(param);

      if (response.data && response.data.data) {
        setCountryData(response.data.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setCountryData([]);
    }
  };

  const getSettingsData = async () => {
    try {
      setIsLoading(true);
      const param = {
        companyID: MAX_COMPANY_ID,
      };
      const response = await mySettings(param);
      if (response.data && response.data.data) {
        const res = response.data.data;

        formik.setFieldValue("accountNumber", res?.accountNumber);
        formik.setFieldValue("pickupCountryID", res?.pickupCountryID);
        formik.setFieldValue("pickupDistance", res?.pickupDistance);
        formik.setFieldValue("pickupDistanceUnit", res?.distanceUOM);
        formik.setFieldValue("pickupPostalCode", res?.pickupPostalCode);
        formik.setFieldValue("pickupStateProvID", res?.pickupStateProvID);
        formik.setFieldValue("pickupTimezoneID", res?.pickupTimezoneID);
        formik.setFieldValue("pickupAirportID", res?.pickupAirportID);

        formik.setFieldValue("deliveryCountryID", res?.deliveryCountryID);
        formik.setFieldValue("deliveryPostalCode", res?.deliveryPostalCode);
        formik.setFieldValue("deliveryStateProvID", res?.deliveryStateProvID);
        formik.setFieldValue("deliveryTimezoneID", res?.deliveryTimezoneID);

        formik.setFieldValue("packageInfo[0].pieces", res?.pieces);
        formik.setFieldValue("packageInfo[0].weight", res?.weight);
        // formik.setFieldValue("packageInfo[0].height", res?.height);
        // formik.setFieldValue("packageInfo[0].width", res?.width);
        // formik.setFieldValue("packageInfo[0].length", res?.length);
        formik.setFieldValue("packageInfo[0].weightUOM", res?.weightUOM);
        formik.setFieldValue("totalDstUnit", res?.distanceUOM);
        formik.setFieldValue("totalWeight", res?.weight); //same as per current site

        // if (res?.pieces && res?.length && res?.height && res?.width) {
        //   const result = calculateDimWeight(
        //     "US",
        //     "US",
        //     res?.pieces,
        //     res?.length,
        //     res?.width,
        //     res?.height,
        //     "IN",
        //     res?.weightUOM
        //   );
        //   if (result) {
        //     formik.setFieldValue("packageInfo[0].dimWt", result); //same as per current site
        //     formik.setFieldValue("totalDMWeight", result); //same as per current site
        //   }
        // }
        getZipCode("pickupPostalCode", res?.pickupPostalCode, res);
        getZipCode("deliveryPostalCode", res?.deliveryPostalCode, res);
        setSettingsData(res);

        const currentDateTime = response.data.data?.currentDateTime;
        const formatTime = currentDateTime.split(".")[0];
        formik.setFieldValue("orderDateTime", formatTime || "");
        formik.setFieldValue("pickupReadyTime", formatTime || "");
        formik.setFieldValue("requestedDeliveryTime", formatTime || "");

        setServicesDependancyFinished((_prev) => ({
          ..._prev,
          settingsAPI: true,
        }));
      }
      setIsLoading(false);
    } catch (err) {
      setSettingsData(undefined);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSettingsData();
    // getService();
    // getZipCode();
    getCountry();
    //getAddress();
  }, []);

  useEffect(() => {
    //areAllServicesDepFinished:: Needed for stop the multiple api call onLoad
    const areAllServicesDepFinished = Object.values(
      servicesDependancyFinished
    ).every((value) => value === true);

    let debounceTimeout: NodeJS.Timeout;
    if (areAllServicesDepFinished) {
      debounceTimeout = setTimeout(() => {
        getService();
      }, 500); // 500ms delay
    }

    // Clear the timeout if one of the dependencies changes before the timeout finishes
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [
    servicesDependancyFinished,
    formik.values?.pickupCountryID,
    formik.values?.pickupCityName,
    formik.values?.pickupStateProvID,
    formik.values?.pickupPostalCode,
    formik.values?.pickupDropAirportID,
    formik.values.deliveryCountryID,
    formik.values.deliveryCityName,
    formik.values.deliveryStateProvID,
    formik.values.deliveryPostalCode,
    formik.values.deliveryDropAirportID,
    formik.values.totalDst,
  ]);

  const handleZipCodeBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const postalCode = zipCodeData[0]?.postalCode;
    const postalDelCode = zipCodeDelData[0]?.postalCode;
    const keyName: string = event.target.name;
    if (
      (keyName == "pickupPostalCode" && postalCode != event.target.value) ||
      (keyName == "deliveryPostalCode" && postalDelCode != event.target.value)
    ) {
      getZipCode(keyName);
    }
  };
  const handleAddressCodeBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const keyName: string = event.target.name;
    if (event.target.value) {
      getAddress(keyName);
    } else {
      if (keyName == "pickupAddressCode") {
        setPickupAddressCodeError(false);
      } else {
        setDeliveryAddressCodeError(false);
      }
    }
  };

  const handleAddressCodeKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      const { pickupAddressCode } = formik.values;
      if (pickupAddressCode) {
        await getAddress(); // Assuming getAddress is an async function
      }
    }
  };

  const getAddress = async (keyName: string = "") => {
    try {
      setIsLoading(true);
      const param = {
        addressCode:
          keyName == "pickupAddressCode"
            ? formik.values.pickupAddressCode
            : formik.values.deliveryAddressCode,
        companyName: "",
        cityName: "",
        stateProvID: "",
        countryID: "",
        postalCode: "",
        userGroupGUID: userGroupGUID,
      };
      const response = await address(param);
      if (response.data && response.data.data) {
        const res = response.data.data;

        if (param.addressCode && res.length > 0) {
          const _zipCodeData = [
            {
              cityName: res[0].cityName,
              postalCode: "",
              stateProvID: "",
              timezoneID: "",
              countryID: "",
              countryName: "",
              airportID: "",
              airportPostalCode: "",
              address1: "",
              address2: "",
              address3: "",
            },
          ];

          if (keyName == "pickupAddressCode") {
            setPickupAddressCodeError(false);
            formik.setFieldValue("pickupCountryID", res[0].countryID || "");
            formik.setFieldValue("pickupStateProvID", res[0].stateProvID || "");
            formik.setFieldValue("pickupTimezoneID", res[0].timezoneID || "");
            formik.setFieldValue("pickupPostalCode", res[0].postalCode || "");
            formik.setFieldValue("pickupCityName", res[0].cityName || "");
            if (res[0].cityName) {
              setZipCodeData(_zipCodeData);
            }
            getZipCode(
              "pickupPostalCode",
              res[0].postalCode,
              "",
              res[0].cityName
            );
          } else {
            setDeliveryAddressCodeError(false);
            formik.setFieldValue("deliveryCountryID", res[0].countryID || "");
            formik.setFieldValue(
              "deliveryStateProvID",
              res[0].stateProvID || ""
            );
            formik.setFieldValue("deliveryCityName", res[0].cityName || "");
            formik.setFieldValue("deliveryTimezoneID", res[0].timezoneID || "");
            formik.setFieldValue("deliveryPostalCode", res[0].postalCode || "");

            if (res[0].cityName) {
              setZipCodeDelData(_zipCodeData);
            }
            getZipCode(
              "deliveryPostalCode",
              res[0].postalCode,
              "",
              res[0].cityName
            );
          }
        } else {
          if (keyName == "pickupAddressCode") {
            setPickupAddressCodeError(true);
          } else {
            setDeliveryAddressCodeError(true);
          }
        }
      } else {
        if (keyName == "pickupAddressCode") {
          setPickupAddressCodeError(true);
        } else {
          setDeliveryAddressCodeError(true);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const getZipCode = async (
    keyName: string = "",
    postalCode: string = "",
    settingData: any = "",
    cityname?: any
  ) => {
    try {
      const {
        pickupPostalCode,
        pickupCountryID,
        pickupDistanceUnit,
        deliveryPostalCode,
        deliveryCountryID,
        deliveryDistanceUnit,
      } = formik.values;
      let _Code = postalCode ? postalCode : pickupPostalCode;
      let _CountryId = pickupCountryID;
      let _DistanceUnit = pickupDistanceUnit;
      if (keyName == "deliveryPostalCode") {
        _Code = postalCode ? postalCode : deliveryPostalCode;
        _CountryId = deliveryCountryID;
        _DistanceUnit = deliveryDistanceUnit;
      }
      setIsLoading(true);
      if (_Code) {
        const param = {
          postalCode: _Code,
          countryID: _CountryId,
          distanceUOM: _DistanceUnit,
          companyID: MAX_COMPANY_ID,
        };
        //const response: any = await zipCode(param);
        const response: any = await zipcodeDetails(param);
        if (response.data && response.data.data) {
          const res = response.data.data;
          if (res.length > 0) {
            if (keyName == "deliveryPostalCode") {
              setZipCodeDelData(response.data.data);
              formik.setFieldValue("deliveryCountryID", res[0].countryID);
              formik.setFieldValue("deliveryStateProvID", res[0].stateProvID);
              if (cityname != null) {
                const cityExists = res.some(
                  (r: { cityName: string }) => r.cityName === cityname
                );
                if (!cityExists) {
                  formik.setFieldValue(
                    "deliveryCityName",
                    settingData
                      ? settingData?.deliveryCityName
                      : res[0].cityName
                  );
                }
              } else {
                formik.setFieldValue(
                  "deliveryCityName",
                  settingData ? settingData?.deliveryCityName : res[0].cityName
                );
              }
              formik.setFieldValue("deliveryTimezoneID", res[0].timezoneID);
              formik.setFieldValue("deliveryDistance", res[0].distance);
              formik.setFieldValue("deliveryDistanceUnit", res[0].distanceUOM);
              formik.setFieldValue("deliveryDropAirportID", res[0].airportID);
              // formik.setFieldValue("deliveryAddressCode", res[0]?.addressCode);

              setServicesDependancyFinished((_prev) => ({
                ..._prev,
                deliveryPostalCode: true,
              }));
            } else {
              setZipCodeData(response.data.data);
              formik.setFieldValue("pickupCountryID", res[0].countryID);
              formik.setFieldValue("pickupStateProvID", res[0].stateProvID);
              if (cityname != null) {
                const cityExists = res.some(
                  (r: { cityName: string }) => r.cityName === cityname
                );
                if (!cityExists) {
                  formik.setFieldValue(
                    "pickupCityName",
                    settingData ? settingData?.pickupCityName : res[0].cityName
                  );
                }
              } else {
                formik.setFieldValue(
                  "pickupCityName",
                  settingData ? settingData?.pickupCityName : res[0].cityName
                );
              }
              formik.setFieldValue("pickupTimezoneID", res[0].timezoneID);
              formik.setFieldValue("pickupDistance", res[0].distance);
              formik.setFieldValue("pickupDistanceUnit", res[0].distanceUOM);
              formik.setFieldValue("pickupDropAirportID", res[0].airportID);
              // formik.setFieldValue("pickupAddressCode", res[0]?.addressCode);
            }
            setServicesDependancyFinished((_prev) => ({
              ..._prev,
              pickUpPostalCode: true,
            }));
            getTotalDistance(settingData, keyName, res[0], _Code);
          } else {
            if (keyName == "deliveryPostalCode") {
              setZipCodeDelData([]);
              setServicesDependancyFinished((_prev) => ({
                ..._prev,
                deliveryPostalCode: true,
              }));
            } else {
              setZipCodeData([]);
              setServicesDependancyFinished((_prev) => ({
                ..._prev,
                pickUpPostalCode: true,
              }));
            }
          }
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (keyName == "deliveryPostalCode") {
        setZipCodeDelData([]);
      }
      if (keyName == "pickupPostalCode") {
        setZipCodeData([]);
      }
    }
  };

  const getTotalDistance = async (
    defaultData: any = "",
    keyName: string = "",
    apiData: any = "",
    _Code: any = ""
  ) => {
    let type = keyName == "pickupPostalCode" ? "PD" : "DD";
    let params = getTotalDistancePayload(
      accounts,
      formik,
      defaultData,
      keyName,
      apiData,
      _Code
    );
    params = {
      ...params,
      userName: settingsData ? settingsData?.logonName : null,
    };

    const response: any = await totalPickupDeliveryDistance(params);
    if (response.data && response.data.data) {
      const totalDistance = response.data.data?.totalDistance;
      const distance = response.data.data?.distance;
      if (type == "PD") {
        formik.setFieldValue("pickupDistance", distance);
        setServicesDependancyFinished((_prev) => ({
          ..._prev,
          pickUpDistance: true,
        }));
      } else {
        formik.setFieldValue("deliveryDistance", distance);
        setServicesDependancyFinished((_prev) => ({
          ..._prev,
          deliveryDistance: true,
        }));
      }
      formik.setFieldValue("totalDst", totalDistance);
    }
  };

  useEffect(() => {
    if (formik.values.serviceID) {
      getAttributeByServices();
    }
  }, [formik.values.serviceID]);

  const handleSubmit = () => {
    formik.handleSubmit();
    if (Object.keys(formik.errors).length > 0) {
      errorRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        top: 50,
      });
    }
  };

  useEffect(() => {
    if (
      Object.keys(formik.errors).length == 1 &&
      (formik.errors?.pickupPostalCode || formik.errors?.deliveryPostalCode)
    ) {
      errorRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        top: 50,
      });
    }
  }, [formik.errors]);

  const calculateDimWeigthItem = (
    pieces: any,
    height: any,
    length: any,
    width: any,
    lengthUnit: string,
    weightUOM: string,
    index: number
  ) => {
    const dimWeight = calculateDimWeight(
      "US",
      "US",
      pieces,
      length,
      width,
      height,
      lengthUnit,
      weightUOM
    );

    formik.setFieldValue(`packageInfo.${index}.dimWt`, dimWeight); //same as per current site
    calculateTotalDmWeight(dimWeight, index);
  };

  const calculateTotalWeight = (weigth: any = 0, index: number = 0) => {
    const totalWeight = getCalculateTotalWeight(formik, weigth, index);
    formik.setFieldValue("totalWeight", totalWeight);
  };

  const calculateTotalDmWeight = (dmWeigth: any = 0, index: number = 0) => {
    const totalDMWeight: any = getCalculateTotalDmWeight(
      formik,
      dmWeigth,
      index
    );

    formik.setFieldValue(
      "totalDMWeight",
      totalDMWeight && totalDMWeight > 0 ? parseFloat(totalDMWeight) : "0"
    );
  };

  const handleRemoveItem = (remove: any, index: number) => {
    remove(index);
    calculateTotalDmWeight();
    calculateTotalWeight();
  };
  const updateUnitForRestOfItem = (e: string, fieldName: string) => {
    formik.values.packageInfo.forEach((_, i) => {
      if (i !== 0) {
        formik.setFieldValue(`packageInfo.${i}.${fieldName}`, e);
        const dimWeight = calculateDimWeigthItem(
          _.pieces,
          _.height,
          _.length,
          _.width,
          fieldName == "unit" ? e : _.unit,
          fieldName == "weightUOM" ? e : _.weightUOM,
          i
        );
        formik.setFieldValue(`packageInfo.${i}.dimWeight`, dimWeight);
      }
    });
  };
  const checkFlightStatus = async () => {
    const param = {
      uCompanyID: MAX_COMPANY_ID,
      uServiceID: formik.values.serviceID,
    };
    const response: any = await checkServiceList(param);
    if (response.data && response.data.data) {
      const serviceNonIntegrated = response.data.data?.serviceNonIntegrated;
      if (serviceNonIntegrated) {
        setIsFlightOptionVisible(true);
      } else {
        setIsFlightOptionVisible(false);
      }
    }
  };
  useEffect(() => {
    if (formik.values.serviceID) {
      checkFlightStatus();
    }
  }, [formik.values.serviceID]);

  const totalEstimateAmount = useMemo(() => {
    return calculateTotalAmount(rateEstimateData);
  }, [rateEstimateData]);
  const totalEstimateTaxAmount = useMemo(() => {
    return calculateTotalTaxAmount(rateEstimateData);
  }, [rateEstimateData]);
  const totalEstimateNetAmount = useMemo(() => {
    return calculateTotalNetAmount(rateEstimateData);
  }, [rateEstimateData]);
  useEffect(() => {
    calculateTotalWeight(formik.values.packageInfo[0].weight, 0);
    calculateTotalDmWeight(formik.values.packageInfo[0].dimWt, 0);
  }, [formik.values.packageInfo.length]);

  return (
    <div>
      <>
        <div
          className="active-order Rate-screen"
          style={{ paddingBottom: "27px" }}
        >
          <Box
            className="Header-main mb-8 mt-16"
            display="flex"
            alignItems="start"
            justifyContent="space-between"
            ref={errorRef}
          >
            <Typography
              className="Main-Header side-by-side-header"
              variant="h4"
              color={"primary"}
            >
              Rate Estimate
            </Typography>

            <Typography
              className="info-text"
              variant="h6"
              sx={{
                display: {
                  xs: "none", // Hide on mobile devices
                  sm: "flex", // Show on small and larger devices
                  justifyContent: "end",
                },
              }}
            >
              The information contained in this rate estimation tool is not
              intended and should not be used or construed as a cost associated
              with the shipment. This information is provided solely for
              informational purposes. Actual costs could change due to a variety
              of factors, including, but not limited to accessorial charges,
              duties/VAT, etc.
            </Typography>
          </Box>

          {/* Show Error Block */}
          <>
            <div>
              <DisplayError formik={formik} />
            </div>
          </>

          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <Box className="d-flex flex-column w-100 border p16 rounded mb-24 account-16">
                <Typography variant="h6" className="sub-track-header">
                  Account and Service Information
                </Typography>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    marginTop: {
                      xs: 1, // 8px margin-top for mobile
                      sm: 0, // 0 margin-top for larger screens
                    },
                  }}
                >
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={4} sm={4} lg={4}>
                        <FormControl size="small" className="w-100">
                          <InputLabel id="demo-select-small-label">
                            Select Account
                          </InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="ddReAccount"
                            label="Select Account"
                            name="accountNumber"
                            onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            value={formik.values.accountNumber}
                            error={
                              formik.touched.accountNumber &&
                              Boolean(formik.errors.accountNumber)
                            }
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },

                              PaperProps: {
                                style: menuStyles,
                              },
                            }}
                          >
                            <MenuItem value="All">All</MenuItem>
                            {accounts.length > 0 &&
                              accounts.map((account: any) => (
                                <MenuItem
                                  key={account.userAccountRowGUID}
                                  value={account.accountNumber}
                                >
                                  {account.displayName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} md={4} sm={4} lg={4}>
                        <FormControl size="small" className="w-100">
                          <InputLabel id="demo-select-small-label">
                            Service Type
                          </InputLabel>

                          <Select
                            variant="outlined"
                            defaultValue=""
                            label="Service Type"
                            id="ddReServiceType"
                            name="serviceID"
                            onChange={formik.handleChange}
                            //onBlur={formik.handleBlur}
                            value={formik.values.serviceID}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },

                              PaperProps: {
                                style: menuStyles,
                              },
                            }}
                            error={
                              formik.touched.serviceID &&
                              Boolean(formik.errors.serviceID)
                            }
                          >
                            {/* <MenuItem value="All">(None)</MenuItem> */}
                            {serviceData.length > 0 &&
                              serviceData.map((service: any) => (
                                <MenuItem
                                  key={service.serviceID}
                                  value={service.serviceID}
                                >
                                  {service.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4} sm={4} lg={4}>
                        <div className="small-cal-view">
                          <CustomDatePicker
                            id="txtReOrderDateTime"
                            handleDateChange={formik.handleChange}
                            label="Order Date/Time"
                            name="orderDateTime"
                            value={formik.values.orderDateTime}
                            isClearable={true}
                            showError={
                              formik.touched.orderDateTime &&
                              Boolean(formik.errors.orderDateTime)
                            }
                            showTimeSelect={true}
                          />
                        </div>
                        {/* <TextField
                          className="w-100"
                          size="small"
                          id="datetime-start-local"
                          label="Order Date/Time"
                          type="datetime-local"
                          name="orderDateTime"
                          InputLabelProps={{ shrink: true }}
                          onChange={formik.handleChange}
                          //onBlur={formik.handleBlur}
                          value={formik.values.orderDateTime}
                          error={
                            formik.touched.orderDateTime &&
                            Boolean(formik.errors.orderDateTime)
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton>
                                  <img
                                    src={calendarIcon}
                                    alt="Calendar icon"
                                    style={{ maxWidth: "100%" }}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                            endAdornment: !!formik.errors.orderDateTime && (
                              <ErrorInputAdornment />
                            ),
                          }}
                        /> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box className="d-flex flex-column w-100 border p16 rounded mb-16">
                <Typography variant="h6" className="sub-track-header pb-16">
                  Package Information
                </Typography>

                <Grid container spacing={2} className="pb-16">
                  <FieldArray name="packageInfo">
                    {({ push, remove }) => (
                      <>
                        {formik.values.packageInfo.map((item, index) => (
                          <>
                            <Grid
                              item
                              lg={10 / 6}
                              md={10 / 6}
                              sm={5}
                              xs={9 / 2}
                            >
                              <TextField
                                fullWidth
                                name={`packageInfo.${index}.pieces`}
                                id={`txtRePackageInfo${index}.Pieces`}
                                label="Pieces"
                                size="small"
                                // type="number"
                                // onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                inputProps={{ min: 1 }}
                                onKeyDown={handleDecimalValue}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  calculateDimWeigthItem(
                                    e.target.value,
                                    item.height,
                                    item.length,
                                    item.width,
                                    item.unit,
                                    item.weightUOM,
                                    index
                                  );
                                }}
                                value={item.pieces}
                                error={
                                  Boolean(
                                    formik.touched.packageInfo &&
                                      (formik.touched.packageInfo as any)[index]
                                        ?.pieces
                                  ) &&
                                  Boolean(
                                    formik.errors.packageInfo &&
                                      (formik.errors.packageInfo as any)[index]
                                        ?.pieces
                                  )
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              lg={10 / 6}
                              md={10 / 6}
                              sm={5}
                              xs={9 / 2}
                            >
                              <TextField
                                fullWidth
                                id={`txtRePackageInfo.${index}Weight`}
                                name={`packageInfo.${index}.weight`}
                                label="Weight"
                                size="small"
                                //type="number"
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  calculateTotalWeight(e.target.value, index);
                                }}
                                onKeyDown={handleDecimalValue}
                                inputProps={{ min: 0 }}
                                value={item.weight}
                                error={
                                  Boolean(
                                    formik.touched.packageInfo &&
                                      (formik.touched.packageInfo as any)[index]
                                        ?.weight
                                  ) &&
                                  Boolean(
                                    formik.errors.packageInfo &&
                                      (formik.errors.packageInfo as any)[index]
                                        ?.weight
                                  )
                                }
                              />
                            </Grid>

                            <Grid
                              item
                              lg={1}
                              md={1}
                              sm={2}
                              xs={3}
                              style={{ paddingLeft: "8px" }}
                            >
                              <FormControl size="small" className="w-100">
                                <InputLabel id="demo-select-small-label">
                                  Unit
                                </InputLabel>
                                <Select
                                  labelId="demo-select-small-label"
                                  //onChange={formik.handleChange}
                                  // onBlur={formik.handleBlur}
                                  onChange={(e) => {
                                    updateUnitForRestOfItem(
                                      e.target.value,
                                      "weightUOM"
                                    );
                                    formik.handleChange(e);
                                    calculateDimWeigthItem(
                                      item.pieces,
                                      item.height,
                                      item.length,
                                      item.width,
                                      item.unit,
                                      e.target.value,
                                      index
                                    );
                                  }}
                                  value={item.weightUOM}
                                  label="Unit"
                                  id={`ddRePackageInfo.${index}WeightUOM`}
                                  name={`packageInfo.${index}.weightUOM`}
                                  inputProps={{
                                    readOnly: index === 0 ? false : true,
                                  }}
                                >
                                  <MenuItem value="LB" id="liReLb">
                                    LB
                                  </MenuItem>
                                  <MenuItem value="KG" id="liReKg">
                                    KG
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              lg={10 / 6}
                              md={10 / 6}
                              sm={10 / 4}
                              xs={6}
                            >
                              <TextField
                                fullWidth
                                size="small"
                                id={`txtRePackageInfo.${index}Length`}
                                name={`packageInfo.${index}.length`}
                                label="Length"
                                //type="number"
                                onKeyDown={handleDecimalValue}
                                // onChange={formik.handleChange}
                                inputProps={{ min: 0 }}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  calculateDimWeigthItem(
                                    item.pieces,
                                    item.height,
                                    e.target.value,
                                    item.width,
                                    item.unit,
                                    item.weightUOM,
                                    index
                                  );
                                }}
                                value={item.length}
                                error={
                                  Boolean(
                                    formik.touched.packageInfo &&
                                      (formik.touched.packageInfo as any)[index]
                                        ?.length
                                  ) &&
                                  Boolean(
                                    formik.errors.packageInfo &&
                                      (formik.errors.packageInfo as any)[index]
                                        ?.length
                                  )
                                }
                                // InputProps={{
                                //   endAdornment: !!(
                                //     formik.errors.packageInfo as any
                                //   )[index]?.length && (
                                //     <ErrorInputAdornment />
                                //   ),
                                // }}
                              />
                            </Grid>
                            <Grid
                              item
                              lg={10 / 6}
                              md={10 / 6}
                              sm={10 / 4}
                              xs={6}
                            >
                              <TextField
                                fullWidth
                                size="small"
                                id={`txtRePackageInfo.${index}Width`}
                                name={`packageInfo.${index}.width`}
                                label="Width"
                                // type="number"
                                onKeyDown={handleDecimalValue}
                                //onChange={formik.handleChange}
                                inputProps={{ min: 0 }}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  calculateDimWeigthItem(
                                    item.pieces,
                                    item.height,
                                    item.length,
                                    e.target.value,
                                    item.unit,
                                    item.weightUOM,
                                    index
                                  );
                                }}
                                // onBlur={formik.handleBlur}
                                value={item.width}
                                error={
                                  Boolean(
                                    formik.touched.packageInfo &&
                                      (formik.touched.packageInfo as any)[index]
                                        ?.width
                                  ) &&
                                  Boolean(
                                    formik.errors.packageInfo &&
                                      (formik.errors.packageInfo as any)[index]
                                        ?.width
                                  )
                                }
                                // InputProps={{
                                //   endAdornment: !!(
                                //     formik.errors.packageInfo as any
                                //   )[index]?.width && (
                                //     <ErrorInputAdornment />
                                //   ),
                                // }}
                              />
                            </Grid>
                            <Grid
                              item
                              lg={10 / 6}
                              md={10 / 6}
                              sm={10 / 4}
                              xs={6}
                            >
                              <TextField
                                fullWidth
                                size="small"
                                id={`txtRePackageInfo.${index}Height`}
                                name={`packageInfo.${index}.height`}
                                label="Height"
                                // type="number"
                                onKeyDown={handleDecimalValue}
                                // onChange={formik.handleChange}
                                inputProps={{ min: 0 }}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  calculateDimWeigthItem(
                                    item.pieces,
                                    e.target.value,
                                    item.length,
                                    item.width,
                                    item.unit,
                                    item.weightUOM,
                                    index
                                  );
                                }}
                                value={item.height}
                                error={
                                  Boolean(
                                    formik.touched.packageInfo &&
                                      (formik.touched.packageInfo as any)[index]
                                        ?.height
                                  ) &&
                                  Boolean(
                                    formik.errors.packageInfo &&
                                      (formik.errors.packageInfo as any)[index]
                                        ?.height
                                  )
                                }
                                // InputProps={{
                                //   endAdornment: !!(
                                //     formik.errors.packageInfo as any
                                //   )[index]?.height && (
                                //     <ErrorInputAdornment />
                                //   ),
                                // }}
                              />
                            </Grid>
                            <Grid
                              item
                              lg={1}
                              md={1}
                              sm={2}
                              xs={4}
                              style={{ paddingLeft: "8px" }}
                            >
                              <FormControl size="small" className="w-100">
                                <InputLabel id="demo-select-small-label">
                                  Unit
                                </InputLabel>
                                <Select
                                  labelId="demo-select-small-label"
                                  // onChange={formik.handleChange}
                                  //onBlur={formik.handleBlur}
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                    updateUnitForRestOfItem(
                                      e.target.value,
                                      "unit"
                                    );
                                    calculateDimWeigthItem(
                                      item.pieces,
                                      item.height,
                                      item.length,
                                      item.width,
                                      e.target.value,
                                      item.weightUOM,
                                      index
                                    );
                                  }}
                                  value={item.unit}
                                  label="Unit"
                                  id={`ddRePackageInfo.${index}Unit`}
                                  name={`packageInfo.${index}.unit`}
                                  inputProps={{
                                    readOnly: index === 0 ? false : true,
                                  }}
                                >
                                  <MenuItem value="IN">IN</MenuItem>
                                  <MenuItem value="CM">CM</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              lg={10 / 6}
                              md={10 / 6}
                              sm={10 / 4}
                              xs={12}
                            >
                              <TextField
                                fullWidth
                                size="small"
                                id={`txtRePackageInfo.${index}DimWt`}
                                name={`packageInfo.${index}.dimWt`}
                                label="Dim Weight"
                                // type="number"
                                onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                //onKeyDown={handleDecimalValue}
                                value={item.dimWt}
                                error={Boolean(
                                  formik.errors.packageInfo &&
                                    (formik.errors.packageInfo as any)[index]
                                      ?.dimWt
                                )}
                                InputProps={{
                                  inputProps: { min: 0 },
                                  readOnly: true,
                                  style: { pointerEvents: "none" },
                                }}
                              />
                            </Grid>

                            {index + 1 == formik.values.packageInfo.length && (
                              <>
                                <div
                                  className="pt-16 Divider-top mt-16"
                                  style={{
                                    marginLeft: "16px",
                                  }}
                                >
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      lg={7 / 5}
                                      md={7 / 5}
                                      sm={10 / 4}
                                      xs={9 / 2}
                                    >
                                      <TextField
                                        fullWidth
                                        id={`txtReDeclValue`}
                                        size="small"
                                        name={`declValue`}
                                        label="Decl Value"
                                        //type="number"
                                        onChange={formik.handleChange}
                                        // onBlur={formik.handleBlur}
                                        value={formik.values.declValue}
                                        inputProps={{ min: 0 }}
                                        onKeyDown={handleDecimalValue}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      lg={7 / 5}
                                      md={7 / 5}
                                      sm={10 / 4}
                                      xs={9 / 2}
                                    >
                                      <TextField
                                        fullWidth
                                        id={`txtReTotalDst`}
                                        name={`totalDst`}
                                        label="Total Dst"
                                        //  type="number"
                                        onChange={formik.handleChange}
                                        // onBlur={formik.handleBlur}
                                        size="small"
                                        value={formik.values.totalDst}
                                        inputProps={{ min: 0 }}
                                        onKeyDown={handleDecimalValue}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      lg={1}
                                      md={1}
                                      sm={2}
                                      xs={3}
                                      style={{ paddingLeft: "8px" }}
                                    >
                                      <FormControl
                                        size="small"
                                        className="w-100"
                                      >
                                        <InputLabel id="demo-select-small-label">
                                          Unit
                                        </InputLabel>
                                        <Select
                                          labelId="demo-select-small-label"
                                          onChange={formik.handleChange}
                                          // onBlur={formik.handleBlur}
                                          value={formik.values.totalDstUnit}
                                          label="Unit"
                                          id={`ddReTotalDstUnit`}
                                          name={`totalDstUnit`}
                                        >
                                          <MenuItem value="MI">MI</MenuItem>
                                          <MenuItem value="KM">KM</MenuItem>
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid
                                      item
                                      lg={7 / 5}
                                      md={7 / 5}
                                      sm={10 / 4}
                                      xs={12}
                                    >
                                      <TextField
                                        size="small"
                                        fullWidth
                                        id={`txtReTotalWeight`}
                                        name={`totalWeight`}
                                        label="Total Weight"
                                        // type="number"
                                        onChange={formik.handleChange}
                                        //onBlur={formik.handleBlur}
                                        value={formik.values.totalWeight}
                                        InputProps={{
                                          inputProps: { min: 0 },
                                          readOnly: true,
                                          style: { pointerEvents: "none" },
                                        }}
                                        // onKeyDown={handleDecimalValue}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      lg={2}
                                      md={2}
                                      sm={10 / 4}
                                      xs={12}
                                    >
                                      <TextField
                                        size="small"
                                        fullWidth
                                        id={`txtReTotalDMWeight`}
                                        name={`totalDMWeight`}
                                        label="Total Dim Weight"
                                        //type="number"
                                        onChange={formik.handleChange}
                                        //  onBlur={formik.handleBlur}
                                        value={formik.values.totalDMWeight}
                                        InputProps={{
                                          inputProps: { min: 0 },
                                          readOnly: true,
                                          style: { pointerEvents: "none" },
                                        }}
                                        // onKeyDown={handleDecimalValue}
                                      />
                                    </Grid>

                                    <Grid className="mt-16 ml-40 Grid_button">
                                      {formik.values.packageInfo.length ==
                                        1 && (
                                        <Button
                                          id="btnReRemove"
                                          className="disabled_btn"
                                          sx={{
                                            width: {
                                              xs: "50%", // width 50% on xs screens
                                            },
                                            marginLeft: {
                                              xs: "16px", // margin-left 16px on xs screens
                                            },
                                          }}
                                          variant="outlined"
                                          disabled
                                          onClick={() =>
                                            handleRemoveItem(remove, index)
                                          }
                                        >
                                          Remove
                                        </Button>
                                      )}
                                      {formik.values.packageInfo.length > 1 && (
                                        <Button
                                          className="Remove_activate"
                                          id="btnRemove"
                                          sx={{
                                            width: {
                                              xs: "50%", // width 50% on xs screens
                                            },
                                            marginLeft: {
                                              xs: "16px", // margin-left 16px on xs screens
                                            },
                                          }}
                                          variant="outlined"
                                          onClick={() =>
                                            handleRemoveItem(remove, index)
                                          }
                                        >
                                          Remove
                                        </Button>
                                      )}
                                      <Button
                                        className="add-piecebtn"
                                        id="btnReAddPiece"
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                          width: {
                                            xs: "50%", // width 50% on xs screens
                                          },
                                        }}
                                        onClick={() => {
                                          push({
                                            pieces:
                                              formik.values.packageInfo[
                                                formik.values.packageInfo
                                                  .length - 1
                                              ].pieces || "",
                                            weight:
                                              formik.values.packageInfo[
                                                formik.values.packageInfo
                                                  .length - 1
                                              ].weight || "",
                                            width:
                                              formik.values.packageInfo[
                                                formik.values.packageInfo
                                                  .length - 1
                                              ].width || "",
                                            length:
                                              formik.values.packageInfo[
                                                formik.values.packageInfo
                                                  .length - 1
                                              ].length || "",
                                            height:
                                              formik.values.packageInfo[
                                                formik.values.packageInfo
                                                  .length - 1
                                              ].height || "",
                                            weightUOM:
                                              formik.values.packageInfo[0]
                                                .weightUOM,

                                            unit: formik.values.packageInfo[0]
                                              .unit,
                                            dimWt:
                                              formik.values.packageInfo[
                                                formik.values.packageInfo
                                                  .length - 1
                                              ].dimWt || "",
                                          });
                                        }}
                                      >
                                        ADD PIECE
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </div>
                              </>
                            )}
                          </>
                        ))}
                      </>
                    )}
                  </FieldArray>
                </Grid>
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box className="d-flex flex-column w-10 border p16 rounded">
                      <Typography
                        variant="h6"
                        className="sub-track-header mb-8"
                      >
                        Pickup Details
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            variant="outlined"
                            id="txtRePickupAddressCode"
                            label="Code"
                            size="small"
                            className="w-100"
                            name="pickupAddressCode"
                            onChange={formik.handleChange}
                            onBlur={handleAddressCodeBlur}
                            value={formik.values.pickupAddressCode}
                            InputLabelProps={{
                              shrink:
                                !!formik.values.pickupAddressCode ||
                                formik.touched.pickupAddressCode,
                            }}
                            onKeyDown={handleAddressCodeKeyDown}
                            error={
                              formik.touched.pickupAddressCode &&
                              Boolean(formik.errors.pickupAddressCode)
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      handleClickOpen("pickupAddressCode")
                                    }
                                    id="btnRePickupLookup"
                                  >
                                    <MoreHorizIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <FormControl size="small" className="w-100">
                            <InputLabel id="demo-select-small-label">
                              Country
                            </InputLabel>
                            <Select
                              labelId="demo-select-small-label"
                              id="ddRePickupCountry"
                              // Ensure this matches your state variable
                              label="Country"
                              name="pickupCountryID"
                              onChange={formik.handleChange}
                              //onBlur={formik.handleBlur}
                              value={formik.values.pickupCountryID}
                              fullWidth
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },

                                PaperProps: {
                                  style: menuStyles,
                                },
                              }}
                            >
                              {countryData.length > 0 &&
                                countryData.map((country: any) => (
                                  <MenuItem
                                    key={country.countryID}
                                    value={country.countryID}
                                  >
                                    {country.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <FormControl size="small" className="w-100">
                            <InputLabel id="demo-select-small-label">
                              City
                            </InputLabel>
                            <Select
                              labelId="demo-select-small-label"
                              id="ddRePickupCity"
                              label="City"
                              fullWidth
                              name="pickupCityName"
                              onChange={formik.handleChange}
                              // onBlur={formik.handleBlur}
                              value={formik.values.pickupCityName}
                              sx={{ width: isMobile ? "100%" : undefined }}
                            >
                              {zipCodeData.length > 0 &&
                                zipCodeData.map((city: any) => (
                                  <MenuItem
                                    key={city.cityName}
                                    value={city.cityName}
                                  >
                                    {city.cityName}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <TextField
                            variant="outlined"
                            label="State"
                            size="small"
                            id="txtRePickupState"
                            name="pickupStateProvID"
                            className="w-100"
                            onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            value={formik.values.pickupStateProvID}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <TextField
                            label="ZIP"
                            name="pickupPostalCode"
                            onChange={formik.handleChange}
                            onBlur={handleZipCodeBlur}
                            id="txtRePickupPostalCode"
                            //onKeyDown={handleZipCodeKeyDown}
                            value={formik.values.pickupPostalCode}
                            variant="outlined"
                            className="w-100"
                            size="small"
                            error={
                              formik.touched.pickupPostalCode &&
                              Boolean(formik.errors.pickupPostalCode)
                            }
                            InputProps={{
                              endAdornment: formik.touched.pickupPostalCode &&
                                !!formik.errors.pickupPostalCode && (
                                  <ErrorInputAdornment />
                                ),
                            }}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={7}>
                          <Grid container spacing={1}>
                            <Grid item lg={7} md={7} sm={7} xs={7}>
                              <TextField
                                variant="outlined"
                                label="Dist."
                                size="small"
                                id="txtRePickupDistance"
                                name="pickupDistance"
                                className="w-100"
                                onChange={formik.handleChange}
                                //  onBlur={formik.handleBlur}
                                value={formik.values.pickupDistance}
                              />
                            </Grid>
                            <Grid item lg={5} md={5} sm={5} xs={5}>
                              <FormControl size="small" className="w-100">
                                <Select
                                  labelId="demo-select-small-label"
                                  id="ddRePickupDistanceUnit"
                                  fullWidth
                                  name="pickupDistanceUnit"
                                  onChange={formik.handleChange}
                                  // onBlur={formik.handleBlur}
                                  value={formik.values.pickupDistanceUnit}
                                >
                                  <MenuItem
                                    key={"MI"}
                                    value={"MI"}
                                    id="liRePickupDisMi"
                                  >
                                    MI
                                  </MenuItem>
                                  <MenuItem
                                    key={"KM"}
                                    value={"KM"}
                                    id="liRePickupDisKm"
                                  >
                                    KM
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={5}>
                          <TextField
                            variant="outlined"
                            label="Drop A/P"
                            size="small"
                            id="txtRePickupDropAirport"
                            name="pickupDropAirportID"
                            className="w-100"
                            inputProps={{ maxLength: 3 }}
                            onChange={(e) => {
                              const re = /^[a-zA-Z\s]*$/;
                              const value = e.target.value;
                              if (re.test(value)) {
                                formik.setFieldValue(
                                  "pickupDropAirportID",
                                  value
                                );
                              }
                            }}
                            value={formik.values.pickupDropAirportID}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <TextField
                            size="small"
                            variant="outlined"
                            label="Wait Time"
                            name="pickupWaitTime"
                            id="txtRePickupWaitTime"
                            className="w-100"
                            onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            value={formik.values.pickupWaitTime}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <TextField
                            variant="outlined"
                            label="Attempts"
                            name="pickupAttempts"
                            id="txtRePickupAttempts"
                            className="w-100"
                            size="small"
                            onChange={formik.handleChange}
                            //onBlur={formik.handleBlur}
                            value={formik.values.pickupAttempts}
                          />
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <div className="small-cal-view">
                            <CustomDatePicker
                              handleDateChange={formik.handleChange}
                              label="Ready Time"
                              name="pickupReadyTime"
                              id="txtRePickupReadyTime"
                              value={formik.values.pickupReadyTime}
                              isClearable={true}
                              showError={
                                formik.touched.pickupReadyTime &&
                                Boolean(formik.errors.pickupReadyTime)
                              }
                              showTimeSelect={true}
                            />
                          </div>
                          {/* <TextField
                            className="w-100"
                            size="small"
                            id="datetime-start-local"
                            label="Ready Time"
                            type="datetime-local"
                            name="pickupReadyTime"
                            onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            value={formik.values.pickupReadyTime}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton>
                                    <img
                                      src={calendarIcon}
                                      alt="Calendar icon"
                                      style={{ maxWidth: "100%" }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              endAdornment: !!formik.touched.pickupReadyTime &&
                                !!formik.errors.pickupReadyTime && (
                                  <ErrorInputAdornment />
                                ),
                            }}
                            error={
                              formik.touched.pickupReadyTime &&
                              Boolean(formik.errors.pickupReadyTime)
                            }
                          /> */}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box className="d-flex flex-column w-100 border p16 rounded">
                      <Typography
                        variant="h6"
                        className="sub-track-header mb-8"
                      >
                        Delivery Details
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <TextField
                            variant="outlined"
                            label="Code"
                            size="small"
                            className="w-100"
                            name="deliveryAddressCode"
                            id="ddReDeliveryDistanceUnit"
                            onChange={formik.handleChange}
                            onBlur={handleAddressCodeBlur}
                            value={formik.values.deliveryAddressCode}
                            onKeyDown={handleAddressCodeKeyDown}
                            error={
                              formik.touched.deliveryAddressCode &&
                              Boolean(formik.errors.deliveryAddressCode)
                            }
                            InputLabelProps={{
                              shrink:
                                !!formik.values.deliveryAddressCode ||
                                formik.touched.deliveryAddressCode,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      handleClickOpen("deliveryAddressCode")
                                    }
                                    id="btnReDeliveryLookup"
                                  >
                                    <MoreHorizIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <FormControl size="small" className="w-100">
                            <InputLabel id="demo-select-small-label">
                              Country
                            </InputLabel>
                            <Select
                              labelId="demo-select-small-label"
                              id="ddReDeliveryCountry"
                              // Ensure this matches your state variable
                              label="Country"
                              name="deliveryCountryID"
                              onChange={formik.handleChange}
                              // onBlur={formik.handleBlur}
                              value={formik.values.deliveryCountryID}
                              fullWidth
                              sx={{ width: isMobile ? "100%" : undefined }}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },

                                PaperProps: {
                                  style: menuStyles,
                                },
                              }}
                            >
                              {countryData.length > 0 &&
                                countryData.map((country: any) => (
                                  <MenuItem
                                    key={country.countryID}
                                    value={country.countryID}
                                  >
                                    {country.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <FormControl size="small" className="w-100">
                            <InputLabel id="demo-select-small-label">
                              City
                            </InputLabel>
                            <Select
                              labelId="demo-select-small-label"
                              id="ddReDeliveryCity"
                              label="City"
                              fullWidth
                              name="deliveryCityName"
                              onChange={formik.handleChange}
                              // onBlur={formik.handleBlur}
                              value={formik.values.deliveryCityName}
                              sx={{ width: isMobile ? "100%" : undefined }}
                            >
                              {zipCodeDelData.length > 0 &&
                                zipCodeDelData.map((city: any) => (
                                  <MenuItem
                                    key={city.cityName}
                                    value={city.cityName}
                                  >
                                    {city.cityName}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <TextField
                            variant="outlined"
                            label="State"
                            size="small"
                            id="txtReDeliveryState"
                            name="deliveryStateProvID"
                            className="w-100"
                            onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            value={formik.values.deliveryStateProvID}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <TextField
                            label="ZIP"
                            name="deliveryPostalCode"
                            id="txtReDeliveryPostalCode"
                            onChange={formik.handleChange}
                            onBlur={handleZipCodeBlur}
                            // onKeyDown={handleZipCodeKeyDown}
                            value={formik.values.deliveryPostalCode}
                            variant="outlined"
                            className="w-100"
                            size="small"
                            error={
                              formik.touched.deliveryPostalCode &&
                              Boolean(formik.errors.deliveryPostalCode)
                            }
                            InputProps={{
                              endAdornment: formik.touched.deliveryPostalCode &&
                                !!formik.errors.deliveryPostalCode && (
                                  <ErrorInputAdornment />
                                ),
                            }}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={7}>
                          <Grid container spacing={1}>
                            <Grid item lg={7} md={7} sm={7} xs={7}>
                              <TextField
                                variant="outlined"
                                size="small"
                                label="Dist."
                                id="txtReDeliveryDistance"
                                name="deliveryDistance"
                                className="w-100"
                                onChange={formik.handleChange}
                                // onBlur={formik.handleBlur}
                                value={formik.values.deliveryDistance}
                              />
                            </Grid>
                            <Grid item lg={5} md={5} sm={5} xs={5}>
                              <FormControl size="small" className="w-100">
                                <Select
                                  labelId="demo-select-small-label"
                                  fullWidth
                                  id="ddReDeliveryDistanceUnit"
                                  name="deliveryDistanceUnit"
                                  onChange={formik.handleChange}
                                  // onBlur={formik.handleBlur}
                                  value={formik.values.deliveryDistanceUnit}
                                >
                                  <MenuItem key={"MI"} value={"MI"}>
                                    MI
                                  </MenuItem>
                                  <MenuItem key={"KM"} value={"KM"}>
                                    KM
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={5}>
                          <TextField
                            variant="outlined"
                            label="Drop A/P"
                            size="small"
                            id="txtReDeliveryDropAirport"
                            name="deliveryDropAirportID"
                            className="w-100"
                            inputProps={{ maxLength: 3 }}
                            onChange={(e) => {
                              const re = /^[a-zA-Z\s]*$/;
                              const value = e.target.value;
                              if (re.test(value)) {
                                formik.setFieldValue(
                                  "deliveryDropAirportID",
                                  value
                                );
                              }
                            }}
                            value={formik.values.deliveryDropAirportID}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <TextField
                            variant="outlined"
                            size="small"
                            label="Wait Time"
                            name="deliveryWaitTime"
                            id="txtReDeliveryWaitTime"
                            className="w-100"
                            onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            value={formik.values.deliveryWaitTime}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <TextField
                            size="small"
                            variant="outlined"
                            label="Attempts"
                            name="deliveryAttempts"
                            id="txtReDeliveryAttempts"
                            className="w-100"
                            onChange={formik.handleChange}
                            //onBlur={formik.handleBlur}
                            value={formik.values.deliveryAttempts}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <div
                            className="gap-8 small-cal-view"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <CustomDatePicker
                              handleDateChange={formik.handleChange}
                              label="Delivery Time"
                              name="requestedDeliveryTime"
                              id="txtReDeliveryTime"
                              value={formik.values.requestedDeliveryTime}
                              isClearable={true}
                              showError={
                                formik.touched.requestedDeliveryTime &&
                                Boolean(formik.errors.requestedDeliveryTime)
                              }
                              showTimeSelect={true}
                            />

                            {/* <TextField
                              className="w-100"
                              size="small"
                              id="datetime-start-local"
                              label="Delivery Time"
                              type="datetime-local"
                              name="requestedDeliveryTime"
                              onChange={formik.handleChange}
                              value={formik.values.requestedDeliveryTime}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton>
                                      <img
                                        src={calendarIcon}
                                        alt="Calendar icon"
                                        style={{ maxWidth: "100%" }}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                                endAdornment: !!formik.touched
                                  .requestedDeliveryTime &&
                                  !!formik.errors.requestedDeliveryTime && (
                                    <ErrorInputAdornment />
                                  ),
                              }}
                              error={
                                formik.touched.requestedDeliveryTime &&
                                Boolean(formik.errors.requestedDeliveryTime)
                              }
                            /> */}
                            {isFlightOptionVisible && (
                              <IconButton
                                onClick={() => {
                                  setOpenRouteDialog(true);
                                }}
                                id="btnReFlight"
                              >
                                <FlightIcon />
                              </IconButton>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box className="d-flex flex-column w-100 border p16 rounded">
                      <Typography variant="h6" className="sub-track-header">
                        Attributes
                      </Typography>

                      <Grid item sx={{ paddingLeft: "0px" }}>
                        <Box
                          className="d-flex flex-column w-100 ${servicesAttribute?.length ? 'mt-16' : 'mt-0'}`}"
                          sx={{
                            maxHeight: "252px",
                            overflowY: "auto",
                            paddingLeft: "10px",
                          }}
                        >
                          {servicesAttribute?.map((_attr) => {
                            return (
                              <FormControlLabel
                                sx={{ width: "98%" }}
                                control={
                                  <Checkbox
                                    onChange={formik.handleChange}
                                    checked={formik.values.attributeList.includes(
                                      _attr?.attributeTypeCode
                                    )}
                                    id="cbReAttributes"
                                    name="attributeList"
                                    value={_attr?.attributeTypeCode}
                                  />
                                }
                                label={_attr?.attributeTypeName}
                              />
                            );
                          })}
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box className="d-flex flex-column w-100 border p16 rounded mt-16">
                <Typography variant="h6" className="sub-track-header">
                  Charges
                </Typography>
                <Box className="mt-16" sx={{ width: "100%" }}>
                  <Paper
                    className="ActiveOrder mb-40 Rate-table"
                    sx={{ width: "100%", mb: 2 }}
                  >
                    <TableContainer className="rate-estimate">
                      <Table aria-labelledby="tableTitle">
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort as any}
                          rowCount={rateEstimateData.length}
                          headCells={headCells}
                          itemDTO={"RateEstimate"}
                        />

                        <TableBody>
                          {isLoading ? (
                            <TableRow>
                              <TableCell
                                className=""
                                colSpan={12}
                                align="center"
                              >
                                <Loader />
                              </TableCell>
                            </TableRow>
                          ) : rateEstimateData.length === 0 ? (
                            <TableRow
                              className="Nodata_found Auto-height"
                              id="trChargesDataNotFound"
                            >
                              <TableCell
                                className=""
                                colSpan={12}
                                align="center"
                                id="tdDataNotFound"
                              >
                                {notData || "No data to display."}
                              </TableCell>
                            </TableRow>
                          ) : (
                            rateEstimateData.map(
                              (row: RateEstimateChargeDTO, index: any) => {
                                return (
                                  <TableRow
                                    hover
                                    key={row.chargeCode}
                                    id="trCharges"
                                  >
                                    <TableCell
                                      style={{ minWidth: 100 }}
                                      id="tdChargeCode"
                                    >
                                      {row.chargeCode}
                                    </TableCell>
                                    <TableCell
                                      style={{ minWidth: 154 }}
                                      id="tdName"
                                    >
                                      {row.name}
                                    </TableCell>
                                    <TableCell
                                      style={{ minWidth: 225 }}
                                      id="tdDescription"
                                    >
                                      {row.description}
                                    </TableCell>
                                    <TableCell
                                      style={{ minWidth: 120 }}
                                      id="tdAmount"
                                    >
                                      {row.amount}
                                    </TableCell>
                                    <TableCell
                                      style={{ minWidth: 70 }}
                                      id="tdTaxAmount"
                                    >
                                      {row.taxAmount}
                                    </TableCell>
                                    <TableCell
                                      style={{ minWidth: 70 }}
                                      id="tdNetAmount"
                                    >
                                      {row.netAmount}
                                    </TableCell>
                                    <TableCell
                                      style={{ minWidth: 70 }}
                                      id="tdFuelApply"
                                    >
                                      {
                                        <FormControlLabel
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            width: "100%",
                                          }}
                                          control={
                                            <Checkbox
                                              className="table_chkbx"
                                              checked={row.fuelApply}
                                              value={row.fuelApply}
                                              id="cbFuelApply"
                                            />
                                          }
                                          label={""}
                                        />
                                      }
                                    </TableCell>
                                    <TableCell
                                      style={{ minWidth: 70 }}
                                      id="tdTaxable"
                                    >
                                      <FormControlLabel
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          width: "100%",
                                        }}
                                        control={
                                          <Checkbox
                                            className="table_chkbx"
                                            checked={row.taxable}
                                            value={row.taxable}
                                            id="cbTaxable"
                                          />
                                        }
                                        label={""}
                                      />
                                    </TableCell>
                                    <TableCell
                                      style={{ minWidth: 70 }}
                                      id="tdDiscountable"
                                    >
                                      <FormControlLabel
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          width: "100%",
                                        }}
                                        control={
                                          <Checkbox
                                            className="table_chkbx"
                                            checked={row.discountable}
                                            value={row.discountable}
                                            id="cbDiscountable"
                                          />
                                        }
                                        label={""}
                                      />
                                    </TableCell>
                                    <TableCell
                                      style={{ minWidth: 62 }}
                                      id="tdSecurityApply"
                                    >
                                      {row.securityApply}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )
                          )}

                          {rateEstimateData && rateEstimateData.length > 0 && (
                            <>
                              <TableRow hover className="Rateestimate-total">
                                <TableCell
                                  style={{ minWidth: 100 }}
                                ></TableCell>
                                <TableCell
                                  style={{ minWidth: 154 }}
                                ></TableCell>
                                <TableCell
                                  style={{ minWidth: 225 }}
                                ></TableCell>
                                <TableCell
                                  className="Rate-total-amount"
                                  style={{ minWidth: 120 }}
                                >
                                  {totalEstimateAmount}
                                </TableCell>
                                <TableCell
                                  className="Rate-total-amount weight-600"
                                  style={{ minWidth: 70 }}
                                >
                                  {totalEstimateTaxAmount}
                                </TableCell>
                                <TableCell
                                  className="Rate-total-amount weight-600"
                                  style={{ minWidth: 70 }}
                                >
                                  {totalEstimateNetAmount}
                                </TableCell>
                                <TableCell style={{ minWidth: 70 }}></TableCell>
                                <TableCell style={{ minWidth: 70 }}></TableCell>
                                <TableCell style={{ minWidth: 70 }}></TableCell>
                                <TableCell style={{ minWidth: 62 }}></TableCell>
                              </TableRow>
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Box>
              </Box>
            </form>
          </FormikProvider>
        </div>
        <div className="container-fluid">
          <Box className="d-flex justify-content-end gap-2 w-100 Divider-top pt-16 pb-16">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="d-flex rounded me-2 rate_btn"
              endIcon={<img src={arrowicon} alt="icon right" />}
              onClick={handleSubmit}
              id="btnCalcRateEstimate"
            >
              CALCULATE RATE ESTIMATE
            </Button>
            {/* <LoadingButton
            variant="contained"
            color="primary"
            loadingPosition="end"
            className="d-flex rounded me-2"
            endIcon={<img src={arrowicon} alt="icon right" />}
            onClick={() => handleSubmit("submit")}
          >
            BOOK ORDER
          </LoadingButton> */}
          </Box>
        </div>
      </>
      <CodeDialog
        open={open}
        handleClose={handleClose}
        addressType="pickupAddress"
        onSelectAddress={onSelectAddress}
      />

      <RateEstimateTermsDialog
        open={openTermDialog}
        handleClose={() => setOpenTermDialog(false)}
      />
      <RateEstimateRouteDialog
        open={openRouteDialog}
        handleClose={() => setOpenRouteDialog(false)}
        formik={formik}
        handleCalculate={() => {
          formik.handleSubmit();
        }}
      />
    </div>
  );
};

export default RateEstimate;
