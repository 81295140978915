import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";
import { UnDetailsItemDto } from "../../../models/long-form-item-dto";

// Action Types for searchUNDetails
const SEARCHUN_DETAILS_REQUEST = "SEARCHUN_DETAILS_REQUEST";
const SEARCHUN_DETAILS_SUCCESS = "SEARCHUN_DETAILS_SUCCESS";
const SEARCHUN_DETAILS_FAILURE = "SEARCHUN_DETAILS_FAILURE";

// Action Creators for searchUNDetails
export const searchUNDetailsRequest = () => ({
  type: SEARCHUN_DETAILS_REQUEST,
});

export const searchUNDetailsSuccess = () => ({
  type: SEARCHUN_DETAILS_SUCCESS,
});

export const searchUNDetailsFailure = (error: string) => ({
  type: SEARCHUN_DETAILS_FAILURE,
  payload: error,
});

// Initial State for UnDetails
export interface SearchUNDetailsState {
  isAuthenticated: boolean;
  searchUNDetailsLoading: boolean;
  searchUNDetailsError: string | null;
}

const initialAuthState: SearchUNDetailsState = {
  isAuthenticated: false,
  searchUNDetailsLoading: false,
  searchUNDetailsError: null,
};

// Define the interface for the API response
interface searchUNDetailsResponse {
  success: boolean;
  message: string;
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
  totalPages: number;
  items: keyof UnDetailsItemDto;
  statusCode: string;
}

// Reducer for Authentication
const searchUNDetailsReducer = (
  state = initialAuthState,
  action: any
): SearchUNDetailsState => {
  switch (action.type) {
    case SEARCHUN_DETAILS_REQUEST:
      return { ...state, searchUNDetailsLoading: true, searchUNDetailsError: null };
    case SEARCHUN_DETAILS_SUCCESS:
      return { ...state, searchUNDetailsLoading: false, isAuthenticated: true };
    case SEARCHUN_DETAILS_FAILURE:
      return { ...state, searchUNDetailsLoading: false, searchUNDetailsError: action.payload };
    default:
      return state;
  }
};

// ThsearchUNk for UnDetails
export const searchUNDetails =
  (param: {
    companyID: number;
    unNumber: string;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(searchUNDetailsRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Lookup/SearchUnDetails`,
        param
      );
      // Assuming AccosearchUNt was successful if no error occurred
      dispatch(searchUNDetailsSuccess());
      const data: searchUNDetailsResponse = response.data;
      // Store token in localStorage
      if (response.status === 200 && data.success) {
        // Store token in localStorage
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(searchUNDetailsFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(searchUNDetailsFailure(error.response.data.message));
      } else {
        dispatch(searchUNDetailsFailure(error.message));
      }
    }
  };

export default searchUNDetailsReducer;
