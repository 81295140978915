import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../../config/axios-interceptor";
import { API_BASE_URL } from "../../../config/constants";

// Action Types for Commodity
const COMMODITY_REQUEST = "COMMODITY_REQUEST";
const COMMODITY_SUCCESS = "COMMODITY_SUCCESS";
const COMMODITY_FAILURE = "COMMODITY_FAILURE";

// Action Creators for Commodity
export const commodityRequest = () => ({
  type: COMMODITY_REQUEST,
});

export const commoditySuccess = (data: any) => ({
  type: COMMODITY_SUCCESS,
  payload: data,
});

export const commodityFailure = (error: string) => ({
  type: COMMODITY_FAILURE,
  payload: error,
});

// Initial State for Commodity
export interface CommodityState {
  isAuthenticated: boolean;
  commodityLoading: boolean;
  commodityError: string | null;
  commodityData: any;
}

const initialAuthState: CommodityState = {
  isAuthenticated: false,
  commodityLoading: false,
  commodityError: null,
  commodityData: [],
};

// Define the interface for the API response
interface CommodityResponse {
  success: boolean;
  message: string;
  data: {
    commodityGuid: string;
    companyId?: number;
    commodityName: string;
    isDG?: boolean;
    isRadioactive?: boolean;
    isPackagingType?: boolean;
    isTemperatureRange?: boolean;
    isDryIce?: boolean;
    insertUserGuid: string;
    insertDateTime: any;
    updateUserGuid: string;
    updateDateTime: any;
  };
  statusCode: string;
}

// Reducer for Authentication
const commodityReducer = (
  state = initialAuthState,
  action: any
): CommodityState => {
  switch (action.type) {
    case COMMODITY_REQUEST:
      return { ...state, commodityLoading: true, commodityError: null };
    case COMMODITY_SUCCESS:
      return {
        ...state,
        commodityLoading: false,
        isAuthenticated: true,
        commodityData: action.payload,
      };
    case COMMODITY_FAILURE:
      return {
        ...state,
        commodityLoading: false,
        commodityError: action.payload,
      };
    default:
      return state;
  }
};

// Thunk for Commodity
export const commodity = () => async (dispatch: Dispatch) => {
  dispatch(commodityRequest());
  try {
    const response = await axiosInstance.get(
      `${API_BASE_URL}/api/Lookup/GetCommodity`
    );
    // Assuming Account was successful if no error occurred

    const data: CommodityResponse = response.data;
    // Store token in localStorage
    if (response.status === 200 && data.success) {
      dispatch(commoditySuccess(response));
      // Store token in localStorage
      return { data };
    } else {
      // Handle different status codes if necessary
      dispatch(commodityFailure(data.message));
    }
    return response.data;
  } catch (error: any) {
    if (error.response.data.message) {
      dispatch(commodityFailure(error.response.data.message));
    } else {
      dispatch(commodityFailure(error.message));
    }
  }
};

export default commodityReducer;
