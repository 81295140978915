import { Dispatch } from "redux"; // Import Dispatch type

import { API_BASE_URL } from "../../config/constants";
import axiosInstance from "../../config/axios-without-loader";

// Action Types for Address
const MULTI_FILE_UPLOAD_REQUEST = "MULTI_FILE_UPLOAD_REQUEST";
const MULTI_FILE_UPLOAD_SUCCESS = "MULTI_FILE_UPLOAD_SUCCESS";
const MULTI_FILE_UPLOAD_FAILURE = "MULTI_FILE_UPLOAD_FAILURE";

// Action Creators for Address
export const multipleFileUploadRequest = () => ({
  type: MULTI_FILE_UPLOAD_REQUEST,
});

export const multipleFileUploadSuccess = () => ({
  type: MULTI_FILE_UPLOAD_SUCCESS,
});

export const multipleFileUploadFailure = (error: string) => ({
  type: MULTI_FILE_UPLOAD_FAILURE,
  payload: error,
});

// Initial State for Address
export interface MultipleFileUploadState {
  isAuthenticated: boolean;
  loading: boolean;
  fileUploadError: string | null;
}

const initialAuthState: MultipleFileUploadState = {
  isAuthenticated: false,
  loading: false,
  fileUploadError: null,
};

// Define the interface for the API response
interface MultipleFileUploadResponse {
  success: boolean;
  message: string;
  data: any;
  statusCode: string;
}

// Reducer for Authentication
const multipleFileUploadWithoutLoaderReducer = (
  state = initialAuthState,
  action: any
): MultipleFileUploadState => {
  switch (action.type) {
    case MULTI_FILE_UPLOAD_REQUEST:
      return { ...state, loading: true, fileUploadError: null };
    case MULTI_FILE_UPLOAD_SUCCESS:
      return { ...state, loading: false, isAuthenticated: true };
    case MULTI_FILE_UPLOAD_FAILURE:
      return { ...state, loading: false, fileUploadError: action.payload };
    default:
      return state;
  }
};

// Thunk for Address
export const multipleFileUploadWithoutLoader =
  (param: { files: File[]; shipmentGUID: string; insertUserID: number; documentTypeID?: any; }) =>
  async (dispatch: Dispatch) => {
    dispatch(multipleFileUploadRequest());
    try {
      const formData = new FormData();
      param.files.forEach((file) => {
        formData.append("Files", file);
      });
      formData.append("ShipmentGUID", param.shipmentGUID);
      formData.append("InsertUserID", param.insertUserID.toString());
      if(param.documentTypeID){
        formData.append("documentTypeID", param.documentTypeID.toString());
      }
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/MultipleUploadDocument`,
        formData,{
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(multipleFileUploadSuccess());
      const data: MultipleFileUploadResponse = response.data;
      if (response.status === 200 && data.success) {
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(multipleFileUploadFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(multipleFileUploadFailure(error.response.data.message));
      } else {
        dispatch(multipleFileUploadFailure(error.message));
      }
    }
  };

export default multipleFileUploadWithoutLoaderReducer;
