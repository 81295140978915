import { useState, useEffect } from "react";

const useLocalDateTime = (customTimeZone: string) => {
  const [localDateTime, setLocalDateTime] = useState("");
  const timeZoneMap: any = {
    "USA-ET": "America/New_York",
    "USA-CT": "America/Chicago",
    "USA-MT": "America/Denver",
    "USA-PT": "America/Los_Angeles",
    "US-HI": "Pacific/Honolulu",
    "US-AK": "America/Anchorage",
    "US-AZ": "America/Phoenix",
    IL: "Asia/Jerusalem",
    UTC: "UTC",
    GB: "Europe/London",
    "AU-ACT": "Australia/Sydney",
    "AU-NSW": "Australia/Sydney",
    "AU-QLD": "Australia/Brisbane",
    "AU-TAS": "Australia/Hobart",
    "AU-VIC": "Australia/Melbourne",
    "AU-NT": "Australia/Darwin",
    "AU-SA": "Australia/Adelaide",
    "AU-WA": "Australia/Perth",
    AU1: "Australia/Lord_Howe",
    AU3: "Australia/Sydney", // NSW(exc) not explicitly defined, assuming Sydney
    TW: "Asia/Taipei",
    HK: "Asia/Hong_Kong",
    TH: "Asia/Bangkok",
    MY: "Asia/Kuala_Lumpur",
    CH: "Europe/Zurich",
    VN: "Asia/Ho_Chi_Minh",
    CN: "Asia/Shanghai",
    IN: "Asia/Kolkata",
    NZ: "Pacific/Auckland",
    KR: "Asia/Seoul",
    JP: "Asia/Tokyo",
  };

  useEffect(() => {
    const getCurrentLocalDateTime = (customTimeZone: string) => {
      const timeZone = timeZoneMap[customTimeZone] || "UTC"; // Default to UTC if timezone is not found
      const now = new Date();
      const options: any = {
        timeZone: timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };

      // Format the date
      const formatter = new Intl.DateTimeFormat("en-US", options);
      // var t= new Date(localDateTime);
      const [
        { value: month },
        ,
        { value: day },
        ,
        { value: year },
        ,
        { value: hour },
        ,
        { value: minute },
      ] = formatter.formatToParts(now);
      // Construct and return the formatted date and time
      return `${year}-${month}-${day}T${hour}:${minute}`;
    };
    var dateTime = "";
    if (customTimeZone) {
      dateTime = getCurrentLocalDateTime(customTimeZone);
    }

    setLocalDateTime(dateTime);
  }, [customTimeZone]);

  return localDateTime;
};

export default useLocalDateTime;
