import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { EditOrderDto } from "../../../models/edit-active-order-dto";

interface PickupAddressProps {
  orderDetails: EditOrderDto;
}

const EditPickupAddress: React.FC<PickupAddressProps> = ({ orderDetails }) => {
  const [formData, setFormData] = useState({
    ...orderDetails,
  });

  useEffect(() => {
    if (orderDetails) {
      setFormData({
        ...orderDetails,
      });
    }
  }, [orderDetails]);

  return (
    <>
      <Box className="d-flex flex-column w-100 border rounded detail-info-section">
        <Typography variant="h6" className="Sub-header">
          Pickup Details
        </Typography>
        <Box className="d-flex flex-column gap-12">
          <Box className="d-flex gap-2">
            <TextField
              id="txtEoPickupCompanyName"
              size="small"
              variant="outlined"
              label="Company Name"
              className="w-100"
              value={formData.puCompanyName}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
          <TextField
           id="txtEoPickupAddress1"
            size="small"
            variant="outlined"
            label="Address Line 1"
            className="w-100"
            value={formData.puAddress1}
            InputProps={{
              readOnly: true,
              inputProps: { maxLength: 50 }
            }}
          />
          <TextField
            size="small"
            id="txtEoPickupAddress2"
            variant="outlined"
            label="Address Line 2  (Optional)"
            className="w-100"
            value={formData.puAddress2}
            InputProps={{
              readOnly: true,
              inputProps: { maxLength: 50 }
            }}
          />
          <TextField
            size="small"
            variant="outlined"
            id="txtEoPickupAddress3"
            label="Address Line 3 (Optional)"
            className="w-100"
            value={formData.puAddress3}
            InputProps={{
              readOnly: true,
              inputProps: { maxLength: 50 }
            }}
          />
          <Box className="d-flex gap-2">
            <TextField
              size="small"
              id="txtEoPickupCity"
              variant="outlined"
              label="City"
              className="w-100"
              value={formData.puCityName}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              size="small"
              id="txtEoPickupState"
              variant="outlined"
              label="State"
              className="w-100"
              value={formData.puStateProvID}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
          <Box className="d-flex gap-2">
            <TextField
              size="small"
              variant="outlined"
              id="txtEoPickupPostalCode"
              label="ZIP"
              className="w-100"
              value={formData.puPostalCode}
              InputProps={{
                readOnly: true,
              }}
            />

            <TextField
              variant="outlined"
              label="Country"
              size="small"
              id="txtEoPickupCountry"
              className="w-100"
              value={formData.puCountryID}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
          <Box className="d-flex gap-2">
            <TextField
              variant="outlined"
              label="SEE"
              size="small"
              className="w-100"
              id="txtEoPickupSee"
              value={formData.puPersonToSee}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
        </Box>
        <Box className="d-flex flex-column mt-3">
          <Typography variant="h6" className="Sub-header">
            Pickup Contact
          </Typography>
          <Box className="d-flex gap-12">
            <TextField
              size="small"
              variant="outlined"
              id="txtEoPickupPhoneNumber"
              label="Phone"
              className="w-100"
              value={formData.puPhoneNumber}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EditPickupAddress;
