import { Dispatch } from "redux"; // Import Dispatch type
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";
import {
  EditOrderDto,
  UpdateEditOrderDto,
} from "../../models/edit-active-order-dto";

// Action Types for Edit Order
const UPDATEEDITORDER_REQUEST = "UPDATEEDITORDER_REQUEST";
const UPDATEEDITORDER_SUCCESS = "UPDATEEDITORDER_SUCCESS";
const UPDATEEDITORDER_FAILURE = "UPDATEEDITORDER_FAILURE";

// Action Creators for Edit Order
export const updateEditOrderRequest = () => ({
  type: UPDATEEDITORDER_REQUEST,
});

export const updateEditOrderSuccess = () => ({
  type: UPDATEEDITORDER_SUCCESS,
});

export const updateEditOrderFailure = (error: string) => ({
  type: UPDATEEDITORDER_FAILURE,
  payload: error,
});

// Initial State for Edit Order
export interface UpdateEditOrderState {
  isAuthenticated: boolean;
  updateEditOrderLoading: boolean;
  updateEditOrderError: string | null;
}

const initialAuthState: UpdateEditOrderState = {
  isAuthenticated: false,
  updateEditOrderLoading: false,
  updateEditOrderError: null,
};

// Define the interface for the API response
interface UpdateEditOrderResponse {
  success: boolean;
  message: string;
  data: Array<EditOrderDto>;
  statusCode: string;
}

// Reducer for Authentication
const updateEditOrderReducer = (
  state = initialAuthState,
  action: any
): UpdateEditOrderState => {
  switch (action.type) {
    case UPDATEEDITORDER_REQUEST:
      return {
        ...state,
        updateEditOrderLoading: true,
        updateEditOrderError: null,
      };
    case UPDATEEDITORDER_SUCCESS:
      return { ...state, updateEditOrderLoading: false, isAuthenticated: true };
    case UPDATEEDITORDER_FAILURE:
      return {
        ...state,
        updateEditOrderLoading: false,
        updateEditOrderError: action.payload,
      };
    default:
      return state;
  }
};

// Thunk for Active Order
export const updateEditOrder =
  (param: UpdateEditOrderDto) => async (dispatch: Dispatch) => {
    dispatch(updateEditOrderRequest());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/UpdateEditOrder`,
        param
      );
      // Assuming  Active Order was successful if no error occurred
      dispatch(updateEditOrderSuccess());
      const data: UpdateEditOrderResponse = response.data;
      if (response.status === 200 && data.success) {
        return { data };
      } else {
        // Handle different status codes if necessary
        dispatch(updateEditOrderFailure(data.message));
      }
      return response.data;
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(updateEditOrderFailure(error.response.data.message));
        return error.response.data;
      } else {
        dispatch(updateEditOrderFailure(error.message));
      }
    }
  };

export default updateEditOrderReducer;
