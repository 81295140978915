import { Dispatch } from "redux"; // Import Dispatch type
import { API_BASE_URL } from "../../config/constants";
import axios from "axios";

// Action Types for CHANGE_PASSWORD
const CHANGE_PASSWORD_REQUEST = "auth/CHANGE_PASSWORD_REQUEST";
const CHANGE_PASSWORD_SUCCESS = "auth/CHANGE_PASSWORD_SUCCESS";
const CHANGE_PASSWORD_FAILURE = "auth/CHANGE_PASSWORD_FAILURE";

// Action Creators for changePassword
export const changePasswordRequest = () => ({
    type: CHANGE_PASSWORD_REQUEST,
});

export const changePasswordSuccess = () => ({
    type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFailure = (error: string) => ({
    type: CHANGE_PASSWORD_FAILURE,
    payload: error,
});

export interface AuthState {
    isAuthenticated: boolean;
    loading: boolean;
    error: string | null;
}

const initialAuthState: AuthState = {
    isAuthenticated: false,
    loading: false,
    error: null,
};

interface ChangePasswordResponse {
    success: boolean;
    message: string;
    data: string
    statusCode: string;
}

const authReducer = (state = initialAuthState, action: any): AuthState => {
    switch (action.type) {
        case CHANGE_PASSWORD_REQUEST:
            return { ...state, loading: true, error: null };
        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, loading: false, isAuthenticated: true };
        case CHANGE_PASSWORD_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export const fetchRecoveryData =
    (param: { GUID: string | null }) =>
        async (dispatch: Dispatch) => {
            dispatch(changePasswordRequest());
            try {
                const response = await axios.post(
                    `${API_BASE_URL}/api/Authentication/GetRecoveryData`,
                    param
                );
                // Assuming login was successful if no error occurred
                dispatch(changePasswordSuccess());
                const data: ChangePasswordResponse = response.data;
                return data;
            } catch (error: any) {
                if (error.response && error.response.data.message) {
                    dispatch(changePasswordFailure(error.response.data.message));
                } else {
                    dispatch(changePasswordFailure(error.message));
                }
            }
        };


export const handleSubmitPwd =
    (param: { GUID: string | null; Password: string }) =>
        async (dispatch: Dispatch) => {
            dispatch(changePasswordRequest());
            try {
                const response = await axios.post(
                    `${API_BASE_URL}/api/Authentication/ChangePassword`,
                    param
                );
                // Assuming login was successful if no error occurred
                dispatch(changePasswordSuccess());
                const data: ChangePasswordResponse = response.data;
                return data;
            } catch (error: any) {
                if (error.response && error.response.data.message) {
                    dispatch(changePasswordFailure(error.message));
                    return error.response.data;
                } else {
                    dispatch(changePasswordFailure(error.message));
                }
            }
        };
export default authReducer;