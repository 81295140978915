import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { DefaultData } from "./long-form-order.reducer";


const useDefaultDataLogic = () => {
  const dispatch = useDispatch();
  const { LongFormLoading, LongFormError } = useSelector((state: RootState) => state.longForm);

  useEffect(() => {
    if (LongFormError) {
      // dispatch(showNotification('Active Order failed: ' + error, 'error'));
    }
  }, [LongFormError, dispatch]);

  const handleDefaultData = async (param: {
    companyID: number;
  }) => {
    try {
      const response = await dispatch(DefaultData(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return { LongFormLoading, LongFormError, defaultData: handleDefaultData };
};

export default useDefaultDataLogic;
