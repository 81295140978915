import { Dispatch } from "redux"; // Import Dispatch type
import { ImportOrderReqItemDto } from "../../models/import-order-item-dto";
import { API_BASE_URL } from "../../config/constants";
import axios from "axios";
import axiosInstance from "../../config/axios-interceptor";

// Action Types for  Import Order
const IMPORT_ORDERS_REQUEST = "IMPORT_ORDERS_REQUEST";
const IMPORT_ORDERS_SUCCESS = "IMPORT_ORDERS_SUCCESS";
const IMPORT_ORDERS_FAILURE = "IMPORT_ORDERS_FAILURE";

// Action Creators for  Import Order
export const importOrdersRequest = () => ({
  type: IMPORT_ORDERS_REQUEST,
});

export const importOrdersSuccess = () => ({
  type: IMPORT_ORDERS_SUCCESS,
});

export const importOrdersFailure = (error: string) => ({
  type: IMPORT_ORDERS_FAILURE,
  payload: error,
});

// Initial State for  Import Order
export interface importOrdersState {
  isAuthenticated: boolean;
  importOrderLoading: boolean;
  importOrderError: string | null;
}

const initialAuthState: importOrdersState = {
  isAuthenticated: false,
  importOrderLoading: false,
  importOrderError: null,
};

// Define the interface for the API response
interface importOrdersResponse {
  success: boolean;
  message: string;
  data: Array<ImportOrderReqItemDto>;
  statusCode: string;
}

// Reducer for Authentication
const importOrdersReducer = (
  state = initialAuthState,
  action: any
): importOrdersState => {
  switch (action.type) {
    case IMPORT_ORDERS_REQUEST:
      return { ...state, importOrderLoading: true, importOrderError: null };
    case IMPORT_ORDERS_SUCCESS:
      return { ...state, importOrderLoading: false, isAuthenticated: true };
    case IMPORT_ORDERS_FAILURE:
      return {
        ...state,
        importOrderLoading: false,
        importOrderError: action.payload,
      };
    default:
      return state;
  }
};

// Thunk for Import Order
export const importOrders =
  (param: {
    CompanyID?: number | null;
    UserId?: number | null;
    UserGUID?: string | null;
    MainFile?: File | undefined;
    File?: string | null;
    FileName?: string | null;
    FileExt?: string | null;
    FilePath?: string | null;
    ImportRunGUID?: string | null;
    SizeUOM?: string;
    WeightUOM?: string;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(importOrdersRequest());

    // Create a new FormData object
    const formData = new FormData();

    // Append each parameter to the form data
    if (param.CompanyID !== undefined && param.CompanyID !== null) {
      formData.append("CompanyId", param.CompanyID.toString());
    } else {
      formData.append("CompanyId", "");
    }
    if (param.UserId !== undefined && param.UserId !== null) {
      formData.append("UserId", param.UserId.toString());
    } else {
      formData.append("UserId", "");
    }
    if (param.UserGUID) {
      formData.append("UserGUID", param.UserGUID);
    } else {
      formData.append("UserGUID", "");
    }
    if (param.MainFile) {
      formData.append("MainFile", param.MainFile);
    } else {
      formData.append("MainFile", "");
    }
    if (param.File) {
      formData.append("File", param.File);
    } else {
      formData.append("File", "");
    }
    if (param.FileName) {
      formData.append("FileName", param.FileName);
    } else {
      formData.append("FileName", "");
    }
    if (param.FileExt) {
      formData.append("FileExt", param.FileExt);
    } else {
      formData.append("FileExt", "");
    }
    if (param.FilePath) {
      formData.append("FilePath", param.FilePath);
    } else {
      formData.append("FilePath", "");
    }
    if (param.ImportRunGUID) {
      formData.append("ImportRunGUID", param.ImportRunGUID);
    } else {
      formData.append("ImportRunGUID", "");
    }
    if (param.SizeUOM) {
      formData.append("SizeUOM", param.SizeUOM);
    } else {
      formData.append("SizeUOM", "");
    }
    if (param.WeightUOM) {
      formData.append("WeightUOM", param.WeightUOM);
    } else {
      formData.append("WeightUOM", "");
    }

    try {
      const response = await axiosInstance.post(
        `${API_BASE_URL}/api/Orders/ImportOrder`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      dispatch(importOrdersSuccess());
      const data: importOrdersResponse = response.data;
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return error.response.data;
        dispatch(importOrdersFailure(error.response.data.message));
      } else {
        dispatch(importOrdersFailure(error.message));
      }
    }
  };

export default importOrdersReducer;
