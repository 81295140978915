import React, { useEffect, useState } from "react";
import { Container, Box, Typography, Button } from "@mui/material";
import logo from "../../assets/images/logo/mxweb-logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useVerifyUserLogic from "./verify-user.logic";
import Loader from "../../shared/util/loader";

const ConfirmSignUp: React.FC = () => {
  const [fromEmail, setFromEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { verifyUserError, verifyUser } = useVerifyUserLogic();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const userGUID = urlParams.get("UserGUID");

    if (userGUID) {
      setFromEmail(true);
      verifyUserData(userGUID);
    }
  }, []);

  const verifyUserData = async (userGUID: string) => {
    try {
      setIsLoading(true);
      const param = {
        userGUID: userGUID,
      };
      const response = await verifyUser(param);
      if (response.data && response.data.data) {
        if (response.data.success) {
          setIsLoading(false);
        }
      }
    } catch (err) {}
  };

  const navigate = useNavigate();
  const handlePrivacyStatementClick = () => {
    navigate("/privacy-statement");
  };
  const handleSupportSignIn = () => {
    navigate("/support-sign-in");
  };

  return (
    <div className="container-fluid active-order search-order-panel">
      {isLoading ? (
        <Box className="d-flex justify-content-center align-items-center vh-100">
          <Loader />
        </Box>
      ) : (
        <Container className="login-section" maxWidth="sm" sx={{ mt: 4 }}>
          {/* Logo */}
          <Box className="lognin-image">
            <img src={logo} alt="Company Logo" style={{ maxWidth: "100%" }} />
          </Box>

          <Box my={1}>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              textAlign="start"
              className="sign-in-title"
            >
              New User Request
            </Typography>
          </Box>
          <div className="form-border">
            <Box className="alert alert-success d-flex align-items-center mt-0 p-2 pe-1">
              <CheckCircleOutlineIcon sx={{ mr: 1, color: "success.main" }} />
              {fromEmail ? (
                <Typography>
                  Thank you! Your user request has been confirmed. Click the
                  button below to continue to sign in.
                </Typography>
              ) : (
                <Typography>
                  Your user ID will be authorized within 24 hours. You will
                  receive notification once the authorization is completed.
                </Typography>
              )}
            </Box>
            <Box
              sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}
              className="d-flex justify-content-between"
            >
              <Button
                variant="outlined"
                className="d-flex w-100 rounded fw-bold me-3"
                startIcon={<ChevronLeftIcon />}
                onClick={() => navigate("/")}
                style={{ whiteSpace: "nowrap" }}
              >
                TO SIGN IN
              </Button>
              {!fromEmail && (
                <Button
                  variant="contained"
                  className="d-flex w-100 rounded"
                  onClick={() => navigate("/sign-up")}
                  style={{ whiteSpace: "nowrap" }}
                >
                  NEW REQUEST
                </Button>
              )}
            </Box>
          </div>
          <Box>
            <Box
              className="Privacy_info"
              maxWidth="sm"
              sx={{ display: "flex", gap: "64px", width: "100%" }}
            >
              <Button className="text" onClick={handlePrivacyStatementClick}>
                Privacy Statement
              </Button>
              <Button className="text-custom" onClick={handleSupportSignIn}>
                Support login
              </Button>
            </Box>
          </Box>
        </Container>
      )}
    </div>
  );
};

export default ConfirmSignUp;
