import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { address } from "./address.redux";


type AddressParams = {
  addressCode: string;
  companyName: string;
  cityName: string;
  stateProvID: string;
  countryID: string;
  postalCode: string;
  userGroupGUID: string;
};
const useAddressLogic = () => {
  const dispatch = useDispatch();
  const { loading, error, addressData } = useSelector((state: RootState) => state.address);

  useEffect(() => {
    if (error) {
      // dispatch(showNotification('Address failed: ' + error, 'error'));
    }
  }, [error, dispatch]);

  const areParamsEqual = (params1: AddressParams, params2: AddressParams): boolean => {
    return Object.keys(params1).every((key) => {
      const keyTyped = key as keyof AddressParams; 
      return params1[keyTyped] === params2[keyTyped];
    });
  };

// Function to check if the time difference is less than 5 minutes
const isApiExecuteAtWithinFiveMinutes = (apiExecuteAt: Date): boolean => {
  const now = new Date();
  const fiveMinutesInMs = 5 * 60 * 1000;
  return now.getTime() - new Date(apiExecuteAt).getTime() < fiveMinutesInMs;
};

  const handleAddress = async (param: {
    addressCode: string;
    companyName: string;
    cityName: string;
    stateProvID: string;
    countryID:string;
    postalCode:string;
    userGroupGUID:string;
  }) => {
    try {
      
      //Use the redux data if previous request params and current request params is same and request made within 5 minute
      if (addressData && addressData?.data?.data.length > 0 && areParamsEqual(addressData?.apiParams, param) && isApiExecuteAtWithinFiveMinutes(addressData?.apiExecuteAt)) {
        return addressData;
      } else {
        const response = await dispatch(address(param) as any);
        return response;
      }
     
    } catch (err) {
      throw err;
    }
  };

  return { loading, error, address: handleAddress };
};

export default useAddressLogic;
