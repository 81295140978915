import React, { useState } from "react";
import logo from "../../assets/images/logo/mxweb-logo.svg";
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../config/constants";
import { CheckCircleOutline } from "@mui/icons-material";
import useForgotPasswordLogic from "./forgot-password.logic";

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [instructions, setInstructions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [guid, setGuid] = useState("");
  let { error, handleEmailApi } = useForgotPasswordLogic();

  const handleBackToSignIn = () => {
    navigate("/");
  };

  const handlePrivacyStatementClick = () => {
    navigate("/privacy-statement");
  };

  const handleSupportSignIn = () => {
    navigate("/support-sign-in");
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);
    if (value && !/\S+@\S+\.\S+/.test(value)) {
      setEmailError(true);
      setEmailHelperText("Please specify a valid email address");
    } else {
      setEmailError(false);
      setEmailHelperText("");
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!email) {
      setEmailError(true);
      setEmailHelperText("Please specify a valid email address");
    } else {
      if (!/\S+@\S+\.\S+/.test(email)) {
        setEmailHelperText("Please specify a valid email address");
      } else {
        try {
          setLoading(true);
          const response = await handleEmailApi({ emailAddress: email });
          if (response.success) {
            setInstructions(true);
          } else {
            navigate("/error-page");
          }
        } catch {
          navigate("/error-page");
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <div
      className="mlr-16"
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Container className="login-section" maxWidth="sm" sx={{ mt: 4 }}>
        {/* Logo */}
        <Box className="lognin-image">
          <img src={logo} alt="Company Logo" style={{ maxWidth: "100%" }} />
        </Box>

        {/* Forgot Password title */}
        <Box className="Forgot-password-form-border">
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            textAlign="start"
            className="sign-in-title"
          >
            Forgot your password?
          </Typography>

          {/* Instructions */}
          <div className="p16">
            <Typography
              variant="h5"
              component="h1"
              gutterBottom
              textAlign="start"
              className="Forgot-password-statement"
            >
              To recover your sign in credentials, enter the email address
              associated with your account and select “Recover Password”
            </Typography>
            {/* Instructions after submit */}
            {instructions && (
              <Stack sx={{ width: "100%" }} spacing={2} className="mt-3">
                <Alert
                  severity="success"
                  className="alert-submit"
                  icon={<CheckCircleOutline style={{ color: "#006939" }} />}
                  style={{ backgroundColor: "#E0F0EA" }}
                  id="txtFpSuccess"
                >
                  Instructions regarding resetting your password have been sent
                  to your email address. If no email is received within ten
                  minutes, check that the submitted address is correct.
                </Alert>
              </Stack>
            )}

            <div>
              <form className="forgot-field" onSubmit={handleSubmit}>
                {/* Email input */}
                <TextField
                  id="txtFpEmail"
                  name="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleEmailChange}
                  error={emailError}
                  helperText={emailHelperText}
                />
                <Box
                  className=""
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={2}
                ></Box>
                {/* Recover button */}
                <LoadingButton
                  className="forgot-password"
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  loading={loading}
                  id="btnFpRecoverPassword"
                >
                  Recover Password
                </LoadingButton>
              </form>
              <div className="alert">
                <Stack sx={{ width: "100%" }}>
                  <Alert severity="info" className="alert-info" id="txtFpSecurityNoti">
                    <AlertTitle className="alert-title">
                      Security Notification
                    </AlertTitle>
                    This site contains proprietary and confidential information
                    which is the sole property of the company. Access to this
                    site is logged and monitored for unauthorized usage.
                    Information regarding your location and computer is being
                    recorded and will be used if necessary for any prosecution
                    of illegal activity or unauthorized access.
                  </Alert>
                </Stack>
              </div>
            </div>
            <Button className="Back-btn" onClick={handleBackToSignIn} id="btnFpBackToSignIn">
              <KeyboardArrowLeftIcon className="back-icon" />
              <Typography className="back-icon-text">
                BACK TO SIGN IN PAGE
              </Typography>
            </Button>
          </div>
        </Box>
        {/* Other links */}
        <Box>
          <Box
            className="Privacy_info forgot_btn"
            maxWidth="sm"
            sx={{
              display: "flex",
              gap: "64px",
              width: "100%",
              marginTop: "auto",
            }}
          >
            <Button className="text" onClick={handlePrivacyStatementClick} id="btnFpPrivacyStatement">
              Privacy Statement
            </Button>
            <Button className="text-custom" onClick={handleSupportSignIn} id="btnFpSupportLogin">
              Support login
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default ForgotPassword;
