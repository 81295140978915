// src/reducers/ontimeReducer.ts
import { Dispatch } from "redux";
import { OntimePerformanceDto, OntimePerformanceRequestDto } from "../../models/ontime-perfomance-dto";
import axiosInstance from "../../config/axios-interceptor";
import { API_BASE_URL } from "../../config/constants";

// Action Types
const ONTIME_PERFORMANCE_REQUEST = "ONTIME_PERFORMANCE_REQUEST";
const ONTIME_PERFORMANCE_SUCCESS = "ONTIME_PERFORMANCE_SUCCESS";
const ONTIME_PERFORMANCE_FAILURE = "ONTIME_PERFORMANCE_FAILURE";

// Action Creators
export const ontimePerformanceRequest = () => ({
  type: ONTIME_PERFORMANCE_REQUEST,
});

export const ontimePerformanceSuccess = (performanceData:  OntimePerformanceDto[]) => ({
  type: ONTIME_PERFORMANCE_SUCCESS,
  payload: performanceData,
});

export const ontimePerformanceFailure = (error: string) => ({
  type: ONTIME_PERFORMANCE_FAILURE,
  payload: error,
});

// Initial State
export interface OntimePerformanceState {
  loading: boolean;
  performanceData: OntimePerformanceDto[];
  isAuthenticated: boolean;
  ontimeError: string | null;
}

const initialState: OntimePerformanceState = {
  loading: false,
  isAuthenticated: false,
  ontimeError: null,
  performanceData: []
};

// Define the interface for the API response
interface OntimePerformanceResponse {
  success: boolean;
  message: string;
  totalCount: number;
  pageCount: number;
  data: {
    shipmentGUID: string;
  shipmentNumber: number;
  quotedDeliveryDateTime: string;
  actualDeliveryDateTime: string;
  signature: string;
  accountNumber: string | undefined;
  accountName: string;
  serviceCode: string;
  serviceName: string;
  pickupAirportID: string;
  deliveryAirportID: string;
  orderState: string;
  logoUrl: string;
  qualityControlID: number;
  qualityControlCode: string;
  qualityControlName: string;
  qualityControlIsControllable: boolean;
  isLate: boolean;
  countIsLate: number;
  countControllable: number;
  countUncontrollable: number;
  countUnknown: number;
  deliveryPostalCode: string;
  startDate?: string | null;
  endDate?: string | null;
  };
  statusCode: string;
}

// Reducer
const ontimePerformanceReducer = (
  state = initialState,
  action: any
): OntimePerformanceState => {
  switch (action.type) {
    case ONTIME_PERFORMANCE_REQUEST:
      return { ...state, loading: true, ontimeError: null };
    case ONTIME_PERFORMANCE_SUCCESS:
      return { ...state, loading: false, isAuthenticated: true , performanceData: action.payload };
    case ONTIME_PERFORMANCE_FAILURE:
      return { ...state, loading: false, ontimeError: action.payload };
    default:
      return state;
  }
};

export default ontimePerformanceReducer;

// Thunk
export const fetchOntimePerformanceData = (params: OntimePerformanceRequestDto) => async (dispatch: Dispatch) => {
  dispatch(ontimePerformanceRequest());
  try {
    const response = await axiosInstance.post(`${API_BASE_URL}/api/Report/OntimePerformanceReport`, params);
    const data: OntimePerformanceResponse = response.data;
    if (response.status === 200 && data.success) {
      dispatch(ontimePerformanceSuccess(response.data.data)); // Ensure data.data is an array
      return data.data; // Return the data if needed
    } else {
      dispatch(ontimePerformanceFailure(data.message));
      throw new Error(data.message);
    }
  } catch (error: any) {
    dispatch(ontimePerformanceFailure(error.message || "Error occurred"));
    throw error;
  }
};

// Action Types
const EXPORT_ONTIME_PERFORMANCE_REQUEST = "EXPORT_ONTIME_PERFORMANCE_REQUEST";
const EXPORT_ONTIME_PERFORMANCE_SUCCESS = "EXPORT_ONTIME_PERFORMANCE_SUCCESS";
const EXPORT_ONTIME_PERFORMANCE_FAILURE = "EXPORT_ONTIME_PERFORMANCE_FAILURE";

// Action Creators
export const exportOntimePerformanceRequest = () => ({
  type: EXPORT_ONTIME_PERFORMANCE_REQUEST,
});

export const exportOntimePerformanceSuccess = () => ({
  type: EXPORT_ONTIME_PERFORMANCE_SUCCESS,
});

export const exportOntimePerformanceFailure = (error: string) => ({
  type: EXPORT_ONTIME_PERFORMANCE_FAILURE,
  payload: error,
});

export const exportOntimePerformanceData = (params: OntimePerformanceRequestDto, exportType: "PDF" | "XLS") => async (dispatch: Dispatch) => {
  dispatch(exportOntimePerformanceRequest());
  try {
    const response = await axiosInstance.post(`${API_BASE_URL}/api/Report/ExportOntimeReport`, { ...params, ExportType: exportType }, {
      responseType: 'blob' // Important to handle the response as a binary file
    });

    if (response.status === 200) {
      dispatch(exportOntimePerformanceSuccess());

      // Create a URL for the blob and trigger the download
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `ontime_performance.${exportType.toLowerCase()}`;
      link.click();
    } else {
      dispatch(exportOntimePerformanceFailure('Failed to export the report'));
    }
  } catch (error: any) {
    dispatch(exportOntimePerformanceFailure(error.message || "Error occurred"));
  }
};


