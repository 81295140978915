import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import { exportInvoice, invoicesData } from "./invoices.reducer";
import { InvoiceRequestDto } from "../../models/invoices-dto";

const useInvoicesLogic = () => {
  const dispatch = useDispatch();
  const { InvoiceDataError, InvoiceDataLoading } = useSelector(
    (state: RootState) => state.invoices
  );

  const handleInvoiceData = async (param: InvoiceRequestDto) => {
    try {
      const response = await dispatch(invoicesData(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };
  const handleExportInvoice = async (param: any) => {
    try {
      const response = await dispatch(exportInvoice(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    InvoiceDataError,
    InvoiceDataLoading,
    exportInvoice: handleExportInvoice,
    invoicesData: handleInvoiceData,
  };
};

export default useInvoicesLogic;
