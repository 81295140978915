import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { formatDate } from "../../util/date-utils";
import { formatDateTZ } from "../../util/date-tz-utils";
import {
  formatPickupLocation,
  formatPickupFullLocation,
} from "../../util/format-pickup-location";
import {
  formatDeliveryLocation,
  formatDeliveryFullLocation,
} from "../../util/format-delivery-location";

const RenderCellData = ({ columnId, row }: any) => {
  switch (columnId) {
    case "shipmentNumber":
      return (
        <Link
          to={`/track-order?ShipmentGUID=${row.shipmentGUID}`}
          className="primary"
        >
          {row?.shipmentNumber}
        </Link>
      );
    case "orderDateTime":
      return formatDate(row.orderDateTime);
    case "trackingNumber":
      return row.trackingNumber;
    case "pickupAddress":
      return (
        <>
          <Box
            dangerouslySetInnerHTML={{ __html: formatPickupLocation(row) }}
          ></Box>
          {row?.pickupPostalCode}
        </>
      );
    case "deliveryAddress":
      return (
        <>
          <Box
            dangerouslySetInnerHTML={{ __html: formatDeliveryLocation(row) }}
          ></Box>
          {row?.deliveryPostalCode}
        </>
      );
    case "reference":
      return row.reference;
    case "weight":
      return row.weight ? row.weight.toFixed(1) : "";
    case "status":
      return <Typography className="status_lbl">{row.status}</Typography>;
    case "podSignature":
      return row.podSignature;
    case "podDateTime":
      return (
        <Box className="weight-500">
          {row.podDateTime ? formatDate(row.podDateTime) : ""}
          <Box className="pod_datetime">
            {row.podDateTime ? formatDateTZ(row.podDateTime) : ""}
          </Box>
        </Box>
      );
    case "accountNumber":
      return row.accountNumber;
    case "officeCode":
      return row.officeCode;
    case "reference2":
      return row.reference2;
    case "reference3":
      return row.reference3;
    case "pieces":
      return row.pieces;
    case "weightUOM":
      return row.weightUOM;
    case "quotedDeliveryDateTime":
      return formatDate(row.quotedDeliveryDateTime);
    case "distanceUOM":
      return row.distanceUOM;
    case "pickupAttempts":
      return row.pickupAttemptCount;
    case "deliveryAttempts":
      return row.deliveryAttemptCount;
    case "pickupWaitingTime":
      return row.pickupWaitingTime;
    case "deliveryWaitingTime":
      return row.deliveryWaitingTime;
    case "pickupDistance":
      return row.pickupDistance;
    case "deliveryDistance":
      return row.deliveryDistance;
    case "pickupAddressCode":
      return row.pickupAddressCode;
    case "deliveryAddressCode":
      return row.deliveryAddressCode;
    case "contents":
      return row.contents;
    case "totalCharges":
      return row.totalCharges;
    case "baseCharge":
      return row.baseCharge;
    case "weightCharge":
      return row.weightCharge;
    case "fuelCharge":
      return row.fuelCharge;
    case "securityCharge":
      return row.securityCharge;
    case "pickupMileageCharge":
      return row.pickupMileageCharge;
    case "pickupAttemptCharge":
      return row.pickupAttemptCharge;
    case "pickupWaitingCharge":
      return row.pickupWaitingCharge;
    case "pickupWeekendCharge":
      return row.pickupWeekendCharge;
    case "deliveryMileageCharge":
      return row.deliveryMileageCharge;
    case "deliveryAttemptCharge":
      return row.deliveryAttemptCharge;
    case "deliveryWaitingCharge":
      return row.deliveryWaitingCharge;
    case "deliveryWeekendCharge":
      return row.deliveryWeekendCharge;
    case "otherCharge":
      return row.otherCharge;
    case "holdAndDeliver":
      return row.holdAndDeliver;
    case "orderByName":
      return row.orderByName;
    case "pickupPersonToSee":
      return row.pickupPersonToSee;
    case "deliveryAttention":
      return row.deliveryAttention;
    case "reference4":
      return row.reference4;
    case "serviceID":
      return row.serviceID;
    case "securityScreeningCharge":
      return row.securityScreeningCharge;
    case "pickupAddressFull":
      return (
        <Box
          dangerouslySetInnerHTML={{ __html: formatPickupFullLocation(row) }}
        ></Box>
      );
    case "deliveryAddressFull":
      return (
        <Box
          dangerouslySetInnerHTML={{ __html: formatDeliveryFullLocation(row) }}
        ></Box>
      );
    case "pickupPostalCode":
      return row.pickupPostalCode;
    case "deliveryPostalCode":
      return row.deliveryPostalCode;
    case "pickupAirportID":
      return row.pickupAirportID;
    case "deliveryAirportID":
      return row.deliveryAirportID;
    case "referenceValue_Type4":
      return row.referenceValue_Type4;
    case "pickupCompanyName":
      return row.pickupCompanyName;
    case "pickupAddress1":
      return row.pickupAddress1;
    case "pickupAddress2":
      return row.pickupAddress2;
    case "pickupCityName":
      return row.pickupCityName;
    case "pickupStateProvID":
      return row.pickupStateProvID;
    case "pickupPostalCode":
      return row.pickupPostalCode;
    case "pickupCountryID":
      return row.pickupCountryID;
    case "deliveryCompanyName":
      return row.deliveryCompanyName;
    case "deliveryAddress1":
      return row.deliveryAddress1;
    case "deliveryAddress2":
      return row.deliveryAddress2;
    case "deliveryAddress3":
      return row.deliveryAddress3;
    case "deliveryCityName":
      return row.deliveryCityName;
    case "deliveryStateProvID":
      return row.deliveryStateProvID;
    case "deliveryPostalCode":
      return row.deliveryPostalCode;
    case "reference5":
      return row.reference5;
    case "deliveryCountryID":
      return row.deliveryCountryID;
    case "totalDistance":
      return row.totalDistance;
    case "updatedPickupDateTime":
      return row.updatedPickupDateTime;
    case "actualPickupArrival":
      return row.actualPickupArrival;
    case "routing":
      return row.routing;
    case "invoiceList":
      return row.invoiceList;
    case "reference6":
      return row.reference6;
    case "reference7":
      return row.reference7;
    case "reference8":
      return row.reference8;
    case "reference9":
      return row.reference9;
    case "pickupEmailAddress":
      return row.pickupEmailAddress;
    case "pickupPhoneNumber":
      return row.pickupPhoneNumber;
    case "deliveryEmailAddress":
      return row.deliveryEmailAddress;
    case "deliveryPhoneNumber":
      return row.deliveryPhoneNumber;
    case "pickupInstructions":
      return row.pickupInstructions;
    case "deliveryInstructions":
      return row.deliveryInstructions;
    case "orderSource":
      return row.orderSource;
    default:
      return null;
  }
};

const renderField = (label: string, value: any) => (
  <Typography variant="body1" className="Field_info">
    <span className="field_label">{label}</span>{" "}
    <span className="Field_value">{value}</span>
  </Typography>
);

export const RenderMobileCellData = ({ columnId, row }: any) => {
  switch (columnId) {
    case "shipmentNumber":
      return (
        <Typography variant="h6" className="Order-head Field_info">
          <span className="field_label">Order #</span>{" "}
          <span className="Order_id Field_value">
            <Link
              to={`/track-order?ShipmentGUID=${row?.shipmentGUID}`}
              className="primary"
            >
              {row?.shipmentNumber}
            </Link>
          </span>
        </Typography>
      );
    case "orderDateTime":
      return (
        <>
          <Typography variant="body1" className="Field_info">
            <span className="field_label">Placed</span>{" "}
            <span className="Field_value">{formatDate(row.orderDateTime)}</span>
          </Typography>
        </>
      );
    case "trackingNumber":
      return (
        <>
          <Typography variant="body1" className="Field_info">
            <span className="field_label">Track #</span>{" "}
            <span className="Field_value">{row.trackingNumber}</span>
          </Typography>
        </>
      );
    case "pickupAddress":
      return (
        <Typography variant="body1" className="Field_info">
          <span className="field_label"> Pickup </span>
          <span className="Field_value">
            <Box
              dangerouslySetInnerHTML={{
                __html: formatPickupLocation(row),
              }}
            ></Box>
            {row?.pickupPostalCode}
          </span>
        </Typography>
      );
    case "deliveryAddress":
      return (
        <Typography variant="body1" className="Field_info">
          <span className="field_label">Delivery </span>
          <span className="Field_value">
            <Box
              dangerouslySetInnerHTML={{
                __html: formatDeliveryLocation(row),
              }}
            ></Box>
            {row?.deliveryPostalCode}
          </span>
        </Typography>
      );
    case "reference":
      return (
        <>
          {" "}
          <Typography variant="body1" className="Field_info">
            <span className="field_label">REF</span>{" "}
            <span className="Field_value">{row.reference}</span>
          </Typography>
        </>
      );
    case "weight":
      return (
        <>
          <Typography variant="body1" className="Field_info">
            <span className="field_label">WT</span>{" "}
            <span className="Field_value">
              {row.weight ? row.weight.toFixed(1) : ""}
            </span>
          </Typography>
        </>
      );
    case "status":
      return (
        <>
          <Typography variant="body1" className="Field_info">
            <span className="field_label">Status</span>
            <span className="Field_value">
              <i>{row.status}</i>
            </span>
          </Typography>
        </>
      );
    case "podSignature":
      return (
        <Typography variant="body1" className="Field_info">
          <span className="field_label">SIGNED</span>{" "}
          <span className="Field_value">{row?.podSignature}</span>
        </Typography>
      );
    case "podDateTime":
      return (
        <Typography variant="body1" className="Field_info">
          <span className="field_label">DEL. DATE </span>
          <span className="Field_value">
            <Box className="weight-500">
              {row.podDateTime ? formatDate(row.podDateTime) : ""}
              <Box className="pod_datetime">
                {row.podDateTime ? formatDateTZ(row.podDateTime) : ""}
              </Box>
            </Box>
          </span>
        </Typography>
      );
    case "updatedDeliveryDateTime":
      return (
        <Typography variant="body1" className="Field_info">
          <span className="field_label">EST. DELIVERY</span>
          <span className="Field_value">
            {" "}
            {row.updatedDeliveryDateTime
              ? formatDateTZ(row.updatedDeliveryDateTime)
              : row.estimatedDeliveryDateTime
              ? formatDateTZ(row.estimatedDeliveryDateTime)
              : "N/A"}
          </span>
        </Typography>
      );
    case "accountNumber":
      return renderField("Acct#", row.accountNumber);
    case "officeCode":
      return renderField("Ofc", row.officeCode);
    case "reference2":
      return renderField("REF - #2", row.reference2);
    case "reference3":
      return renderField("REF - #3", row.reference3);
    case "pieces":
      return renderField("PCS", row.pieces);
    case "weightUOM":
      return renderField("Wt UOM", row.weightUOM);
    case "quotedDeliveryDateTime":
      return (
        <>
          <Typography variant="body1" className="Field_info">
            <span className="field_label">QDT</span>{" "}
            <span className="Field_value">
              {formatDate(row.quotedDeliveryDateTime)}
            </span>
          </Typography>
        </>
      );
    case "distanceUOM":
      return renderField("Dst UOM", row.distanceUOM);
    case "pickupAttempts":
      return renderField("Pu Att", row.pickupAttemptCount);
    case "deliveryAttempts":
      return renderField("DEL Att", row.deliveryAttemptCount);
    case "pickupWaitingTime":
      return renderField("Pu Wait", row.pickupWaitingTime);
    case "deliveryWaitingTime":
      return renderField("Del Wait", row.deliveryWaitingTime);
    case "pickupDistance":
      return renderField("Pu Dst", row.pickupDistance);
    case "deliveryDistance":
      return renderField("Del Dst", row.deliveryDistance);
    case "pickupAddressCode":
      return renderField("Pu Code", row.pickupAddressCode);
    case "deliveryAddressCode":
      return renderField("Del Code", row.deliveryAddressCode);
    case "contents":
      return renderField("Contents", row.contents);
    case "totalCharges":
      return renderField("$Charges", row.totalCharges);
    case "baseCharge":
      return renderField("$Base", row.baseCharge);
    case "weightCharge":
      return renderField("$Weight", row.weightCharge);
    case "fuelCharge":
      return renderField("$Fuel", row.fuelCharge);
    case "securityCharge":
      return renderField("$Security", row.securityCharge);
    case "pickupMileageCharge":
      return renderField("$Pu Dst", row.pickupMileageCharge);
    case "pickupAttemptCharge":
      return renderField("$Pu Att", row.pickupAttemptCharge);
    case "pickupWaitingCharge":
      return renderField("$Pu Wait", row.pickupWaitingCharge);
    case "pickupWeekendCharge":
      return renderField("$Pu WkEnd", row.pickupWeekendCharge);
    case "deliveryMileageCharge":
      return renderField("$Del Dst", row.deliveryMileageCharge);
    case "deliveryAttemptCharge":
      return renderField("$Del Att", row.deliveryAttemptCharge);
    case "deliveryWaitingCharge":
      return renderField("$Del Wait", row.deliveryWaitingCharge);
    case "deliveryWeekendCharge":
      return renderField("$Del WkEnd", row.deliveryWeekendCharge);
    case "otherCharge":
      return renderField("$Other", row.otherCharge);
    case "holdAndDeliver":
      return renderField("$Hold", row.holdAndDeliver);
    case "orderByName":
      return renderField("Caller", row.orderByName);
    case "pickupPersonToSee":
      return renderField("Pu Contact", row.pickupPersonToSee);
    case "deliveryAttention":
      return renderField("Del Contact", row.deliveryAttention);
    case "reference4":
      return renderField("REF - #4", row.reference4);
    case "serviceID":
      return renderField("Service", row.serviceID);
    case "securityScreeningCharge":
      return renderField("$Screening", row.securityScreeningCharge);
    case "pickupAddressFull":
      return (
        <Typography variant="body1" className="Field_info">
          <span className="field_label">Pickup Full </span>
          <span className="Field_value">
            <Box
              dangerouslySetInnerHTML={{
                __html: formatPickupFullLocation(row),
              }}
            ></Box>
          </span>
        </Typography>
      );

    case "deliveryAddressFull":
      return (
        <Typography variant="body1" className="Field_info">
          <span className="field_label">Deliver Full </span>
          <span className="Field_value">
            <Box
              dangerouslySetInnerHTML={{
                __html: formatPickupFullLocation(row),
              }}
            ></Box>
          </span>
        </Typography>
      );

    case "pickupPostalCode":
      return renderField("Pickup Zip", row.pickupPostalCode);
    case "deliveryPostalCode":
      return renderField("Deliver Zip", row.deliveryPostalCode);
    case "pickupAirportID":
      return renderField("Orig AP", row.pickupAirportID);
    case "deliveryAirportID":
      return renderField("Dest AP", row.deliveryAirportID);
    case "referenceValue_Type4":
      return renderField("REF - MISC", row.referenceValue_Type4);
    case "pickupCompanyName":
      return renderField("Pu - Company", row.pickupCompanyName);
    case "pickupAddress1":
      return renderField("Pu - Addr1", row.pickupAddress1);
    case "pickupAddress2":
      return renderField("Pu - Addr2", row.pickupAddress2);
    case "pickupCityName":
      return renderField("Pu - City", row.pickupCityName);
    case "pickupStateProvID":
      return renderField("Pu - State", row.pickupStateProvID);
    case "pickupPostalCode":
      return renderField("Pu - Postal", row.pickupPostalCode);
    case "pickupCountryID":
      return renderField("Pu - Country", row.pickupCountryID);
    case "deliveryCompanyName":
      return renderField("Del - Company", row.deliveryCompanyName);
    case "deliveryAddress1":
      return renderField("Del - Addr1", row.deliveryAddress1);
    case "deliveryAddress2":
      return renderField("Del - Addr2", row.deliveryAddress2);
    case "deliveryAddress3":
      return renderField("Del - Addr3", row.deliveryAddress3);
    case "deliveryCityName":
      return renderField("Del - City", row.deliveryCityName);
    case "deliveryStateProvID":
      return renderField("Del - State", row.deliveryStateProvID);
    case "deliveryPostalCode":
      return renderField("Del - Postal", row.deliveryPostalCode);
    case "reference5":
      return renderField("Ref #5", row.reference5);
    case "deliveryCountryID":
      return renderField("Del - Country", row.deliveryCountryID);
    case "totalDistance":
      return renderField("Total Distance", row.totalDistance);
    case "updatedPickupDateTime":
      return renderField("Pu Sched DT/TM", row.updatedPickupDateTime);
    case "actualPickupArrival":
      return renderField("Pu Actual DT/TM", row.actualPickupArrival);
    case "routing":
      return renderField("Flights", row.routing);
    case "invoiceList":
      return renderField("Invoice#", row.invoiceList);
    case "reference6":
      return renderField("Ref #6", row.reference6);
    case "reference7":
      return renderField("Ref #7", row.reference7);
    case "reference8":
      return renderField("Ref #8", row.reference8);
    case "reference9":
      return renderField("Ref #5", row.reference9);
    case "pickupEmailAddress":
      return renderField("Pu - Email", row.pickupEmailAddress);
    case "pickupPhoneNumber":
      return renderField("Pu - Phone", row.pickupPhoneNumber);
    case "deliveryEmailAddress":
      return renderField("Del - Email", row.deliveryEmailAddress);
    case "deliveryPhoneNumber":
      return renderField("Del - Phone", row.deliveryPhoneNumber);
    case "pickupInstructions":
      return renderField("Pu - Instr", row.pickupInstructions);
    case "deliveryInstructions":
      return renderField("Del - Instr", row.deliveryInstructions);
    case "orderSource":
      return renderField("Order Source", row.orderSource);
    default:
      return null;
  }
};

export default RenderCellData;
