import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { commodity } from "./commodity.reducer";

const useCommodityLogic = () => {
  const dispatch = useDispatch();
  const { commodityLoading, commodityError, commodityData } = useSelector(
    (state: RootState) => state.commodity
  );

  useEffect(() => {
    if (commodityError) {
      // dispatch(showNotification('Account failed: ' + error, 'error'));
    }
  }, [commodityError, dispatch]);

  const handleCommodity = async () => {
    try {
      if (commodityData && commodityData?.data?.data.length > 0) {
        return commodityData;
      } else {
        const response = await dispatch(commodity() as any);
        return response;
      }
    } catch (err) {
      throw err;
    }
  };

  return { commodityLoading, commodityError, commodity: handleCommodity };
};

export default useCommodityLogic;
